:root {
  --bgColor: #29282e;
  --boxShadow: 0px 3px 6px #00000052;
}
@import "../node_modules/react-phone-number-input/style.css";
/* .bodyContainer .web-body{
  background-color: pink;
  } */
@font-face {
  font-family: "AvantGardeBkBt";
  src: url("../src/assets/font/AVGARDN_2.TTF") format("truetype");
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/font/HelveticaNeue/HelveticaNeue-Thin.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/font/HelveticaNeue/HelveticaNeue-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/font/HelveticaNeue/HelveticaNeue.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/font/HelveticaNeue/HelveticaNeue-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/font/HelveticaNeue/HelveticaNeue-Bold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@import url("./webindex.css");

html {
  background: white;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(#c8edee, #daceec);
  background: -moz-linear-gradient(#c8edee, #daceec);
  background: -webkit-linear-gradient(#c8edee, #daceec);
  background: linear-gradient(#c8edee, #daceec);  */
  min-height: 100vh;
}
/* .gradient-bg{
  background: linear-gradient(#c8edee, #daceec);
  background: -moz-linear-gradient(#c8edee, #daceec);
  background: -webkit-linear-gradient(#c8edee, #daceec);
  background: linear-gradient(#c8edee, #daceec);
  height: 100vh;
  max-width: 100%;
} */
.h_n {
  font-family: "HelveticaNeue", sans-serif;
}
.without__b {
  font-size: 1rem;
}
body.modal-open #root {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.searchFlex {
  display: flex;
  margin-top: 12px !important;
}
.form-group.searchgroup {
  width: 100%;
}
.mt-38 {
  margin-top: 38px;
}
.selectfa {
  /* position: absolute;
  color: #fff;
  top: 29%;
  left: 4%; */
  position: absolute;
  left: 2%;
  top: 28%;
  color: #fff;
}
.selectfa1 {
  top: 39%;
}
.form-group.searchParent {
  position: relative;
}
.form-group.searchgroup select {
  width: 96%;
  font-size: 14px;
  padding: 10px 6px;
  border-radius: 6px;
  border: none;
  margin-right: 12px;
  background: #5a7f80;
  color: #fff;
  font-weight: 600;
}

.pad-R0 {
  padding-left: 15px;
  padding-right: 0;
}
.phone-search {
  display: none;
}
.popupscroll {
  overflow-y: auto;
  max-height: 100vh;
}
.pad-L0 {
  padding-right: 15px;
  padding-left: 0;
}
.bg-none {
  background: transparent !important;
}
/* .mob-btnss-div{
  flex-wrap: wrap;
} */
.mob-btnss-div .start-end-datepicker {
  width: calc(35% - 127px);
}
.roundborder {
  border-radius: 14px;
}
::-webkit-scrollbar {
  width: 14px;
  height: 18px;
  border-radius: 10px;
  border: 1px solid #061e1e;
}
::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
.selectDate .modal-header {
  padding-bottom: 0;
}
.selectDate .modal-body .services-offered,
.selectDate .modal-body {
  text-align: center;
}
.my-forms.popupSearch {
  min-width: 100% !important;
}

/* ::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px gray;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  outline: 1px solid rgb(82, 82, 82);
} */
.my-forms::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}
.my-forms:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
.my-forms::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.my-forms:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
ul {
  list-style-type: none;
}
a {
  color: #000;
}
a:hover {
  color: #000;
  text-decoration: none;
}
.img-responsive {
  display: block;
  width: 100%;
  height: auto;
}
.mT-4 {
  margin-top: 4px;
}
.nav-item a {
  cursor: pointer;
}
.client_review {
  overflow: auto;
}
.mobile_trigger {
  display: none;
}
.cm_box {
  padding: 0px 42px;
}
.mobile_trigger .fa-bars {
  font-size: 20px;
  color: #fff;
}
.logo {
  float: left;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  font-weight: 500;
}
.dropdown-menu {
  list-style: none;
  background-color: #a3a3a3e0;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  box-shadow: inset 0px 0px 24px #0000001f;
}
.searchfield {

  /* display: none; */
}
.menu_open .searchfield {
  display: block;
}
.dropdown-item,
.dropdown-item:hover {
  padding: 9px 1.5rem;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  background-color: transparent;
}
.seacrh-mob--hide .fa-close {
  display: none;
}
.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #061e1e;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  transform: translate(-50%, -50%);
}
.times_no > div {
  height: 6em;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-primary {
  background: linear-gradient(#9acddc, #91dcdd);
  padding: 10px 10px;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 4px 5px #b3b1b1;
  border: none;
}
.btn-default {
  background: #b0b0b0;
  padding: 10px 10px;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 4px 5px #b3b1b1;
}
.btn-default:hover {
  color: #fff;
}
.btn-coninue,
.btn-coninue:hover {
  background: #061e1e;
  color: #fff;
}
.btn-edit,
.btn-edit:focus {
  /* background: #fdfd96;
  padding: 5px 23px;
  font-size: 14px;
  color: #040404;
  box-shadow: 0px 4px 5px #b3b1b1;
  border-radius: 50px;
  margin-left: 20px; */
  /*width: 88px;*/
  background: #fdfd9600;
  padding: 6px 12px;
  font-size: 12px;
  color: #040404e3;
  border-radius: 5px;
  margin-left: 20px;
  border: 1px solid #b3b1b1;
  min-width: 104px;
  font-weight: 600;
}

.btn-delete,
.btn-delete:focus {
  /* background: #ff6f6f;
  padding: 5px 23px;
  font-size: 14px;
  color: #040404;
  box-shadow: 0px 4px 5px #b3b1b1;
  border-radius: 50px;
  margin-left: 20px; */
  /*width: 88px;*/
  background: pink;
  padding: 6px 12px;
  font-size: 12px;
  color: #040404e3;
  border-radius: 5px;
  margin-left: 20px;
  min-width: 104px;
  margin-top: 10px;
    font-weight: 600;
}

.light_text {
  color: #061e1e;
  font-size: 20px;
}
/* .sortingdiv{
  margin-right: 9%;
} */
.sortingdiv p {
  border-radius: 8px;
  background: #5a7f80;
  padding: 8px;
}
.sortingdiv p span {
  padding: 12px;
  color: #a0c4c5;
  cursor: pointer;
}
.sortingdiv p span.active {
  color: #fff;
  font-weight: 500;
}
.cm_search {
  padding-left: 64px;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-right: 64px;
}
.cm_sort {
  padding-right: 64px;
  margin-top: 12px;
}
.delete,
.delete:focus {
  background: #feafbc;
}
.MobView {
  display: none;
}

.setting-page-main-mobileView-sidebar .setting-menu-bar {
  display: block !important;
}
.setting-page-main-mobileView-sidebar {
  font-family: "HelveticaNeue", sans-serif;
}

/* ---------------------------navbar style----------------------------- */
ul.navbar-nav.mr-auto.cm-nav {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* .cm-navbar {
  padding: .5rem 1rem ;
  background-image: linear-gradient(#9DC7DC, #9ee5ea);
} */
.img-logo {
  width: 64%;
  height: auto;
}
.cm_navbar-brand,
.cm_navbar-brand:hover {
  font-size: 34px;
  color: #fff;
  font-family: "AvantGardeBkBt";
}
.client_review_second {
  color: #95989a;
}
.login-btn {
  background: #061e1e;
  border-radius: 9px;
  padding: 8px 23px !important;
  color: #fff !important;
  margin-left: 10px;
}
/*--------------inner header-------------*/
.open .dropdown-menu {
  display: block;
}
.cm-navbar {
  padding: 0.5rem 1rem;
  background-image: linear-gradient(#9dc7dc, #9ee5ea);
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 6px #0000005c;
}
.dropdown-img {
  position: relative;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.seacrh-mob--hide {
  width: 99%;
}
/* ----------------------------------left-home------------------------------------ */
.servicePopup {
  padding: 0px;
}
.serviceheader {
  position: relative;
  left: 50%;
  top: 2%;
  transform: translatex(-50%);
  font-size: 22px;
}
.cm_bg {
  background: #fff;
  max-width: 1200px;
  margin: 54px auto;
}
.dashboard {
  padding: 40px 0px;
}

.services-offered table {
  border-collapse: collapse;
  width: 100%;
}
.services-offered tr:first-child {
  border-top: 1px solid #ddd;
}
.services-offered th,
td {
  padding: 16px;
  text-align: left;
  color: #727272;
  border-bottom: 1px solid #ddd;
}
.services-offered .cm_table {
  margin: 40px 16px;
}
.services-offered svg {
  width: 23px;
}
.services-offered h5 {
  padding-left: 14px;
}
.our_team {
  margin: 40px 0px;
}
.team_member {
  display: flex;
  align-items: center;
}
.services-offered tr td:last-child {
  text-align: right;
  cursor: pointer;
  width: 20%;
}
.services-offered tr td:first-child {
  text-align: left;
  width: 40%;
}
.services-offered tr td:nth-child(2),
.services-offered tr td:nth-child(3) {
  width: 20%;
}
.services-offered tr:hover {
  background: #eee;
  cursor: pointer;
}
.round-img p {
  text-align: center;
  margin: 9px 0px;
}
.search-discoveed-reader p {
  font-size: 20px;
  color: var(--bgColor);
  font-weight: 100;
}
.discoverd-modalWrp .cm_hide_mod::before,
.discoverd-modalWrp .cm_hide_mod::after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 23px;
  width: 3px;
  background-color: var(--bgColor);
}
.discoverd-modalWrp .cm_hide_mod::before {
  transform: rotate(45deg);
}
.discoverd-modalWrp .cm_hide_mod::after {
  transform: rotate(-45deg);
}

.circle-img {
  width: 130px;
  height: 130px;
  padding: 8px;
  object-fit: cover;
}
.circle-img {
  border-radius: 100px;
}
.time-deatils {
  padding: 0px !important;
  margin: 20px 0;
}
.leftspan {
  float: left;
  background: white;
  position: relative;
  bottom: 13px;
  padding: 0px 4px 0 0;
}
.rightspan {
  float: right;
  background: #ffffff;
  position: relative;
  bottom: 13px;
  padding: 0px 0px 0 4px;
}
.border-dot {
  background-image: linear-gradient(to right, #888 10%, #fff 0%);
  background-position: top;
  background-size: 15px 2px;
  background-repeat: repeat-x;
  float: left;
  width: 100%;
}
.nodiv {
  background: rgba(230, 230, 230, 0.51);
  display: flex;
  align-items: center !important;
  justify-content: space-around;
  color: #000;
  height: 54px;
}
.payMnt-methdo > .btn {
  width: 29px;
  height: 29px;
  /*background: #000;*/
  padding: 0px;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-reader-modal-view-wrpp {
  padding: 1rem;
}

.nodiv p {
  margin: 0;
}

.circle-img:hover,
.circle-img:active,
img.circle-img.active {
  border-radius: 100px;
  border: 2px solid black;
}
.member_discription {
  text-align: center;
}
.member_discription p {
  padding: 20px 0px;
}
.member_work {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: nowrap;
  overflow-y: auto;
}
.member_work img {
  width: 114px;
  padding: 8px;
  height: 136px;
  object-fit: cover;
}
.react-tabs__tab-list {
  border-bottom: none !important;
  margin: 0 0 0px !important;
  padding: 0 !important;
  white-space: nowrap;
  max-width: 100%;
  overflow-y: auto;
  min-height: 223px;
}
.react-tabs__tab--selected {
  background: transparent !important;
  border-color: transparent !important;
  color: black !important;
  border-radius: 0 !important;
}
.react-tabs__tab {
  display: inline-block;
  border: none !important;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 16px 0px !important;
  cursor: pointer;
}
.react-tabs__tab {
  display: inline-block;
  border: none;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 0px 0px;
  cursor: pointer;
}
.react-tabs__tab:focus {
  box-shadow: none !important;
  border-color: transparent !important;
  outline: none;
}
/* *****************************************
********************************   Setting new pages SANDEEP   ********************************** */
.new-setting-menu-bar .setting-user-Title {
  background: var(--bgColor);
  border-radius: 9px;
  box-shadow: var(--boxShadow);
  padding: 1rem;
}

.new-setting-menu-bar .setting-user-Title h2 {
  color: #fff;
  font-size: 1.875rem;
}
.setting-menu-bar {
  border-radius: 9px;
  overflow: hidden;
  box-shadow: var(--boxShadow);
}
.setting-menu-bar li a {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 1.5rem 0.5rem 1.5rem 1.25rem;
  align-items: center;
}
.setting-menu-bar li a .icon {
}
.setting-menu-bar li a .sTitle {
  width: calc(100% - 45px);
  padding-left: 1.5rem;
  color: var(--bgColor);
}
.setting-menu-bar li a .sTitle .red {
  color: red;
}
.setting-menu-bar li a .sTitle > strong {
  font-size: 1.25rem;
  font-weight: 500;
}
.setting-menu-bar li a .sTitle > .text-sm {
  font-size: 1rem;
  font-weight: 100;
}
.setting-menu-bar {
  padding: 1.5rem 0px;
}
.setting-menu-bar li.active {
}
.setting-menu-bar li a:hover,
.setting-menu-bar li a.active {
  background: var(--bgColor);
}
.setting-menu-bar li a:hover > .sTitle,
.setting-menu-bar li a.active > .sTitle {
  color: #fff;
}
.setting-menu-bar li a:hover .icon > svg path,
.setting-menu-bar li a.active .icon > svg path {
  fill: #fff;
}
.new-setting-wrapper {
  padding: 0px;
}
.new-stng-cmn {
  background: #fff;
  box-shadow: var(--boxShadow);
  border-radius: 9px;
  margin-bottom: 2rem;
  padding: 2.5rem 2rem 2rem;
  margin-bottom: 2rem !important;
}
.setting-detls-img > h5 {
  font-size: 1.875rem;
  margin-bottom: 1rem;
}
.setting-detls-img > p {
  font-size: 1.25rem;
  color: #061e1e;
  font-weight: 100;
  margin-bottom: 1.75rem;
}
.btn-black,
.btn-req {
  background: var(--bgColor);
  border-radius: 9px;
  transition: all 0.25s;
  color: #fff;
  border-color: var(--bgColor);
  padding: 0.85rem 2rem;
  font-size: 20px;
  font-weight: bold;
}
.btn-black:hover {
  color: #061e1e;
  background: transparent;
  border-color: #061e1e;
}
.btn-req {
  background: #c8eeee;
  border-color: #c8eeee;
  color: var(--bgColor);
}
.btn-req:hover {
  background: transparent;
  color: var(--bgColor);
  border-color: #c8eeee;
}

.saloon-settings-wrp h5 {
  font-size: 1.875rem;
  color: #061e1e;
  margin-bottom: 1.5rem;
}
.saloon-settings-wrp .form-group {
  margin-bottom: 1.5rem;
}
.saloon-settings-wrp > .form-group {
  padding-left: 1rem;
}
.saloon-settings-wrp .form-group > label {
  font-size: 1rem;
  font-weight: 500 !important;
  margin-bottom: 2px;
  color: #061e1e;
}
.saloon-settings-wrp .form-control,
.saloon-settings-wrp .address_input_wrap .location-search-input,
.booking-urlpage .clipBorad > .form-control,
.selct-stng__control {
  background: #f7f7f7;
  border: 1px solid #707070 !important;
  border-radius: 9px;
}
.saloon-settings-wrp .form-control,
.saloon-settings-wrp .address_input_wrap .location-search-input,
.selct-stng__control {
  height: calc(3.5em + 0.75rem + 2px);
}
.saloon-settings-wrp .form-control:focus,
.saloon-settings-wrp .address_input_wrap .location-search-input:focus {
  height: calc(3.5em + 0.75rem + 2px) !important;
  border-radius: 9px;
}
.booking-urlpage .clipBorad > .form-control {
  padding: 0.375rem 1.25rem;
}
.btn-inf {
  background: #007ea0;
  color: #fff;
}
.btn-disc,
.btn-inf,
.btn-line-blck {
  padding: 0.75rem 1.75rem;
  border-radius: 9px;
  font-size: 1.25;
  font-weight: bold;
}
.disccover-crd-redr-descr {
  padding: 20px 0px 0px;
}
.disconnects {
  padding-top: 10px;
}
.btn-disc {
  background: #feafbc;
  color: var(--bgColor);
}
.btn-inf:hover {
  background: transparent;
  color: #007ea0;
  border-color: #007ea0;
}
.btn-line-blck {
  border-color: var(--bgColor);
}
.btn-line-blck:hover {
  background: var(--bgColor);
  color: #fff;
}
.para-30 {
  font-size: 1.875rem;
  color: var(--bgColor);
}
.check-wrp-settings {
  line-height: 40px;
}
.check-wrp-settings.check_wraper > span {
  top: calc(50% - 10.5px);
  width: 25px;
  height: 25px;
}
.check-wrp-settings.check_wraper > input {
  top: calc(50% - 10px);
  width: 25px;
  height: 25px;
}
.check-wrp-settings.check_wraper > span::after {
  width: 8px;
  height: 17px;
  left: calc(50% - 4px);
  top: calc(50% - 11px);
}
._booking_setting p{
font-size: 15px;
font-weight: 100;
}
._booking_setting ._row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._booking_setting ._row ._card{
width:50%
}
._booking_setting ._row ._fee{
  width: calc(100% - 50% - 110px);
}
._booking_setting ._row ._icon{
  width: 60px;
  text-align: center;
}
._booking_setting ._row ._icon ._cross {
  width: 28px;
  height: 28px;
  background: #FEAFBC;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: 0 auto;
}
._booking_setting ._row ._icon ._cross svg path {
  fill: #670000;
}
._booking_setting ._row ._card .input_group_u._staff_member .css-1uccc91-singleValue, 
._booking_setting ._row ._card .input_group_u._staff_member .css-1wa3eu0-placeholder{
  font-size: 17px;
  color: #495057c4;
}
._booking_setting ._row .form-control:focus,
._booking_setting ._row .input_group_u .css-yk16xz-control, 
._booking_setting ._row .input_group_u .css-1pahdxg-control{
  height: calc(2.5em + 0.75rem + 2px) !important;
  font-size: 0.9rem;
}

._booking_setting ._row ._icon ._cross svg {
  width: 11px;
  height: 11px;
}
._booking_setting ._row ._fee .form-group.input_group_u._staff_member{
  position: relative;
}
._booking_setting ._row ._fee ._persantge {
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 22px;
  color: #707070;
  transform: translateY(-15%);
}
._booking_setting ._row._add_btn {
  justify-content: flex-start;
  color: #007EA0;
  font-size: 14px;
  font-weight: 500;margin-bottom: 1.5rem;
}
._booking_setting ._row._add_btn span{
    width: 25px;
    height: 25px;
    background: #B1D7E2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-right: 14px;
}
._booking_setting ._row._add_btn span svg {
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
}
._booking_setting ._row._add_btn span svg path{
  fill:#007EA0
}
.check-wrp-settings.check_wraper {
  padding-left: 3.25rem;
}
.fz-20 {
  font-size: 20px;
}
.booking-urlpage .clipBorad > .form-control {
  font-size: 30px;
}
.selct-stng__control {
  border-radius: 9px !important;
}

.booking-urlpage .text-center.link_img h3,
.heading-40 {
  font-size: 40px;
}
.profile-setting-temp .CurrencySelect {
  display: flex;
  align-items: center;
}
.profile-setting-temp .file_upload {
  width: 154px;
  height: 154px;
}
.profile-setting-temp .file_upload i {
  width: 40px;
  height: 40px;
  background: #007ea0;
  border: 0px none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  right: 15px;
}
.desct-accnt-desc > h3 {
  font-size: 30px;
  font-weight: 100;
  color: var(--bgColor);
}
.anctPassword .form-control > span {
  font-size: 60px;
  line-height: 30px;
  display: block;
  padding-top: 1.5rem;
}
.account--password-temp .cm_error_msg:empty {
  display: none;
}
.card-readerWrp img {
  border-radius: 9px;
}
.card-reader-crd-desc > h4 {
  font-size: 30px;
  margin-bottom: 1.25rem;
}
.card-reader-crd-desc > p {
  font-size: 20px;
  margin-bottom: 1.5rem;
  font-weight: 100;
}
.card-reader-crd-desc > h4,
.card-reader-crd-desc > p {
  color: var(--bgColor);
}
.discover-card-reader-lst {
  background: #f7f7f7;
  border-radius: 9px;
  padding: 0px 1rem 1rem;
  border: 3px solid transparent;
  cursor: pointer;
  position: relative;
}
.discover-card-reader-lst:not(:last-child) {
  margin-bottom: 2rem;
}
.discover-card-reader-lst.active-card {
  border-color: var(--bgColor);
}
.tick-Check {
  max-width: 58px;
  position: absolute;
  right: 25px;
  top: calc(50% - 29px);
}
.tick-Check img {
  max-width: 100%;
}
.disccover-crd-redr-img {
  margin-top: -29px;
  transform: rotate(-18deg);
}
.card-discover-top {
  margin-bottom: 3rem;
}
.disccover-crd-redr-descr > h4,
.card-discover-access-code > p {
  font-size: 20px;
}
.disccover-crd-redr-descr > p {
  font-size: 1rem;
  font-weight: 100;
}
.disccover-crd-redr-descr > h4,
.disccover-crd-redr-descr > p,
.card-discover-access-code > p {
  color: var(--bgColor);
}
.card-discover-access-code > p {
  font-weight: 100;
}
.card-discoversDes > h4,
.payout-balance-crd-desc > h4,
.widrawal-history > h3 {
  font-size: 30px;
  color: var(--bgColor);
}
.widrawal-history > h3 {
  color: #061e1e;
  margin-bottom: 2rem;
}
.card-discoversDes > p,
.payout-balance-crd-desc > p {
  font-size: 20px;
  font-weight: 100;
  color: var(--bgColor);
}
.payout-balance-crd-desc > p {
  color: #061e1e;
  margin-bottom: 2rem;
}
.payout-balance-crd-desc > h4 {
  margin-bottom: 1.5rem;
}
.payout-balance-crd {
  /* background: var(--bgColor);
  background-image: url('./assets/images/card-bg.png'); */
  background: url("./assets//images/card-design.png") no-repeat;
  border-radius: 9px;
  color: #fff;
  padding: 1rem;
  position: relative;
  z-index: 9;
  background-position: center;
  background-size: 108%;
}
.payout-balance-crd:after {
  content: "";
  /* position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(41 40 46 / 87%);
  top: 0px;
  left: 0;
  border-radius: 9px;
  z-index: -1; */
}
.payout-balance-crd .btn {
  position: absolute;
  right: -8px;
  top: -5px;
  padding: 0.375rem 0.25rem;
  background: transparent !important;
  box-shadow: initial;
  line-height: normal;
  color: #fff !important;
}
.payout-balance-crd .show.dropdown .dropdown-menu > a {
  border-top: 0px;
}
.payout-balance-crd .dropdown-item:last-child {
  border-bottom: 0px none !important;
}
.payout-balance-crd > .dropdown > .btn:focus {
  box-shadow: initial !important;
  border-color: initial;
  color: #fff;
}
.payout-balance-crd .show.dropdown .dropdown-menu {
  background: #fff;
  box-shadow: initial;
  border-radius: 9px;
}
.payout-balance-crd .show.dropdown .dropdown-menu > a {
  color: #29282e;
  font-weight: 400;
  display: block;
}
.payout-balance-crd .show.dropdown .dropdown-menu > a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.text-del {
  color: #ff0000 !important;
}
.payout-balance-crd .show.dropdown .dropdown-menu > a.text-del {
  color: #ff0000;
}
.payout-balance-crd > .dropdown > .btn::after {
  display: none;
}
.payout-balance-crd > .dropdown > .btn > svg path,
.payout-balance-crd > .btn > svg > path {
  fill: #fff;
}
.balance-crd-top {
  margin-bottom: 5rem;
}
.balance-crd-top > p,
.btm-crd-left > p,
.btm-crd-rgt > p {
  font-size: 26px;
  font-weight: 100;
  margin-bottom: 0px;
}
.balance-crd-top > h4 {
  font-size: 41px;
}
.btm-crd-rgt > p {
  font-weight: 300;
}
.btm-crd-rgt .fa {
  font-size: 16px;
  vertical-align: middle;
}
.btm-crd-rgt .fa:not(:last-child) {
  margin-right: 2px;
}
.widrawal-history .table tr th {
  font-size: 1rem;
  font-weight: 500;
  color: var(--bgColor);
}
.widrawal-history .table tr td {
  font-size: 20px;
  color: #000;
}
.widrawal-history .table tr td.fw-500 {
  font-weight: 500;
  color: var(--bgColor);
}
.widrawal-history .table tr:nth-child(odd) td {
  background: #f7f7f7;
}
.pagination-wrap {
  background: #f7f7f7;
  padding: 1.5rem 0px;
}
.pagination-wrap .pagination {
  justify-content: center;
  margin-bottom: 0px;
}
.pagination-wrap > .pagination > li > a {
  color: #727272;
  display: block;
  width: 29px;
  font-size: 22px;
  height: 29px;
  border-radius: 9px;
  text-align: center;
}
.pagination-wrap > .pagination > li:first-child,
.pagination-wrap > .pagination > li:last-child {
  display: none;
}
.pagination-wrap > .pagination > li.active > a {
  background: #117d9d;
  color: #fff;
}
.widrawal-history .table tr td,
.widrawal-history .table tr th {
  padding: 1.25rem 1.25rem;
  vertical-align: middle;
}
.wd-icon img {
  width: 55px;
}
.addBank-Accnount .btn-inf {
  font-weight: bold;
  padding: 1rem 1.75rem;
}
.text-grey {
  color: #707070;
}
.stripe-blance h4 {
  font-size: 20px;
  color: var(--bgColor);
}
.stripe-blance > p {
  font-size: 30px;
  font-weight: 100;
  color: var(--bgColor);
}
.stripe-balance-history .col-md-4:not(:last-child) {
  border-right: 3px solid #e6e6e6;
}
.stripe-balance-history .col-md-4:nth-child(3) {
  border: 0px none;
}

/* *****************************************
********************************   Setting new pages SANDEEP  END  ********************************** */

/* -------------------------------right-home---------------------------------------- */
.mapouter {
  position: relative;
  text-align: right;
  height: auto;
  width: 100%;
  padding-top: 46px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 10vw;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #888;
  min-height: 228px;
}
.imgheight {
  height: 230px;
  border-radius: 8px;
  object-fit: cover;
}

.discription_text {
  color: #95989a;
  padding: 4px 0px;
}

.opening_time {
  padding: 26px 0px;
}
.opening_time h3 {
  padding: 8px 0px;
  font-weight: 400;
}
.opening_time div {
  padding: 0px 26px;
}
.opening_time div p {
  color: #95989a;
}
.comment {
  margin-top: 36px;
}
.rating-header {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.rating-header > span:nth-child(1) {
  font-size: 50px;
  color: #ab9567;
  font-weight: 500;
  text-shadow: 0px 0px 1px #a2a2a2;
}
.rating-header > span:nth-child(2) {
  /* margin-left: 46px;  */
}
span.Star span {
  color: #ab9567;
  font-size: 30px;
  padding: 0px 6px;
  text-shadow: 0px 2px 4px #a2a2a2;
}
.rating-header > span:nth-child(3) {
  font-size: 30px;
  font-weight: 500;
}
.client_review_first {
  display: flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
}
.push {
  margin-left: auto;
}
.push span.fa.fa-star,
.push span.fa.fa-star-half-o {
  color: #aa9567;
  padding: 0px 2px;
}
.client_review_first > div:nth-child(1) {
  margin-right: 12px;
}
.client_review_first > div:nth-child(2) > span:nth-child(1) {
  color: black;
  font-weight: 600;
  font-size: 22px;
}
.client_review_first > div:nth-child(3) {
}
.client_review_first > div:nth-child(3) > span:nth-child(1) {
  font-size: 16px;
  color: red;
}
/* -----------------------------------lgoin popup------------------------------------ */
.modealPrev i {
  float: left;
  color: #000;
  font-size: 36px;
  font-weight: bold;
  cursor: pointer;
}
button.close {
  opacity: 1 !important;
  font-size: 46px !important;
}

.popup-content,
.modal-content {
  border-radius: 20px;
  box-shadow: 0px 0px 8px #5d5d5d;
  border-color: #707070 !important;
  background: rgba(255, 255, 255, 0.81);
  padding-top: 8px;
  padding-bottom: 8px;
}
.popupLogo {
  width: 170px;
}
.loginPopup {
  max-width: 400px;
  margin: 0 auto;
}
.login_h3 {
  margin-bottom: 20px;
}
.loginPopup h5 {
  padding: 5px 0px;
}
.myprofile_h2 {
  padding: 20px 26px;
}
.loginPopup p {
  color: #061e1e;
  text-align: justify;
  text-align-last: center;
  font-weight: 600;
  font-size: 18px;
  padding: 30px 0px;
  white-space: normal;
}

.btns_padding {
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
}

.facebook-btn {
  background: #3b5998;
  color: #fff;
  padding: 8px 12px;
  font-size: 22px;
}
.facebook-btn:hover {
  color: #fff;
}

.facebook-btn img {
  width: 25px;
}

.gmail-btn {
  background: linear-gradient(
    180deg,
    rgba(154, 202, 220, 1) 0%,
    rgba(154, 204, 220, 1) 0%,
    rgba(154, 204, 220, 1) 0%,
    rgba(146, 219, 221, 0.9360119047619048) 100%
  );
  padding: 8px 10px;
  font-size: 22px;
  color: #fff;
}
.gmail-btn:hover {
  color: #fff;
}

.cm_discript {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.form-control,
.form-control:focus {
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
}
/* -----------------------------------booking page------------------------------------ */
.team-round-img {
  position: absolute;
  left: 50%;
  top: 76%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.btnsml-screen button.btn.btn-sm.btn-edit.blue_btn:focus {
  background: #007EA0;
  border-color: #007EA0;
}
.cm_team-round-img {
  width: 140px;
  height: 140px;
  border-radius: 100px;
  position: relative;
}
.account_img {
  margin: 30px 0px;
  position: relative;
}
.editmyaccount-content {
  width: 46% !important;
  background: #fff !important;
}
.account-table span {
  text-decoration: underline;
}
.cm_form,
.cm_form:focus {
  background: #fafafa;
  border-style: none !important;
  border-bottom: 2px solid #888 !important;
  outline: none !important;
  height: auto;
  border-radius: 0px !important;
  border-radius: 0px;
}
/* .cm_form:focus{
  background: #ffffff;
  border-style: none !important;
  border-bottom: 2px solid #888  !important;
  outline: none !important;
  height: auto;
  border-radius: 0px;
  padding: 0px;
} */
.circleform,
.circleform:hover,
.circleform:focus {
  border-radius: 100px;
  padding-left: 30px;
}
.save-btn {
  background: #9ee4ea;
}
/* -----------------------------------booking confirm popup------------------------------------ */
.booking_bg {
  background: #fff;
  border-radius: 12px;
}
.booking_dateinfo {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 4px;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
}
.review_star span {
  color: #aa9567;
  padding: 0px 3px;
  font-size: 30px;
}
.Stylist-detail {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.profile_img {
  margin: 20px 20px 20px 6px;
}
.Stylist-detail .profile_img img {
  width: 100px;
  height: 100px;
}
.profile-info h5,
.profile-info h6,
.profile-info p {
  margin-bottom: 0px;
}
.confrim-msz {
  padding: 10px 20px;
}
.img-14round {
  border-radius: 14px;
}
.image-gallery-thumbnail-inner img {
  object-fit: cover;
  width: 90px;
  height: 100px;
}
/* -----------------------------------dashboard------------------------------------ */

.contentBg {
  background: #fff;
  /* display: flex;
  justify-content: space-between; */
  border-radius: 12px;
  padding: 12px;
  margin: 0px 20px;
  padding: 12px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 7px #bbb;
}
ul.sidenavbar {
  list-style-type: none;
  background: #8aa9ae;
  padding: 12px;
  margin: 0px 20px;
  border-radius: 21px;
}
ul.sidenavbar li {
  display: block;
}
ul.sidenavbar li {
  display: block;
  color: #b8d7dc;
  padding: 0px 16px;
  text-decoration: none;
  font-size: 20px;
}

/* Change the link color on hover */
ul.sidenavbar li:hover,
ul.sidenavbar li:active,
ul.sidenavbar li .active {
  background-color: transparent;
  color: white;
}
ul.sidenavbar li:after {
  border: none !important;
  background: transparent !important;
}
.booking-footer {
  display: flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  justify-content: space-between;
  margin: 14px 0px;
  align-items: flex-end;
}
.booking-footer div p {
  margin-bottom: 0rem;
}
.cm_bookin_deatils {
  padding: 5px 28px 30px;
}
.booking_tab {
  padding-bottom: 30px;
  padding-top: 0px;
}
.mT80 {
  margin-top: 85px;
}
/*-----------------------------------dashboard review ---------------------- */
.push.star-size span {
  font-size: 26px;
}
.review-section {
  padding: 12px;
}
.review-btn {
  float: right;
}
/* ------------------------------dashboard footer------------------------  */
footer {
  background: #8ea3ad;
  display: flex;
  justify-content: flex-end;
}
.footer-section {
  min-height: calc(100vh - 79px);
}
.social-icon a i {
  font-size: 38px;
  padding: 10px;
  color: #fff;
}
.social-icon {
  margin: 10px 20px 10px 0px;
}
/* ---------------------------------image modal popup------------------------------- */
.popup-content.image_modal-content {
  background: transparent !important;
  box-shadow: none;
  border: none !important;
  width: 34% !important;
}
.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
  color: transparent;
  line-height: 0;
  text-shadow: none;
  transition: none;
}
.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before {
  font-size: 0;
  padding: 0;
  text-shadow: none;
}
.image-gallery-thumbnails {
  margin-top: 14px;
}
.image-gallery-slide {
  background: transparent;
}
.image-gallery-slide img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
.image_modal-overlay button.close {
  color: #fff;
  font-size: 54px;
  font-weight: 100;
  position: absolute;
  top: 22px;
  left: -31px;
}
.image-gallery-thumbnail img {
  border-radius: 6px;
  box-shadow: 0px 0px 8px #000;
}
.image-gallery-thumbnail.active {
  border: none;
}
.image-gallery-thumbnail.active img {
  box-shadow: none !important;
}
.image-gallery-thumbnails {
  overflow-x: auto;
  width: 100%;
}
/* .image-gallery-thumbnails-container{
  overflow-y: auto;
} */
/* ---------------------------------calender and time slot------------------------------- */
.cm_pos_relative {
  position: relative;
}
/* .cm_calendar {
  position: absolute;
  bottom: 4px;
  transform: translateY(-50%);
  z-index: 9999;
  right: 154%;
} */

/* .cm_calendar {
  position: absolute;
  bottom: -219px;
  transform: translateY(-50%);
  z-index: 9999;
  right: 26%;
  min-height: 600px;
  background: #DCDCDC;
} */

.cursor-pointer{
  cursor: pointer !important;
}
.modal-body.calender {
  padding: 4px;
}
.react-calendar__month-view__days {
  display: flex;
  overflow: hidden;
}
.cm_calendar .modal-body {
  padding-top: 0rem !important;
}
.modal-body {
  padding: 0.8rem;
}
.cm_calendar .month {
  text-align: center !important;
  font-weight: 700;
  padding-bottom: 12px;
  text-transform: capitalize;
}
.add-service {
  padding: 0px 10px;
}
span.addservices {
  width: 20px;
  height: 20px;
  position: absolute;
}

/* .react-calendar button abbr {
  border: 1px solid red;
  background: pink;
  border-radius: 40px;
} */
.react-calendar__month-view__days {
  display: block;
  white-space: nowrap !important;
  overflow-x: auto;
  flex-wrap: nowrap !important;
}
.member_work img {
  border-radius: 14px;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth,
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend,
button.react-calendar__tile.react-calendar__month-view__days__day {
  width: 50px !important;
  max-width: 50px !important;
  overflow: initial !important;
  border: 1px solid #061e1e;
  background: transparent;
  border-radius: 100%;
  margin: 0px 9.5px;
  min-width: 44px;
  min-height: 44px;
  color: #061e1e;
  line-height: 1;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center
  strong {
  position: absolute;
  top: -68%;
}
.react-calendar__tile--active {
  background: #006edc;
  color: #061e1e;
}
.react-calendar__month-view__days {
  padding: 12px 0px;
}
button.react-calendar__navigation__label {
  font-weight: 600;
  color: #061e1e;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday {
  color: #061e1e;
}
.react-calendar {
  width: 100% !important;
  background: transparent !important;
  border: none !important;
}
.timeslot {
  min-height: 200px;
}
.pad-0 {
  padding-left: 0;
  padding-right: 0;
}
.timeslot-date {
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #d4d4d4;
}

.timeslot-date h5 {
  color: #061e1e;
  text-align: center;
  font-size: 14px;
}
.timeslot ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0px;
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 295px;
}
li.cal-active {
  background: #061e1e !important;
  color: #91dedd !important;
  position: relative;
}
li.selected-time:after {
  content: "";
  position: absolute;
  height: 5px;
  border-top: 2px solid #061e1e;
  width: 27px;
  left: -19%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.timeslot ul li,
.timeslot ul li a {
  font-size: 14px;
  background: #91dedd;
  color: #061e1e;
  width: 52px;
  border-radius: 8px;
  margin: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  position: relative;
}
.timeslot ul li.active,
.timeslot ul li.active a {
  background: #061e1e;
  color: #91dedd;
}
table.confirm-book {
  width: 100%;
}
table.confirm-book tr td:first-child {
  width: 40%;
  text-align: center;
}
table.confirm-book tr {
  font-weight: 500;
}
table.confirm-book tr td {
  font-size: 16px;
  color: #061e1e;
  width: 24%;
  text-align: center;
}
.pad-14 {
  padding: 14px 0px;
}
.height80vh {
  max-height: 80vh;
}
.booknow-btn {
  background: #061e1e;
  color: #fff;
  margin: 20px;
  width: 80%;
}
.booknow-btn {
  color: #fff !important;
}

.cm_loader {
  position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    justify-content: center;
    align-items: center;
}
.inputLoader {
  position: relative;
  padding-right: 25px;
}
.lds-spinner.Inpldr {
  position: absolute;
  right: 4px;
  top: -9px;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 27px;
  height: 27px;
}
.card-discoversDes .lds-spinner,
.lds-spinner.Inpldr {
  width: 27px;
  height: 27px;
  transform: scale(0.35);
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--bgColor);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.stats.seatIcn p span {
  background: #000;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.stats.seatIcn p span svg {
  fill: #fff;
  width: 38px;
}
.apply-coupon-wrp {
  display: flex;
}
.apply-coupon-wrp .btn {
  width: 40%;
  margin-left: 10px;
}
.review-circle-img {
  border-radius: 100px;
  width: 60px;
  height: 60px;
}
.beuSprt > p {
  font-size: 1rem;
  font-weight: 100;
  color: var(--bgColor);
}
.cm_bookin_deatils span {
  padding: 0px 6px;
}
.cm_bookin_deatils .active {
  border-bottom: 3px solid;
  padding-bottom: 8px;
}
.notfound {
  /* width: 100%;
  line-height: 1.4;
  text-align: center;
  margin-top: 100px; */
  width: 100%;
  line-height: 1.4;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.notfound .notfound-404 {
  position: relative;
  height: 240px;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  /* color: #262626; */
  color: #789eaf;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0px 0px #fff;
}

.notfound .notfound-404 h3 {
  font-family: "Cabin", sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notfound h2 {
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 50px;
  margin-bottom: 25px;
}
.accountleft p {
  padding: 12px;
}
.border-right {
  border-right: 1px solid #7a7a7a !important;
}
.cm_error_msg,
.account-table span .cm_error_msg {
  color: red;
  font-size: 14px;
  margin-left: 4px;
  /* padding-bottom: 10px; */
  text-decoration: none !important;
}
.form-control:focus {
  border-color: transparent;
  outline: none;
  box-shadow: none;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
  width: 48px !important;
  height: 44px !important;
  max-width: 50px !important;
  overflow: initial !important;
  border: 1px solid #91dedd;
  background: #91dedd;
  border-radius: 100%;
  margin: 0px 9.5px;
  min-width: 44px;
  min-height: 44px;
  color: #061e1e;
  line-height: 1;
  margin: 0px 0px 0px 0px;
}

.rwdp-DayCircle.rwdp-DayCircle-selected.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
  background: #061e1e;
  color: #91dedd;
}
/* ----       for diabled-------- */
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.gray {
  background: #95989a;
  color: #0b0b0b;
  border-color: #95989a;
}
.gray-holiday {
  background: #95989a;
  color: #0b0b0b;
  border-color: #95989a;
  width: 50px !important;
  height: 50px !important;
  max-width: 50px !important;
  overflow: initial !important;
  border: 1px solid #061e1e;
  background: transparent;
  border-radius: 100%;
  margin: 0px 9.5px;
  min-width: 44px;
  min-height: 44px;
  color: #061e1e;
  line-height: 1;
  margin: 0px 0px 0px 0px;
}
.skyblue-holiday {
  background: #95989a;
  color: #0b0b0b;
  border-color: #95989a;
}

.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.skyblue {
  background: #91dedd;
  border-color: #91dedd;
  color: #061e1e;
}

.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.White {
  background: transparent;
  border-color: #061e1e;
  color: #000;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.pink {
  background: #a9a8a8;
  border-color: #a9a8a8;
  color: #000;
  margin-top: -16px;
  width: 54px !important;
  height: 54px !important;
  border: 2px solid #a9a8a8;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.pink:active {
  background: #061e1e;
  border-color: #061e1e;
  color: #91dedd;
}
.pink {
  background: pink;
  border-color: pink;
  color: #000;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.darkblue {
  background: #061e1e;
  border-color: #061e1e;
  color: #91dedd;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.red {
  background: red;
  border-color: red;
  color: #91dedd;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.darkblue
  strong {
  color: #061e1e;
}
.rwdp-DayCircle-container.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.rwdp-DayCircle-unavailable-text {
  display: none;
}

.rwdp-DayCircle-today {
  display: none;
}
.rwdpNextWeekArrow {
  border-left: 20px solid #061e1e !important;
  right: 0;
}
.rwdpPrevWeekArrow {
  border-right: 20px solid #061e1e !important;
  left: 0;
}
.cm_table .fa-check-circle {
  font-size: 23px;
  color: #061e1e;
}
.rwdpDayBoxDesktop .rwdp-DayCircle-container:focus {
  outline: none !important;
}
.rwdp-DayCircle.rwdp-DayCircle-selected.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center
  strong {
  color: #061e1e;
}
.phn-name {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal-body .table-responsive.cm_table {
  margin: 2px 0px 12px;
}
/* .modal-body .services-offered tr td:first-child {
  text-align: left;
  width: 70%;
} */
/* ------------------------------------------profile----------------------- */
.suggestion-item,
.suggestion-item--active {
  display: block !important;
  width: 100% !important;
  position: static !important;
  z-index: 99 !important;
  color: #fff !important;
  overflow-wrap: break-word !important;
  font-size: 14px !important;
  padding: 10px 0 !important;
  background: #7c999a !important;
  border-bottom: 1px solid #b1b1b1 !important;
}

.suggestion-item:last-child,
.suggestion-item--active:last-child {
  border-bottom: none !important;
  border-radius: 0px 0px 6px 6px !important;
}

.suggestion-item:first-child,
.suggestion-item--active:first-child {
  border-top: 1px solid #fff !important;
  border-radius: 6px 6px 0px 0px !important;
}

.pad0 {
  padding: 0px;
}
.account-table table {
  width: 100%;
}
.account-table table tr td:first-child {
  border-right: 1px solid #7b7b7b;
}

.account-table table tr td {
  width: 50%;
  text-align: left;
  border-bottom: none;
  padding: 19px 0;
  padding-left: 40px;
}
.width-auto {
  width: auto !important;
}
/* ------------------------------------------Home page----------------------- */
.cm_bg_home {
  background: #00000000 !important;
  max-width: 1600px !important;
  margin: 20px auto;
}
.home,
.home-search {
  padding-left: 12px;
  margin-left: 50px;
  padding-right: 12px;
  margin-right: 50px;
}
.homeimge-height {
  cursor: pointer;
  height: 315px;
  object-fit: cover;
}

.autocomplete-dropdown-container {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 99;
  background: #597f80 !important;
  color: #fff;
  overflow-wrap: break-word;
}
button:focus {
  outline: none;
}
.hairdreser_img {
  width: 100%;
  min-height: 300px;
  border-radius: 14px;
  box-shadow: 0px 0px 5px #7f7f7f;
}
.home-box {
  background: #fff;
  border-radius: 14px;
  box-shadow: 0px 0px 6px #888;
  padding: 12px;
  margin-top: 20px;
  margin-bottom: 42px;
  min-height: 564px;
  cursor: pointer;
}
.home.home-box:hover {
  box-shadow: 0px 0px 30px #6b6b6b;
}
.homerating {
  padding: 0px 8px;
  margin-bottom: 12px;
}
.home-box img.img-fluid,
.home-box img.img-thumbnail {
  max-width: 100%;
  height: auto;
  min-width: 100%;
  border-radius: 14px;
}
.p12 {
  padding: 12px 12px;
}
.homeimge-height {
  cursor: pointer;
  height: 315px !important;
  object-fit: cover;
}
.homerating > span:nth-child(1) {
  font-size: 24px;
}
.homerating > span:nth-child(2) {
  margin-left: 8px;
}
.homerating span.Star span {
  font-size: 20px;
  padding: 2px 4px;
}
.homerating > span:nth-child(3) {
  font-size: 20px;
}
.photobox {
  display: flex;
  flex-flow: row;
  overflow: auto;
  max-width: 324px;
  position: relative;
  bottom: -8px;
}
.photobox img {
  width: 80px;
  height: 100px;
  padding: 8px 4px;
  border-radius: 14px;
  object-fit: cover;
}
.flex-container {
  display: flex;
  align-items: flex-end;
}
.view_service_btn {
  /* background: linear-gradient(#9acddc, #91dcdd); */
  background: #29282e;
  color:#fff;
  border-radius: 60px;
  font-size: 16px;
  padding: 4px 26px 0 11px;
  border: none;
  position: relative;
  font-weight: 400;
  min-width: 160px;
  text-align: right;
  padding-right: 40px;
  font-weight: 500;
}
.view_service_btn .fa-angle-right {
  font-size: 32px;
  position: absolute;
  right: 4%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.top_home {
  display: flex;
  /* margin-top:42px; */
  justify-content: space-between;
}
.p-rel {
  position: relative;
}
.my-forms {
  font-size: 14px;
  padding: 10px 6px;
  border-radius: 6px;
  border: none;
  margin-right: 12px;
  background: #5a7f80;
  color: #fff;
  min-width: 160px;
  max-width: 160px;
  margin-top: 12px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-weight: 600;
}
.btn.btn-xs.searchbtn {
  background: #5a7f80;
  border-radius: 6px;
  color: #fff;
}
ul.react-tabs__tab-list.booking {
  min-height: auto !important;
  overflow: hidden;
}
ul.booking .react-tabs__tab {
  color: #888;
  font-weight: 400;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
ul.booking .react-tabs__tab:not(:last-child) {
  margin-right: 20px;
}
li.react-tabs__tab.react-tabs__tab--selected {
  color: #3c3c3c !important;
  font-weight: 500;
}
.react-tabs__tab--selected:after {
  content: "";
  position: absolute;
  height: 3px;
  left: -4px;
  right: -4px;
  top: 50px;
  background: #3c3c3c;
}
.payment-form > .plan-package-parent > .plan-package:not(.selected) {
  color: rgb(41 40 46 / 53%);
}
.paymentFrm-label .form-label {
  z-index: -1;
}
.react-tabs .react-tabs__tab:focus:after {
  background: #3c3c3c !important;
}
.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: transparent !important;
}
.react-tabs__tab:focus {
  background: transparent !important;
}
.uplod_fafa {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 20px;
  background: #040404;
  padding: 10px;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
}
.cm_calendar button.close {
  font-size: 31px;
  padding: 0px 14px;
}
.cursorP {
  cursor: pointer;
}
.services-offered tr td:first-child {
  font-weight: 500;
}
.blure_bg {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

.algnC {
  align-items: center;
}
.algnCc {
  margin-top: -9px;
}
.booking_confirm h4 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
}
.booking_confirm h5 {
  font-size: 15px;
  text-align: right;
}
.colgray {
  color: #888;
}
.cm_txtmsz {
  font-size: 13px !important;
  padding: 5px !important;
  margin-bottom: 0px;
}
.view_service_btn {
  /* padding: 0px 40px 3px 18px; */
  min-width: 121px;
        padding-right: 23px;
        padding: 10px !important;
        text-align: center;
        border-radius: 5px;
        line-height: 1.5;
        font-weight: 600;
        font-size: 14px;
}
.view_service_btn p {
  margin-bottom: 0;
}
.hideBigscreen {
  display: none;
}

.cm_min_card_content_height {
  min-height: 88px;
}

.cm_min_slide {
  position: relative;
  min-height: 118px;
}

.cm_view_service_btn {
  position: absolute;
  right: 12px;
  bottom: 0;
}

span.cm_sort_i,
span.cm_sort_i span {
  padding: 0;
}

span.cm_sort_i img {
  width: 22px;
}
/* ---------------------------------------------------------------new booking start----------------------------- */
.body_container {
  /* min-height: 100vh; */
  position: relative;
  padding: 22px 1.5rem 22px calc(250px + 1.5rem);
}
.body_container.cm_bg_white.diary-module-wrp {
  padding: 22px 0.25rem 22px calc(250px + 1.5rem);
}
.nav_menu_open .body_container.cm_bg_white.diary-module-wrp {
  padding: 22px 0.25rem 22px calc(60px + 1.5rem);
}
.sidenav {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  width: 250px;
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  background: #29282e;
  padding: 10px;
  z-index: 999;
  padding-top: 80px;
}

.sidenav ul li {
  margin-bottom: 30px;
}

.sidenav ul li a {
  display: block;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
}
.sidenav ul li a .menu_icon > svg {
  fill: #fff;
}
.sidenav ul li a .menu_icon.trncIcon > svg #Path_1539 path:nth-child(2),
.sidenav ul li a .menu_icon.trncIcon > svg #Path_1540 path:nth-child(2) {
  fill: #fff;
}
.sidenav ul li a.active,
.sidenav ul li a:hover {
  background: #fff;
  color: #29282e;
}
.sidenav ul li a.active .menu_icon > svg,
.sidenav ul li a:hover .menu_icon > svg,
.sidenav ul li a.active .menu_icon.trncIcon > svg #Path_1539 path:nth-child(2),
.sidenav ul li a:hover .menu_icon.trncIcon > svg #Path_1539 path:nth-child(2),
.sidenav ul li a.active .menu_icon.trncIcon > svg #Path_1540 path:nth-child(2),
.sidenav ul li a:hover .menu_icon.trncIcon > svg #Path_1540 path:nth-child(2) {
  fill: #29282e;
}
.sidenav ul li a:hover .menu_icon._checkout > svg path,
.sidenav ul li a.active .menu_icon._checkout > svg path{
  fill: #29282e;
}
.sidenav ul li a:hover  svg path#Icon_open-bullhorn{
  fill: #29282e !important;
}

.sidenav ul li a.active .menu_icon._markt svg path {
  fill: #000;
}

/* .sidenava.menu-show-mobile ul li a.active .menu_icon._markt svg path {
  fill: #000 !important;
} */

.setting-page-main-mobileView-sidebar.padding-40{
  padding-top:45px;
}
.menu_icon img {
  width: 24px;
}

.sidenav_text {
  margin-left: 20px;
}

.menu_toogle_icon {
  position: absolute;
  top: 30px;
  left: 19px;
  cursor: pointer;
}

.menu_toogle_icon img {
  width: 24px;
}

.nav_menu_open .sidenav {
  width: 60px;
}
.sidenav ul {
  padding-inline-start: 0;
}
.nav_menu_open .sidenav_text {
  display: none !important;
}

.nav_menu_open .sidenav ul li a {
  width: 46px;
  border-radius: 9px;
}

.nav_menu_open .body_container {
  padding: 22px 1.5rem 22px calc(60px + 1.5rem);
}
.diary-module-wrp {
}

.top_header {
  padding: 0.8rem 2.2rem;
  width: calc(100% - 250px);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
  background: #f7f7f7;
  text-align: center;
  /* min-height: 70px; */
  min-height: 73px;
  /* box-shadow: 0px 0px 6px #0000005c; */
}

.nav_menu_open .top_header ,
.nav_menu_open .body_bg_lightGray .top_header{
  width: calc(100% - 60px);
}

.top_header h1 {
  font-size: 30px;
  font-weight: 400;
}
.svg_sidenav svg {
  width: 20px;
  height: 20px;
}

/* --sticky button-------- */
.add-newbooking {
  background-color: #061e1e;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: relative;
  box-shadow: 0px 0px 11px #666666;
}
#addbutton {
  position: fixed;
  bottom: 22px;
  right: 22px;
  z-index: 9999;
}
.add-newbooking svg {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* ---------------booking-------------- */
.booking-parent {
  padding: 3rem 0rem 1rem 3rem;
}
.new-booking-tab .nav-item.nav-link.active {
  background-color: transparent;
  border: none;
}
/* .view-booking-parent {
  display: flex;
  justify-content: center;
  padding: 3rem 0rem 1rem 0rem;
} */

.new-booking-tab .nav-item {
  font-size: 24px;
  color: #000;
  margin-bottom: 30px;
  position: relative;
  font-weight: 300;
}

.new-booking-tab .nav-item.active {
  color: #000;
  font-weight: 600;
}

.new-booking-tab .nav-item.active:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 9px;
  height: 4px;
  background: #bfe4ea;
  left: 0;
}

.bg_white {
  background: white;
  min-height: calc(100vh - 68px);
}

.new-booking-tab {
  border-bottom: 0;
}

.cm_picker_wrap {
  position: relative;
}

.cm_picker_wrap .react-datepicker__input-container {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.cm_picker_wrap .react-datepicker__input-container input {
  height: 100%;
  cursor: pointer;
}

.cm_picker_wrap > p {
  margin: 0;
  font-size: 20px;
}

.cm_picker_wrap .react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0;
}

.booking_form_wrapper {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.31);
  border-radius: 9px;
  padding: 30px;
}

.cm_row > div {
  display: inline-block;
  vertical-align: top;
}

.cm_25 {
  width: 150px;
  margin-right: 10px;
}

.cm_75 {
  width: calc(100% - 181px);
}

.cm_select {
  box-shadow: none;
  width: 100%;
  text-align: left;
  background: transparent;
  color: #000;
  border: 1px solid #b0b3b5 !important;
  font-size: 21px;
  position: relative;
}

.cm_select:after {
  position: absolute;
  right: 15px;
  top: 24px;
}

.btn-primary:focus,
.cm_select:hover {
  color: #000;
  background-color: transparent;
}

.cm_select:not(:disabled):not(.disabled).active,
.cm_select:not(:disabled):not(.disabled):active,
.show > .cm_select.dropdown-toggle {
  color: #000;
  background-color: transparent;
}

.cm_select_wrapper .dropdown-menu {
  background: #fff;
  border: 1px solid #b0b3b5 !important;
  width: 100%;
  box-shadow: none;
  border-radius: 6px;
  padding: 0;
}
.cm_select_wrapper:not(.cm-selectNt-relative) .dropdown-menu {
  position: relative !important;
  transform: none !important;
  right: 0 !important;
  top: 5px !important;
}

ul.cm_select_ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.cm_select_ul li {
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
}

ul.cm_select_ul li:first-child {
  border-top: 1px solid rgba(112, 112, 112, 0.5);
}

ul.cm_select_ul li a {
  color: #727272;
  font-weight: 400;
}

ul.cm_select_ul li a:hover {
  color: #000;
}

.cm_select_wrapper .dropdown-menu h4 {
  font-size: 21px;
  padding: 16px;
}

.tab_content_wrapper {
  display: inline-block;
  max-width: 60%;
  width: 100%;
}

.cm_right_panal {
  display: inline-block;
  width: calc(40% - 30px);
  margin-left: 20px;
  vertical-align: top;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.31);
  border-radius: 9px;
  padding: 30px;
  margin-bottom: 274px;
  position: relative;
}

ul.buttons_border_wrap {
  position: absolute;
  margin: 0 -30px;
  top: calc(100% + 90px);
  padding: 0;
  list-style: none;
  border: 1px solid #061e1e;
  border-radius: 9px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: #fff;
  width: 103%;
}

ul.buttons_border_wrap.withcheckoutbtndropdown {
  top: calc(100% + 145px);
}
ul.buttons_border_wrap li {
  cursor: pointer;
  padding: 10px 15px;
  font-size: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  text-align: center;
}

ul.buttons_border_wrap li:first-child {
  border: 0 !important;
}

.cm_red_c {
  color: #ff002a;
}

.not_working {
  position: absolute;
  width: calc(100% + 26px);
  height: 30px;
  left: -26px;
  top: -15px;
  background: rgba(0, 0, 0, 0.1);
}

.salonName {
  font-weight: 500;
  font-size: 20px;
}

.cm_search_wrapper {
  margin-top: 20px;
  position: relative;
}

.cm_search_wrapper i {
  position: absolute;
  right: 20px;
  top: 18px;
}

.cm_bao {
  background: #bfe4ea;
  margin: -23px;
  margin-bottom: 15px;
  border-radius: 10px 10px 0 0;
  position: relative;
  padding: 15px;
  padding-right: 95px;
}
.clnt-ckout-head {
  margin: -30px;
  margin-bottom: 15px;
}

.cm_bao img {
  position: absolute;
  right: 35px;
  top: 15px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.cm_bao .dropdown {
  position: absolute;
  right: 15px;
  top: 15px;
}

.cm_bao .dropdown-toggle {
  background: transparent;
  box-shadow: none;
  color: #000;
  padding: 0;
}

.cm_bao .dropdown-toggle::after {
  display: none;
}

.cm_bao .dropdown-menu {
  left: auto !important;
  /* right: 0 !important; */
  right: 11px !important;
  transform: none !important;
  /* top: 40px !important; */
  top: 0px !important;
  box-shadow: 0px 0px 4px #23232370;
}
.cm_bao .btn-primary:not(:disabled):not(.disabled).active:focus,
.cm_bao .btn-primary:not(:disabled):not(.disabled):active:focus,
.cm_bao
  .show
  > .btn-primary.dropdown-toggle:focus
  .cm_bao
  .btn-primary:not(:disabled):not(.disabled).active,
.cm_bao .btn-primary:not(:disabled):not(.disabled):active,
.cm_bao .show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.cm_bao h3 {
  font-size: 22px;
}

.cm_bao p {
  margin: 0;
}

.cm_right_panal .tab-content {
  margin-left: -30px;
  margin-right: -30px;
}

.cm_right_panal .card-header {
  padding: 0;
  background-color: transparent;
}

.cm_right_panal .btn-link {
  font-weight: 400;
  color: #000000;
  text-decoration: none;
}

.cm_right_panal .card-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.card-body ul li {
  border-bottom: 1px solid #e8e8e8;
  padding: 5px 15px;
}

.accordion {
  margin-bottom: 50px;
}

.accordion .card-body {
  padding: 0;
}

.card-body ul li h4,
.card-body ul li p {
  margin: 0;
}

.cm_bg_uniq {
  background: #d6d5ec;
  padding: 5px 14px;
  margin: -5px;
  margin-left: -15px;
  margin-right: 6px;
  min-width: 60px;
}

.cm_bg_uniq_dark {
  background: #e3beeb;
}

.cm_btns.m_revers.withcheckoutbtn {
  top: 150px;
}
.m_revers {
  position: relative;
  top: 96px;
  margin: -30px;
}

.cm_btns .btn-dark {
  padding: 10px 10px;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 4px 5px #b3b1b1;
  border: none;
}

.payment_detail {
  text-align: right;
}

.cm_ammount {
  margin-bottom: 30px;
  margin-top: 30px;
}

.cm_ammount p {
  margin-bottom: 5px;
}

.cm_ammount h4 {
  border: 1px solid #061e1e;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

.cm_popup .modal-content {
  background: #fff !important;
}

.cm_popup .modal-header {
  background: #bfe4ea;
  margin-top: -8px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 20px;
}

.cm_staff_catd {
  background: #f7f7f7;
  border-radius: 9px 9px 0 0;
  position: relative;
  padding: 19px 15px;
  padding-right: 50px;
  cursor: pointer;
}

.cm_staff_catd img {
  position: absolute;
  right: 14px;
  top: 11px;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.cm_staff_catd h3 {
  font-size: 20px;
  margin: 0;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: 300;
}

.cm_staff_catd.active {
  background: linear-gradient(#000, #000);
  color: #fff;
  font-weight: 600;
}
.cm_staff_catd.active h3 {
  font-weight: 500;
}

.cm_over_auto {
  overflow: auto;
  position: absolute;
  width: 100%;
  top: -69px;
}

.cm_box_view {
  padding: 15px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.31);
  position: relative;
  margin-top: 100px;
  padding-top: 40px;
  border-radius: 9px;
}

.srv_search {
  max-width: 320px;
  margin-bottom: 36px;
}

.srv_search .form-control {
  min-width: 320px !important;
}

.srvc_table {
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.cm_tab_row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #dadada;
  margin: 0 -15px;
  cursor: pointer;
}

span.cm_b_span {
  display: block;
  height: 100%;
}

.cm_tab_row.selected {
  background: #fff;
  cursor: default;
}
.cm_main_col {
  width: 50%;
  position: relative;
  padding: 20px;
  padding-left: 70px;
}

.cm_main_col_2,
.cm_main_col_3 {
  width: 25%;
  text-align: center;
  padding: 5px;
}
.cm_main_col img {
  position: absolute;
  left: 14px;
  top: 11px;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.cm_main_col p {
  margin: 0;
  cursor: pointer;
}

.cm_table_input {
  width: 110px;
  margin: 0 auto;
}

.cm_main_col_3 {
  position: relative;
  padding-right: 30px;
}

.cm_main_col_3 i {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  cursor: pointer;
}
.container.addservice .cm_main_col_3 i{
  right:11px
}

.cross_wrap {
  padding-right: 30px;
}

.cross_wrap i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.seetings_wrap_box {
  padding: 0 15px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.31);
  position: relative;
}

.border_right {
  border-right: 1px solid rgba(114, 114, 114, 0.5);
}

.file_upload_wrap {
  position: relative;
  text-align: center;
  background: #f7f7f7;
  border-radius: 9px;
}

.file_upload_wrap input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
}

.file_upload_wrap img {
  width: 100%;
  max-height: 320px;
  object-fit: cover;
  border-radius: 9px;
}

.seetings_wrap_box .col-md-6 {
  padding: 15px 4rem;
}

.link_img img {
  max-height: 270px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.link_img h3 {
  font-weight: 400;
  margin-bottom: 25px;
  margin-top: 6px;
}

.text-center.link_img h3 {
  font-weight: 400;
  margin-bottom: 25px;
  margin-top: 6px;
}
.thank-you-modall-wrps-overlay > .thank-you-modall-wrps-content {
  max-width: 500px;
  width: 100% !important;
}

.check_wraper {
  position: relative;
  padding-left: 30px;
}

.check_wraper > span {
  position: absolute;
  width: 21px;
  height: 21px;
  border: 2px solid #061e1e;
  border-radius: 3px;
  left: 0;
  top: 0;
}

.check_wraper > input {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}

.check_wraper > span:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 12px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  left: 5px;
  top: 1px;
  transform: rotate(45deg);
  opacity: 0;
}

.check_wraper > input:checked + span:after {
  opacity: 1;
}
.check_wraper > input:checked + span {
  background: #061e1e;
}

.same_box {
  margin-bottom: 45px;
}

.same_box img {
  max-width: 100%;
}

.form-group.position-relative .form-control {
  padding-left: 50px;
}

img.input__icon {
  position: absolute;
  width: 30px;
  top: 44px;
  left: 10px;
}

.same_box h3 {
  font-weight: 400;
  margin: 30px 0;
}

.hairdress_modal {
  max-width: 100%;
  margin: 0;
}

/* staff style new */
.staff-modal-wrapper {
  padding: 2.5rem 3rem 1rem 5rem;
  color: #29282e;
  font-family: "HelveticaNeue", sans-serif;
}
.add-staff-modal-wrp .modal-content {
  background: #fff !important;
}
.staff-top-mdl > h1 {
  font-size: 3.35rem;
  font-weight: 500;
  color: #007ea0;
  margin-bottom: 1rem;
}
.staff-modal-wrap-left > h3 {
  color: #29282e;
  font-size: 1.875rem;
  margin-bottom: 1.9rem;
  font-weight: 400;
}
.staff-modal-wrap-left > p {
  font-size: 1.25rem;
  color: #29282e;
  font-weight: 100;
  padding-right: 3rem;
  line-height: 35px;
}
.emlpyed-bx label {
  position: relative;
}
.emlpyed-bx label > input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  position: absolute;
  display: none;
}
.epluyed-with-radio:before,
.epluyed-with-radio:after {
  content: "";
  position: absolute;
  top: calc(1rem + 8px);
  width: 30px;
  height: 30px;
  left: 20px;
  border-radius: 50%;
}
.epluyed-with-radio {
  padding: 1rem 1rem 1rem 4rem;
  cursor: pointer;
  border: 1px solid #707070;
  border-radius: 9px;
  position: relative;
  transition: all 0.2s;
}
.emlpyed-bx {
  margin-bottom: 2.5rem;
}
.emlpyed-bx:first-child {
  margin-bottom: 3rem;
}
.staff-emplyed-plan {
  padding-top: 0.25rem;
}
.staff-btn-btm > .btn {
  font-size: 1.4rem;
}
.epluyed-with-radio-body > p {
  margin-bottom: 0.65rem;
}
.epluyed-with-radio-topbar {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.epluyed-with-radio-topbar > h3 {
  margin-bottom: 0px;
  font-size: 1.875rem;
  font-weight: normal;
}
.epluyed-with-radio-topbar > span {
  margin-left: auto;
  font-size: 1.875rem;
  font-weight: 100;
}
.activation-code-wrp > span {
  color: #007ea0;
  font-size: 1.313rem;
  font-weight: 100;
  cursor: pointer;
}
.epluyed-with-radio:before {
  border: 2px solid #007ea0;
}
.emlpyed-bx label > input[type="radio"]:checked ~ .epluyed-with-radio:after {
  background: #007ea0;
  transform: scale(0.5);
}
.emlpyed-bx label > input[type="radio"]:checked ~ .epluyed-with-radio {
  border: 4px solid #007ea0;
  box-shadow: 3px 10px 10px #00000050;
}
.epluyed-with-radio-body {
  font-size: 1rem;
  font-weight: 100;
  color: #29282e;
}
.add-acvt-input {
  position: relative;
  width: calc(100% - 125px);
  flex: 0 0 auto;
}
.add-acvt-input > span,
.applyCode > span {
  position: absolute;
  padding: 0.25rem;
  right: 10px;
  top: calc(50% - 16px);
  cursor: pointer;
}
.applyCode > span {
  top: calc(50% - 19px);
}
.applyCode {
  position: relative;
  background: #e6e6e6;
  border-radius: 9px;
  color: #007ea0;
  padding: 0.5rem 1rem;
  text-align: left;
  font-size: 1.25rem;
  font-weight: 100;
}
.applyCode > span {
  color: #000000;
}
.apply-activation-code > p {
  color: #007ea0;
  font-weight: 100;
}
.codeErr {
  color: #d80000;
  font-size: 1rem;
  text-align: right;
  width: 100%;
  font-weight: 100;
  flex: 0 0 auto;
  padding-top: 5px;
}
.add-acivation-field > .btn {
  margin-left: auto;
  font-size: 1.25rem;
  border-radius: 9px;
  padding: 0.375rem 1.25rem;
}
.add-acivation-field {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.staff-emplyed-plan > .table tr td,
.staff-emplyed-plan > .table tr th {
  padding: 0.25rem;
  font-size: 1.25rem;
  color: #29282e;
}
.staff-modal-wrapper .row > .col-lg-7 {
  flex: 0 0 54.333333%;
  max-width: 54.333333%;
}
.staff-modal-wrapper .row > .col-lg-5 {
  flex: 0 0 45.666667%;
  max-width: 45.666667%;
}
.staff-emplyed-plan > .table tr th {
  font-weight: 500;
}
.staff-emplyed-plan > .table tr td {
  font-weight: 100;
}
.staff-emplyed-plan > .table tr td.code-aply > strike {
  font-size: 1rem;
}
.staff-btn-btm > p {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 100;
}
.add-acvt-input > input,
.add-acvt-input > input::placeholder {
  color: #007ea0 !important;
}
.staff-mebers-img > img {
  max-width: 170px;
  width: 100%;
}
.staff-mebers-img.employedImge > img {
  max-width: 288px;
}
.staff-mebers-content > h3 {
  font-size: 1.875rem;
}
.staff-mebers-content > p {
  font-size: 1.25rem;
  color: #29282e;
  font-weight: 100;
}
.staff-memb-colmn {
  background: #f7f7f7;
  border-radius: 9px;
  padding: 0.5rem 1.25rem;
}
.staff-memb-colmn > h4 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.staff-memb-colmn > p {
  font-size: 1.25rem;
  font-weight: 100;
  margin-bottom: 0.15rem;
}
.staff-top-mdl svg {
  width: 124px;
  height: 152px;
  fill: #007ea0;
}
.staff-modal-wrap-left {
  position: relative;
  /* padding-top: 4.9rem; */
  padding-right: 2rem;
  margin-top: 1.5rem;
}
.icon-weather {
  /* position: absolute;
  right: 0px;
  top: 0px; */
}
.staff-top-mdl {
  display: inline-flex;
  align-items: baseline;
}
.thank-you-modall-wrps-overlay > .thank-you-modall-wrps-content {
  max-width: 500px;
  width: 100% !important;
}
.add-staff-modal-wrp .modal-xl {
  max-width: 1350px;
  width: 100%;
}
.staff-page-temp .nav-item.nav-link {
  color: #061e1e;
  font-weight: 100;
  text-align: left !important;
}
.manage-Staffs-members {
  font-family: "HelveticaNeue", sans-serif;
}
/* staff style end new */

.hairdress_modal .modal-content {
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 !important;
}

.hairdress_modal .modal-header {
  border: 0;
}

.modal_header {
  background: #f7f7f7;
  padding: 15px;
  position: relative;
}

.hairdress_modal_body {
  max-width: 900px;
  margin: 15px auto;
  width: 100%;
  padding: 40px 15px;
  background: #fff;
  border-radius: 9px;
}

.check_wrap {
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.check_wrap span {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 3px;
  border: 2px solid #061e1e;
  border-radius: 3px;
}

.check_wrap input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}

.check_wrap span:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 12px;
  border-bottom: 2px solid #061e1e;
  border-right: 2px solid #061e1e;
  transform: rotate(45deg);
  left: 4px;
  top: 0px;
  opacity: 0;
}

.check_wrap input:checked + span:after {
  opacity: 1;
}

.custom__control {
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
}

span.custom__indicator-separator {
  display: none !important;
}

.hairdress_modal_body .form-group {
  margin-bottom: 28px;
}

.cm_close {
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
}

.stap_wrapper {
  text-align: center;
  padding-top: 300px;
  position: relative;
  min-height: 500px;
}

.stap_wrapper img {
  width: auto;
  position: absolute;
  top: 0;
  left: 50%;
  height: 280px;
  transform: translateX(-50%);
  max-width: 100%;
  object-fit: cover;
}

.stap-action {
  text-align: center;
}

.stap-action .btn {
  padding-left: 35px;
  padding-right: 35px;
  font-weight: 600;
}

.stap-indicate {
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  list-style: none;
  text-align: center;
}

.stap-indicate li {
  width: 18px;
  height: 18px;
  background: rgba(114, 114, 114, 0.25);
  display: inline-block;
  margin: 10px;
  border-radius: 100%;
  cursor: pointer;
}

.stap-indicate li.active {
  background: #c4ddea;
}

.go-through-modal .modal-content {
  background: #fff !important;
}

.go-through-modal .modal-header {
  border-bottom: 0;
}

.hairdress_login,
.modal-content {
  border-radius: 9px;
}

.cm_check_list {
  margin: 0;
  padding: 0;
  display: inline-block;
  text-align: left;
  list-style: none;
}

.cm_check_list li {
  font-size: 13px;
  position: relative;
  padding-left: 30px;
}

.cm_check_list li:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 10px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  left: 10px;
  top: 4px;
  transform: rotate(35deg);
}

.auth_wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  overflow: auto;
  max-height: 100vh;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
}

.btn-dark {
  background-color: #061e1e;
  border-color: #061e1e;
}
.btn-dark-blue,
.btn-dark-blue:active,
.btn-dark-blue:hover {
  background-color: #007ea0;
  border-color: #007ea0;
  color: #fff;
}
.btn-border,
.btn-border:active,
.btn-border:hover {
  background-color: rgba(255, 255, 255, 0);
  border-color: #000;
  color: #000;
}
.go-through-modal.auth_wrapper .modal-body {
  max-width: 600px;
  margin: 50px auto;
  background: #fff;
  border-radius: 9px;
  padding: 30px;
  width: 100%;
}
.start-end-datepicker {
  width: 100%;
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.start-end-datepicker .form-group.mb-0.sm_date_picker {
  width: calc(100% / 2);
  height: 100% !important;
}
.start-end-datepicker .sm_date_picker input {
  width: 100%;
  border: none !important;
  border-radius: 0 !important;
  height: calc(2em + 0.75rem + 2px);
  margin: 4px 0;
}
.start-end-datepicker .sm_date_picker input {
}
.start-end-datepicker .dash {
  font-size: 20px;
  font-weight: 500;
  color: #061e1e;
}
.start-end-datepicker .sm_date_picker input:focus {
  border: 1px solid #b0b3b500 !important;
  box-shadow: none !important;
}

.sm_date_picker .react-datepicker-wrapper {
  width: 140px;
  display: block;
}

.full_date_picker .react-datepicker-wrapper {
  display: block;
}

.full_date_picker input,
.sm_date_picker input {
  width: 100%;
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
}

.full_date_picker input:focus,
.sm_date_picker input:focus {
  border: 1px solid #b0b3b5 !important;
  box-shadow: none !important;
}

.add_break_btn {
  text-align: center;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  margin: 15px -15px;
  padding: 15px;
  cursor: pointer;
}

.add_break_btn svg {
  width: 15px;
  margin-right: 20px;
}

.add_break_btn svg rect {
  fill: #000;
}

.break_list > p {
  position: relative;
  margin-top: 15px;
  margin: 0;
}

.break_list > p i {
  position: absolute;
  right: 15px;
  top: 2px;
}

.break_list {
  border-top: 1px solid #707070;
  margin: 15px -15px;
  padding: 15px;
}

.join_for_free .facebook-btn {
  background: #061e1e;
  padding: 0px 30px;
  display: inline-block;
  width: auto;
}

.join_for_free .gmail-btn {
  background: #dff1f4;
  padding: 12px 30px;
  color: #061e1e;
  box-shadow: none;
  width: auto;
  display: inline-block;
}

.join_for_free .gmail-btn,
.join_for_free .facebook-btn {
  min-width: 380px;
}

input.react-autosuggest__input {
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
  width: 100%;
}
.analytics-tabsHead--wrap > .react-tabs__tab-list {
}
.analytics-tabsHead--wrap > .react-tabs__tab-list .circle-img:hover {
  border: 0px none;
}
.analytics-tabsHead--wrap
  > .react-tabs__tab-list
  .react-tabs__tab:first-child
  .circle-img {
  background: #a8c5d0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.analytics-search-bar .form-control {
  height: calc(2em + 0.75rem + 4px) !important;
}
.filter-box.dropdown-toggle.btn.btn-primary {
  background: transparent;
  color: #888;
  border: 1px solid #b0b3b5 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  box-shadow: 0 0 0;
  border-radius: 10px;
}
.filter .dropdown-menu a {
  padding: 15px 18px 12px;
  color: #29282eab;
  border: none;
}
.filter .dropdown-menu a .selected {
  background: #c4dceaa3;
  border-radius: 9px;
}
.filter .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000064;
  border-radius: 9px;
  color: #29282e;
  min-width: 325px;
  min-height: 195px;
  padding: 12px 18px;
  padding-left: 42px;
}
.filter-search-parent {
  display: flex;
  width: 97%;
}
.filter {
  width: 50px;
}
.cm_search_wrapper.client_search.m-0.analytics-search-bar {
  width: calc(35% - 80px);
  display: flex;
}
.cm_search_wrapper.analytics-search-bar i {
  top: calc(50% - 8px);
}
.multiselect-native-select select {
  display: none;
}
/* /.filter .css-1pahdxg-control */
button.multiselect.dropdown-toggle.btn.btn-default span,
.filter .css-1hb7zxy-IndicatorsContainer,
.filter .css-1rhbuit-multiValue {
  display: none;
}
.css-1rhbuit-multiValue {
  /* display: none; */
  background-color: transparent !important;
}
.filter .css-1pahdxg-control {
  width: 50px !important;
  height: 48px !important;
  overflow: none;
  outline: none;
  border-radius: 9px;
  border: 1px solid #b0b3b5 !important;
  box-shadow: 0 0 0;
}
.filter .css-1pahdxg-control:hover,
.filter .css-1pahdxg-control:active,
.filter .css-1pahdxg-control:focus {
  border: 1px solid #b0b3b5 !important;
  box-shadow: 0 0 0;
}
.filter .css-1wa3eu0-placeholder,
.filter .css-2b097c-container spann {
  color: hsl(0deg 0% 100% / 0%) !important;
  width: 50px;
  z-index: 99;
}
.filter .css-yk16xz-control {
  position: relative;
  width: 50px;
  border-radius: 10px;
  height: 48px;
  text-align: center;
}
button.multiselect.dropdown-toggle.btn.btn-default:after,
.filter .css-yk16xz-control:after,
.filter .css-1pahdxg-control:after {
  content: "";
  font-size: 0px;
  background: url("./assets//images/filter-results-button.svg") no-repeat;
  color: #fff;
  padding: 0.7rem;
  background-size: 100%;
  background-position: center;
  background-position: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
}
.filter.openSelect .css-1pahdxg-control:after {
  /* transform: rotate(180deg) !important; */
  transform: translate(-50%, -50%);
  /* display: none; */
}
.filter .css-yt9ioa-option .select-first-input,
.filter .css-26l3qy-menu .select-first-input {
  font-weight: bold !important;
}

.filter .css-1okebmr-indicatorSeparator {
  width: 0;
}
.filter svg.css-19bqh2r {
  display: none;
}
.filter .css-26l3qy-menu {
  min-width: 300px;
  box-shadow: 0px 3px 6px #00000064;
  border-radius: 9px;
  left: -42px;
}
.css-b8ldur-Input {
  visibility: hidden;
  /* display: none; */
}
.filter .css-yt9ioa-option,
.client_filter .css-yt9ioa-option {
  background-color: transparent;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}
.css-4ljt47-MenuList {
  padding: 8px 20px;
}
.client_filter .css-4ljt47-MenuList {
  padding: 2px 2px;
}
.filter .css-yt9ioa-option label,
.filter .css-9gakcf-option label,
.filter .css-1n7v3ny-option label,
.filter .css-9gakcf-option label,
.client_filter .css-yt9ioa-option label,
.client_filter .css-9gakcf-option label,
.client_filter .css-1n7v3ny-option label,
.client_filter .css-9gakcf-option label {
  margin-bottom: 0;
  font-weight: 400 !important;
  margin-left: 20px;
  width: calc(100% - 45px);
}
.filter .css-4ljt47-MenuList div:first-child .css-9gakcf-option label,
.client_filter .css-4ljt47-MenuList div:first-child .css-9gakcf-option label {
  font-weight: 600 !important;
}
.filter .css-9gakcf-option:active,
.filter .css-1n7v3ny-option,
.filter .css-9gakcf-option {
  background-color: #007ea021 !important;
  color: #000;
  border-radius: 9px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 2px;
}
.client_filter {
  position: relative;
}
.selected_count {
  position: absolute;
  top: 15px;
  left: 15px;
  font-weight: 500;
}
.client_filter .css-9gakcf-option:active,
.client_filter .css-1n7v3ny-option,
.client_filter .css-9gakcf-option {
  background-color: #ffffff00 !important;
  color: #000;
  display: flex;
  align-items: center;
}
.client_filter .css-9gakcf-option:active,
.client_filter .css-1n7v3ny-option,
.client_filter .css-9gakcf-option {
  padding: 15px 20px !important;
}
.filter
  span.multiselect-native-select
  .btn-group
  button.multiselect.dropdown-toggle.btn.btn-default {
  background: transparent;
  color: #888;
  border: 1px solid #b0b3b5 !important;
  font-size: 0rem;
  font-weight: 400;
  line-height: 1.5;
  box-shadow: 0 0 0;
  border-radius: 10px;
  width: 50px;
}
.filter .dropdown-menu li,
.client_filter .dropdown-menu li {
  padding: 8px;
}
.filter .checkbox,
.client_filter .checkbox {
  position: relative;
  font-weight: 400 !important;
}
.filter input[type="checkbox"],
.client_filter input[type="checkbox"] {
  width: 23px;
  height: 23px;
  accent-color: #007ea0;

  /* margin-right: 20px; */
  border-radius: 13px;
}
.client_filter
  .css-1hb7zxy-IndicatorsContainer
  .css-tlfecz-indicatorContainer:first-child,
.client_filter .css-1rhbuit-multiValue {
  display: none;
}
.client_filter .css-yk16xz-control {
  /* width: 200px; */
  border-radius: 10px;
  height: 54px;
}

.client_filter .css-1pahdxg-control,
.client_filter .css-yk16xz-controll {
  width: 200px !important;
  height: 54px !important;
  overflow: none;
  outline: none;
  border-radius: 9px;
  border: 1px solid #b0b3b5 !important;
  box-shadow: 0 0 0;
}
.client_filter .css-1okebmr-indicatorSeparator {
  width: 0;
}
.client_top_container_first,
.client_top_container_last {
  width: calc(100% / 2);
}
.client_top_container_first,
.client_top_container_last {
  display: flex;
}
/* .client_filter.closeSelect, .client_top_container_first ,.export_btn_parent{
  margin-right: 10px;
} */
.client_top_container .client_filter.closeSelect,
.client_top_container .client_filter.openSelect,
.client_top_container .cm_search_wrapper.client_search,
.client_top_container .export_btn_parent {
  width: 22%;
}

.select__service .black_heading .product {
  padding: 15px 10px;
}
.select__service .black_heading h5 {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 17px;
}
.select__service .black_heading h3 {
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
  margin-left: 17px;
}
.select__service .black_heading .table td span{
  font-size: 14px;
  margin-left: 17px;
}
.service_checkout_datepicker .react-datepicker-wrapper { width: 98%; }
.service_checkout_datepicker .react-datepicker__input-container .__datepicker {
   width: 100%;  border: 0px;
   /* min-height: 64px; */
   min-height: 56px;
   position: relative;
   z-index: 9;
   background: #00800000;
   cursor: pointer;
   }
.react-datepicker__input-container { 

  width: 100%; }
  ._datepickr .react-datepicker-wrapper{
    position: relative;
  }
  input.__datepicker {
    color: #ffffff00;
}
.show__date { 
  position: absolute; 
  top: 38%; 
  left: 30px; 
  transform: translateY(-50%); 
  z-index: 1;
  width: calc(100% - 80px );
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}
.service_flex_div.new__edit_page .show__date {
  top: 30%;
}
.booking-parent.view-booking-parent .new-invoice-parent {
  align-items: center;
}
.new-invoice-parent.__new_checkout .cm_right_panal {
    margin-bottom: 0;
}

.navbar__space{
  margin-left: 24px ;
  margin-right: 22px
}
.service_flex__space {
  margin-left: 49px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,0);
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0,0,0,.05);
}
.topbar_btn_group {
  position: absolute;
  right: 27px !important;
  top: 18px !important;
}
.topbar_btn_group .btn {
  box-shadow: 0px 2px 5px #a2a2a2;
}
.topbar_btn_group .btn-white {
  padding: 4.5px 4.5px 4.5px 11px;
}
.disabled_div {
  background: #F7F7F7 !important;
  cursor: not-allowed;border: none !important;
}
.service_selected1.border-1.disabled_div{
  border: none;
}
.booking-parent.booking_space__fix {
  padding-left: 34px;
}
.service_flex_div.new__checkout_page {
  margin-left: 32px;
}
.input_group_u.border_blue .css-yk16xz-control, 
.input_group_u.border_blue .css-1pahdxg-control,
.__service .service_selected1.border_blue {
  border-color: #007EA0;
}
.input_group_u._staff_member .css-1uccc91-singleValue ,
.input_group_u._staff_member .css-1wa3eu0-placeholder{
  font-size: 22px;color: hsl(0,0%,20%)
}
.service_flex_div.service_flex_div.service_flex__space .service_selected1 {
  max-height: 58px;
}
.service_flex_div.service_flex_div .input_group_u .css-1uccc91-singleValue ,
.show__date
.input_group_u._staff_member .css-1wa3eu0-placeholder{
  font-size: 22px;
}
.complete__btn{
    box-shadow: none !important;
    background: #e5919100 !important;
    padding:20px 0 !important;
}
.input_group_u.__Client.hide__arrow .service_selected1, 
.input_group_u.__service.hide__arrow .service_selected1 
{
  background-image: none !important;
}
._staff_member .css-tlfecz-indicatorContainer ,
._staff_member .css-1gtu0rj-indicatorContainer {
  padding: 0;
}
._staff_member .css-6q0nyr-Svg {
  width: 24px;
  height: 24px;
  line-height: 2;
  padding: 0;
}
._staff_member .css-6q0nyr-Svg path {
  fill: #000;
}
/* .service_select.___service.__newservice {
  position: relative;
  width: 100%;

}
.service_select.___service.__newservice:after{
  content: '';
  background-image: url("./assets/images/down-arrow-icon1.png") !important;
  background-position: calc(100% - 8px) center !important;
  -webkit-background-size: 29px !important;
  -moz-background-size: 29px !important;
  background-size: 29px !important;
  background-repeat: no-repeat !important;
  position: absolute;
  top:40px;
  width: 50px;
} */
.service_select.___service,
.input_group_u.__service .service_selected1,
.__Client .service_selected1{
  background-image: url("./assets/images/down-arrow-icon1.png") !important;
  background-position: calc(100% - 8px) center !important;
  -webkit-background-size: 29px !important;
  -moz-background-size: 29px !important;
  background-size: 29px !important;
  background-repeat: no-repeat !important;
  }
  .fix_time_box .__time .start_time1 {
    background: #ffc0cb00;font-weight: 400;
    font-size: 17px;
}

.body_bg_lightGray .input_group_u.__service .service_selected1,
.body_bg_lightGray .input_group_u.__Client .service_selected1,
.body_bg_lightGray .input_group_u._staff_member .service_selected1,.currency_sml1{
  font-size: 22px;
}
.body_bg_lightGray .input_group_u.__Client .service_selected1{
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.booking-parent.booking_space__fix .cm_select {
  margin-top: 2px;
  border-radius: 6px;
}
.booking-parent.booking_space__fix .cm_select::after {
  background-image: url("./assets/images/down-arrow-icon1.png") !important;
  background-position: calc(100% - 1px) center !important;
    background-size: 29px !important;
    background-repeat: no-repeat !important;
    border: none;
    width: 30px;
    height: 30px;
    right: 6px;
    top: 10px;
}
.booking-parent.booking_space__fix .form-control:focus {
  height: calc(2.5em + 0.75rem + 2px) !important;
}
.dropdown-menu.__open_dropdown {
  background-color: #ffffffe0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  box-shadow: 0px 0px 24px #0000001f;
}
.dropdown-menu.__open_dropdown .dropdown-item:last-child,
.dropdown-menu.__open_dropdown .dropdown-item:last-child:hover {
  border-bottom: none;
}
.dropdown-menu.__open_dropdown .dropdown-item, .dropdown-menu.__open_dropdown .dropdown-item:hover {
  font-weight: 400;
  border-bottom: 1px solid #00000024;
  color: #000;
  cursor: pointer;
}
.toggle_arrow {
  width: 26px;
  height: 26px;
  margin-left: 3px;
}
.toggle_arrow.up{
  transform: rotate(180deg);
}
.react-tabs__tab--selected .toptab._black_tab {
  background: linear-gradient(90deg, #29282e, #29282e);
  -webkit-background: linear-gradient(90deg, #29282e, #29282e);
  color: #fff;font-weight: 600;
}
.react-tabs__tab--selected .toptab._black_tab h6{
  color: #fff;font-weight: 600;
}
.analytics-tabsHead--wrap > .react-tabs__tab-list .react-tabs__tab:first-child .circle-img{
  background: #ffffff;
}
.sidebar_tog_icon_open svg path {
  fill: #fff;
}

.more__option ,.more__option:hover,.more__option:active{
  padding: 4.5px 4.5px 4.5px 11px;
  box-shadow: 0 2px 5px #a2a2a2 !important;
  background: linear-gradient(#fff, #fff);
  border-color: #fff;
  color: #000 !important;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.more__option_menu.dropdown-menu.show {
  background-color: hsla(0,0%,100%,.8784313725490196);
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 6px;
  box-shadow: 0 0 24px rgba(0,0,0,.12156862745098039);
  padding: 0;
}
.more__option_menu.dropdown-menu .dropdown-item {
  font-weight: 400;
  border-bottom: 1px solid rgba(0,0,0,.1411764705882353);
  color: #000;
  cursor: pointer;
}
.show.dropdown .more__option .toggle_arrow.down{
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
button.more__option.dropdown-toggle::after{
  display: none;
}
.tooltip_parent._edit_staff_tooltip span.info {
  top: 0px !important;
  right: calc( 100% - 200px) !important;
}
.tooltip_parent._edit_staff_tooltip .tooltip-body {
  top: 2px !important;
  right:calc( 100% - 333px) !important;
  width: 320px;
}
.cancel__mobile{display: none;}
.service_topbar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
}
.service_topbar .booking__interval label {
  font-size: 15px;
  margin-bottom: 2px;
  font-weight: 500 !important;
}
.srv_search .form-control:focus ,
.__service_edit_modal .form-control:focus{
  height: calc(2.5em + 0.75rem + 2px)!important;;
  /* height: auto !important; */
}
.form-group._modal_booking label {
  font-weight: 600;
}
.form-group._modal_booking {
  width: 275px;
  margin: 0 auto;
  text-align: left;
}
.form-group._modal_booking .cm_error_msg {
  font-size: 12px;
  font-weight: 100;
}
.__service_edit_modal .stap-action button {
  min-width: 190px;
  padding: .65rem .75rem;
}
.__service_edit_modal .stap-action button.btn.btn-dark {
  background: #29282E;
}
._add_service_container {
  display: flex;
}
._add_service_box.form-group {
  width: calc(100% / 5 - 30px);
  margin: 0 15px;
}
.show__color {
  width: 33px;
  height: 33px;
  border: 1px solid #29282E;
  border-radius: 9px;
}
/* input[type="color"] {
  width: 100px;
  height: 100px;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  outline: none;
}
.color-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  background-color: transparent;
} */
.service_topbar .booking__interval p.form-control {
  line-height: 2.5;
}
.dairy-container-header.__new_one .top_header {
  box-shadow: none !important;
}
.checkout__save_btn_mobile{display: none;}
/* .cm_picker_wrap.__event .react-datepicker-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
} */
.btn.btn-blue.loder_start {
  border-radius: 100px;margin: 0 auto;
  transition: all 1s ease;
}
.loder_start .spinner-border.spinner-border-sm {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.btn.btn-blue.cancel_start {
  transition: all 1s ease;
  width: 100%;
  background: #707070;
  border-color: #707070;
  border-radius: 0.25rem;
  /* transition: transform 0.2s ease-in-out 0s; */
}

.__newclipBorad {
  width: 100%;
  height: auto;
  background: #E8E8E8;
  border-radius: 9px;
  display: flex;
}
.__newclipBorad .btn {
  width: 146px;
  outline: none;
  border: none;
  border-radius: 9px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 800;
}
.__newclipBorad .btn.btn-info {
  background: #007EA0;
}
.__newclipBorad .btn.btn-black,.__newclipBorad .btn.btn-black:hover {
  color: #fff;
  padding: .375rem .75rem;
  background: #061e1e;
  border-color: #061e1e;
  font-size: 1rem;
}
.booking-urlpage .clipBorad.__newclipBorad input.form-control {
  background: #f7f7f700 !important;
  border: none !important;
  border-radius: 0 !important;padding-right: 11px;
  
}
.booking-urlpage .clipBorad > .form-control {
  font-size: 21px;
}
.btn-info:focus,.btn:focus{
  box-shadow: 0 0 0 0rem rgba(58,176,195,.5);

}
/* /---change by radha---- */
.duration__input{
  background:#f7f7f700; 
  width:90%;
  border:0px;text-align: center;outline: none
}
.toast-body-notify {
  z-index: 9999;
}
.salon_switch_container {
  display: flex;
  align-items: center;padding-left: 1rem;
}
.salon_switch_container .switch{margin-bottom: 0;}
.salon_switch_container .switch .slider{background-color:#ccc}
.salon_switch_container .switch input:checked + .slider {
  background-color: #007ea0;
}
.salon_switch_container p {
  font-weight: 300;
  font-size: 15px;margin-bottom: 2px;margin-left: 40px;
}
.salon_switch_container .switch {
    width: 56px;
    height: 25px;
}
.salon_switch_container .slider:before {
  height: 19px;
    width: 20px;
    left: 3px;
    bottom: 3px;
}
.salon_switch_container._header_toggle{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.salon_switch_container._header_toggle_mobile {
  margin: 20px 0 12px 0;
  width: 100%;
  justify-content: center;
}
.salon_switch_container._header_toggle p,
.salon_switch_container._header_toggle_mobile p{
  font-weight: 400;
}
.proceesing_input{
      border: 1px solid #d0d0d0;
      width: 67px;
      cursor: auto;
      text-align: center;
      border-radius: 8px;
      padding: 2px;
      margin-right: 12px;
  }
/* -------------marketing css start--------- */
.__markt {
  width: 228px;
  min-height: 160px;
  background: #FFFFFF;
  border: 1px solid #BABABA;
  border-radius: 13px;
  padding: 12px;
  margin: 0 0 20px 20px;
}
._service_parent_body._marketting_parent {
  height: auto;
  padding-bottom: 0;
}
#_sales .__markt .cursor-pointer p {
  height: 94px;
}
#_updates .__markt .cursor-pointer p {
  height: 66px;
  /* background: pink; */
}
#_custom .__markt .cursor-pointer p,
#_reminders .__markt .cursor-pointer p{
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
#_custom .__markt._create_new .cursor-pointer p,
#_custom .__markt._create_new .cursor-pointer p{
height: auto;
}

._markt_body{
  display: flex;
  flex-wrap: wrap;margin-left: -20px
}
.__markt p {
  font-size: 16px;
  font-weight: 100;
}
.__markt ._bottom{
  display:flex;justify-content: space-between;
  margin-top: 43px;
}
.__markt ._bottom ._left{
  display:flex;
}
.__markt ._bottom ._left p{
  cursor: pointer;line-height: 1

}
.__markt ._bottom ._left p.disabled svg path:last-child{
  fill: #cccccc;
}
.__markt ._bottom ._left p.disabled svg circle:last-child,
.__markt ._bottom ._left p.disabled:last-child svg g,
.__markt ._bottom ._left p.disabled:last-child svg g line{
stroke: #cccccc;
}

.__markt._create_new {
  display: flex;
  align-items: center;
  justify-content: center;
}
._new {
  text-align: center;
}
._new p {
  font-weight: 400;
}
._new svg{width: 40px;
    height: 40px;
    background: #007f9f;
    border-radius: 100%;
}
._new svg g:first-child g {
  fill: #007f9f;
}
._new svg g:nth-child(2) g {
  fill: #fff;
}
._reminder_section {
  width: 700px;
  margin: 0 auto;
}
._sms_reminder {display: flex;}
._sms_reminder ._image {
  width: 40%;
  /* background: pink; */
}
._sms_reminder ._text {
  width: calc(100% - 40% );
  padding-left: 25px;
}
._sms_reminder ._text ._head {
  justify-content: space-between;
  align-items: center;
}
._sms_reminder ._text ._head h4 ,._remind h5{
  font-weight: 400;
  font-size: 23px;
  margin-bottom: 0;
}
._sms_reminder ._text p:nth-child(3),
._remind p{
  font-weight: 100
}
._remind .input_group_u._staff_member .service_selected1 ,
._remind .input_group_u._staff_member .css-yk16xz-control{
  border: 1px solid #7070707a !important;
  font-size: 15px;
}
._remind .input_group_u._staff_member .css-yk16xz-control,
._remind .input_group_u._staff_member  .css-1pahdxg-control {
  padding: 6px 10px
}
._remind .css-26l3qy-menu{
z-index: 99;
}
._remind .input_group_u._staff_member .css-1wa3eu0-placeholder{
   font-size: 15px;
}
._remind .input_group_u._staff_member ._field_label_vk{
  font-size: 13px;
}
._remind .input_group_u._staff_member .form-control ,
._remind .input_group_u._staff_member .form-control:focus{
  height: calc(2.1em + 0.75rem + 2px)!important;
}
._sms_reminder ._text ._link{
  color: #007EA0;
  text-decoration: underline;
  display: block;
  text-align: center;
  font-weight: 500;
  margin: 45px 0 5px 0;
}
._preview_reminder ._circle {
  width: 450px;
  height: 450px;
  background: #C8EEEE;
  margin: 0 auto;
  border-radius: 100%;
  position: relative;
}
._preview_reminder ._phone {
  width: 62%;
    height: 84%;
    background: white;
    border-top-left-radius: 52px;
    border-top-right-radius: 52px;
    position: absolute;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);
}
._preview_reminder ._phone ._dot{
  background: #C8EEEE;
  border-radius: 18px;
  width: 90px;
  height: 26px;
  position: relative;
  top: 12px;
  left: 50%;
  transform: translate(-50%, 0px);
}
._preview_reminder ._phone  ._msz_box {
  width: 80%;
  min-height: 139px;
  background: #F0F0F0;
  border-radius: 20px;
  margin-left: 12px;
  position: relative;padding: 14px;
}
._preview_reminder ._phone  ._msz_box:after {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 16px solid transparent;
  border-left: 16px solid #f0f0f0;
  content: '';
  position: absolute;
  bottom: -15px;
  left: 0px;
}
._preview_reminder ._phone ._msz_box p {
  font-size: 14px;
}
.__marketing_invoice{
  padding:2.5rem 0;
}
.__marketing_invoice .table-responsive.payout_table th {
  font-size: 12px;
}
.__marketing_invoice  .widrawal-history .table thead tr th:first-child{
  width: 190px;
}
.__marketing_invoice .table .W-refr.status {
  width: 156px !important;
}
.setting-menu-bar li a .icon svg path{
fill:#000
}
._sms_priview{
  width: 312px;
  min-height: 110px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BABABA;
  border-radius: 20px;
  text-align: left;
  display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 17px;
}
._sms_priview .first{
  font-size: 22px;
  font-weight: 100;
  margin-bottom: 0
}
._sms_priview .first span {
  color: #29282E;
  font-size: 32px;
  font-weight: 400;
}
._sms_priview .last{
  font-weight: 200;
  margin-bottom: 10px;
}
._marketing_setting h5{
  font-size: 24px;
    font-weight: 400;
}
._marketing_setting p{
  font-size: 15px;
    font-weight: 200;
    line-height: 1.4;
}
._marketing_setting .switch_conatiner {
  display: flex;
  align-items: center;
}
._marketing_setting .switch_conatiner .switch_box{
  width: 50%
}

._marketing_setting .switch_box h5 {
  font-size: 15px;
    font-weight: 500;
    margin-bottom: 26px;
}
._update_custom .input_group_u._staff_member .css-1uccc91-singleValue,
._update_custom .input_group_u._staff_member .css-1wa3eu0-placeholder,
.form-group.input_group_u._staff_member  .css-1uccc91-singleValue{
  font-size: 17px;
  color: hsl(0deg 0% 20% / 81%);
}

.__marketing_invoice table td{
min-width: 160px !important;
}
.discoverd-modalWrp .modal-content {
  margin: 0 30px;
  background: #fff;
}
.discoverd-modalWrp .new-stng-cmn._sms_reminder {
  margin-bottom: 0 !important;
  padding: 2.5rem 1rem 2rem 3rem;
}
.discoverd-modalWrp .new-stng-cmn._sms_reminder ._text {
  margin-left: 65px;
}
.discoverd-modalWrp .new-stng-cmn._sms_reminder ._text p{
  font-weight: 200;
}
.discoverd-modalWrp .new-stng-cmn._sms_reminder ._text h4{
  font-weight: 200;
}


.marketing-widrawal-history thead tr th {
min-width: 162px
}

.marketing-widrawal-history thead tr th:last-child, .marketing-widrawal-history thead tr th:nth-child(6) {
  min-width: 100px
  }

  .editable-border{
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .newEditableContent {
    height: 150px;
    padding: 10px;
    width: 100%;
    border: 0px;
    margin-top:3px;
}

.newEditableContent:focus-visible {
    padding: 8px;
    padding-top: 15px !important;
    border-top:0px;
    outline: 0px;
}

.editableContentButton {
  text-align: center;
  letter-spacing: 0px;
  font-size: 0.8rem;
  opacity: 1;
  border: 1px solid #d7a94100;
  border-radius: 4px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  margin-left: 6px;
  margin-right: 6px;
}

.editableContentButton.bg_1{
  color: #A06F00;
  background-color: #d7a94152;
}

.editableContentButton.bg_2 {
  color: #00A000;
  background-color: #00a00017;
}

.editableContentButton.bg_3 {
  color: #A00000;
  background-color: #a000001a;
}

.editableContentButton.bg_4 {
  color: #5600A0;
  background-color: #5600a021;
}


.editableContentButton.bg_5 {
  color: #007EA0;
  background-color: #007ea01a;
}

._link{
  cursor: pointer;
}

._sms_reminder ._link.disabled{
  color: #878787 !important;
  cursor: not-allowed;
}
body.modal-open.add_card_modal_open #root{
  filter:blur(0);
  -webkit-filter: blur(0px);
}
body.modal-open .new-setting-wrapper{
  filter:blur(5px);
  -webkit-filter: blur(5px);
}

  body.only---sidenav.modal-open.add_card_modal_open .show_sidenav_topbar,
  body.only---sidenav.modal-open.add_card_modal_open .menus_open_modal.modal{
    top: 75px;
    left: 250px;
    width: calc(100% - 250px);
  }
  body.only---sidenav.modal-open.add_card_modal_open.nav_menu_open .show_sidenav_topbar,
  body.only---sidenav.modal-open.add_card_modal_open.nav_menu_open .menus_open_modal.modal{
    top: 75px;
    left: 60px;
    width: calc(100% - 60px);
  }
  /* .discoverd-modalWrp.menus_open_modal.modal.show .modal-dialog{
    margin-left: 0;
  } */
  .sidenava ul li a.active .menu_icon._markt svg path,
  .sidenava ul li a:hover .menu_icon._markt svg path{
    fill: #29282E !important;
  }
  .editable_span_prent {
    display: inline-flex;
    flex-wrap: wrap;
    row-gap: 10px;
   }
.right-container .mob-btnss-div._new_transaction_change .btn.btn-dark-blue
{
  min-width: 86px;
}.booking_status_filter .btn{
  /* width: 90px; */
  width: 80px;
  height: 42px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 9px;
  font-size: 14px;
}
.booking_status_filter .btn.btn-dark{
  background: #29282E;
  color:#fff;
  border: none;
}
/* ._new_transaction_change{

} */
.right-container ._new_transaction_change .btn.btn-dark-blue {
  min-width: 110px;
  height: 42px;
  background: #007EA0 0% 0% no-repeat padding-box;
  border-radius: 9px;
  font-size: 14px;
}

.right-container ._new_transaction_change .right-container-block.client_search .form-control ,
._new_transaction_change .start-end-datepicker{
  height: calc(1.3em + 1.1rem + 4px) !important;
  border-radius: 9px;
}
._new_transaction_change .cm_search_wrapper.client_search.m-0.analytics-search-bar {
  width: calc(35% - 140px);
}
._new_transaction_change .start-end-datepicker .sm_date_picker input{
  height: calc(1em + 1rem + 4px) !important;
  border-radius: 9px;
}
/* ._new_transaction_change .cm_search_wrapper.analytics-search-bar i {
  top: calc(50% - 14px);
} */
._new_transaction_change .filter.right-container-block .css-yk16xz-control, 
._new_transaction_change .filter.right-container-block .css-1pahdxg-control {
  height: 42px !important;
  border-radius: 9px;
}
._new_transaction_change .right-container-block, 
._new_transaction_change .booking_status_filter .btn {
  margin: 0 5px !important;
}

._no_show_parent h3{
  font-size: 22px;
    margin-bottom: 25px;
}
._no_show_parent h6{
  /* font-size: 17px;
  font-weight: 300; */
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
._no_show_parent h5{
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 45px;
}
._no_show_parent .transaction_option .currency_div .currency_sml {
  color: #101010;
  font-size: 22px;
}
.no_show_modal .modal-dialog.modal-lg{
  max-width: 665px;
}
._no_show_parent ._paragrap {
  font-size: 14px;
  /* font-weight: 100; */
  color: #605f5f;
}
._no_show_parent .transaction_option{
  width: 90%;
    height: 56px;
    justify-content: flex-start;
    margin: 0 auto;
    border-radius: 9px;
    margin-bottom: 12px;
    border: 1px solid #a2a2a2b0;
    font-size: 22px;
    padding: 12px 0px 12px 20px;

}
._no_show_parent .transaction_option.disabled{
  background: #eaeaea7d;
  color:#888;
  border: none;
 
}
._no_show_parent .transaction_option label.radio-container{
  margin-bottom: 20px;
}
._no_show_parent .transaction_option label.radio-container{
  margin-bottom: 0;
}
._no_show_parent .transaction_option .checkmark {
  border: 1px solid #DADADA;
  top:4px;
}
._no_show_parent .transaction_option .checkmark {
  top:-13px;
}
._no_show_parent .btn-dark-blue {
  min-width: 200px;
  font-size: 17px;
}
.editable_currncy{
  display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 46px );
}
.editable_currncy .p{
  width: calc( 100% - 161px )
}
.editable_currncy .currency_div ,
.editable_currncy .edit_price_input{
  font-size: 17px;
  background: #fff;
}
.editable_currncy .currency_div {
  width: 123px;
    border: 1px solid #70707042;
}
.editable_currncy .currency_div .currency_sml {
  border-right: 1px solid #80808040;
  padding: 2px 0px;
}
.no_show_modal .modal-content {
  background: white;
}
.widrawal-history .payout_table .table tr td ._download__icon svg {
  width: 20px;
  height: 20px;
}
._row  .form-control, 
._row  .address_input_wrap .location-search-input {
  height: calc(2.5em + 0.75rem + 2px);
  border-color: #00000036 !important;
}
._booking_setting ._row ._card .input_group_u._staff_member .css-1uccc91-singleValue, 
._booking_setting ._row ._card .input_group_u._staff_member .css-1wa3eu0-placeholder{
  font-size: 0.9rem;
}
._row  .form-control.inactive-input-field,
._booking_setting ._row ._disabled.input_group_u.form-control:focus, 
._booking_setting ._row ._disabled.input_group_u .css-yk16xz-control,
 ._booking_setting ._row ._disabled.input_group_u .css-1pahdxg-control,
 ._row ._disabled.input_group_u .css-1fhf3k1-control{
border: none !important;
}
._disabled.form-group.input_group_u._staff_member .css-tlfecz-indicatorContainer {
  display: none;
}
._row .form-group.input_group_u._staff_member .css-g1d714-ValueContainer{
  padding: 0px 8px;
}
._row .input_group_u .css-1fhf3k1-control{
  height: calc(2.5em + 0.75rem + 6px) !important;
    border-color: #707070;
    border-radius: 9px;
    background: #f7f7f7;
    color: #495057;
    font-size: 0.9rem;
}
._booking_setting ._row .form-control:focus, 
._booking_setting ._row .input_group_u .css-yk16xz-control, 
._booking_setting ._row .input_group_u .css-1pahdxg-control{
  height: calc(2.5em + 0.75rem + 6px) !important;
}
._row .input_group_u .css-107lb6w-singleValue{
  color: #495057;
}
._row .input_group_u .css-109onse-indicatorSeparator{display: none;}
._row .input_group_u  .css-1fhf3k1-control .css-6q0nyr-Svg{
  margin-right: 8px;
}
/* css-1fhf3k1-control */
.cancel_appointment_modal_parent .cm_modal{
  background: #fff;
  padding: 20px 32px;
  border-radius: 9px;
}
.cancel_appointment_modal_parent .modal-dialog {
    max-width: 650px;
}
.cancel_appointment_modal_parent .modal-dialog .modal-content{
  padding-top: 0;
  padding-bottom: 0;
}
.cancel_appointment_modal .btn-inf ,
.cancel_appointment_modal .btn-inf:hover{
  min-width:200px;
  background: #007ea0;
  font-size: 15px;
  color: #fff;
}
.cancel_appointment_modal .cm_modal .modal-header {
  padding: 0.5rem 1rem;
}
.cancel_appointment_modal ._checkbox{
  font-size: 15px;
  font-weight: 600;
}
.cancel_appointment_modal h3{
  font-size: 22px;
}
.cancel_appointment_modal ._cancel_detail{
display: flex;
justify-content: space-between;
    background: #F7F7F7;
    padding: 12px;
    border-radius: 9px;
    margin: 30px 25px;
}
.cancel_appointment_modal ._cancel_detail ._first{
  display: flex;
}
.cancel_appointment_modal ._cancel_detail ._first ._img img{
  width: 55px;
  height: 55px;
  border-radius: 100px;
  border: 1px solid #888;
  margin-right: 15px;
}
.cancel_appointment_modal ._cancel_detail ._first ._text h5{
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}
.cancel_appointment_modal ._cancel_detail ._first ._text p,
.cancel_appointment_modal ._cancel_detail ._last p{
  font-size: 12px;
}
.cancel_appointment_modal ._cancel_detail ._last{
  text-align: center;
}
.cancel_appointment_modal ._cancel_detail ._last p{
  
}
.cancel_appointment_modal ._cancel_detail ._last h2{
  font-size: 22px;
    font-weight: 600;
}
.cancel_appointment_modal ._cancel_policy{

}
.cancel_appointment_modal ._cancel_policy h6{
  font-size: 15px;font-weight: 600;
}
.cancel_appointment_modal ._cancel_policy p{
  /* margin-bottom: 0; */
    font-size: 13px;
}
.cancel_appointment_modal ._cancel_policy p span{
font-weight: 600;
}
.animation_modal .modal-dialog {
  max-width: 300px;margin: 0 auto !important;}

  .animation_modal ._animation {
    min-height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cldr-date-mobile .react-datepicker-popper[data-placement^="bottom"]{
  left: 50% !important;
  transform: translate3d(-50%, 43px, 0px) !important;
}
._border_link{
  color: #007EA0;
    text-decoration: underline;
    font-size: 15px;
}
.user_pic button.more__option.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none !important;
}
.more__option_menu.dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}
.shift_day_modal{
  text-align: left;
  /* width: 70%; */
}
.__service_edit_modal.shift_day_modal.modal h5.mb-3.mdl-hdkng {
  font-size: 1.25rem !important;
}
._shift_day_time{
  display: flex;
  align-items: center;
  justify-content: flex-start; 
}
._shift_day_time .form-group.sm_date_picker {
  width: 24%;
  /* background: pink; */
}
._shift_day_time ._dashed_centert {
  width: 40px;
  position: relative;
}
._shift_day_time ._dashed_centert span:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 2px;
  background: #29282D;
  top: 12px;
  left: 35%;
}
._shift_day_time .form-group.sm_date_picker .react-datepicker-wrapper {
  width: 100%;
}
._shift_day_time .react-datepicker-wrapper .react-datepicker__input-container input {
  height: calc(2em + 0.75rem + 2px);
  font-size: 15px;
}
.shift_day_modal button.btn {
  min-width: 110px;
}
.shift_day_modal button.btn.btn-info{
background-color: #007EA0;
border-color: #007EA0;
}
.shift_day_modal .modal-content {
  padding: 0 0 !important;
    border-radius: 12px !important;
}
.shift_day_modal .modal-body{
background: #fff;border-radius: 9px;
}
.shift_day_modal .modal-body .btn-danger{
  background-color: #D50000;
    border-color: #D50000;
}

.btn-group-container{
  display: flex;
  justify-content: space-between;
}

@media (min-width: 360px) and (max-width: 500px) {
  .client_top_container .client_filter.closeSelect,
  .client_top_container .client_filter.openSelect,
  .client_top_container .cm_search_wrapper.client_search,
  .client_top_container .export_btn_parent {
    width: 100%;
  }
  
  
}

@media (min-width: 700px) and (max-width: 900px) {
  .client_top_container .client_filter.closeSelect,
  .client_top_container .client_filter.openSelect,
  .client_top_container .cm_search_wrapper.client_search,
  .client_top_container .export_btn_parent {
    width: 100%;
  }
}
.client_top_container .cm_search_wrapper.client_search input.form-control {
  min-width: 100%;
}
.client_top_container .add_client-btn {
  width: 15%;
  font-size: 14px;
}

.client_top_container .export_btn_parent.btn-parent .btn,
.client_top_container .css-2b097c-container,
.client_top_container .cm_search_wrapper.client_search input.form-control,
.client_top_container .client_filter .css-yk16xz-control,
.client_top_container .sm_date_picker .react-datepicker-wrapper,
.client_top_container .client_filter .css-1pahdxg-control {
  width: 100% !important;
}
.client_top_container .cm_search_wrapper.client_search .form-control:focus {
  height: calc(2.5em + 0.75rem + 2px) !important;
}
.client_top_container .export_btn_parent.btn-parent {
  width: 10%;
}
.client_top_container svg.css-6q0nyr-Svg {
  fill: #000;
}
.analytics-tabsHead--wrap .btn-dark-blue {
  padding: 0.6rem 0.75rem;
}
.analytics-tabsHead--wrap
  > .react-tabs__tab-list
  .react-tabs__tab:first-child
  .circle-img
  > svg {
  max-width: 30px;
  fill: #476065;
}

.join_for_free h2 {
  font-size: 44px;
  margin-top: 20px;
}

.join_for_free h3 {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 40px;
}

.modal_header h3 {
  font-size: 40px;
}

.stap_wrapper h2 {
  font-size: 38px;
  margin: 20px 0;
}

.stap_wrapper h3 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 23px;
  font-weight: 300;
}

.cm_right_check {
  margin: 35px 0;
}

.cm_right_check .check_wrap {
  top: 6px;
  margin-right: 0;
  margin-left: 10px;
}

.cm_right_check .btn {
  min-width: 220px;
  margin-top: 20px;
}

.cm_fw_l {
  font-weight: 300 !important;
}

.cm_fw_m {
  font-weight: 500 !important;
}

.cm_fw_b {
  font-weight: 600 !important;
}

.h_n label {
  font-weight: 600 !important;
}

.h_n .btn-dark {
  font-weight: 600;
}

.cm_bottom_line {
  position: relative;
}

.cm_bottom_line:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: -2px;
  height: 4px;
  background: #bfe4ea;
  left: 0;
}

td.alfa_text {
  color: #000;
}

.cm_full_date .react-datepicker-wrapper {
  display: block;
}

.setting_page .nav-tabs .nav-link,
.staff_page .nav-tabs .nav-link {
  font-weight: 300;
}
.setting_page .nav-tabs .nav-link.active,
.staff_page .nav-item.nav-link.active {
  font-weight: 600;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 99;
  top: 60px;
  max-height: 267px;
  overflow: auto;
}

.react-autosuggest__suggestions-container ul {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
}

.react-autosuggest__suggestions-container ul li {
  display: block;
  padding: 10px 15px;
  border-top: 1px solid #b0b3b5;
  cursor: pointer;
}

.salonAddress {
  font-size: 14px;
  font-weight: 300;
}

.react-autosuggest__suggestions-container ul li:first-child {
  border: 0;
}

.react-autosuggest__suggestions-container ul li:hover {
  background: #f9f9f9;
}

body body {
  background: #fff !important;
}

.address_input_wrap {
  position: relative;
}

.address_input_wrap .location-search-input {
  height: calc(2.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
  width: calc(100% - 0px);
}

.address_input_wrap.w-100 .location-search-input {
  width: 100%;
}

.cm_check_btn {
  position: absolute;
  width: 54px;
  height: 54px;
  border: 1px solid #b0b3b5;
  right: 0;
  top: 0;
  border-radius: 6px;
  text-align: center;
  line-height: 54px;
  font-size: 24px;
  color: #cacaca;
  cursor: pointer;
}

.cm_check_btn:hover {
  color: #000;
}

.cm_check_btn.active {
  border: 1px solid #000;
  color: #000;
}

.address_input_wrap .suggestion-item,
.address_input_wrap .autocomplete_location {
  background: #fff !important;
  color: #000 !important;
}

.address_input_wrap .suggestion-item {
  padding: 10px !important;
}

.address_input_wrap .autocomplete_location {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  border: 1px solid #b0b3b5;
  border-radius: 6px;
  z-index: 99;
}

.only_shadow {
  padding: 0 15px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.31);
  position: relative;
}

.cm_PhoneInput .PhoneInputInput,
.cm_PhoneInput .PhoneInputInput:focus {
  border: 0;
  outline: none;
}

.text_red {
  color: #ff002a;
}

.pointer_c span,
.pointer_c p {
  cursor: pointer;
}

img.PhoneInputCountryIconImg {
  object-fit: cover !important;
  width: 24px !important;
  height: 16px !important;
}

.pointer-event {
  cursor: pointer;
}

.daily-schedule {
  width: 100%;
}

.daily-schedule tr td:first-child,
.daily-schedule th td:first-child,
.daily-schedule tr td:last-child,
.daily-schedule th td:last-child {
  border: 0 !important;
}

table.daily-schedule tr > td:first-child {
  color: transparent;
  width: 1px !important;
  font-size: 10px;
}

.daily-schedule td.daily-schedule__event {
  width: calc(100% - 50px);
  border-radius: 9px;
  vertical-align: text-top;
  position: relative;
  background: transparent !important;
}

.daily-schedule td.daily-schedule__event > * {
  padding: 0 !important;
}

.booking_card {
  position: absolute !important;
  top: 0;
  width: calc(100% + -10px);
  right: 7px;
  height: calc(100% - 5px);
  left: -10px;
}

.daily-schedule tr td:first-child {
  width: 1px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.new-booking-tab {
  justify-content: center;
}

/* .cm_over_cal {
  position: relative;
}

.times_no {
  position: absolute;
  background: #fff;
  z-index: 9;
  top: 190px;
  left: 0;
}

.diary-parent-wrap {
  overflow: auto;
  overflow-y: hidden;
  position: relative;
  height: 2729px;
} */

.times_no {
  /* position: fixed; */
  background: #fff;
  z-index: 9;
  /*left: 250px;
  top: 279px; */
  /* overflow-y: scroll;
  height: 100vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
  left: 272px;
  top: 273px; */
  /* margin-top: -30px; */
}
.times_no::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.nav_menu_open .times_no {
  /* left: 72px; */
}

tr.left_bg {
  border-left: 12px solid #d6d5ec;
}

.client_table .table tbody td {
  vertical-align: middle;
}

.CurrencySelect .flag-select__options {
  width: 100%;
  left: 0;
}

.CurrencySelect .flag-select__option__icon {
  top: 0;
}

::-webkit-scrollbar-thumb {
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0);
}
.react-switch-bg svg {
  display: none;
}
.top-container {
  /* display: flex; */
  width: 100%;
  margin: 12px 0px 20px 0px;
}
.transacion-container {
  display: flex;
  /*width: calc( 100% - 390px); */
  width: 100%;
}
.transacion-box {
  width: calc(100% / 6);
  /* max-width: 372px; */
  min-height: 145px;
  max-height: 170px;
  background: #c4dcea 0% 0% no-repeat padding-box;
  border-radius: 9px;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  text-align: center;
  margin: 0 12px;
}
.transacion-box .head,
.transacion-box .text {
  height: 50%;
  display: flex;
  justify-content: center;
}
.transacion-box .head {
  align-items: flex-end;
  /* background: pink; */
}
.transacion-box .text {
  align-items: flex-start;
  /* background: cyan; */
}

.right-container {
  /* width: 390px; */
  width: 100%;
  margin: 24px 0 0 0;
}
.right-container .form-group .react-datepicker-wrapper {
  width: 100%;
}
.right-container button.btn.btn-dark-blue.mr-3 {
  display: flex;
  align-items: center;
  padding-bottom: 13px;
}
.export_btn_parent {
  display: flex;
  align-items: center;
}
.right-container button span svg,
.export_btn_parent button span svg {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
/* ---new changes start--- */
.transction-tble {
  padding: 0 24px;
}
.right-container .btn.btn-dark-blue {
  min-width: 120px;
}
.right-container-block {
  margin: 0 10px !important;
}
.filter.right-container-block .css-yk16xz-control,
.filter.right-container-block .css-1pahdxg-control {
  height: 54px !important;
}
.right-container .right-container-block.client_search .form-control {
  height: calc(2em + 1.1rem + 4px) !important;
}
/* ---new changes end--- */
.export_btn_parent .btn {
  padding: 0.8rem 0.75rem;
  display: flex;
  align-items: center;
}
.right-container .client_search .form-control {
  min-width: 50%;
}
.transacion-box:nth-child(2) {
  background: #c8eeee 0% 0% no-repeat padding-box;
}
.transacion-box:nth-child(3) {
  background: #ddbff0 0% 0% no-repeat padding-box;
}
.transacion-box:nth-child(4) {
  background: #fdf0c2 0% 0% no-repeat padding-box;
}
.transacion-box:nth-child(5) {
  background: #ffd9d9 0% 0% no-repeat padding-box;
}
.transacion-box:nth-child(6) {
  background: #c1ffc9 0% 0% no-repeat padding-box;
}
.transacion-box h2 {
  letter-spacing: 0px;
  color: #061e1e;
  /* font-size: 38px; */
  font-size: 2.1vw;
  font-weight: 400;
}
.transacion-box p {
  /* font-size: 20px; */
  font-size: 1.08vw;
  font-weight: 200;
  letter-spacing: 0px;
  color: #061e1e;
}
.fc-event-main .booking_card img {
  width: 30px !important;
  height: 30px !important;
  top: 5px !important;
}
.added-staff-modal-wrp .modal-content {
  width: 864px;
  /* UI Properties */
  background: #ffffff !important;
  box-shadow: 0px 3px 6px #00000052;
  border-radius: 9px;
  padding: 20px 40px;
}
.added-staff-modal h4 {
  font-size: 30px;
  letter-spacing: 0px;
  color: #29282e;
}
.added-staff-modal p {
  font-size: 20px;
  letter-spacing: 0px;
  color: #061e1ec6;
}
.added-staff-modal button {
  margin-top: 70px;
}
/* ------trancation fee--- */
.transaction_Dropdown.dropdown button::after {
  display: none;
}
.transaction_Dropdown.dropdown .dropdown-menu {
  background: #fdffff;
  box-shadow: 0px 13px 31px #00000036;
  border: 1px solid #7070707a;
  border-radius: 9px;
  color: #29282ea1;
  font-size: 16px;
  min-width: 250px;
  text-align: center;
}
.transaction_Dropdown.dropdown
  .btn-primary:not(:disabled):not(.disabled).active,
.transaction_Dropdown.dropdown
  .btn-primary:not(:disabled):not(.disabled):active,
.transaction_Dropdown.dropdown .show > .btn-primary.dropdown-toggle {
  background: transparent;
}
.transaction_option p {
  margin-bottom: 0;
}
.transaction_option.selected p {
  font-weight: 600;
}
.transaction_option label.radio-container {
  margin-bottom: 20px;
}
.transaction_Dropdown.dropdown .dropdown-item,
.transaction_Dropdown.dropdown .dropdown-item:hover {
  color: #29282ed1;
  border: none;
}
.transaction_Dropdown.dropdown .btn-primary {
  /* background: transparent; */
  background: pink;
  box-shadow: none;
}
.transaction_Dropdown.dropdown .btn-primary span svg,
.transaction_Dropdown .dropdown-toggle.btn.btn-primary svg {
  width: 19px;
  height: 19px;
  cursor: pointer !important;
}
.pos-relative {
  position: relative;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgb(38 143 255 / 50%);
}
.transaction_Dropdown.dropdown {
  position: absolute;
  right: 1px;
  top: -4px;
}
.transaction_option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
  margin: 0 -32px;
  padding: 12px 60px;
  border-bottom: 1px solid #eeeeeeb0;
}
.transaction_option.selected {
  background: #cbe4eb;
}
.transaction_btn button {
  padding: 0.75rem 1.75rem;
  font-size: 16px;
}
.tooltip_parent span.info svg {
  width: 22px;
  height: 22px;
}
.tooltip_parent span.info {
  position: absolute;
  top: 9px;
  right: 13px;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  z-index: 9;
}
.tooltip-body {
  position: absolute;
  background: #f7f7f7;
  box-shadow: 0px 3px 6px #00000064;
  border-radius: 9px;
  padding: 15px;
  width: 210px;
  /* min-height: 200px; */
  padding-top: 26px;
  z-index: 99;
  right: 1px;
  top: 2px;
}
.tooltip-body .close svg {
  width: 15px;
  height: 15px;
}
.tooltip-body .close {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 2px;
}
.tooltip-body .close {
  position: absolute;
  right: 14px;
  top: 6px;
  opacity: 1;
}
.tooltip-body .close svg {
  width: 15px;
  height: 15px;
}
.tooltip-body .close svg g path {
  fill: black;
}
.tooltip-body p {
  font-size: 10px;
  letter-spacing: 0px;
  color: #29282ec2;
  line-height: 1.3;
}
.tooltip-body h5 {
  font-size: 15px;
  color: #29282e;
  font-weight: 600;
  margin: 5px 0 15px;
}
.tooltip_parent p a {
  font-size: 11px;
  letter-spacing: 0px;
  color: #007ea0;
  text-decoration: underline;
  text-align: center;
}
.card-discover-access-code .li-tag {
  display: flex;
  margin: 25px 0;
}
.card-discover-access-code .li-tag b {
  margin-right: 6px;
  font-weight: 600;
}
.card-discover-access-code .li-tag b span {
  font-weight: 600;
}
.client_top_container .export_btn_parent.btn-parent .btn,
.client_top_container .add_client-btn,
.start-end-datepicker,
.start-end-datepicker .sm_date_picker input {
  border-radius: 10px !important;
}
.client_top_container .client_filter .css-yk16xz-control {
  border-color: #0000;
  box-shadow: 0px 3px 8px #afafafb3;
}
.client_top_container .cm_search_wrapper.client_search input.form-control,
.start-end-datepicker {
  border-color: #061e1e !important;
}

/* ------dairy page dropdown------ */
.visible-staff-body {
  /* background: pink; */
  width: 225px;
  height: 81px;
  position: absolute;
}
.visible-staff-body .client_filter .css-yk16xz-control,
.visible-staff-body .client_filter .css-1pahdxg-control {
  height: 50px !important;
  border: none !important;
}
.drag-show_body {
  position: relative;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000039;
  border-radius: 9px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
}
.drag-show_body .right_corner {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translate(0, -50%);
}
.drag-show_body .right_corner svg {
  width: 30px;
  height: 30px;
}
.drag-show_body label {
  color: black;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.drag_dropdown_body ul {
  margin-block-start: 0;
  padding-inline-start: 0;
  padding: 12px;
  margin-bottom: 0;
}
.drag_dropdown_body ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
}
.drag_dropdown_body ul li .inner {
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
}
.drag_dropdown_body ul li .inner label {
  width: calc(100% - 23px);
  padding-left: 15px;
  margin-bottom: 0;
  font-size: 15px;
}
.drag_dropdown_body ul li:first-child,
.drag_dropdown_body ul li:nth-child(2) {
  border: none;
  padding: 5px 0;
}
.drag_dropdown_body ul li:first-child label,
.drag_dropdown_body ul li:nth-child(2) label {
  font-weight: 600 !important;
}
.drag_dropdown_body ul li span svg {
  width: 34px;
  fill: #525252;
}
.drag_dropdown_body {
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  max-height: 300px;
  overflow-y: auto;
  padding: 2px 2px;
  position: absolute;
  z-index: 9999;
  width: 100%;
  box-sizing: border-box;
}
.visible-staff-body .css-1wa3eu0-placeholder,
.visible-staff-body .css-tlfecz-indicatorContainer {
  color: #000;
}
.visible-staff-body .client_filter.closeSelect {
  position: absolute;
  box-shadow: 0px 1px 6px rgb(0 0 0 / 24%);
  border-radius: 9px;
  bottom: 0;
}

.visible-staff-body .css-26l3qy-menu {
  z-index: 999;
}
.visible-staff-body .client_filter,
.visible-staff-body .css-2b097c-container,
.visible-staff-body .client_filter .css-yk16xz-control,
.visible-staff-body .client_filter .css-1pahdxg-control {
  width: 100% !important;
}
.visible-staff-body
  .client_filter
  .css-26l3qy-menu
  .css-yt9ioa-option:first-child
  label {
  color: black;
  /* background-color: pink; */
  font-weight: bold !important;
}
.sidenav.menu-show-desktop .menu_toogle_icon .sidebar_tog_icon_open svg {
  fill: #fff;
}
.nav_menu_open
  .sidenav.menu-show-desktop
  .menu_toogle_icon
  .sidebar_tog_icon_close
  svg {
  fill: #fff;
  transform: rotate(180deg);
}
.date-filter-icon-body_mobile .icon {
  display: none;
}
.filter-dropdown .modal-content {
  height: 100vh;
  border-radius: 0;
  background: white !important;
  border-color: #fff !important;
  box-shadow: 0px 0px 8px transparent;
  border: none !important;
}
.filter-modal-body ul {
  padding-inline-start: 0px;
}
.filter-modal-body .text-right p {
  color: #007ea0;
  font-weight: 600;
}
.modal-dialog.filter-dropdown {
  margin: 0;
  width: 100%;
  max-width: 100%;
}
.filter-modal-body .drag_dropdown_body {
  border-radius: 4px;
  box-shadow: 0 0 0 !important;
  width: 96%;
}

.filter-modal-body .client_filter label {
  margin-bottom: 0;
  margin-left: 12%;
}
.filter-modal-body h3 {
  font-size: 26px;
  margin-bottom: 40px;
}
.filter-modal-body .client_filter {
  display: flex;
  align-items: center;
  padding: 12px 0;
  justify-content: space-between;
  border-top: 1px solid #cdcdcd40;
}
.filter-modal-body .client_filter .inner {
  display: flex;
  width: 80%;
}
.filter-modal-body .client_filter span svg {
  width: 41px;
  height: 41px;
  fill: #6f6f6f;
}
.filter-modal-body .client_filter.no-border {
  border: none;
  font-weight: 600;
}
.filter-modal-body .client_filter input[type="checkbox"] {
  accent-color: #000000;
}
.fade.modal-backdrop.showw {
  display: none;
  opacity: 0;
  z-index: -1;
}

body.dairy-filter-menu_open .fade.modal-backdrop.showw {
  opacity: 0.5;
  z-index: 1040;
  display: block;
}
body.dairy-filter-menu_open .calendar_card.calendar-card-mobile {
  z-index: 999;
}
body.dairy-filter-menu_open .visible-staff-body {
  z-index: 9999;
}
.dairy-filter-menu_open .dairy-container-header,
.dairy-filter-menu_open .sidenav.menu-show-desktop,
.dairy-filter-menu_open .new--diary--clndr,
.dairy-filter-menu_open #addbutton {
  filter: blur(2px);
}
/* .client_top_container .export_btn_parent.btn-parent .btn,
.client_top_container .cm_search_wrapper.client_search input.form-control,
.client_top_container .sm_date_picker .react-datepicker-wrapper,  */
@media screen and (max-width: 1440px) {
  ._row .input_group_u .css-1fhf3k1-control,
   ._booking_setting ._row .form-control:focus, 
  ._booking_setting ._row .input_group_u .css-yk16xz-control,
   ._booking_setting ._row .input_group_u .css-1pahdxg-control{
    height: calc(2.5em + 0.75rem + 2px) !important;
  }
  .card-discoversDes > h4,
  .payout-balance-crd-desc > h4,
  .widrawal-history > h3,
  .card-reader-crd-desc > h4,
  .new-setting-menu-bar .setting-user-Title h2,
  .setting-detls-img > h5,
  .saloon-settings-wrp h5,
  .desct-accnt-desc > h3 {
    font-size: 1.3rem;
  }
  .new-setting-menu-bar .setting-user-Title {
    margin-bottom: 2rem !important;
  }
  .card-discoversDes > p,
  .payout-balance-crd-desc > p,
  .disccover-crd-redr-descr > h4,
  .card-discover-access-code > p,
  .booking-urlpage .clipBorad > .form-control {
    font-size: 1.01rem;
  }
  .btn-disc,
  .btn-inf,
  .btn-line-blck,
  .stripe-blance h4,
  .card-reader-crd-desc > p,
  .btn-black,
  .btn-req {
    font-size: 1rem;
  }
  .balance-crd-top > p,
  .btm-crd-left > p,
  .btm-crd-rgt > p,
  .stripe-blance > p,
  .para-30 {
    font-size: 1.215rem;
  }
  .para-30 {
    line-height: 1.5;
  }
  .balance-crd-top > h4 {
    font-size: 1.25rem;
  }
  .booking-urlpage .text-center.link_img h3,
  .heading-40 {
    font-size: 1.5rem;
  }
  .setting-menu-bar li a .sTitle > strong,
  .setting-detls-img > p,
  .clipBorad > .fz-20 {
    font-size: 0.9rem;
  }
  .setting-menu-bar li a .sTitle > .text-sm,
  .disccover-crd-redr-descr > p,
  .saloon-settings-wrp .form-control,
  .saloon-settings-wrp .address_input_wrap .location-search-input,
  .selct-stng__control,
  .saloon-settings-wrp .form-group > label {
    font-size: 0.9rem;
  }
  .saloon-settings-wrp .form-group > label > .text-grey {
    font-size: 12px;
  }
  .setting-menu-bar li a {
    padding: 1.15rem 0.5rem 1.15rem 1rem;
  }
  .widrawal-history .table tr td,
  .widrawal-history .table tr th {
    padding: 1.25rem 1rem;
    font-size: 0.9rem;
    word-wrap: break-word;
    word-break: break-all;
  }
  .setting-menu-bar li a .sTitle > .text-sm {
    font-size: 0.8rem;
  }
  .widrawal-history .table {
    table-layout: fixed;
  }
  .wd-icon img {
    max-width: 100%;
    width: auto;
  }
  .widrawal-history .table thead tr th:first-child {
    /* width: 10%; */
    width: 190px;
  }
  .widrawal-history .table .W-date {
    width: 30%;
  }
  .widrawal-history .table .W-refr {
    /* width: 43%; */
    width: 190px;
  }
  .widrawal-history .table .W-amt {
    /* width: 17%; */
    width: 100px;
  }
  .card-discover-top {
    margin-bottom: 2.25rem;
  }
  .anctPassword .form-control > span {
    padding-top: 1rem;
    font-size: 33px;
  }
  .img-accnt-password > img {
    max-width: 250px;
  }
  .new-setting-wrapper > .container {
    max-width: 89% !important;
  }
  .btm-crd-rgt .fa {
    font-size: 0.75rem;
  }
  .setting-menu-bar li a .icon {
    max-width: 30px;
    display: inline-block;
    width: 100%;
  }
  .setting-menu-bar li a .icon svg {
    width: 100%;
  }
  .setting-menu-bar li a .sTitle {
    width: calc(100% - 30px);
    padding-left: 1rem;
  }
  .widrawal-history > h3 {
    margin-bottom: 0.85rem;
  }
  .staff-top-mdl > h1 {
    font-size: 3rem;
  }
  .staff-modal-wrap-left > h3,
  .epluyed-with-radio-topbar > h3,
  .epluyed-with-radio-topbar > span,
  .staff-btn-btm > .btn {
    font-size: 1.35rem;
  }
  .activation-code-wrp > span {
    font-size: 1.1rem;
  }
  .epluyed-with-radio:before,
  .epluyed-with-radio:after {
    top: calc(0.75rem + 5px);
  }
  .epluyed-with-radio-body {
    font-size: 0.85rem;
  }
  .epluyed-with-radio-body > p {
    margin-bottom: 0.5rem;
  }
  .staff-emplyed-plan > .table tr td,
  .staff-emplyed-plan > .table tr th,
  .staff-mebers-content > p,
  .staff-memb-colmn > h4,
  .staff-memb-colmn > p,
  .applyCode {
    font-size: 1.1rem;
  }
  .staff-mebers-content > h3 {
    font-size: 1.563rem;
  }
  .add-staff-modal-wrp .modal-xl {
    max-width: 1241px;
  }
  .staff-top-mdl svg {
    width: 96px;
    height: 105px;
  }
}

/* ---------------------------------------------------------------new booking end----------------------------- */
@media (max-width: 1400px) {
  .cm_right_panal {
    width: 400px;
  }

  .tab_content_wrapper {
    max-width: calc(100% - 431px);
  }
  .cm_bookin_deatils {
    padding: 5px 14px 30px;
  }
  .cm_bookin_deatils.booking_tab.legal_tab .react-tabs__tab {
    font-size: 26px !important;
  }
  .photobox {
    bottom: 8px;
  }
  .new-setting-menu-bar .setting-user-Title {
    padding: 1rem 0.75rem;
  }
  .new-setting-menu-bar .setting-user-Title h2 {
    font-size: 1.35rem;
    text-align: center;
  }
}

@media (max-width: 1023px) {
  .booking-parent {
    padding: 0;
  }
  .cm_right_panal {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .tab_content_wrapper {
    max-width: 100%;
  }

  .invoice_card_wrapper {
    margin: 15px auto;
  }
}

@media (min-width: 991px) and (max-width: 1260px) {
  .visible-staff-body {
    width: 187px;margin-left: 12px;
	}
  .drag_dropdown_body ul li .inner label {
      word-wrap: break-word;
  }
  .drag_dropdown_body ul li .inner {
    width: calc(100% - 30px);
  }
  .drag_dropdown_body ul li span svg {
    width: 30px;
  }
  .drag_dropdown_body {
      overflow-x: hidden;
      overflow-y: auto;
  }
  .client_filter input[type="checkbox"] {
    width: 18px;
    height: 18px
  }
  .client_top_container .add_client-btn {
    font-size: 14px;
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .rating-header > span:nth-child(1) {
    font-size: 40px;
  }
  .rating-header > span:nth-child(2) {
    margin-left: 0;
  }
  span.Star span {
    font-size: 24px;
  }
  .rating-header > span:nth-child(3) {
    font-size: 22px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .editable_currncy {
    width: calc(100% - 98px );
}
  .gmap_canvas {
    height: 16vw;
  }
  .p12 .text-left,
  .p12 .text-right {
    text-align: left !important;
  }
  .flex-container {
    justify-content: space-around;
    padding: 12px 0px;
  }
  .new--diary-clnder-body .demo-app-main .fc .fc-timegrid-body,
  .new--diary-clnder-body .fc-media-screen .fc-timegrid-slots > table {
    width: 100% !important;
  }
}

@media (min-width: 1100px) {
  .top_header .menu_toogle_icon {
    display: none;
  }
  .widrawal-history .table .W-refr.status {
    width: 85px;
  }
}
@media (max-width: 1199px) {
  .home,
  .home-search {
    margin-left: 0;
    margin-right: 0;
  }

  .cm_search {
    padding-left: 12px;
  }

  .cm_sort {
    padding-right: 12px;
  }

  .cm_min_slide {
    position: relative;
    min-height: 178px;
    padding-bottom: 65px;
  }

  .cm_view_service_btn {
    right: auto;
    bottom: 0;
    transform: translateX(-50%);
    left: 50%;
  }

  .photobox {
    margin: 0 auto;
  }
  .time-clnder--0 .demo-app-main .staff_user{
    height: 60.72px;
  }
  .new-stng-cmn {
    padding: 1.5rem 1.5rem;
  }
  .balance-crd-top > p,
  .btm-crd-left > p,
  .btm-crd-rgt > p,
  .balance-crd-top > h4 {
    font-size: 1.15rem;
  }

  .balance-crd-top {
    /* margin-bottom: 2rem; */
  }
  .balance-crd-bottom {
  }
  .payout-balance-crd-desc > h4 {
    font-size: 1.25rem;
    margin-bottom: 0.9rem;
  }
  .payout-balance-crd-desc > p {
    margin-bottom: 0.75rem;
    font-size: 17px;
  }
  .btn-inf,
  .btn-line-blck,
  .btn-black {
    padding: 0.55rem 1.25rem;
    font-size: 18px;
  }
  .card-discoversDes > h4,
  .payout-balance-crd-desc > h4,
  .widrawal-history > h3 {
    font-size: 1.2rem;
  }
  .widrawal-history .table tr td,
  .setting-menu-bar li a .sTitle > .text-sm {
    font-size: 0.9rem;
  }
  .setting-menu-bar li a .sTitle > strong {
    font-size: 1.1rem;
  }
  .setting-menu-bar li a {
    padding: 1.15rem 0.5rem 1.15rem 1rem;
  }
  .stripe-blance h4 {
    font-size: 1.063rem;
  }
  .stripe-blance > p {
    font-size: 1.5rem;
  }
  .widrawal-history .table tr td,
  .widrawal-history .table tr th {
    padding: 1.25rem 0.5rem;
  }
  .booking-urlpage .text-center.link_img h3 {
    font-size: 1.563rem;
  }
  .clipBorad > .fz-20,
  .booking-urlpage .clipBorad > .form-control,
  .check-wrp-settings.para-30,
  .account--password-temp .desct-accnt-desc > h3 {
    font-size: 1rem;
  }
  .check-wrp-settings.para-30 {
    line-height: 1.5;
  }
  .account--password-temp .img-accnt-password > img {
    max-width: 165px;
  }
  .account--password-temp .new-stng-cmn.saloon-settings-wrp {
    padding-top: 0.2rem;
  }
  .account--password-temp .desct-accnt-desc .heading-40 {
    font-size: 30px;
  }
  .account--password-temp .desct-accnt-desc > h3 {
    text-align: left;
  }
  .saloon-settings-wrp > .form-group {
    padding-left: 0px;
  }
  .account--password-temp .anctPassword .form-control > span {
    font-size: 30px;
    padding-top: 0.75rem;
  }
  .sidenava .sidenava-meue ul,
  .setting-menu-bar ul {
    overflow-y: scroll;
    max-height: 115vh;
    padding-bottom: 5rem !important;
    height: 100%;
  }
  .sidenava .sidenava-meue ul::-webkit-scrollbar {
    display: none;
  }
  .setting-menu-bar ul::-webkit-scrollbar {
    display: none;
  }
  .setting-menu-bar ul {
  }
  .new-setting-wrapper > .container {
    max-width: 100% !important;
  }
  .nav_menu_open .new-setting-wrapper > .container {
    max-width: 92% !important;
  }
  .staff-modal-wrapper {
    padding: 2.5rem 3rem 1rem 2rem;
  }
  .staff-modal-wrap-left,
  .staff-modal-wrap-left > p {
    padding-right: 0px;
  }
  .staff-top-mdl > h1 {
    font-size: 2.25rem;
  }
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .editable_currncy {
    width: calc(100% - 81px );
}
}
@media (min-width: 1100px) and (max-width: 1185px) {
  .card-readeers--mob-view > .account-pauout-balance .col-md-6,
  .connected-card-reder-mob .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .card-readerWrp {
    margin-bottom: 1rem;
  }
  .connected-card-reder-mob .col-5 {
  }
  .connected-card-reder-mob .col-7 {
    flex: 0 0 45.666667%;
    max-width: 45.666667%;
  }
}
@media (min-width: 767px) {
  .date-filter-icon-body_mobile .icon.calender {
    display: none;
  }
}

@media (max-width: 1100px) {
  .sidenav {
    /* left: -251px;

    width: 100% !important; */
  }

  .nav_menu_open .sidenav {
    left: 0;
    z-index: 99999;
  }
  .nav_menu_open .new-setting-wrapper > .container,
  .new-setting-wrapper > .container {
    max-width: 100% !important;
  }

  .new-setting-wrapper > .container {
    max-width: 100% !important;
  }
  .nav_menu_open .body_container,
  .body_container {
    padding: 16px;
  }

  .nav_menu_open .top_header,
  .top_header {
    width: 100%;
  }

  .sidenav ul li a {
    width: 46px;
    border-radius: 100%;
  }
  .nav_menu_open .sidenav ul li a {
    width: 100%;
    border-radius: 9px;
  }

  .sidenav_text {
    display: none !important;
  }
  .nav_menu_open .sidenav_text {
    display: inline-block !important;
  }

  .nav_menu_open .menu__overlay {
    position: fixed;
    width: calc(100% - 250px);
    height: 100%;
    right: 0;
    top: 0;
    background: black;
    z-index: 9999999999;
    opacity: 0.5;
    cursor: e-resize;
  }

  .setting-menu-bar {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 999;
    display: none;
    background: #fff;
    height: 100%;
    top: 65px;
    box-shadow: var(--boxShadow);
    border-radius: initial;
  }
  .setting-temps-pages .setting-menu-bar {
    display: block;
  }
  .top-header-setting-title {
    background: var(--bgColor);
    color: #fff;
    padding: 12px 5px;
    text-align: center;
    position: relative;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 65px;
    z-index: 999;
  }
  .top-header-setting-title > .btn {
    position: absolute;
    left: 0px;
    color: #fff;
    top: calc(50% - 19px);
  }
  .top-header-setting-title > h5 {
    margin-bottom: 0px;
    font-size: 20px;
    padding-top: 5px;
    height: 53px;
  }
  .new-setting-wrapper .container > .row > .col-md-4,
  .new-setting-wrapper .container > .row > .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .setting-user-Title {
    display: none;
  }
  .d-1100-none {
    display: none !important;
  }
  .clipBorad > .fz-20,
  .booking-urlpage .clipBorad > .form-control,
  .check-wrp-settings.para-30,
  .account--password-temp .desct-accnt-desc > h3 {
    font-size: 1rem;
  }
  .check-wrp-settings.para-30 {
    line-height: 1.7;
  }
}

@media (max-width: 991px) {
  .animation_modal .modal-content {
    border-radius: 9px !important;
    padding-top: 0 !important;
}
/* ._service_parent_body._marketting_parent{height: calc(100%  )!important;} */
._no_show_parent .transaction_option {
  width: 100%;
  padding: 12px 0px 12px 6px;
}
._no_show_parent .editable_currncy{
  width: calc(100% - 0px );
  font-size: 12px;}
  ._no_show_parent .editable_currncy .currency_div {
    font-size: 17px;
    min-height: 40px;
    margin: 0 4px;
  }
  .editable_currncy .currency_div .currency_sml {
    padding: 7px 0px;
}
.editable_currncy .currency_div {
  width: 112px;
}
  ._no_show_parent .edit_price_input {
    font-size: 17px;
  }
.mobile-hidden{display: none;}

  .service_row {
    height: auto !important;
}
._marketing_setting .switch_conatiner.pl-4{
  padding-left: 0 !important;
}
._marketing_setting .switch_conatiner .switch_box {
  padding: 0 6px;
}
._marketing_setting .switch_box h5{text-align: center;}
.salon_switch_container {
  justify-content: center;
}
._card_row td {
  width: calc(100% / 3 ) !important;
}

._card_row ._icon{
  display: none;
}

._marketing_setting .switch_conatiner .switch_box {
  padding: 0 6px;
}
.new-stng-cmn.__marketing ,
.new-stng-cmn.__marketing_invoice{
    padding: 1.5rem .5rem;
    box-shadow:none
}
._card_row td:nth-child(3) {
  min-width: 104px !important;
}
._marketing_setting .switch_conatiner.pl-4.sssssssssssssss {
  align-items: flex-start;
}
._marketing_setting .switch_box._setting_Name_label h5{text-align: left;}
.booking-parent.booking_space__fix {
  padding-left: 0px;
}
.booking_space__fix .text-right.cm_right_check {
  margin-right: 15px;
}
.booking_space__fix .cm_picker_wrap {
  padding-top: 15px;
  padding-left: 15px;
}
  nav.nav.nav-tabs._newCheckout_wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}
  .client_top_container .export_btn_parent {
    margin-bottom: 12px;
  }
  .client_top_container .cm_search_wrapper.client_search {
    margin: 12px 0 !important;
  }
  .client_top_container .export_btn_parent.btn-parent,
  .client_top_container .export_btn_parent,
  .client_top_container .add_client-btn {
    width: 100%;
  }
  .cm_view_service_btn {
    /* padding: 0px 40px 0px 18px !important; */
    padding: 10px !important;
  }

  .transaction_btn button {
    padding: 0.65rem 1.65rem;
    font-size: 14px;
  }
  .my-forms {
    min-width: 110px;
    max-width: 110px;
  }
  .cm_min_card_content_height,
  .cm_min_slide {
    min-height: 1px;
  }
  .round_check_wrap .checkmark {
    height: 24px;
    width: 24px;
    border-radius: 5px;
  }
  .round_check_wrap .checkmark:after {
    left: 8px;
    top: 2px;
    width: 8px;
    height: 14px;
  }
  .round_check_wrap p {
    font-size: 13px;
    font-weight: normal;
    padding-left: 6px;
    margin-bottom: 0;
  }

  .photobox > div {
    display: inline-block;
  }

  .photobox {
    display: block;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    max-width: 100%;
  }

  .diary-parent > .d-flex {
    min-width: 100%;
  }
  .diary-parent {
    margin-top: 61px !important;
    height: calc(100vh - 157px) !important;
  }
  .thank-you-modall-wrps-overlay .popup-content {
    width: 100% !important;
    border-radius: initial;
    border-color: rgb(112 112 92 / 0%) !important;
  }
  .mobile-view-accont-payble {
    background: #fff;
    box-shadow: var(--boxShadow);
    box-shadow: 0px 3px 10px -6px rgb(0 0 0 / 32%);
    border-radius: initial;
    margin-bottom: 2rem;
    padding: 0rem 0px 1.5rem;
    margin-bottom: 2rem !important;
  }
  .new-stng-cmn {
    box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 32%);
    border-radius: initial;
  }
  .mobile-view-accont-payble > .new-stng-cmn {
    background: transparent;
    box-shadow: initial;
    padding: 0px;
    margin-bottom: 0px !important;
  }
  .mobile-view-accont-payble .account-pauout-balance {
    margin-bottom: 2rem !important;
  }
  .account-pauout-balance .payout-balance-crd-desc > p,
  .account-pauout-balance .payout-balance-crd-desc > .btn {
    display: none;
  }
  .account-pauout-balance .col-lg-7 {
    order: 2;
  }
  .account-pauout-balance .col-lg-5 {
    order: 1;
  }
  .body_container.cm_bg_white.setting_page {
    padding: 16px 2px;
  }
  .stripe-blance h4 {
    font-size: 12px;
  }
  .stripe-blance > p {
    font-size: 1rem;
  }
  .stripe-balance-history > .btn {
    max-width: 74%;
    margin: 0px auto;
    width: 100%;
  }
  .new-setting-wrapper .container > .row {
    margin: 0px -9px;
  }
  .new-setting-wrapper .container > .row > .col-md-4,
  .new-setting-wrapper .container > .row > .col-md-8 {
    padding: 0px 9px;
  }
  .payout-balance-crd-desc > h4 {
    padding-left: 10px;
  }
  .stripe-balance-history .btn {
    max-width: 70%;
    width: 100%;
    margin: 0px auto;
  }
  .new-setting-wrapper {
    padding: 0px;
  }
  .widrawal-history .table thead tr th:first-child,
  .widrawal-history .table tbody tr td:first-child {
    /* display: none; */
  }
  .widrawal-history .table thead tr th,
  .widrawal-history .table tbody tr td {
    font-size: 16px;
  }
  .widrawal-history .table tbody tr td {
    font-weight: 300;
  }

  .text-blue-light {
    color: #007ea0 !important;
  }
  .fw-m-300 {
    font-weight: 300 !important;
  }
  .mob-view-drp {
    background: #fff;
    box-shadow: initial;
    border-radius: 9px;
    border: 1px solid #707070;
    box-shadow: var(--boxShadow);
    text-align: center;
    max-width: 95%;
    margin: 0px auto;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 9;
    right: 0;
    left: 0;
  }
  .stripe-balance-history {
    position: relative;
  }
  .mob-view-drp > a:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  .mob-view-drp > a {
    color: #29282e;
    padding: 9px 1.5rem;
    font-weight: 400;
    display: block;
  }
  .mobile-view-blurr-wrp .widrawal-history,
  .mobile-view-blurr-wrp .bl-m {
    filter: blur(1.1px);
  }
  .card-readeers--mob-view {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .card-discoversDes > p,
  .payout-balance-crd-desc > p,
  .card-reader-crd-desc > p,
  .disccover-crd-redr-descr > h4,
  .card-discover-access-code > p,
  .setting-detls-img > p {
    font-size: 1rem;
  }
  .card-discover-top {
    margin-bottom: 2rem;
  }
  .card-readerWrp > .heading-30 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  .card-readeers--mob-view .new-stng-cmn {
    padding: 1.5rem 0.5rem;
  }
  .card-reader-crd-desc .btn-black,
  .disconnects > .btn {
    max-width: 90%;
    margin: 0px auto;
  }
  .disconnects > .btn,
  .setting-detls-img .btn {
    max-width: 100%;
    width: 100%;
  }
  .discover-card-reader-lst .row > .col-5 {
    order: 2;
  }
  .discover-card-reader-lst .row > .col-7 {
    order: 1;
  }
  .discover-card-reader-lst .row > .col-12 {
    order: 3;
  }
  .tick-Check {
    display: none;
  }
  .btn-Readerss {
    display: grid;
    grid-template-columns: calc(50% - 6px) calc(50% - 6px);
    gap: 10px;
  }
  .connected-card-reder-mob {
    border: 0px none;
  }
  .saloon-dtls-setting-mob .new-stng-cmn {
    padding: 0.5rem 0.5rem 1.5rem;
  }
  .setting-sln-img > h5 {
    font-size: 24px;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  .saloon-settings-wrp h5 {
    font-size: 24px;
  }
  .setting-sln-img {
    margin-bottom: 1rem;
  }
  .setting-detls-img > p {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  .saloon-dtls-setting-mob .new-stng-cmn.sln-fmr-dtls-mob {
    padding: 0.5rem 1rem 1.5rem;
  }
  .saloon-settings-wrp.sln-fmr-dtls-mob > .form-group {
    padding-left: 0px;
  }
  .saloon-settings-wrp .form-control,
  .saloon-settings-wrp .address_input_wrap .location-search-input,
  .selct-stng__control {
    height: calc(3em + 0.75rem + 0px);
  }
  .editSaloon > .btn {
    max-width: 45%;
    width: 100%;
  }
  .booking-urlpage .new-stng-cmn {
    padding: 0rem 1.5rem 1.5rem;
  }
  .booking-urlpage .link_img > img {
    max-width: 220px;
  }
  .profile-setting-temp .file_upload {
    margin: 0px auto;
  }
  .profile-setting-temp .saloon-settings-wrp textarea.form-control {
    height: auto;
    min-height: 120px;
  }
  .profile-setting-temp .saloon-settings-wrp .form-group > label .text-grey {
    font-size: 14px;
  }
  .payout-balance-crd .btn {
    right: 10px;
    top: 1px;
    font-size: 1.5rem;
  }
  .add-staff-modal-wrp > .modal-dialog {
    max-width: 100%;
  }
  .staff-modal-wrap-left {
    padding-right: 0px;
    margin-top: 0px;
  }
  .staff-top-mdl > h1 {
    font-size: 1.5rem;
  }
  .staff-modal-wrapper {
    padding: 2.5rem 0rem 0rem 0rem;
  }
  .staff-top-mdl svg {
    width: 54px;
    position: relative;
    top: 15px;
    height: 68px;
  }
  .staff-modal-wrap-left > h3,
  .epluyed-with-radio-topbar > h3,
  .epluyed-with-radio-topbar > span {
    font-size: 1.18rem;
  }
  .staff-modal-wrap-left > p,
  .activation-code-wrp > span,
  .staff-emplyed-plan > .table tr td {
    font-size: 0.9rem;
  }
  .staff-top-mdl {
    margin-bottom: 1.5rem;
  }
  .staff-modal-wrap-left > h3 {
    margin-bottom: 0.75rem;
  }
  .staff-modal-wrap-left,
  .staff-modal-wrap-right {
    box-shadow: 0px 3px 6px #00000052;
    padding: 0px 1rem 1rem;
  }
  .staff-modal-wrap-right {
    box-shadow: 0px 1px 6px #00000052;
  }
  .staff-modal-wrap-left > p {
    line-height: 27px;
  }
  .staff-modal-wrap-left {
    margin-bottom: 2rem;
  }
  .add-staff-modal-wrp .modal-body {
    padding: 0px;
  }
  .add-staff-modal-wrp > .modal-dialog {
    margin: 0px;
  }
  .epluyed-with-radio-topbar > h3,
  .epluyed-with-radio-topbar > span {
    font-size: 1rem;
  }
  .staff-emplyed-plan > .table tr td,
  .staff-emplyed-plan > .table tr th {
    padding: 0.25rem !important;
  }
  .staff-emplyed-plan > .table tr td.code-aply > strike,
  .apply-activation-code > p {
    font-size: 0.75rem;
  }
  .epluyed-with-radio {
    padding: 1rem 1rem 1rem 0rem;
  }
  .epluyed-with-radio-topbar {
    padding-left: 4rem;
    margin-bottom: 1.5rem;
  }
  .epluyed-with-radio:before,
  .epluyed-with-radio:after {
    top: calc(0.45rem + 5px);
  }
  .epluyed-with-radio-body {
    padding-left: 1rem;
  }
  .add-acvt-input > input,
  .codeErr,
  .applyCode,
  .add-acivation-field > .btn,
  .staff-emplyed-plan > .table tr td,
  .staff-emplyed-plan > .table tr th,
  .staff-mebers-content > p {
    font-size: 0.9rem;
  }
  .staff-btn-btm {
    text-align: center;
    margin-top: 2rem;
  }
  .staff-btn-btm > .btn {
    width: 100% !important;
    max-width: 170px;
  }
  .staff-modal-wrap-right .emlpyed-bx:nth-child(2) {
    margin-bottom: 0px;
  }
  .staff-mebers-content h3 {
    font-size: 1.25rem;
  }
  .add-acivation-field > .btn {
    max-width: 115px;
    width: 100%;
  }
  .thank-you-modall-wrps-overlay .popup-content {
    width: 100% !important;
    border-radius: initial;
    border-color: rgb(112 112 92 / 0%) !important;
  }
  .staff-memb-colmn {
    margin-bottom: 1rem;
  }
  .staff-btn-btm > .btn {
    font-size: 1.1rem;
  }
  .staff-page-temp .nav-item.nav-link {
    text-align: center !important;
  }
  .staff-modal-wrap-right {
    padding-top: 1.9rem;
  }
  .add-staff-modal-wrp .modal-content > .cm_hide_mod {
    display: none;
  }
  .staff-modal-wrapper .row > .col-lg-5,
  .staff-modal-wrapper .row > .col-lg-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .hairdress_modal_body {
    padding-bottom: 100px !important;
  }

  /* --yaha se 991*/
  .top-container {
    margin: 0 !important;
    flex-wrap: wrap;
  }
  .new-tranctions-body-wrpp > .row > .col-sm-12 {
    padding: 0px 9px;
  }
  .transaction-new--wrp {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 9px;
    gap: 9px;
    margin: 4px 0px;
  }
  .transacion-container {
    width: calc(100%) !important;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }
  .right-container {
    margin: 0 auto;
  }
  .transacion-box {
    width: calc(100%) !important;
    min-height: 100px;
    max-height: 100px;
    margin: 8px auto !important;

    min-width: 110px;
  }
  .transaction-new--wrp > .transacion-box {
    min-height: inherit;
    padding: 1.25rem 0.25rem;
    max-height: initial;
  }
  .mob-btnss-div {
    display: grid !important;
    /* grid-template-columns: 83px 50px 1fr 50px; */
    grid-template-columns: 120px 50px 1fr 50px;
    grid-gap: 5px;
    gap: 5px;
    margin-bottom: 5px;
    position: relative;
  }
  button.btn.btn-dark-blue.mr-md-2 {
    font-size: 13px;
    padding: 0.2rem 0rem;
  }
  .mob-btnss-div .start-end-datepicker {
    border-color: #061e1e !important;
    height: calc(2.5em + 0.7rem + 2px);
    width: 100%;
    overflow: hidden;
    border-radius: 9px;
    order: 3;
  }
  .right-container .filter.mt-5 {
    margin-top: 0 !important;
    width: 100%;
    order: 2;
  }
  .new-tranctions-body-wrpp .analytics-search-bar {
    transition: all 0.25s;
    width: 50px;
    margin: 0px !important;
    position: absolute;
    right: 0px;
  }
  .mob-btnss-div .cm_search_wrapper.client_search.m-0.analytics-search-bar {
    width: 50px;
  }
  .analytics-search-bar.seacrh-mob--hide .form-control {
    height: calc(2em + 0.75rem + 6px);
    border-color: #061e1e !important;
  }
  .mob-btnss-div .client_search .form-control {
    border-radius: 9px;
  }
  .seacrh-mob--hide .form-control {
    text-indent: -999px;
  }
  .filter .css-yk16xz-control,
  .filter .css-1pahdxg-control {
    border-color: hsl(0deg 0% 0%) !important;
  }
  .mob-btnss-div
    .cm_search_wrapper.mt-5.client_search.m-0.analytics-search-bar.seacrh-mob--view {
    width: 100% !important;
  }
  .new-tranctions-body-wrpp .analytics-search-bar.seacrh-mob--view {
    width: 100% !important;
    transition: all 0.25s;
    transform: translateX(0px);
    bottom: 0px;
    margin: 0px !important;
  }
  .new-tranctions-body-wrpp
    .analytics-search-bar.seacrh-mob--view
    input.form-control {
    padding-left: 34px;
    border-color: #061e1e !important;
  }
  .right-container-block {
    margin: 0 0px !important;
  }
  .mob-btnss-div.showSecrhbar {
    padding-bottom: 66px;
  }
  .mob-btnss-div.showSecrhbar {
    /* grid-template-columns: 83px 50px 1fr; */
    grid-template-columns: 120px 50px 1fr;
  }
  .seacrh-mob--view .fa-search {
    right: auto;
    left: 12px;
    color: #727272;
  }
  .seacrh-mob--view .fa-close {
    padding: 0.5rem;
    top: calc(50% - 16px) !important;
    right: 7px;
  }
  /* ----yaha tak 991 */

  .tooltip_parent span.info {
    top: 0px;
  }
  .payout-balance-crd {
    margin: 0px 0.25rem;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 32%);
  }
}

@media (min-width: 481px) and (max-width: 990px) {
  .popup-content.image_modal-content {
    width: 80% !important;
  }
}

@media (max-width: 767px) {
  ._no_show_parent .currency_sml1.currency_sml_input.edit_price_input {
    padding-left: 0rem!important;
}
._markt_body {
    justify-content: center;
}
._booking_setting ._row ._fee {
  width: calc(100% - 50% - 76px);
}
.booking-urlpage .new-stng-cmn._booking_setting {
  padding: 0rem 1rem 1.5rem;
}
.__markt {
  margin: 0 0px 20px 20px;
}
._service_parent_body._marketting_parent{
  height:auto !important;
}
._service_parent_body._marketting_parent ._service_parent {
  text-align: center;
}
.new-stng-cmn._sms_reminder{
  flex-wrap: wrap;
}
._sms_reminder ._image{text-align: center;}
.new-stng-cmn._preview_reminder {
  padding: 1.5rem 0;
}
._preview_reminder ._circle {
  width: 320px;
  height: 320px;
}
._preview_reminder ._phone {
  width: 85%;
}
.new-setting-menu-bar._market_sidebar .setting-menu-bar {
  display: block;
  position: initial;
  height: auto;
}
.new-setting-menu-bar._market_sidebar .setting-menu-bar ul{
  padding-bottom: 0 !important;
}
._sms_reminder ._image ,
._sms_reminder ._text{
  width: 100%;
}
  .tooltip_parent._edit_staff_tooltip .tooltip-body {
    top: 5px !important;
    right: 0 !important;
    width: 100%;
}
.checkout__save_btn_mobile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.checkout__save_btn_mobile .btn {
  font-size: 18px;
  min-width: 150px;
}
.topbar_btn_group {
  display: none !important;
}
.cancel__mobile{display: block;}
  .main_service_div {
    width: 100% !important;
}
.fix_time_box {
  margin: 7px 0;
}
.service_flex_div.new__checkout_page {
  margin-left: 0px;
}
  ._newCheckout_wrapper .nav-link {
    min-width: 120px !important;
    }
    .navbar__space {
      margin-left: -10px;
      margin-right:0px;
  }
  .service_flex__space {
    margin-left: 16px;
}
.select__service .black_heading .table td span,
.select__service .black_heading h3{
  margin-left: 0px;
  padding-left: 0px !important;

}
  span.menu_toogle_icon svg {
    width: 40px;
    height: 40px;
  }
  .transaction-new--wrp {
    grid-template-columns: 100px 100px 100px 100px 100px;
    overflow: scroll;
    gap: 16px;
    grid-auto-flow: column;
  }
  .right-container-block .form-group .react-datepicker-wrapper,
  .start-end-datepicker.right-container-block
    .react-datepicker__input-container,
  .mob-btnss-div
    .start-end-datepicker.right-container-block
    .sm_date_picker
    input {
    height: 100%;
  }
  .mob-btnss-div
    .start-end-datepicker.right-container-block
    .sm_date_picker
    input {
    margin: 0px 0 0px;
  }
  .diary-temp .top_header {
    background: #f7f7f700 !important;
    box-shadow: none  !important;
  }
  .right-container .btn.btn-dark-blue {
    min-width: auto;
  }
  .mob-btnss-div {
    grid-template-columns: 70px 50px 1fr 50px;
  }
  .mob-btnss-div.showSecrhbar {
    grid-template-columns: 70px 50px 1fr;
  }
  .cm_25 {
    width: 100%;
    margin-right: 0;
  }
  .setting-sln-img > h5,
  .saloon-settings-wrp h5,
  .card-discoversDes > h4,
  .payout-balance-crd-desc > h4,
  .widrawal-history > h3,
  .card-readerWrp > .heading-30 {
    font-size: 1.1rem;
  }
  .search-discoveed-reader > .btn-inf {
    margin: 0px !important;
  }
  .search-discoveed-reader {
    justify-content: center;
  }
  .card-discover-top {
    margin-bottom: 1.25rem;
  }
  .disconnects > .btn,
  .setting-detls-img .btn {
    max-width: 70%;
    width: 100%;
  }
  .search-discoveed-reader p {
    font-size: 1rem;
  }
  .search-discoveed-reader .btn-inf {
    font-size: 16px;
  }
  .btn-disc,
  .btn-inf,
  .btn-line-blck,
  .card-reader-crd-desc > p,
  .btn-black,
  .btn-req,
  .stripe-blance > p {
    font-size: 0.874rem;
  }
  .stripe-balance-history > .row {
    margin: 0px -7px;
  }
  .stripe-balance-history > .row .col-4 {
    padding: 0px 7px;
  }
  .stripe-blance h4 {
    font-size: 0.75rem;
  }
  .cm_75 {
    width: 100%;
  }

  .calendar_card {
    padding: 10px !important;
  }

  .cm_mob_block {
    display: block !important;
    margin-top: 15px !important;
  }

  .cm_mob_block a.btn {
    display: block;
    margin-top: 15px;
  }

  .top_header h1 {
    text-align: center;
    font-size: 20px;
    padding-left: 40px;
    margin-top: 7px;
  }
  .MobView {
    display: block;
  }

  .transaction-new--wrp > .transacion-box h2 {
    font-size: 18px;
  }
  .transaction-new--wrp > .transacion-box p {
    font-size: 12px;
    margin-bottom: 0px;
  }
  .graph-shadow.new-tranctions-body-wrpp {
    box-shadow: initial;
    padding: 10px 15px;
  }

  .new--tabs-transactios .react-tabs__tab-list .react-tabs__tab .toptab {
    margin-right: 15px;
    border-radius: 30px;
    padding: 9px 7px 9px 12px;
    box-shadow: 0 0 6px rgb(0 0 0 / 16%);
  }
  .new--tabs-transactios
    .react-tabs__tab-list
    .react-tabs__tab.react-tabs__tab--selected
    .toptab {
    background: #29282e;
  }
  .new--tabs-transactios
    .react-tabs__tab-list
    .react-tabs__tab.react-tabs__tab--selected
    .toptab.first
    h6 {
    color: #fff;
  }
  .new--tabs-transactios .toptab h6,
  .new--tabs-transactios .toptab.first h6 {
    padding: 0 5px 0px 0px;
    font-size: 12px;
    font-weight: 200;
  }

  .new--tabs-transactios .toptab .circle-img {
    width: 32px;
    height: 32px;
    padding: 0px !important;
  }
  .new--tabs-transactios.analytics-tabsHead--wrap
    > .react-tabs__tab-list
    .react-tabs__tab:first-child
    .circle-img
    > svg {
    max-width: 20px;
    width: 20px;
  }
  .new--tabs-transactios
    .react-tabs__tab-list
    .react-tabs__tab.react-tabs__tab--selected
    .toptab.first
    .circle-img {
    background: rgb(255 255 255 / 56%);
  }
  .new--tabs-transactios
    .react-tabs__tab-list
    .react-tabs__tab.react-tabs__tab--selected
    .toptab.first
    .circle-img
    svg {
    fill: #29282e;
  }
  .transaction-dashboard-temo-new .body_container {
    padding: 7px 0px 16px;
  }
  .transaction-temp--new .new-tranctions-body-wrpp {
    padding: 0px 9px;
    border-top: none;
  }
  .transaction-temp--new .analytics-tabsHead--wrap > .react-tabs__tab-list {
    padding-left: 9px !important;
  }
  .toptab-parent .react-tabs__tab {
    padding: 0px 0px 0px !important;
  }
  /* --yaha se */
  .new-tranctions-body-wrpp > .row {
    margin: 0px -9px;
  }

  .transaction-temp--new
    .analytics-tabsHead--wrap
    > .react-tabs__tab-list
    .react-tabs__tab {
    margin-bottom: 5px;
    min-width: 120px;
  }
  .transaction-temp--new
    .analytics-tabsHead--wrap
    > .react-tabs__tab-list
    .react-tabs__tab.react-tabs__tab--selected
    .toptab
    > h6 {
    color: #fff;
    font-weight: 500;
  }
  .transaction-temp--new .react-datepicker__input-container {
    font-size: 13px;
  }

  .new-tranctions-body-wrpp > .row .transction-tble {
    padding: 0px;
  }
  .transction-tble .payment-table table td,
  .transction-tble .payment-table table th {
    font-size: 11px;
  }
  /* .seacrh-mob--view .fa-close{
  padding: 0.5rem;
  top: calc(50% - 16px) !important;
  right: 7px;
}
.seacrh-mob--view .fa-search{
  right: auto;
  left: 12px;
  color: #727272;

}
.mob-btnss-div.showSecrhbar{
  padding-bottom: 66px;
}
.mob-btnss-div.showSecrhbar{
  grid-template-columns: 83px 50px 1fr;
}
.new-tranctions-body-wrpp .analytics-search-bar.seacrh-mob--view{
  width: 100%;
  transition: all 0.25s;
  transform: translateX(0px);
  bottom: 0px;
  margin: 0px !important;
}
.mob-btnss-div .cm_search_wrapper.mt-5.client_search.m-0.analytics-search-bar.seacrh-mob--view{
  width: 100% !important;
}
.seacrh-mob--hide .form-control{
  text-indent: -999px;
}
.mob-btnss-div .client_search .form-control{
  border-radius: 9px;
}
.analytics-search-bar.seacrh-mob--hide .form-control{
  height: calc(2.0em + .75rem + 6px);
  border-color: #061E1E !important;
}

.mob-btnss-div .cm_search_wrapper.client_search.m-0.analytics-search-bar {
  width: 50px;
}
.new-tranctions-body-wrpp .analytics-search-bar{
  transition: all 0.25s;
  width: 50px;
  margin: 0px !important;
  position: absolute;
  right: 0px;
}
.right-container .filter.mt-5 {
  margin-top: 0 !important;
  width: 100%;
  order: 2;
}
.mob-btnss-div .start-end-datepicker{
  border-color: #061E1E !important;
  height: calc(2.5em + 0.5rem + 2px);
  width: 100%;
  overflow: hidden;
  border-radius: 9px;
  order: 3;
}
button.btn.btn-dark-blue.mr-md-2 {
  font-size: 13px;
  padding: 0.2rem 0.0rem;
}
.mob-btnss-div{
  display: grid;
  grid-template-columns: 83px 50px 1fr 50px;
  gap: 5px;
  margin-bottom: 5px;
  position: relative;
}
.transaction-new--wrp > .transacion-box{
  min-height: inherit;
  padding: 1.25rem 0.25rem;
  max-height: initial;
}
.transaction-new--wrp{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 9px;
  margin: 4px 0px;
}
.new-tranctions-body-wrpp > .row > .col-sm-12{
  padding: 0px 9px;
} */
  /* ----yaha tak 767 */
  .mob-btnss-div .btn-searchmob {
    border: 1px solid #061e1e;
    width: 50px;
    padding: 5px;
    height: 50px;
  }

  .filter-search-parent.mt-5 {
    margin-top: 0px !important;
  }

  .filter .dropdown .filter-box.dropdown-toggle.btn.btn-primary {
    width: 100%;
    height: calc(2em + 0.75rem + 6px);
    border-color: #061e1e !important;
  }

  .mob-btnss-div > .btn {
    border-radius: 9px;
  }

  .transction-tble .pagination-wrapper {
    justify-content: center;
  }

  .seacrh-mob--view .form-control {
    padding-left: 36px;
    height: calc(2em + 0.75rem + 10px);
    border-color: #061e1e !important;
    border-radius: 9px !important;
    font-size: 14px;
  }
  .clr-blue {
    color: #117d9d !important;
  }
  .clr-blue .bottom-corner svg path {
    fill: #117d9d;
  }
  .analytics-tabsHead--wrap .btn-dark-blue {
    padding: 0.688rem 0.75rem;
  }
  .mob-btnss-div .start-end-datepicker .sm_date_picker input {
    margin: 5px 0 3px;
    padding: 3px;
    font-size: 11px;
  }

  .seacrh-mob--hide .fa-close {
    display: none;
  }
  .seacrh-mob--hide .fa-search {
    color: #727272;
    right: auto;
    left: 0px;
    width: 100%;
    text-align: center;
    height: 100%;
    top: 0px !important;
    line-height: 50px;
  }

  .analytics-search-bar.seacrh-mob--hide .form-control:focus {
    height: calc(2em + 0.75rem + 6px) !important;
  }
  .widrawal-history .table tr td {
    word-break: break-all;
  }
  .widrawal-history .table tbody tr td,
  .widrawal-history .table thead tr th {
    padding: 1rem 0.75rem;
    font-size: 0.8rem;
  }
  .widrawal-history .table {
    table-layout: fixed;
  }
  .widrawal-history .table .W-date {
    width: 25%;
  }

  .widrawal-history .table .W-refr {
    width: 50%;
  }
  .widrawal-history .table .W-amt {
    width: 25%;
  }
  .widrawal-history .table thead tr th:first-child,
  .widrawal-history .table .W-refr,
  .widrawal-history .table .W-amt,
  .widrawal-history .table td {
    width: 130px;
    text-align: center !important;
  }
  .widrawal-history .table thead tr th:nth-child(3),
  .widrawal-history .table tr td:nth-child(3) {
    /* background: pink; */
    width: 85px;
  }
}
@media screen and (device-aspect-ratio: 9/16) {
  .transaction-temp--new .start-end-datepicker .sm_date_picker input:focus {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .shift_day_modal .modal-body {
    padding: 1.1rem 0.4rem !important;
  }
  ._shift_day_time .form-group.sm_date_picker {
    width: calc(100% / 2 - 50px);
  }
  .shift_day_modal .modal-body .btn-group-container .btn {
    min-width: 70px;
}

  .payout_table._payment_method .table ._card_row td ,
  .payout_table._payment_method .table ._card_row td:first-child{
    min-width: 60px !important;
  } 
  .payout_table._payment_method .table ._card_row td:nth-child(3){
    min-width: 82px !important;
  } 
  .salon_switch_container p {
    margin-left: 10px;
  }  
  div#appleid-signin img {
    width: 100%;
    height: 51px;
    object-fit: cover;
  }
  #appleid-signin {
    border-radius: 4px;
    overflow: hidden;
  }
  .cm_search .form-group {
    width: 100%;
  }
  .my-forms {
    min-width: 100%;
    max-width: 100%;
  }
  .cm_search {
    margin-bottom: 0px;
  }
  .cm_sort {
    width: 100%;
    padding-right: 30px;
    margin-top: -20px;
  }

  .sortingdiv {
    margin-top: 0px;
    width: 100%;
  }
  .account-table table tr td {
    padding-left: 10px;
  }
  .booking_dateinfo .review_star span {
    font-size: 16px;
  }
  .popup-content.image_modal-content {
    width: 80% !important;
  }
  .date_info h5 {
    font-size: 12px;
  }
  .booking_dateinfo .date_info h5 {
    text-align: left !important;
  }
  .btn-edit {
    margin-left: 0px;
    margin-right: 20px;
  }
  .review-btn {
    float: none;
  }
  .pad-L0,
  .pad-R0 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .cm_box {
    padding: 0px 12px;
  }
  .review-btn .btn-edit {
    margin-left: 0px;
    margin-right: 12px;
  }
  .client_review_first .push {
    margin-left: 0;
  }
  .notfound .notfound-404 h1 {
    font-size: 100px !important;
    letter-spacing: 0px !important;
    margin-left: -20px;
  }
  .home,
  .home-search {
    margin-left: 0px;
    margin-right: 0px;
  }
  .cm_team-round-img {
    width: 100px;
    height: 100px;
  }
  .team-round-img {
    top: 45%;
  }
  .date_info h6 {
    text-align: left !important;
  }
  .btnsml-screen {
    width: 100%;
    margin-top: 12px;
  }
  .btnsml-screen-center {
    width: 100%;
    text-align: center;
    margin-top: 12px;
  }
  .accountleft p {
    padding: 12px 0px;
  }
  .instant , .standard{
    margin: 2rem 2rem 0rem 2rem !important;
  }
  .modal_div, .modal_div2 {
    display: inherit !important;
 }
 .fade.payout_modal.modal .modal-footer {
  justify-content: center;
 }
 .fade.payout_modal.modal .modal-footer .payout_btn,
 .fade.payout_modal.modal .modal-footer .payout_cancel_btn{
  font-size: 12px;
  margin:.4rem ;
  padding: 0.5rem 1rem;
  min-width: 130px;
 }
 .payout_modal>div>.modal-content {
  background-color: white;
  height: 100% !important;
  /* overflow-x: hidden; */
}
.fade.payout_modal.modal.show .modal-dialog {
  margin: 0rem
}
/* .payout_btn {
  border: 1px solid #007fa0;
} */

}

@media (min-width: 541px) and  (max-width: 767px) {
  .instant , .standard{
    margin: 2rem 2rem 0rem 2rem !important;
  }
  .fade.payout_modal.modal .modal-footer .payout_btn,
  .fade.payout_modal.modal .modal-footer .payout_cancel_btn{
   font-size: 12px;
   margin:.4rem ;
   padding: 0.5rem 1rem;
   min-width: 130px;
  }
  .fade.payout_modal.modal .modal-footer {
    justify-content: center;
  }
  /* .payout_btn {
    border: 1px solid #007fa0;
  } */
}

@media (max-width: 767px) {
  .btnsml-screen-center .btn-delete, .btnsml-screen-center .btn-delete:focus{
    margin-top: 0px;
  }
  .btnsml-screen-center{padding-bottom: 25px;}

  .other_staff.mt-md-4.addservice-popup {
    overflow-x: auto;
}
.other_staff .cm_tab_row.serv-tab-mobl{
  min-width: 450px;
}
.other_staff .cm_tab_row.serv-tab-mobl .cm_main_col{
  width: 150px;
}
  .other_staff .cm_tab_row.serv-tab-mobl .cm_main_col_2{
   width: 80px;
   display: flex;
    align-items: center;
    justify-content: center;
  }
  .other_staff .cm_tab_row.serv-tab-mobl .cm_main_col_3{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right:12px
   }
  .other_staff .cm_tab_row.serv-tab-mobl .cm_main_col_2 .cm_table_input ,
  .cm_main_col_2 .__drop_down{
    width: 100%;
}
.other_staff .cm_tab_row.serv-tab-mobl .cm_main_col_3 .cm_table_input {
  width: 85%;margin-left: 0;
}
.other_staff .cm_tab_row.serv-tab-mobl .cm_main_col_3 i {
  right: 0px;
  top: 56%;
}
.other_staff .cm_tab_row.serv-tab-mobl .custom__control{
  padding: 0;
  border-radius: 6px;
  width: 100%;
  height: calc(2.5em + 0.75rem + 4px);
  /* background: pink; */
}
.other_staff .cm_tab_row.serv-tab-mobl .custom__indicator.custom__dropdown-indicator {
  padding: 0 3px 0 0;
}
.other_staff .cm_tab_row.serv-tab-mobl  .cm_b_span {
  display: flex;
  height: 100%;
  width: 100%;
  /* background: pink; */
  align-items: center;
  justify-content: center;
}
  /* ------------react bootstrap modal------------ */
  .fade.modal.show {
    padding-right: 0px !important;
  }
  .right-container .filter .multiselect-container.dropdown-menu.show {
    display: none;
  }
  .filter .dropdown-menu {
    min-width: 253px;
  }
  .menu_open .menu_ovelay {
    position: fixed;
    height: 100%;
    width: calc(100% - 250px);
    right: 0;
    top: 0;
    z-index: 999;
    cursor: pointer;
  }

  .profile .modal-body {
    padding: 1rem;
  }
  .modal-open .modal::-webkit-scrollbar,
  .modal-open::-webkit-scrollbar {
    width: 0px !important;
    height: 0;
    background-color: #f5f5f5;
  }
  .popupscroll::-webkit-scrollbar {
    width: 0px !important;
  }
  body.modal-open {
    /* position: fixed; */
    height: 100%;
    overflow: hidden !important;
  }
  .popupscroll {
    overflow-y: auto;
    height: calc(100vh - 2px);
    width: 100%;
  }
  .fade.modal.show {
    padding-left: 0 !important;
  }

  .calender_popupbg-content,
  .calender_popupbg {
    background: #dcdcdc !important;
  }
  /* ------------react bootstrap modal------------ */

  .footer-section {
    min-height: calc(100vh - 135px);
  }
  .gmap_canvas {
    height: 23vw;
  }
  .cm_search {
    padding-left: 25px;
  }
  .sortingdiv {
    margin-left: 10px;
    float: left !important;
    margin-top: 20px;
  }
  .home-box {
    margin: 15px;
  }
  .my-forms {
    margin-top: 12px;
  }
  .services-offered tr td:first-child {
    width: 50%;
  }
  .member_discription {
    text-align: center;
    padding: 0 12px;
  }
  .services-offered .cm_table {
    margin: 20px 0px;
  }
  .logo {
    position: absolute;
    right: 1px;
    text-align: right;
    padding-right: 2px;
  }
  .rating-header > span:nth-child(1) {
    font-size: 40px;
  }
  span.Star span {
    font-size: 24px;
  }
  .rating-header > span:nth-child(3) {
    font-size: 25px;
  }
  .mobile_trigger {
    display: block;
  }
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
    width: 30px !important;
    height: 30px !important;
    max-width: 30px !important;
    font-size: 14px;
  }
  .navbar-collapse {
    position: fixed;
    width: 250px !important;
    height: calc(100vh - 60px);
    left: -250px;
    top: 60px;
    background: #ffffff;
    text-align: left;
    padding-top: 30px;
    transition: all 0.3s;
    z-index: 9;
    display: block !important;
  }

  .react-tabs__tab-list::-webkit-scrollbar,
  .cm_calendar .modal-body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .react-tabs__tab-list::-webkit-scrollbar-track,
  .cm_calendar .modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
  }
  .react-tabs__tab-list::-webkit-scrollbar-thumb,
  .cm_calendar .modal-body::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 1px solid transparent;
  }

  .menu_open .navbar-collapse {
    left: 0;
  }
  .navbar-collapse ul li {
    display: block;
    padding: 0 15px;
    margin: 0 0 20px;
  }
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #000;
  }
  .phn-name {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .cm-navbar {
    padding: 0.5rem 1rem;
  }
  .services-offered th,
  td {
    padding: 14px 0px;
    text-align: left;
    font-size: 14px;
  }
  .client_review {
    height: auto !important;
  }
  .services-offered .cm_table {
    margin: 0px 0px 26px;
  }
  .mT80 {
    margin-top: 0px;
  }
  .cm_bookin_deatils {
    padding: 30px 28px;
  }
  .p12 .text-left,
  .p12 .text-right {
    text-align: left !important;
  }
  button.view_service_btn {
    padding: 12px 40px 12px 18px;
    margin-top: 20px;
  }
  .flex-container {
    justify-content: space-around;
    padding: 12px 0px;
  }
  .my_pro {
    margin-bottom: 20px !important;
  }
  .account_img {
    text-align: center;
    max-width: 140px;
    margin: 0 auto;
    margin-bottom: 25px;
  }
  .hide_smallscreen {
    display: none;
  }
  .nav-item.dropdown .dropdown-menu {
    display: block !important;
  }
  .cm_bookin_deatils {
    padding: 0px;
  }
  ul.booking .react-tabs__tab {
    font-size: 25px;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
  .react-tabs__tab--selected:after {
    top: 34px;
  }
  .react-tabs__tab--selected:after {
    width: 50%;
    transform: translate(50%, 50%);
  }
  /* -----------------------------------css for new phone design--------------------------- */
  .cm_bg_home {
    margin: 10px auto;
  }
  .photobox {
    justify-content: center;
  }
  .dropdown-toggle::after {
    display: none;
  }
  button.multiselect.dropdown-toggle.btn.btn-default:after {
    display: block;
  }
  .filter
    span.multiselect-native-select
    .btn-group
    button.multiselect.dropdown-toggle.btn.btn-default {
    border: 1px solid #313131 !important;
    width: 50px;
    height: 49px;
  }
  button.multiselect.dropdown-toggle.btn.btn-default:after {
    padding: 0.6rem;
    background-size: 92%;
  }

  .home-box {
    border-radius: 10px;
    padding: 6px;
    box-shadow: 0px 0px 9px #8888884a;
    margin: 10px -6px;
    min-height: 1px;
  }
  .sortingdiv p span img {
    width: 18px;
  }
  .homerating {
    margin-bottom: 8px;
  }
  .rating-header > span:nth-child(1) {
    font-size: 22px;
  }
  .rating-header > span:nth-child(3) {
    font-size: 19px;
  }
  svg.widget-svg {
    width: 20px !important;
    height: 20px !important;
    margin-top: -5px;
  }
  .homeimge-height {
    height: 216px !important;
  }
  .p12 {
    padding: 5px 0px;
  }
  h4.light_text {
    margin-bottom: 3px;
  }
  .p12 .text-right p {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .photobox img {
    height: 110px;
    border-radius: 12px;
  }
  button.view_service_btn {
    padding: 0px 40px 3px 18px;
  }
  button.view_service_btn p {
    margin-bottom: 0px;
  }
  .flex-container {
    justify-content: space-around;
    padding: 12px 0px 5px;
  }
  .searching_web {
    display: none;
  }
  .phone-search {
    display: block;
  }
  .search-mob-pad {
    padding: 0px 8px;
  }
  .searching_mob .form-group {
    width: 100%;
    min-width: 100%;
    margin-bottom: 0px;
  }
  .searching_mob .form-group .my-forms {
    background: #7c999a;
    margin-top: 8px;
    font-family: Arial, FontAwesome;
    font-weight: 100;
    min-width: 99%;
  }
  .searching_mob .form-group:first-child .my-forms {
    margin-top: 0px;
  }
  .search-mob-pad .sortingdiv {
    background: #7c999a;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    margin-left: 0px;
    width: 99%;
  }
  .search-mob-pad .sortingdiv p {
    margin-bottom: 0px;
    background: #7c999a;
    padding: 7px 14px;
  }

  .small_searchbox {
    padding: 0px 8px;
    display: flex;
  }
  .small_searchbox .search,
  .small_searchbox .sort {
    background: #7c999a;
    font-size: 14px;
    padding: 10px 6px;
    border-radius: 6px;
    border: none;
    color: #fff;
    text-align: center;
    cursor: pointer;
  }
  .small_searchbox .search {
    width: calc(100% - 47px);
    margin-right: 2%;
    text-align: left;
  }
  .small_searchbox .sort {
    width: 46px;
    padding: 3px 6px;
  }
  .small_searchbox .sort img {
    width: 30px;
    height: auto;
  }
  .search .fa-circle {
    font-size: 5px;
    margin: 0 8px;
  }

  .autocomplete-dropdown-container {
    display: block;
    width: 100%;
    position: static;
    z-index: 99;
    background: transparent !important;
    color: #fff;
    overflow-wrap: break-word;
  }

  .suggestion-item,
  .suggestion-item--active {
    display: block !important;
    width: 100% !important;
    position: static !important;
    z-index: 99 !important;
    color: #fff !important;
    overflow-wrap: break-word !important;
    font-size: 14px !important;
    padding: 10px 0 !important;
    background: #7c999a !important;
    border-bottom: 1px solid #b1b1b1 !important;
  }

  .suggestion-item:last-child,
  .suggestion-item--active:last-child {
    border-bottom: none !important;
    border-radius: 0px 0px 6px 6px !important;
  }

  .suggestion-item:first-child,
  .suggestion-item--active:first-child {
    border-top: 1px solid #fff !important;
    border-radius: 6px 6px 0px 0px !important;
  }

  .cm_border_r_none .my-forms {
    border-radius: 6px 6px !important;
    padding-left: 30px;
  }

  .cm_border_r_none .my-forms:focus {
    outline: none !important;
  }

  .cm_i {
    position: absolute;
    top: 20px;
    left: 6px;
    color: #fff;
    font-size: 14px;
  }

  span.autoc_item {
    display: block;
    padding-left: 30px;
  }

  .dropdown-menu {
    background-color: #fff;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .dropdown-item,
  .dropdown-item:hover {
    color: #000000;
    text-decoration: none;
    background-color: transparent;
    border-top: 1px solid #eaeaea;
  }

  .dropdown-item:last-child {
    border-bottom: 1px solid #eaeaea;
  }

  .nav-item.dropdown .dropdown-menu {
    margin: 0 -15px;
  }
  .clnt-dtl-chkut {
    padding-top: 0px;
    margin-bottom: 1.5rem !important;
  }
  .bkng-hstry-erp.cm_client_val {
    display: flex !important;
    flex-wrap: wrap;
  }
  .bkng-hstry-erp > div {
    width: 50%;
  }
  .bkng-hstry-erp > div > .cm_fw_b {
    padding-top: 0px;
  }
  .serv-tab-mobl > .cm_main_col > p {
    max-width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .serv-tab-mobl > .cm_main_col_3 i {
    transform: translateY(-90%);
    right: 8px;
  }
  .mobv-blck {
    display: block;
    width: 100%;
    max-width: 193px;
    margin: 0 auto 15px;
  }
  .cm_hours_popup.stff-modal-hour button.close {
    right: 0px;
    top: -10px;
  }
  .cm_hours_popup.stff-modal-hour .modal-title {
    font-size: 1.25rem;
  }
  .user_pic-profile > img {
    max-width: 100%;
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .user_pic-profile {
    border-bottom: 1px solid #fff;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
  }
  .user_pic-profile > span {
    font-size: 17px;
  }
  .clnt-booking-view-mblvwe .cm_bao {
    margin: 0;
  }
  .clnt-booking-date > p {
    font-weight: 600;
    font-size: 1.05rem;
  }
  .clnt-booking-date {
    margin-bottom: 1rem;
  }
  .cmn-listbkng-dtls {
    background: #f7f7f7;
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 0.5rem;
  }
  .cmn-listbkng-dtls > span {
    font-size: 1rem;
  }
  .cmn-listbkng-dtls > p {
    font-size: 1.25rem;
  }
  .time-cast-bkng {
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr;
    grid-column-gap: 11px;
  }
  .show-bkng-wrp .cm_btns .btn {
    width: 50%;
    margin: 0px 13px !important;
  }
}

@media (min-width: 992px) {
  .__service_edit_modal.shift_day_modal .modal-dialog.modal-lg {
    max-width: 665px;
  }
  .new_appointment {
    display: flex;
    justify-content: center;
    padding-left: 0;
    align-items: center;
  }
  .new_appointment .tab_content_wrapper {
    width: auto;
  }
  .new_appointment .cm_right_panal {
    width: 361px;
    margin-bottom: 0;
    padding: 8rem 0 0 4rem !important;
  }
  .booking_card.cm_wrap_text {
    min-height: 15px !important;
    margin: 0;
    line-height: 15px !important;
    padding-top: 1px !important;
    padding-bottom: 3px !important;
  }
  .booking_card.bkng-crd-dary-new h5 {
    font-size: 11px !important;
    font-weight: 500;
  }
  .booking_card.bkng-crd-dary-new .cm_sdule_time {
    bottom: 0px;
    text-align: right;
    font-size: 10px;
    margin-bottom: 0 !important;
  }
  .new-setting-wrapper > .row > .col-md-3 {
    flex: 0 0 27%;
    max-width: 27%;
  }
  .new-setting-wrapper > .row > .col-md-9 {
    flex: 0 0 73%;
    max-width: 73%;
  }
}

.sidenav_toggler {
  position: absolute;
  left: -60px;
  top: 0;
  font-size: 22px;
  background: #caebee;
  width: 60px;
  height: 70px;
  line-height: 63px;
  text-align: center;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1400px) {
  .date-filter-icon-body_mobile {
    right: -17% !important;
  }
}

.booking_card.cm_wrap_text {
  padding: 7px 15px;
  min-height: 45px;
  /* margin: 0; */
}

.booking_card.cm_wrap_text .cm_sdule_time {
  /* top: 4px; */
}

.booking_card.cm_wrap_text h5 {
  font-size: 14px;
  margin: 0;
}

input.form-control.is_readonly {
  background: rgba(230, 230, 230, 0.51);
  border: 1px solid #dee2e6 !important;
}

.img-browse {
  display: none;
}

.full_btn_with .dropdown button {
  width: 100%;
}

.full_btn_with .dropdown {
  width: 50% !important;
  margin-right: 10px;
}

.full_btn_with .dropdown-menu {
  background-color: #fff;
  border-radius: 9px;
  box-shadow: none;
}
.drp-wrp.dropdown .dropdown-menu {
  padding: 0px !important;
  width: 100%;
}
.drp-wrp.dropdown .dropdown-toggle:after {
  content: "\f078";
  font-family: "FontAwesome";
  border: 0px none !important;
  font-size: 12px;
  margin-left: 0.75rem;
  vertical-align: text-top;
}
.drp-wrp.dropdown .dropdown-toggle[aria-expanded="true"]:after {
  content: "\f077";
}
.drp-wrp.dropdown .dropdown-toggle[aria-expanded="false"]:after {
  content: "\f078";
}
.drp-wrp.dropdown .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0px none;
}

.full_btn_with .dropdown-item,
.full_btn_with .dropdown-item:hover {
  color: #000000;
  border-bottom: 1px solid #dfdfdf;
}

.full_btn_with .show > .btn-outline-dark.dropdown-toggle,
.full_btn_with .dropdown button:focus,
.full_btn_with .dropdown button:hover {
  background: transparent !important;
  color: #000 !important;
  box-shadow: none !important;
}

@media (max-width: 575px) and (min-width: 320px) {
  #_custom .__markt .cursor-pointer p,
#_reminders .__markt .cursor-pointer p,
#_updates .__markt .cursor-pointer p,
#_sales .__markt .cursor-pointer p {
  height: auto;
}
.cancel_appointment_modal_parent .modal-header button.close {
  top: 25px !important;
}
.__markt {
  width: 100%;
}
  .fix_mb_cross svg{
    position: initial;
  }
  .rwdpDayPickerContainer {
    padding: 25px 15px 0px !important;
  }
  .rwdpNextWeekArrow,
  .rwdpPrevWeekArrow {
    border-top: 15px solid transparent !important;
    border-bottom: 15px solid transparent !important;
    top: calc(50% - 12px) !important;
  }
  .rwdpPrevWeekArrow {
    border-right: 15px solid #061e1e !important;
    left: 0;
  }
  .timeslot {
    min-height: auto;
  }
  .rwdpNextWeekArrow {
    border-left: 15px solid #061e1e !important;
    right: 0;
  }
  .rwdpDayBoxDesktop .rwdp-DayCircle-container {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
    width: 35px !important;
    height: 35px !important;
    max-width: 35px !important;
    min-width: 35px;
    min-height: 35px;
    margin: 0px 2px 0px 2px;
    font-size: 10px;
    font-weight: 600;
  }
  svg.widget-svg {
    width: 24px !important;
    height: 24px !important;
    transition: transform 0.2s ease-in-out 0s;
  }
  .add_btn.__add_btn .btn {
    min-width: 100% !important;
    width: 100% !important;
    text-align: center;
    }
  .cm_search_wrapper.client_search.search_product._product_search {
    position: relative !important;
        min-width: 100% !important;
        left: 0;
   }
   .cm_search_wrapper.client_search.search_product._product_search i.fa.fa-search {
    right: 12px;
    top: 50% !important;
    transform: translate(0px, -50%) !important;
   }
  /* ------------------making weakdY ON TOP---------- */
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center
    strong,
  .gray-holiday strong {
    position: absolute;
    top: -68%;
  }
  /* .timeslot ul {
  flex-wrap: nowrap;
  overflow: auto;
  width: 100%;
} */
  .timeslot ul li,
  .timeslot ul li a {
    font-size: 12px;
    background: #91dedd;
    color: #061e1e;
    width: 55px !important;
    border-radius: 8px;
    margin: 9px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding: 6px 10px;
  }
}

@media (max-width: 1199px) and (min-width: 991px) {
  .popup-content.image_modal-content {
    width: 50% !important;
  }
}
@media (max-width: 990px) and (min-width: 768px) {
  .__markt {
    width: calc(100% / 3 - 20px);
  }
  /* .gmap_canvas {
    height: 25vw;
  } */
  /* .date-filter-icon-body_mobile {
    right: -10%;
    transform: translate(10%);
} */
  .account-table table tr td {
    padding-left: 10px;
  }

  .cm_team-round-img {
    width: 124px;
    height: 124px;
  }

  .btn-edit {
    margin-left: 0px;
  }
  .btnsml-screen-center .btn.btn-edit {
    margin-left: 20px;
  }
}
@media (max-width: 991px) and (min-width: 767px) {
  .home,
  .home-search {
    margin-left: 0px;
    margin-right: 0px;
  }
  .user_pic-profile > img {
    max-width: 100%;
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .user_pic-profile {
    border-bottom: 1px solid #fff;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
  }
  .user_pic-profile > span {
    font-size: 17px;
  }
  .p12 .text-left,
  .p12 .text-right {
    text-align: left !important;
  }
  button.view_service_btn {
    padding: 12px 40px 12px 18px;
  }
  .flex-container {
    justify-content: space-around;
    padding: 12px 0px;
  }
}
@media (min-width: 1200px) and (max-width: 1280px) {
  .view_service_btn {
    font-size: 13px;
    min-width: 121px;
    padding-right: 24px;
  }
  .view_service_btn .fa-angle-right {
    font-size: 21px;
    position: absolute;
    right: 4%;
  }
}
@media (max-width: 1673px) and (min-width: 1200px) {
  .editmyaccount-content {
    width: 53% !important;
  }
}
@media (max-width: 1199px) and (min-width: 991px) {
  .editmyaccount-content {
    width: 80% !important;
  }
}

@media (max-width: 1332px) and (min-width: 1200px) {
  ul.booking .react-tabs__tab {
    font-size: 24px;
  }
}
@media (max-width: 1480px) and (min-width: 1333px) {
  ul.booking .react-tabs__tab {
    font-size: 30px;
  }
}

@media (max-width: 1530px) and (min-width: 1481px) {
  ul.booking .react-tabs__tab {
    font-size: 31px;
  }
}

@media (min-width: 766px) {
  .cm_modal {
    overflow: auto;
    /* max-height: calc(100vh - 80px); */
    max-height: 100vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.pink {
    margin-top: -14px;
  }
}
@media (max-width: 575px) {
  .service_flex__space {
    margin-left: 0px;
}
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.pink {
    margin-top: -10px;
  }
  .hideBigscreen {
    display: block;
    margin-top: 20px;
    text-align: center;
  }
  .hideSmallscreen {
    display: none;
  }
}
.contentBg body {
  background: white;
}
@media (max-width: 767px) {
  .image-gallery-thumbnail {
    width: 105px;
  }
  .booking_card.bkng-crd-dary-new h5{display: flex !important;}
  .booking_card.bkng-crd-dary-new h5 ._card_icon_sm{
   margin-right: 6px;
  }
  .booking_card.bkng-crd-dary-new .cm_sdule_time {
    margin-bottom: 2px;
}

}

@media (max-width: 767px) {
  .react-datepicker-wrapper {
    min-width: 100%;
  }
  .cldr-date-mobile .react-datepicker-wrapper {
    min-width: auto;
  }
  .react-datepicker__input-container {
    min-width: 100%;
  }

  .mobile_trigger .fa-bars {
    font-size: 32px;
  }

  .col-sm-4.order-sm-2 {
    position: static;
  }

  .cm_pos_relative_ {
    position: relative;
    padding-top: 15px;
  }
}
@media (min-width: 1010px) and (max-width: 1068px) {
  .cm_search {
    padding-right: 15px;
  }
}
@media (min-width: 10px) and (max-width: 1024px) {
  .my-forms {
    min-width: 148px;
    max-width: 148px;
  }
}
/* @media all and (orientation:portrait) {

  } */

.calendar_card {
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.24);
  background: #fff;
  max-width: 600px;
  margin: 0 auto;
  padding: 15px 30px;
  border-radius: 9px;
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  top: 10px;
  z-index: 9999;
}

.calendar_card p {
  border-radius: 8px;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-top: 5px;
  font-size: 20px;
  font-weight: 500;
  padding-top: 4px;
  cursor: pointer;
  position: relative;
}

@media (min-width: 1100px) and (max-width: 1250px) {
  .calendar_card {
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.24);
    background: #fff;
    max-width: 400px;
    margin: 0 auto;
    padding: 15px 30px;
    border-radius: 9px;
    text-align: center;
    position: absolute;
    left: 62%;
    transform: translateX(-53%);
    width: 100%;
    top: 10px;
    z-index: 9999;
  }
  .user_pic {
    position: absolute !important;
    right: 27px !important;
    top: 9px !important;
  }
}

@media (min-width: 1251px) and (max-width: 1500px) {
  .calendar_card {
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.24);
    background: #fff;
    max-width: 500px;
    margin: 0 auto;
    padding: 15px 30px;
    border-radius: 9px;
    text-align: center;
    position: absolute;
    left: 58%;
    transform: translateX(-53%);
    width: 100%;
    top: 10px;
    z-index: 9999;
  }
}

.current_date p {
  background: #bcebea;
}

.calendar_card h4 {
  min-width: 125px;
  background: #000;
  color: #fff;
  padding: 4px 26px;
  border-radius: 10px;
  margin-left: 36px !important;
  margin-right: 36px !important;
}
.calendar_card h4 .icon svg{
  fill:#fff;
  width: 15px;
    height: 15px;
    transform: rotate(-90deg);
    margin-left: 10px
}
.calendar_card h4 .icon.open svg{
  transform: rotate(90deg);
}
p.cm_gary {
  opacity: 0.3;
}

.calendar_card p:after {
  content: "";
  width: 6px;
  height: 6px;
  background: #061e1e;
  position: absolute;
  bottom: 9px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
}

.cm_holiday p {
  background: #efefef;
}

.calendar_card i {
  cursor: pointer;
}

.cm_weekend p {
  background: #e6ffff;
}

.diary-parent {
  position: relative;
  padding-top: 10px;
  /* padding-left: 65px; */
  height: calc(100vh - 215px);
  margin-top: 86px;
}

.times_no {
  width: 65px;
  text-align: right;
  margin-top: 5rem;
  position: sticky;
  left: 0px;
}

.times_no > div {
  min-height: 30px;
  padding: 0 15px;
  position: relative;
  top: 13px;
  /* top: -29px; */
}

.cm_width_25 .cm_body,
.cm_width_25 .cm_footer {
  max-width: 270px;
  display: inline-block;
  width: 100%;
}

.cm_width_25 {
  text-align: right;
}

/* .cm_line_w {
  width: calc(100vw - 187px);
  height: 1px;
  background: #707070;
  position: absolute;
  bottom: -13px;
  left: 65px;
} */

/* .nav_menu_open .cm_line_w {
  width: calc(100vw - 127px);
} */

.body_container.cm_bg_white {
  background: #fff;
  min-height: calc(100dvh - 68px);
}

.booking_card {
  background: #d6d5ec;
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  z-index: 9;
  min-height: 45px;
}

.booking_card_wrapper {
  padding: 0 15px;
  background-size: 58px;
}

.booking_card_wrapper > .d-flex {
  position: relative;
}

.cm__col {
  position: relative;
  width: calc(100vw / 3 - 90px);
  background-image: url("./assets/images/notpad_bg.png");
  background-size: 60px;
}

table.daily-schedule thead {
  display: none !important;
}

.booking_card_wrap {
  height: 175px;
  padding: 5px;
}

.cm__border {
  border-right: 1px solid #707070;
}
.booking_card img {
  position: absolute;
  right: 14px;
  top: 15px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.booking_card h5 {
  /* padding-right: 50px; */
  padding-right: 30px;
}

.booking_card.cm_wrap_text h5 {
  display: inline-block;
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.cm_sdule_time {
  position: absolute;
  right: 15px;
  bottom: 10px;
  margin: 0;
  font-weight: 500;
  display: flex;
    justify-content: space-between
}
._card_icon_sm svg{
  width: 10px;
    height: 11px;
}
._card_icon svg{
  width: 14px;
  height: 14px;
  margin-bottom: 4px;
}
.cm_sdule_time._p__full {
  width: 98%;
}

.cm__boxs {
  border: 1px solid #b0b3b5;
  border-radius: 5px;
}

.cm__box h5 {
  padding: 15px;
  margin: 0;
  border-bottom: 1px solid #ddd;
  font-size: 28px;
  font-weight: 300;
}

.cm__box table {
  width: 100%;
}

.i_width {
  width: 53px;
}
.i_width svg {
  cursor: pointer;
  width: 21px;
}

.i_width .fa-check-circle {
  font-size: 23px;
  color: #061e1e;
}

ul.walk_client {
  margin: 0 -30px;
  padding: 0;
  list-style: none;
}

ul.walk_client li {
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  padding: 15px;
  padding-left: 75px;
  position: relative;
}

ul.walk_client li p {
  margin: 0;
}

ul.walk_client li:hover {
  background: #dff1f4;
  cursor: pointer;
}

.client_checkout .tab-pane {
  padding: 0 15px;
}

li.add_client_btn {
  background: rgba(191, 228, 234, 0.5);
  padding: 14px !important;
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
}

ul.walk_client li b {
  position: absolute;
  left: 23px;
  top: 42%;
  font-size: 44px;
  text-transform: lowercase;
  transform: translateY(-50%);
  line-height: 1;
}

li.add_client_btn svg {
  display: inline-block;
  width: 18px;
  margin-right: 6px;
}

li.add_client_btn svg rect {
  fill: #000;
}

.client_search .form-control {
  border-radius: 10px;
  min-width: 380px;
}

.client_table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  background: #fff;
  margin-top: 30px;
  border-radius: 5px;
  padding: 5px 0;
}

.client_table th {
  border: 0 !important;
}

.client_table img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.cm_client_val,
.client_detail_wrapper .accordion {
  margin-left: -30px;
  margin-right: -30px;
}

.client_detail_wrapper .card {
  border-radius: 0;
}

.cm_client_val {
  border-top: 1px solid #e8e8e8;
  padding: 15px;
  margin-top: 30px;
}

.cm_client_val > div {
  padding: 0 20px;
  text-align: center;
}

ul.some_detail {
  margin: 0 -30px;
  padding: 0;
}

ul.some_detail li {
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 15px;
  position: relative;
}

ul.some_detail li:last-child {
  border-bottom: 0;
}

ul.some_detail li p {
  margin: 0;
}

li.cm_bg_date > div {
  position: absolute;
  background: #dff1f4;
  left: 0;
  top: 0;
  height: 100%;
  padding: 5px 10px;
}

li.cm_bg_date {
  padding-left: 60px !important;
}
li.cm_bg_date.na-brthday {
  padding-left: 15px !important;
}
.nts-desc-wrp {
  padding: 0px 15px;
}

.about_hairdresser {
  position: absolute;
  width: calc(100% - 100px);
  background: #bcebea;
  top: -90px;
  border-radius: 9px;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.2);
  padding: 9px 15px;
  padding-right: 75px;
  left: 50%;
  transform: translateX(-50%);
}

.about_hairdresser.cm_dark_b,
.top_fix_box.cm_dark_b {
  background: #061e1e;
  color: #fff;
}

.cm_dark_b .text_red {
  color: #fff !important;
}

.sb_selected p {
  background-color: #93fdfb !important;
}

.current_date.sb_selected p {
  background-color: #bcebea;
}

.about_hairdresser img {
  position: absolute;
  right: 14px;
  top: 11px;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  object-fit: cover;
  background: #fff;
}

.about_hairdresser h3,
.hair-dairy-dtls h3 {
  font-size: 22px;
  margin: 0;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.hair-dairy-dtls h5 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.inner-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.invoice_card_wrapper {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.31);
  border-radius: 9px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.cm_head {
  background: #bfe4ea;
  margin-bottom: 15px;
  border-radius: 10px 10px 0 0;
  padding: 15px;
  text-align: center;
}
.black_head{
  background: #29282E !important;
  color: #fff !important;
}
.black_head .dropdown .fa {
  color: #fff;
}
.__new_checkout .cm_head.black_head .text h3,
.__new_checkout .cm_head.black_head .text p {
  color: #fff;
} 
.cm_body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
  margin-top: 175px;
}

.cm_body ul li {
  font-size: 20px;
  font-weight: 300;
  border-bottom: 1px solid rgba(6, 30, 30, 0.5);
  padding: 10px;
}

.cm_footer {
  margin-top: 5px;
  padding: 20px 10px;
  text-align: right;
  border-top: 1px solid rgba(6, 30, 30, 0.5);
  padding-bottom: 70px;
}

.stap_wrapper ._img_wrap {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.stap_wrapper ._img_wrap {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

._img_wrap {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus,
.btn-dark.focus,
.btn-dark:focus {
  box-shadow: none;
}

.cm_hide_mod {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 20px;
  z-index: 9999;
  padding: 15px 20px;
}
.cm_hide_mod svg{
  width: 15px;
  height: 15px;
}
.cm_hide_mod svg path{
  fill:#000
}

@media (max-width: 1300px) {
  .cm__col {
    width: calc(100vw / 2 - 90px);
  }
}

@media (max-width: 991px) {
  .client_top_container {
    flex-wrap: wrap;
  }
  .processing_time_msz {
    width: 80% !important;
  }
  .processing_time_msz:before {
    height: 27% !important;
    left: 50% !important;
}
.processing_time_msz:after {
  content: ' ';
    position: absolute;
    left: 50%;
    bottom: -1px;
    width: 5px;
    height: 27%;
    background: #C36464;
}
  /* .export_btn_parent{
    margin-top: 12px;
  } */
  .client_top_container_first .client_filter,
  .client_top_container .cm_search_wrapper.client_search {
    margin-bottom: 12px !important;
  }
  .client_filter .css-yk16xz-control {
    width: 100%;
    margin-bottom: 12px;
  }
  .client_filter.closeSelect,
  .client_top_container .cm_search_wrapper input.form-control,
  .client_top_container .cm_search_wrapper.client_search {
    width: 100%;
    min-width: 100%;
  }
  .client_top_container .export_btn_parent .btn {
    padding: 0.98rem 0.75rem !important;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-right: 0;
  }
  .body_container.cm_bg_white.diary-module-wrp {
    padding: 20px 16px 16px;
  }
  .cm__col {
    width: calc(100vw - 180px);
  }
  /* .top_fix_box{
    width: calc(100vw - 134px) !important;
  } */
  .top_header {
    width: 100% !important;
  }
  .only---sidenav .cm-navbar-new {
    display: none;
  }

}

@media (max-width: 767px) {
  .client_top_container .export_btn_parent {
    margin-bottom: 12px;
  }

  .calendar_card {
    position: static;
    transform: none;
  }

  .cm__col {
    width: calc(100vw - 156px);
  }

  .cm_staff_catd {
    min-width: 215px;
  }
  .cm_sdule_time {
    right: auto;
  }
  .cm_table_input {
    width: 60px;
  }

  .setting_page .nav-tabs .nav-link,
  .staff_page .nav-tabs .nav-link {
    margin-right: 10px !important;
    min-width: 100px !important;
  }

  /* .body_container.cm_bg_white {
  padding-top: 70px;
} */

  .diary-temp .top_header .user_pic {
    display: none;
  }
  .diary-temp .top_header {
    border-radius: initial !important;
    box-shadow: initial;
    position: static;
    padding: 0;
    min-height: initial !important;
    text-align: left;
  }
  .diary-temp .top_header .menu_toogle_icon {
    position: static;
  }
  .calendar_card.calendar-card-mobile {
    padding: 0px !important;
    background: transparent;
    border-radius: inherit;
    margin-top: 26px;
    box-shadow: initial;
  }
  .dairy-container-header {
    background: #f7f7f7;
    border-radius: 0px 0px 9px 9px;
    box-shadow: 0px 3px 6px #00000029;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    padding: 0.5rem 1rem;
  }
  /* .diary-page-container-body .not_working{
  background: transparent;
} */
  .diary-page-container-body .booking_card img {
    display: none;
  }
  .diary-page-container-body .booking_card h5 {
    padding-right: 0px;
  }
  .mbv-none {
    display: none !important;
  }
  .cldr-date-mobile {
    position: absolute;
    top: 10px;
    z-index: 999;
    left: 50%;
    margin-left: -80px;
    background: #000000;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    padding: 0.35rem 0.5rem;
  }
  /* .calendar_card i {
    color: #fff;
  }
  .calendar_card h4 .icon svg {
    fill: #fff;
  } */
  .cldr-date-mobile{
    background: transparent;
    box-shadow: 0px 3px 6px #00000000;
    width: calc(100% - 120px);
    margin: 0 60px;
    left: 0%;
    top: 4px;

  }
  .current-date-icon{
    top: 8px !important;
    box-shadow: 0px 3px 6px #00000029;
    padding: 2px 11px;
    border-radius: 9px;
    font-size: 23px;

  }
  .cldr-date-mobile i.fa{
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px 15px;
    border-radius: 9px;
  }
  .month-date-picker .icon{
   display: none;
  }
  .calendar-card-mobile .cldr-date-mobile > h4 {
    min-width: initial;
    margin-left: 16px !important;
    margin-right: 16px !important;
    background: #fff0;color:#000;
    box-shadow: 0px 3px 6px #00000029;
    padding: 4px 15px;
  }
  .cldr-dayview-mob > div > span {
    font-size: 11px;
  }
  .cldr-dayview-mob > div > p {
    width: 44px;
    height: 44px;
    line-height: 36px;
    background: #fff;
    box-shadow: 0px 3px 6px #00000017;
  }
  .cldr-dayview-mob > div.current_date > p {
    background: #007ea0;
    color: #fff;
  }
  .cldr-dayview-mob > div {
    margin-bottom: 0.25rem;
  }
  .cldr-dayview-mob > div > p:after {
    display: none;
  }

  /* .diary-parent.diary-page-container-prnt .d-flex{
  flex-wrap: wrap;
} */
  .diary-page-container-body > div {
    width: calc(100vw / 3 - 44px);
  }
  .diary-page-container-body .about_hairdresser {
    background: transparent !important;
    box-shadow: initial;
    color: #29282e !important;
    width: 100%;
    text-align: center;
    padding: 0;
    top: -102px;
  }
  .diary-page-container-body .about_hairdresser img {
    width: 55px;
    height: 55px;
    position: static;
  }
  .about_hairdresser .hair-dairy-dtls > h3 {
    font-size: 14px;
  }
  .about_hairdresser .hair-dairy-dtls > h5 {
    font-size: 12px;
    font-weight: 300;
  }
  .about_hairdresser .hair-dairy-dtls > h5 > .text_red,
  .diary-page-container-body .about_hairdresser {
    color: #29282e !important;
  }
  .about_hairdresser.hair-dairy-dtls {
    padding-top: 0.5rem;
  }
  .diary-parent.diary-page-container-prnt .times_no {
    top: 264px;
  }
  .booking_card.bkng-crd-dary {
    display: flex;
    flex-wrap: wrap;
    padding: 3px 7px;
    flex-direction: column;
    color: #29282e;
  }
  .booking_card.bkng-crd-dary .cstm-name {
    order: 2;
    font-size: 12px;
    margin-bottom: 0.15rem;
  }
  .booking_card.bkng-crd-dary .cm_sdule_time {
    order: 1;
    position: static;
  }
  .booking_card.bkng-crd-dary h5 {
    order: 3;
  }
  .booking_card.bkng-crd-dary h5,
  .booking_card.bkng-crd-dary .cm_sdule_time {
    font-size: 7px;
    /* font-size: 12px; */
    font-weight: 300;
  }
  .client_search .form-control {
    min-width: 100%;
  }
  .view-booking-parent ul.buttons_border_wrap {
    margin: 7px 0px 0px !important;
  }
  .stap-action .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mdl-hdkng {
    font-size: 28px !important;
  }
  .diary-page-container-body > div {
    width: calc(100vw / 3 - 15px);
  }
  /* 15-10-21 end */

  .diary-page-container-body > div {
    /* width: calc(100vw / 3 - 15px) !important; */
    width: calc(100vw / 3 - 15px); /* for mobile view calendar time line */
  }
}

@media (max-width: 576px) {
  .booking_form_wrapper {
    padding: 15px;
  }

  .cm__box h5 {
    font-size: 20px;
  }

  .cm__box table {
    min-width: 300px;
  }

  .i_width {
    text-align: center;
  }

  .calendar_card .d-flex.justify-content-between {
    overflow: auto;
  }

  .new-booking-tab {
    padding-left: 15px;
    padding-right: 15px;
  }

  .cm_picker_wrap {
    position: relative;
    padding: 0 15px;
  }

  .cm__col {
    width: calc(100vw - 80px);
  }

  .top_header {
    padding: 0.8rem 10px;
  }

  .user_pic {
    right: 10px !important;
  }

  .user_pic img {
    width: 35px !important;
    height: 35px !important;
    margin-left: 10px !important;
  }

  .user_pic .btn {
    font-size: 12px;
  }

  .top_header h1 {
    /* margin-top: 0px; */
    margin-top: 9px;padding-left: 0;
}

  .top_header .menu_toogle_icon {
    top: 13px;
  }

  .cm_client_val {
    display: block !important;
  }

  .cm_client_val > div {
    padding: 0 !important;
    margin-bottom: 15px;
  }

  .cm_main_col_3 i {
    right: 2px;
  }
}
@media (max-width: 480px) {
  /* .diary-page-container-body > div {
    width: calc(100vw / 2 - 44px);
  } */
  .diary-page-container-body > div {
    /* width: calc(100vw / 3 - 15px) !important; */
    width: calc(100vw / 3 - 15px); /* for mobile view calendar time line */
  }
  .black_heading .product .col-md-6 ,
  .black_heading .product .col-md-2{
    /* padding: 4px; */
}
.select__service .black_heading h5{
  font-size: 14px;
}
.serviceTable {
  width: 30% !important;
}
}
@media (max-width: 380px) {
  /* .cldr-date-mobile {
    left: 42%;
  } */
}

.staff_page .tab-pane {
  padding: 15px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.31);
  position: relative;
  min-height: calc(100vh - 170px);
}

.staff_page .nav-tabs {
  border-bottom: 0;
}

.setting_page .nav-tabs .nav-link,
.staff_page .nav-tabs .nav-link {
  border: 0;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background: #f7f7f7;
  margin-right: 30px;
  min-width: 200px;
  text-align: left;
  padding: 15px;
  outline: none;
}

.setting_page .nav-item.nav-link.active,
.staff_page .nav-item.nav-link.active {
  background: #bcebea;
}

.staff-page-temp .nav-item.nav-link.active {
  background: #29282e;
  color: #fff;
}

.table_staf_member {
  padding: 15px 0 !important;
}

.top_right {
  position: absolute;
  right: 0px;
  top: -50px;
  min-width: 150px;
}

.table_staf_member td img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}

.staff_page .table td,
.staff_page .table th {
  vertical-align: middle;
}

.user_pic {
  position: absolute;
  right: 65px;
  top: 9px;
}

.user_pic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin-left: 20px;
}

.add_staff_form {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
}

.file_upload {
  width: 96px;
  height: 96px;
  position: relative;
  background: #f7f7f7;
  border-radius: 100%;
}

.file_upload input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 99;
  opacity: 0;
  font-size: 0;
}

.file_upload i {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #000;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  text-align: center;
  line-height: 24px;
  background: #fff;
}

.file_upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.btn-pink {
  background: #feafbc;
  color: #000;
}

.cm_hours_popup .modal-header {
  display: block !important;
}

.cm_hours_popup .modal-header p {
  margin: 0;
}

.cm_hours_popup button.close {
  position: absolute;
  right: 30px;
  top: 24px;
  font-size: 35px;
}
.cm_succ_msg,
.account-table span .cm_succ_msg {
  color: green;
  font-size: 14px;
  margin-left: 4px;
  /* padding-bottom: 10px; */
  text-decoration: none !important;
}

.daily-schedule td {
  padding: 0 1.5rem !important;
  /* line-height: 1;
  height: 28px;
  min-height: 1px !important;
  display: inline-block; */
}

button.btn.btn-primary.btn-block.mb-3 {
  position: relative;
}

span.cm_angle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  margin-left: 10px;
  position: absolute;
  right: 20px;
  top: calc(50% - 4px);
  transform: translateY(-50%) rotate(45deg);
}

.daily-schedule td.daily-schedule-bucket-container,
.daily-schedule td.daily-schedule-bucket-container .daily-schedule-bucket {
  padding: 0 !important;
  position: relative;
}

.bg_white_modal .modal-dialog {
  background: #fff !important;
  border-radius: 12px;
}

.nav_menu_open .sidebar_tog_icon_open,
.sidebar_tog_icon_close {
  display: none;
}

.nav_menu_open .sidebar_tog_icon_close {
  display: block;
}

span.menu_icon svg {
  width: 25px;
  height: 25px;
}

.daily-schedule-bucket button {
  position: absolute;
  width: 100%;
  left: -10px;
  top: -14px;
  height: 31px;
  opacity: 0;
  cursor: pointer;
}

.button-loader {
  position: relative !important;
}

@media (max-width: 1100px) {

  body.only---sidenav.modal-open.add_card_modal_open .show_sidenav_topbar,
  body.only---sidenav.modal-open.add_card_modal_open .menus_open_modal.modal{
  top: 75px;
  left: 0px;
  width: calc(100% - 0px);
}
body.only---sidenav.modal-open.add_card_modal_open.nav_menu_open .show_sidenav_topbar,
body.only---sidenav.modal-open.add_card_modal_open.nav_menu_open .menus_open_modal.modal {
    left: 250px !important;
    width: calc(100% - 250px);
  }


  .times_no {
    /* left: 7px; */
    margin-top: 4.52rem;
  }
  ._card_row_parent {
    min-width: 746px;
    width: 100%  !important;
      }
  /* ------dairy page dropdown phone------ */
 

  .nav_menu_open .sidebar_tog_icon_close {
    display: none;
  }

  .nav_menu_open .sidebar_tog_icon_open,
  .sidebar_tog_icon_close {
    display: block;
  }
  .date-filter-icon-body_mobile {
    position: absolute;
    top: 11px !important;
    right: 4%;
    width: 86px;
    /* background: pink; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .date-filter-icon-body_mobile .icon {
    width: 36px;
    height: 36px;
    background: #f7f7f7;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .times_no {
    /* top: 249px; */
    left: 0px;
    margin-top: 5.75rem;
  }
  .nav_menu_open .menu_toogle_icon .sidebar_tog_icon_open svg {
    fill: #fff;
    transform: rotate(180deg);
    margin-top: -15px;
  }
}
/* @media(max-width: 415px)  and (max-width:23px){
  media(max-width: 767px)
} */
.address_input_wrap.disabled input {
  background-color: #e9ecef;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.darkblue .rwdp-DayCircle {
  background: #061e1e !important;
  border-color: #061e1e !important;
  color: #91dedd !important;
}

#appleid-signin {
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 420px) {
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
    width: 30px !important;
    height: 30px !important;
    max-width: 30px !important;
    min-width: 30px;
    min-height: 30px;
  }
}

body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  /* overflow: hidden; */
}
.top_fix {
  background: #fff;
  /* top: -144px; */
  top: -10px;
  position: sticky;
  position: -webkit-sticky;
  white-space: nowrap;
  z-index: 9999;
  margin-bottom: 20px;
  padding-left: 56px;
}

.top_fix_box {
  width: calc(100vw / 3 - 118px);
  display: inline-block;
  background: #bcebea;
  margin: 0 14px;
  position: relative;
  border-radius: 9px;
  box-shadow: 5px 5px 6px rgb(0 0 0 / 20%);
  padding: 9px 15px;
}

.diary-parent {
  position: relative;
  /* padding-top: 74px; */
  /* padding-left: 65px; */
  height: calc(100vh - 215px);
  /* overflow: auto; --for new dairy change*/
}

.time_line {
  width: 100%;
  height: 2px;
  background: #000;
  position: absolute;
  z-index: 9;
}

.show_bar_time {
  width: 62px;
  height: 21px;
  background: #fff;
  position: absolute;
  z-index: 999;
  color: #000;
  font-size: 0.7rem;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #5552;
}
.tooltip_parent._add_service_tooltip .info {
  top: 36px;
  right: 20px;
}
.tooltip_parent._add_service_tooltip .tooltip-body {
  top: 20px;
}
.tooltip_parent._add_service_tooltip .tooltip-body {
  width: 390px;
}
.tooltip_parent._add_service_tooltip .tooltip-body .about_comm b {
  color: #000;
}
@media (max-width: 767px) {
  .container.addservice {
    margin-top: 30px;
}
.tooltip_parent._add_service_tooltip .info {
  top: -4px;
  right: 6px;
}
.tooltip_parent._add_service_tooltip .tooltip-body {
  width: 100%;
  top: 0px;
}
  .show__color {
    width: 26px;
    height: 26px;
    border-radius: 6px;
}
  .diary-page-container-body > .show_bar_time {
    width: 39px !important;
    height: 16px;
    background: #000;
    position: absolute;
    z-index: 999;
    color: #fff;
    font-size: 0.5rem;
    text-align: center;
    border-radius: 28px;
    left: 4px;
  }
  .checkout_page {
    padding-left: 0px !important;
    width: 100% !important;
}
  .diary-page-container-body > .time_line {
    width: 100vw;
    height: 2px;
    background: #000;
    position: absolute;
    z-index: 9;
    left: 4px;
  }
  .serviceTable {
    width: 30% !important;
  }
}
/* @media  (min-width:1250px) and (max-width:1350px){
  .calendar_card{
    left: 76%;
    transform: translateX(-76%);
  }
} */
/* @media  (min-width:1351px) and (max-width:1550px){
  .calendar_card{
    left: 67%;
    transform: translateX(-67%);
  } 
} */
@media (min-width: 1551px) {
  .calendar_card {
    left: 54%;
    transform: translateX(-54%);
  }
}

.sort_col {
  cursor: pointer;
}
.appleLogo {
  display: inline-block;
  padding: 0 24px;
  font-family: "system-ui";
}
.appleButton {
  background-color: rgba(0, 0, 0, 1);
  border: 0;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(238, 216, 216, 0.6);
  cursor: pointer;
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  font-family: inherit;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  outline-width: 2px;
  padding: 0 24px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition-duration: 167ms;
  transition-duration: 167ms;
  -webkit-transition-property: background-color, color, -webkit-box-shadow;
  transition-property: background-color, color, -webkit-box-shadow;
  transition-property: background-color, box-shadow, color;
  transition-property: background-color, box-shadow, color, -webkit-box-shadow;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  vertical-align: middle;
  z-index: 0;
  color: white !important;
  border-radius: 7px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 52px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 12px;
}

.widrawal-history .payout_table .table tr td {
  min-width: 120px;
}

.current-date-icon {
  position: absolute;
  display: block;
  top: 20px;
  right: 5%;
  cursor: pointer;
}

._filter_rotate {
  transform: translate(0, -50%) rotate(180deg) !important;
  -webkit-transform: translate(0, -50%) rotate(180deg) !important; /* Safari & Chrome */
  -moz-transform: translate(0, -50%) rotate(180deg) !important; /* Firefox */
  -ms-transform: translate(0, -50%) rotate(180deg) !important; /* Internet Explorer */
  transform: translate(0, -50%) rotate(180deg) !important;
  /* transition: all 0.5s; */
}

.export_btn {
  background-color: black;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  border: none;
}

.export_btn2 {
  background-color: #007ea0;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  border: none;
}

.payout_modal>div>.modal-content{
background-color: white ;
height: 750px ;
} 
.fee_modal>div>.modal-content{
  background-color: white ;
}
 .modal-header{
  border-bottom: none !important;
}
.modal-footer {
  border-top: none !important;
}
.close {
  position: absolute !important;
  top: 5px !important;
  right: 12px !important;
  font-weight: 1px !important;
  cursor: pointer;
}
.payout_cancel_btn {
  background-color: white;
  font-size: 20px;
  border-radius: 10px;
  padding: 1rem 3rem;
  color: black;
  margin: 0 1.5rem 2rem 1.5rem;
  font-weight: bolder;
  border: 1px solid black;
  cursor: pointer;
}

.payout_btn {
  background-color: #007ea0;
  border-radius: 10px;
  font-size: 20px;
  padding: 1rem 4.5rem;
  color: white;
  margin: 0 2.5rem 2rem 1.4rem;
  border: none;
  font-weight: bolder;
  cursor: pointer;
}

.payout_title {
  font-size: bolder;
  margin: 0 1.5rem;
  font-size: 32px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.instant {
  display: inline-block;
  border: 2px solid #d3d3d3;
  padding: 1rem;
  border-radius: 9px;
  margin: 6.5rem 0rem 0.5rem 3.5rem;
  background-color: #dee2e629;
  cursor: pointer;
}

.instant_input {
  font-size: 20px;
  margin: 0.3rem;
  font-weight: bold;
}

.standard {
  display: inline-block;
  border: 2px solid #d3d3d3;
  padding: 1rem;
  border-radius: 8px;
  margin: 6.5rem 0rem 0.5rem 1.5rem;
  background-color: #dee2e629;
  cursor: pointer;
}

.instant_details {
  padding: 2.9rem;
  box-sizing: border-box;
  display: inline-block;
  text-align: right;
}

.standard_details {
  padding: 4.8rem 3rem;
  box-sizing: border-box;
  display: inline-block;
  text-align: right;
}

.instant_data {
  padding: 2rem 3rem;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
}
.instant_data1 {
  padding: 2rem 3rem;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
}

.instant_details_data {
  margin-bottom: 2.5rem;
  font-size: 17px;
}
.instant_detail_data {
  margin-bottom: 2.1rem;
  font-size: 16px;
}

.content_strong {
  font-size: 15px;
  padding: 1rem 0.2rem;
  font-weight: bolder;
}

.amount_to_pay {
  font-size: 12px;
  border-right: none;
  padding: 8px 8px 8px 8px;
  border: 1px solid #dee2e6f5;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  -webkit-text-size-adjust:none;
}
.amount_to_pay1 {
  width: 65px;
  margin-bottom: 0.8rem;
  outline: none;
  border-left: none;
  font-size: 12px;
  padding: 8px 8px 7px 8px;
  border: 1px solid #dee2e6f5;
  border-radius: 0px 8px 8px 0px;
}
.amount_to_pay2 {
  width: 65px;
  margin-bottom: 0.8rem;
  outline: none;
  border-left: none;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
  border: 1px solid #dee2e6f5;
  border-radius: 0px 8px 8px 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
.radio_input {
  transform: scale(2.2);
  margin: 0.9rem;
  cursor: pointer;
}
input[type="radio"] {
  accent-color: #007ea0;
}

.standard_details_data {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 14px;
}
.standard_details_data1 {
  margin-top: 2rem;
  margin-bottom: 2.2rem;
  font-size: 14px;
}

.instant_head {
  margin-top: 1rem;
  padding-left: 0.5rem;
  color: #808080c4;
  font-size: 16px;
}

.instant_selected {
  border: 3px solid #007ea0;
}
.standard_input_data {
  margin-bottom: 1.3rem;
  font-size: 14px;
  display: flex;
}
.send_payment {
  font-size: 14px;
}

.drag-show_body > label {
  cursor: pointer;
}
.continue_btn{
  background-color: #007EA0;
  border-radius: 8px;
  padding: 1rem 6rem;
  color: white;
  margin: 0 15rem 1.5rem .5rem;
  border: none;
  font-weight: bolder;
  cursor: pointer;
  font-size: 20px;
}

.payout_heading{
  position: absolute;
  top: 435px;
  left: 242px;
}

.payout_content{
  position: absolute;
  top: 540px;
  left: 140px;
  color: #808080c4;
}
.payout_tag{
  position: absolute;
  top: 540px;
  left: 268px;
  color: #808080c4;
}
.making_money{
  top: 0px;
  position: absolute;
  left: 220px;
}
.spinner{
  position: absolute;
  bottom: 4.4rem;
  right: 16.5rem;
}
.instant_line{
  position: relative;
  left: 45%;
}

.cm_modal_view > .row {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}

.cm_modal_view h6 {
  margin: 0;
}

.cm_modal_view {
  margin-top: 50px;
}
@media (min-width:1000px) and (max-width:1400px){
  .close{
    position: absolute !important;
    top: 5px !important;
    right: 12px !important;
    font-weight: 1px !important;
    cursor: pointer;
  }
.payout_title{
  font-size: bolder;
  margin:0 1.6rem;
}
.payout_cancel_btn{
  background-color: white;
  border-radius: 8px;
  padding: 0.5rem 2.2rem;
  color: black;
  margin: 0 0.5rem 1.5rem 0rem ;
  font-weight: bolder;
  border: 1px solid black;
  cursor: pointer;
}

.payout_btn{
  background-color: #007EA0;
  border-radius: 8px;
  padding: 0.5rem 3.2rem;
  color: white;
  margin: 0 2.5rem 1.5rem 1.5rem;
  border: none;
  font-weight: bolder;
  cursor: pointer;
}
.instant{
  display: inline-block;
  border: 2px solid #D3D3D3;
  padding: 1rem;
  border-radius: 8px;
  margin: 7rem 0.6rem 0.5rem 3.5rem;
  background-color: #dee2e629;
  cursor: pointer;
}

.instant_input{
  font-size: 17px;
}

.standard{
  display: inline-block;
  border: 2px solid #D3D3D3;
  padding: 1rem;
  border-radius: 8px;
  margin: 7rem 0 0.5rem 0.6rem;
  background-color: #dee2e629;
  cursor: pointer;
}
.instant_selected {
  border: 3px solid #007EA0;
}
.loading {
  position: relative;
  width: 31% !important;
  padding: 8px 46px !important;
}

.continue_btn{
  background-color: #007EA0;
  border-radius: 8px;
  padding: 1rem 6.5rem;
  color: white;
  margin: 0 14.5rem 1.5rem .5rem;
  border: none;
  font-weight: bolder;
  cursor: pointer;
}

.payout_heading{
  position: absolute;
  top: 430px;
  left: 242px;
}
.amount_to_pay1{
  width: 65px;
  margin-bottom: 0.8rem;
  outline: none;
  border-left: none;
  font-size: 12px;
  padding: 8px 8px 7px 8px;
  border: 1px solid #dee2e6f5;
  border-radius: 0px 8px 8px 0px;
}
.amount_to_pay2{
  width: 65px;
  margin-bottom: 0.8rem;
  outline: none;
  border-left: none;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
  border: 1px solid #dee2e6f5;
  border-radius: 0px 8px 8px 0px;
}

.payout_content{
  position: absolute;
  top: 535px;
  left: 140px;
  color: #808080c4;
}
.payout_tag{
  position: absolute;
  top: 535px;
  left: 266px;
  color: #808080c4;
}
.making_money{
  top: 0px;
  position: absolute;
  left: 220px;
}
.spinner{
  position: absolute;
  bottom: 0.8rem;
  right: 11.5rem;
}
}

@media (min-width: 701px) and (max-width: 993px) {
  .payout_modal > div > .modal-content {
    height: 680px !important;
  }
  .modal-header {
    border-bottom: none !important;
  }
  .modal-footer {
    border-top: none !important;
  }
  .close {
    position: absolute !important;
    top: 5px !important;
    right: 12px !important;
    font-weight: 1px !important;
  }
  .payout_title {
    font-size: bolder;
    margin: 0 0.2rem;
  }
  .payout_cancel_btn {
    background-color: white;
    border-radius: 8px;
    padding: 0.5rem 2.2rem;
    color: black;
    margin: 0 0.5rem 1.5rem 0rem;
    font-weight: bolder;
    border: 1px solid black;
  }

  .payout_btn {
    background-color: #007ea0;
    border-radius: 8px;
    padding: 0.5rem 3.2rem;
    color: white;
    margin: 0 1rem 1.5rem 0.5rem;
    border: 1px solid #007fa0;
    font-weight: bolder;
  }

  .instant {
    display: inline-block;
    border: 2px solid #d3d3d3;
    padding: 1rem;
    border-radius: 8px;
    margin: 5rem 0.6rem 0.5rem 2rem;
    background-color: #dee2e629;
    cursor: pointer;
  }

  .instant_input {
    font-size: 17px;
  }

  .standard {
    display: inline-block;
    border: 2px solid #d3d3d3;
    padding: 1rem;
    border-radius: 8px;
    margin: 5rem 0 0.5rem 0.6rem;
    background-color: #dee2e629;
    cursor: pointer;
  }

  .instant_details {
    padding: 1.6rem;
    box-sizing: border-box;
    display: inline-block;
    text-align: right;
  }

  .instant_data {
    padding: 1.6rem;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
  }
  .instant_data1 {
    padding: 1.6rem;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
  }

  .instant_details_data {
    margin-bottom: 2.5rem;
    font-size: 14px;
  }

  .content_strong {
    font-size: 15px;
    font-weight: bolder;
  }
  
  .amount_to_pay {
    font-size: 10px;
    padding: 7px 6px 4px 6px;
    border: 1px solid #c2c6ca;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    line-height: 1 !important;
}
  .amount_to_pay1{
    width: 60px;
    outline: none;
    font-size: 11px;
    padding: 6px 8px 4px 8px;
    border: 1px solid #c2c6ca;
    border-radius: 0px 7px 7px 0px;
  }
  .amount_to_pay2 {
    width: 60px;
    outline: none;
    font-size: 11px;
    padding: 6px 8px 4px 8px;
    border: 1px solid #dee2e6f5;
    border-radius: 0px 7px 7px 0px;
  }

  .radio_input {
    transform: scale(1.8);
    cursor: pointer;
  }
  .standard_details {
    padding: 4rem 2rem;
    box-sizing: border-box;
    display: inline-block;
    text-align: right;
  }

  .standard_details_data {
    font-size: 14px;
  }
  .standard_details_data1 {
    margin-bottom: 2.2rem;
    font-size: 14px;
  }

  .instant_head {
    font-size: 14px;
  }
  .send_pay {
    font-size: 14px;
  }
  .instant_selected {
    border: 2px solid #007ea0;
  }
  .standard_input_data {
    font-size: 14px;
    margin-bottom: 1.5rem;
  }
  .instant_detail_data {
    margin-bottom: 2.2rem;
    font-size: 14px;
  }
  .send_payment {
    font-size: 11px;
  }
  .cm_main_col_3 i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .continue_btn{
    background-color: #007EA0;
    border-radius: 8px;
    padding: 0.7rem 5.5rem;
    color: white;
    margin: 0 6.4rem 0.5rem .5rem;
    border: none;
    font-weight: bolder;
    cursor: pointer;
  }

  .payout_heading{
    position: absolute;
    top: 405px;
    left: 86px;
  }

  .payout_content{
    position: absolute;
    top: 500px;
    left: 12px;
    color: #808080c4;
    padding: 0 10px;
  }
  .payout_tag{
    position: absolute;
    top: 500px;
    left: 106px;
    color: #808080c4;
    padding: 0 10px;
  }
  .making_money{
    position: absolute;
    left:60px;
  }
  .spinner{
    position: absolute;
    bottom: 3.4rem;
    right: 13.2rem;
  }
  .instant_line{
    position: relative;
    left: 35%;
  }
}


@media (min-width:375px) and (max-width:409px){
  .payout_title{
    font-size: bolder;
    margin: 1px 0px 12px 1px;
  }
  .payout_cancel_btn {
    background-color: white;
    border-radius: 8px;
    padding: 8px 15px;
    color: black;
    font-weight: bolder;
    border: 1px solid black;
    margin: 1rem 0rem;
    cursor: pointer;
  }
  
  .payout_btn{
    margin: 1rem 0.5rem;
    background-color: #007EA0;
    border-radius: 8px;
    padding: 8px 40px;
    color: white;
    border: none;
    font-weight: bolder;
    cursor: pointer;
  }

  .instant {
    display: inline-block;
    border: 2px solid #d3d3d3;
    border-radius: 8px;
    margin: 3rem 2rem 0rem 2rem;
    background-color: #dee2e629;
    cursor: pointer;
  }

  .instant_input {
    font-size: 17px;
  }

  .standard {
    display: inline-block;
    border: 2px solid #d3d3d3;
    border-radius: 8px;
    margin: 1rem 2rem 0.5rem 2rem;
    background-color: #dee2e629;
    cursor: pointer;
  }


  .instant_details {
    padding: 46px 30px 0px 0px !important;
    box-sizing: border-box;
    display: inline-block;
    text-align: right;
  }

  .instant_data {
    padding: 46px 0px 0px 30px !important;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
  }
  .instant_data1 {
    padding: 15px 0px 0px 30px !important;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
  }

  .instant_details_data {
    margin-bottom: 2.5rem;
    font-size: 12px;
  }
  .modal_div {
    display: flex;
  }
  .modal_div2 {
    display: flex;
  }
  .content_strong {
    font-size: 7px;
    font-weight: bolder;
  }

  .amount_to_pay {
    font-size: 10px;
    padding: 6px 6px 4px 6px;
    border: 1px solid #c2c6ca;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  .content_strong{
    font-size: 7px;
    font-weight: bolder;
  }
  .amount_to_pay1{
    width: 60px;
    outline: none;
    font-size: 10px;
    padding: 6px 5px 3px 8px;
    border: 1px solid #c2c6ca;
    border-radius: 0px 7px 7px 0px;
  }
  .amount_to_pay2 {
    width: 60px;
    outline: none;
    font-size: 10px;
    padding: 6px 5px 3px 8px;
    border: 1px solid #dee2e6f5;
    border-radius: 0px 7px 7px 0px;
  }

  .radio_input {
    transform: scale(1.8);
    cursor: pointer;
  }
  .standard_details {
    padding: 46px 30px 0px 0px !important;
    box-sizing: border-box;
    display: inline-block;
    text-align: right;
  }

  .standard_details_data {
    font-size: 14px;
  }
  .standard_details_data1 {
    font-size: 12px;
    margin-bottom: 2.3rem;
  }

  .instant_head {
    font-size: 13px;
  }
  .send_pay {
    font-size: 12px;
  }
  .instant_selected {
    border: 2px solid #007ea0;
  }
  .instant_detail_data {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    font-size: 11px;
  }
  .standard_input_data {
    margin-top: 2.2rem;
    margin-bottom: 1.4rem;
    font-size: 14px;
  }
  .send_payment {
    font-size: 9px;
  }

  .continue_btn{
    background-color: #007EA0;
    border-radius: 8px;
    padding: 0.7rem 5rem;
    color: white;
    margin: 0 3.5rem 1.5rem 0rem;
    border: none;
    font-weight: bolder;
    cursor: pointer;
  }
  .payout_heading{
    position: absolute;
    top: 435px;
    left: 28px;
  }

  .payout_content{
    position: absolute;
    top: 550px;
    left: 2px;
    color: #808080c4;
    padding: 0px 23px;
  }
  .payout_tag{
    position: absolute;
    top: 550px;
    left: 28px;
    color: #808080c4;
    padding: 0px 23px;
  }
  .payout_data{
    margin: 30px;
    text-align: center;
  }
  .making_money{
    top: 0px;
    position: absolute;
    left: 7px;
  }
  .spinner{
    position: absolute;
    bottom: 3rem;
    right: 11.4rem;
  }
  .instant_line{
    position: relative;
    left: 18%;
  }
  .modal.show .modal-dialog{
    padding: 0px;
    margin: 0px;
  }
  .payout_modal>div>.modal-content{
    background-color: white ;
    height: 100vh;
    } 
}

@media (min-width:410px) and (max-width:540px){
  .payout_title{
    font-size: bolder;
    margin:1px 0px 12px 1px;
  }
  .payout_cancel_btn{
    background-color: white;
    border-radius: 8px;
    padding: 8px 15px;
    color: black;
    font-weight: bolder;
    border: 1px solid black;
    margin: 1rem 0rem;
    cursor: pointer;
  }
  
  .payout_btn{
    margin: 1rem 0.5rem;
    background-color: #007EA0;
    border-radius: 8px;
    padding: 8px 40px;
    color: white;
    border: none;
    font-weight: bolder;
    cursor: pointer;
  }
  
  .instant{
    display: inline-block;
    border: 2px solid #D3D3D3;
    border-radius: 8px;
    margin: 3rem 2rem 0rem 2rem;
    background-color: #dee2e629;
    cursor: pointer;
  }
  
  .instant_input{
    font-size: 17px;
  }
  
  .standard{
    display: inline-block;
    border: 2px solid #D3D3D3;
    border-radius: 8px;
    margin: 1rem 2rem 0.5rem 2rem;
    background-color: #dee2e629;
    cursor: pointer;
  }
  
  .instant_details{
    padding: 46px 30px 0px 0px !important;
    box-sizing: border-box;
    display: inline-block;
    text-align: right ;
  }
  
  .instant_data{
    padding: 46px 0px 0px 30px !important;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
  }
  .instant_data1{
    padding: 15px 0px 0px 30px !important;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
  }
  
  .instant_details_data{
    margin-bottom: 2.5rem;
    font-size: 12px;
  }
 
  .content_strong{
    font-size: 7px;
    font-weight: bolder;
  }


  .amount_to_pay1{
    width: 60px;
    outline: none;
    font-size: 10px;
    padding: 6px 5px 3px 8px;
    border: 1px solid #c2c6ca;
    border-radius: 0px 7px 7px 0px;

  }
  .amount_to_pay2{
    width: 60px;
    outline: none;
    font-size: 10px;
    padding: 6px 5px 3px 8px;
    border: 1px solid #dee2e6f5;
    border-radius: 0px 7px 7px 0px;
  }
  
  .radio_input{
    transform: scale(1.8);
    cursor: pointer;
  }
  .standard_details{
    padding: 46px 30px 0px 0px !important;
    box-sizing: border-box;
    display: inline-block;
    text-align: right;
  }
  
  .standard_details_data{
    font-size: 14px;
  }
  .standard_details_data1{
    font-size: 12px;
    margin-bottom: 2.3rem;
  }
  
  .instant_head{
    font-size: 13px;
  }
  .send_pay{
    font-size: 12px;
  }
  .instant_selected {
    border: 2px solid #007EA0;
  }
  .instant_detail_data{
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    font-size: 11px;
  }
  .standard_input_data{
    margin-top: 2.2rem;
    margin-bottom: 1.4rem;
    font-size: 14px;
  }
  .send_payment{
    font-size: 9px;
  }
  .instant_line{
    position: relative;
    left: 14%;
  }
  .payout_heading{
    position: absolute;
    top: 435px;
    left: 39px;
  }
  .modal.show .modal-dialog{
    margin: 0px;
    padding: 0px;
  }
  .payout_modal>div>.modal-content{
    background-color: white ;
    height: 100vh;
    } 
    .payout_content{
      position: absolute;
      top: 550px;
      left: 2px;
      color: #808080c4;
      padding: 0px 23px;
    }
    .payout_tag{
      position: absolute;
      top: 550px;
      left: 46px;
      color: #808080c4;
      padding: 0px 23px;
    }
    .continue_btn{
      background-color: #007EA0;
      border-radius: 8px;
      padding: 0.7rem 5rem;
      color: white;
      margin: 0 4.4rem 1.5rem 0rem;
      border: none;
      font-weight: bolder;
      cursor: pointer;
    }

    .spinner{
      position: absolute;
      bottom: 3rem;
      right: 11.4rem;
    }
    .instant_line{
      position: relative;
      left: 18%;
    }
    .modal.show .modal-dialog{
      padding: 0px;
      margin: 0px;
    }
    .payout_modal>div>.modal-content{
      background-color: white ;
      height: 100vh;
      } 
}

.loading {
  position: relative;
  width: 29%;
  padding: 8px 46px;
}
.add_button {
  font-size: 14px;
  font-weight: normal;
}

.loader {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
}

.client_filter_up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
  z-index: 999;
}
.product {
  background-color: #000;
  padding: 20px 10px;
  margin-top: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: white;
}
.product_data {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.header_data {
  padding: 0 18px;
}
.iconAdd {
  position: absolute;
  left: 60px;
  bottom: 15px;
}
.iconAdd._add_icon{
  position: static;
}

.search_product._product_search .form-control, .search_product._product_search .form-control:focus {
  height: calc(2.5em + 0.75rem + 2px) !important;
}
.cm_search_wrapper.client_search.search_product._product_search i {
  top: 18px !important;
  right: 0px;
}
.product_cancel {
  background-color: white;
  font-weight: bolder;
  font-size: 20px;
  padding: 12px 45px;
  border-radius: 10px;
  outline: none;
  border: 1px solid black;
  margin: 12px 6px 15px 8px;
  cursor: pointer;
}
.product_save {
  background-color: black;
  margin: 1px 0px 1px 44px;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  padding: 12px 49px;
  border-radius: 10px;
  outline: none;
  border: 1px solid black;
  cursor: pointer;
}
.product_delete {
  background-color: pink;
  margin: 1px 7px 1px 33px;
  color: black;
  font-weight: bolder;
  font-size: 20px;
  padding: 12px 45px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
}
.product_title {
  font-size: 30px;
  margin: 20px 0px 20px 20px;
}
.product_input {
  width: 96%;
  padding: 10px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #7d7d7d;
  font-size: 22px;
}
div.product_input {
  background: #f0f0f0;
}
.product_select {
  width: 96%;
  padding: 13px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #7d7d7d;
  font-size: 22px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/down-arrow-icon.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding-right: 40px;
  -webkit-background-size: 25px;
  -moz-background-size: 25px;
  background-size: 25px;
}

.menu_button {
  position: absolute;
  top: 2.1rem;
  left: 12.3rem;
}
.cart_div {
  position: absolute;
  top: 1.8rem;
  right: 1.5rem;
  background-color: #000;
  padding: 0.4rem;
  border-radius: 10px;
  cursor: pointer;
}
.cart_show {
  color: white;
  font-weight: bolder;
}

.product_modal1 .modal-dialog {
  width: 345px;
  top: 26%;
  left: 9%;
  cursor: pointer;
}
.text_h3 {
  border-top: 1px solid black;
  color: red;
}

.product_div{
  background: #f0f0f0;
}
.black_heading{
  padding: 0 18px;
}

.product_main_div{
  background-color: white;
  position: absolute;
  top: 50%;
  left: 57%;
  transform: translate(-50%,-50%);
  /* width: 21%; */
  width: 385px;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
}
.font_design{
  font-size: 15px;
}

@media (min-width: 1750px) and (max-width: 1800px) {
  .product_save {
    margin: 1px 0px 1px 22px;
  }
}
@media (min-width: 1675px) and (max-width: 1700px) {
  .product_save {
    margin: 1px 0px 1px 10px;
  }
}

@media (min-width: 375px) and (max-width: 410px) {
  .product_main_div{
    background-color: white;
    top: 47%;
    left: 50%;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  }
  .add_button {
    font-size: 14px;
    font-weight: normal;
    width: 40% !important;
    text-align: right;
  }
  .search_product{
    position: absolute;
    width: 61%;
    left: 40%;
    top: 2%;
  }
  .search_service{
    position: relative;
  }
  .iconAdd {
    left: 28px;
  }
  .black_heading{
    padding: 0;
  }
  .product{
    border-radius: 0;
  }
  .add_btn{
    margin-top: 15px;
  }
  .product_modal1 .modal-dialog {
    width: 352px;
    top: 4%;
    left: 4%;
    cursor: pointer;
  }
  .productMainDiv{
    flex-wrap: nowrap !important;
  }
  .product_data{
    border-radius: 0;
    overflow-y: scroll;
  }
  .font_design{
    font-size: 13px;
  }
}
@media (min-width: 411px) and (max-width: 540px) {

  .product_main_div{
    background-color: white;
    top: 45%;
    left: 50%;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  }
  .product_save {
    margin: 1px 0px 1px 70px;
  }
  .product_delete {
    margin: 1px 7px 1px 60px;
  }
  .add_button {
    font-size: 14px;
    font-weight: normal;
    width: 40% !important;
    text-align: right;
  }
  .search_product{
    position: absolute;
    width: 61%;
    left: 40%;
    top: 2%;
  }
  .search_service{
    position: relative;
  }
  .iconAdd {
    left: 28px;
  }
  .black_heading{
    padding: 0;
  }
  .product{
    border-radius: 0;
  }
  .add_btn{
    margin-top: 12px;
  }
  .product_modal1 .modal-dialog {
    width: 370px;
    top: 4%;
    left: 4%;
    cursor: pointer;
  }
  .productMainDiv{
    flex-wrap: nowrap !important;
  }
  .product_data{
    border-radius: 0;
    overflow-y: scroll;
  }
  .font_design{
    font-size: 13px;
  }
}
@media (min-width: 701px) and (max-width: 810px) {
  .product_main_div{
    background-color: white;
    top: 48%;
    left: 50%;
    /* width: 54%; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  }
  /* .product_save {
    margin: 1px 0px 1px 70px;
  } */
  /* .product_delete {
    margin: 1px 7px 1px 60px;
  } */
  .product_modal1 .modal-dialog {
    left: 2%;
  }
  .iconAdd {
    left: 24px;
  }
  .search_product{
    position: absolute;
    width: 61%;
    left: 32%;
    top: 2%;
  }
  .search_service{
    position: absolute;
    width: 61%;
    left: 32%;
    top: 2%;
  }
  .add_btn{
    margin-top: 12px;
  }
  
}
@media (min-width: 811px) and (max-width: 999px) {
  .product_main_div{
    background-color: white;
    top: 48%;
    left: 50%;
    /* width: 51%; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  }
  .product_save {
    /* margin: 1px 0px 1px 70px; */
  }
  /* .product_delete {
    margin: 1px 7px 1px 60px;
  } */
  .product_modal1 .modal-dialog {
    left: 2%;
  }
  .iconAdd {
    left: 24px;
  }
  .search_product{
    position: absolute;
    width: 55%;
    left: 33%;
    top: 2%;
  }
  .search_service{
    position: absolute;
    width: 55%;
    left: 33%;
    top: 2%;
  }
  .add_btn{
    margin-top: 17px;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .product_main_div{
    background-color: white;
    top: 52%;
    left: 51%;
    /* width: 37%; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  }
  .product_save {
    margin: 1px 0px 1px 34px;
  }
  .product_delete {
    margin: 1px 7px 1px 28px;
  }
  .product_modal1 .modal-dialog {
    left: 2%;
  }
  .iconAdd {
    left: 39px;
  }
}
@media (min-width: 1150px) and (max-width: 1250px) {
  .product_main_div{
    background-color: white;
    top: 52%;
    left: 61%;
    /* width: 32%; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  }
  .product_save {
    margin: 1px 0px 1px 34px;
  }
  .product_delete {
    margin: 1px 7px 1px 26px;
  }
  .product_modal1 .modal-dialog {
    left: 13%;
    top: 23%;
  }
  .iconAdd {
    left: 39px;
  }
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .product_main_div{
    background-color: white;
    top: 52%;
    left: 60%;
    /* width: 28%; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  }
  .product_save {
    margin: 1px 0px 1px 40px;
  }
  .product_delete {
    margin: 1px 7px 1px 31px;
  }
  .product_modal1 .modal-dialog {
    left: 12%;
  }
  .iconAdd {
    left: 55px;
  }
  .service_flex_div .service_div .currency_div {
    margin-right: 0px;
}
}
.view_product_modal .modal-dialog .modal-content{
  border-radius: 9px !important;
} 

.service_div {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  margin-top: 25px;
}


.service_1{
  font-size: 15px;
  background-color: #007ea0;
  color: white;
  padding: 2px 10px;
  border-radius: 5px;
}

.view_booking_new .service_1{
  padding: 3px 12px 2px 12px;
  font-size: 13px;
}
.service_1.processing_time {
  background-color: #C36464;
    padding: 3px 18px 2px 18px;
    font-size: 13px;
}
.service_1.card_captured{
  background-color: #7ec364;
}
._processing_time {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.processing_time_msz {
  background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
    border-radius: 0.25rem!important;
    padding: 7px 12px;
    width: 270px;
    text-align: center;margin: 20px;font-size: 14px;
    font-weight: 500;
}
.processing_time_msz ._icon svg{
  width: 20px;
  height: 20px;
}
.processing_time_msz:before {
  content: ' ';
    position: absolute;
    left: 132px;
    top: 0;
    width: 5px;
    height: 100%;
    background: #C36464;
}
.view_booking_new input,
.view_booking_new .__time.time_box,
.view_booking_new .service_selected1,
.view_booking_new .currency_div{
  cursor: default !important;
}
.view_booking_new .service_selected1.disabled_div ,
.view_booking_new .currency_div{
  background: white !important;
  border: 1px solid #00000045 !important;
}
.gr_close {
  font-size: 21px;
  cursor: pointer;
  font-weight: 800;
  margin-top: 16px;
  border: 1px;
}

.service_row{
  height: 75px;
}
.service_select{
  width: 96%;
  padding: 13px 13px 0px 13px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #00000036;
  font-size: 22px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/down-arrow-icon.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding-right: 40px;
  -webkit-background-size: 25px;
  -moz-background-size: 25px;
  background-size: 25px;
}

.time_box{
  width: 133px;
  vertical-align: middle;
  border: 1px solid #00000045;
  border-radius: 7px;
  margin-bottom: 41px;
  text-align: center;
  padding: 8px 0px 1px 0px;
}
.time_box_dis{
  background-color: #9d9a9a1c;
  vertical-align: middle;
  border-radius: 7px;
  margin: 0px 20px 41px 20px;
  text-align: center;
  padding: 8px 35px 2px 35px;
}
.time_box_dis1{
  width: 125px;
  background-color: #9d9a9a1c;
  vertical-align: middle;
  border-radius: 7px;
  margin: 0px 17px 41px 3px;
  text-align: center;
  padding: 8px 35px 2px 35px;
}
.select_duration{
  width: 56px;
  text-align: center;
  background-color: #9d9a9a00;
  border: 0;
  outline: none;
  font-weight: 400;
}
.start_time{
  vertical-align: middle;
  font-size: 14px;
  color: gray;
  font-weight: normal;
}
.start_time1{
  width: 123px;
  vertical-align: middle;
  font-size: 14px;
  border: 0;
  text-align: center;
}
.service_select1{
  width: 101%;
  padding: 13px;
  border-radius: 9px;
  font-size: 22px;
  background-color: #9d9a9a1c;
  margin: 7px 0px 0px -15px;
}
.service_selected{
  width: 99%;
  padding: 13px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #00000036;
  font-size: 22px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/down-arrow-icon.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding-right: 40px;
  -webkit-background-size: 25px;
  -moz-background-size: 25px;
  background-size: 25px;
  margin-top: 5px;
}
.service_selected4{
  width: 100%;
  padding: 13px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #00000036;
  font-size: 22px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/down-arrow-icon.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding-right: 40px;
  -webkit-background-size: 25px;
  -moz-background-size: 25px;
  background-size: 25px;
  margin-top: 5px;
}
.service_selected1{
  width: 96%;
  padding: 13px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #00000036;
  font-size: 22px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/down-arrow-icon.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding-right: 40px;
  -webkit-background-size: 25px;
  -moz-background-size: 25px;
  background-size: 25px;
  margin-top: 5px;
}
.service_selected5{
  width: 98.5%;
  padding: 13px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #00000036;
  font-size: 22px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/down-arrow-icon.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding-right: 40px;
  -webkit-background-size: 25px;
  -moz-background-size: 25px;
  background-size: 25px;
  margin-top: 5px;
}
.service_selected2{
  width: 99%;
  padding: 13px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #00000036;
  font-size: 22px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/down-arrow-icon.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding-right: 40px;
  -webkit-background-size: 25px;
  -moz-background-size: 25px;
  background-size: 25px;
  margin-top: 5px;
}
.service_selected3{
  width: 96%;
  margin-top: 7px;
  padding: 13px;
  border-radius: 9px;
  outline: none;
  background-color: #9d9a9a1c;
  font-size: 22px;
  margin-bottom: 7px;
}
.currency_div{
  background-color: #F7F7F7;
  border-radius: 7px;
  font-size: 24px;
  min-height: 29px;
  margin-top: 5px;
  margin-left: 5px;
  display: flex;
}

.currency_sml{
  border-right: 1px solid #80808040;
  padding: 11px 0px;
}
.new__edit_page .currency_sml {
  padding: 12px 0;
}
.currency_sml1{
  padding: 12px 0px 0 0 !important;
  width: 100%;
  font-size: 22px;
}
.service_2 {
  font-size: 16px;
  background-color: #ffa500d9;
  color: black;
  padding: 4px 16px;
  font-weight: 500;
  border-radius: 5px;
}


.cm_right_panal.client_checkout.newclient-checkout{
  width: 100%;
}
.add_service{
  padding: 6px 17px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  border-radius: 5px;
  margin-left: 97px;
  cursor: pointer;
}
.add_services{
  padding: 6px 17px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  border-radius: 5px;
  margin-left: 126px;
  cursor: pointer;
}
.add_products{
  padding: 6px 17px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  border-radius: 5px;
  margin-left: 30px;
  cursor: pointer;
}
.add_service1{
  padding: 6px 17px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  border-radius: 5px;
  margin-left: 30px;
  cursor: pointer;
}
.selectService{
  margin-left: -12px;
}
.main_service_div{
  width: 63%;
}
.renderService{
  width: 100%;
  padding-top: 30px;
  margin-left: 0px;
}
.checkout_page{
  padding-left: 35px;
  width: 36%;
}
.service_flex_div{
  display: flex;
  flex-wrap: wrap;
}
.serviceTable{
  width: 51%;
}
.body_bg_lightGray .main_service_div {
  width: 63%;
}
.body_bg_lightGray .checkout_page {
  padding-left: 35px;
  width: 36%;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .time_box{
    width: 87px;
    padding: 8px 0px 1px 0px;
  }
  .time_box_dis{
    margin: 0px 14px 41px 14px;
    padding: 8px 15px 2px 15px;
  }
  .time_box_dis1{
    width: 85px;
    margin: 0px 17px 41px 3px;
    padding: 8px 8px 2px 13px;
  }
  .start_time1{
    width: 82px;
  }
  .currency_div{
    margin: 5px -38px 0px 5px;
  }
  .service_svc{
    padding-left: 16px;
  }
  .client_svc{
    padding-left: 0px;
    margin-left: -8px;
  }
  .add_service{
    margin-left: 74px;
  }
  .service_select{
    font-size: 20px;
    background-size: 20px;
  }
  .service_selected{
    font-size: 21px;
    background-size: 21px;
  }
  .service_select1{
    font-size: 21px;
  }
  .selectService {
    padding-left: 34px;
  }
  .service_selected4 {
    width: 101%;
  }
  .booking_div {
    margin: 37px 0px 25px 65px !important ;
  }
  .add_services{
    margin-left: 90px;
  }
  .service_selected5{
    width: 94%;
    margin-left: 20px;
  }
  .product_name{
    margin-left: 21px;
  }
}
@media (min-width: 1090px) and (max-width: 1200px) {
  .service_select{
    font-size: 16px;
    background-size: 16px;
    padding-right: 20px;
  }
  .service_selected{
    width: 98%;
    font-size: 17px;
    background-size: 19px;
  }
  .service_select1{
    font-size: 17px;
  }
  .start_time{
    font-size: 11px;
  }
  .time_box{
    max-height: 56px;
    width: 71px;
    padding: 6px 0px 1px 0px;
  }
  .time_box_dis{
    max-height: 56px;
    margin: 0px 11px 41px 16px;
    padding: 8px 11px 2px 11px;
  }
  .selectService {
    padding-left: 47px;
  }
  .time_box_dis1{
    max-height: 56px;
    width: 71px;
    margin: 0px 17px 41px 3px;
    padding: 8px 8px 2px 13px;
  }
  .select_duration{
    width: 42px;
  }
  .start_time1{
    width: 66px;
  }
  .currency_div{
    margin: 5px -51px 0px 5px;
    font-size: 21px;
  }
  .service_selected1,
  .service_flex_div.service_flex_div .input_group_u .css-1uccc91-singleValue,
  .show__date,
  .input_group_u._staff_member .css-1wa3eu0-placeholder{
    font-size: 18px;
    background-size: 18px;
  }
  .body_bg_lightGray .input_group_u.__service .service_selected1, .body_bg_lightGray .input_group_u.__Client .service_selected1, .body_bg_lightGray .input_group_u._staff_member .service_selected1 ,.currency_sml1 {
    font-size: 18px;
}
  .service_svc{
    padding-left: 29px;
  }
  .client_svc{
    padding-left: 0px;
    margin-left: -17px;
  }
  .add_service{
    margin-left: 63px;
  }
  .service_selected2 {
    width: 98%;
    font-size: 20px;
    background-size: 20px;
  }
  .service_selected4{
    font-size: 19px;
    background-size: 20px;
  }
  .booking_div {
    margin: 37px 0px 25px 55px !important ;
  }
  .add_services{
    margin-left: 76px;
  }
  .service_selected5{
    width: 90%;
    margin-left: 30px;
  }
  .product_name{
    margin-left: 30px;
  }
  .service_flex_div .service_div .currency_div {
    margin-right: 0px;
}
}
@media (max-width:1200px){
  ._staff_member .css-yk16xz-control{max-height: 55px;}
}
@media (min-width: 900px) and (max-width: 1089px) {
  .service_select{
    font-size: 18px;
    background-size: 19px;
    padding-right: 20px;
  }
  .service_selected{
    width: 98%;
    font-size: 18px;
    background-size: 19px;
  }
  .service_selected1,
  .service_flex_div.service_flex_div .input_group_u .css-1uccc91-singleValue,
  .show__date,
  .input_group_u._staff_member .css-1wa3eu0-placeholder{
    font-size: 19px;
    background-size: 19px;
  }
  .service_select1{
    font-size: 18px;
  }
  .start_time{
    font-size: 13px;
  }
  .time_box{
    width: 79px;
    max-height: 58px;
    padding: 6px 0px 1px 0px;
  }
  .time_box_dis{
    margin: 0px 13px 41px 15px;
    max-height: 58px;
    padding: 8px 13px 2px 13px;
  }
  .time_box_dis1{
    width: 79px;
    margin: 0px 17px 41px 3px;
    max-height: 58px;
    padding: 8px 8px 2px 13px;
  }
  .start_time1{
    width: 66px;
  }
  .currency_div{
    margin: 5px -51px 0px 5px;
    font-size: 21px;
  }
  .service_svc{
    padding-left: 40px;
  }
  .client_svc{
    padding-left: 0px;
    margin-left: -13px;
  }
  .add_service{
    margin-left: 68px;
  }
  .selectService{
    padding-left: 56px;
  }
  .service_selected4 {
    width: 101%;
  }
  .booking_div {
    margin: 37px 0px 25px 62px !important ;
  }
  .add_services{
    margin-left: 83px;
  }
  .service_selected5{
    width: 91%;
    margin-left: 30px;
    font-size: 20px;
  }
  .product_name{
    margin-left: 30px;
  }
  .service_flex_div .service_div .currency_div {
    margin-right: 0px;
}
}
@media (min-width: 730px) and (max-width: 850px) {
  .service_select{
    font-size: 14px;
    background-size: 15px;
    padding-right: 20px;
  }
  .service_selected{
    font-size: 17px;
    width: 101%;
    background-size: 19px;
  }
  .service_select1{
    font-size: 17px;
    width: 107%;
  }
  .currency_div{
    font-size: 21px;
  }
  .service_selected1,
  .service_flex_div.service_flex_div .input_group_u .css-1uccc91-singleValue,
  .show__date,
  .input_group_u._staff_member .css-1wa3eu0-placeholder{
    font-size: 18px;
    background-size: 18px;
  }
  .selectService{
    padding-left: 57px;
  }
  .start_time{
    font-size: 12px;
  }
  .time_box{
    width: 70px;
    padding: 6px 0px 1px 0px;
    max-height: 52px;
  }
  .time_box_dis{
    margin: 0px 10px 41px 10px;
    padding: 8px 10px 2px 10px;
    max-height: 52px;
  }
  .time_box_dis1{
    width: 65px;
    margin: 0px 0px 41px -1px;
    padding: 6px 1px 0px 1px;
  }
  .start_time1{
    width: 46px;
  }
  .currency_div{
    margin: 5px -60px 0px 5px;
  }
  .service_svc{
    padding-left: 40px;
  }
  .client_svc{
    padding-left: 0px;
    margin-left: -24px;
  }
  .add_service{
    margin-left: 56px;
  }
  .service_selected4 {
    width: 102%;
    font-size: 20px;
    background-size: 20px;
  }
  .booking_div {
    margin: 37px 0px 25px 47px !important ;
  }
  .add_services{
    margin-left: 66px;
  }
  .service_selected5{
    width: 86%;
    margin-left: 41px;
    font-size: 20px;
  }
  .product_name{
    margin-left: 41px;
  }
}
@media (min-width: 360px) and (max-width: 405px) {
  .grClose{
    position: absolute;
  }
  .add_service{
    margin-left: 58px;
  }
  .service_select{
    font-size: 21px;
    background-size: 21px;
  }
  .time_box{
    max-height: 60px;
    width: 102px;
    padding: 8px 0px 1px 0px;
    margin: 11px 0px 0px 23px;
  }
  .time_box_dis{
    max-height: 60px;
    margin: 11px 16px 41px 17px;
    padding: 8px 22px 2px 22px;
  }
  .time_box_dis1{
    max-height: 60px;
    width: 102px;
    margin: 11px 0px 40px 3px;
    padding: 8px 12px 2px 13px;
  }
  .staff_member{
    margin-top: 70px;
  }
  .client_svc{
    position: absolute;
    top: 285px;
    left: -53px;
  }
  .service_select1{
    width: 97%;
    margin: 6px 0px 0px 7px;
  }
  .price_div{
    margin-top: 6px;
  }
  .service_svc1{
    position: absolute;
    bottom: 344px;
    left: 5px;
  }
  .start_time1 {
    width: 97px;
    font-size: 16px;
  }
  .currency_div {
    width: 98%;
    margin: 5px 5px 25px 5px;
  }
  .currency_sml {
    margin: 0px 10px 0px 6px;
  }
  .editable_currncy .currency_sml {
    margin: 0px 3px 0px 6px;
  }

  .checkout_page{
    padding-left: 0px !important;
    width: 100% !important;
  }
  .main_service_div , .body_bg_lightGray .main_service_div{
    width: 100%;
  }
  .service_svc{
    position: absolute;
    top: 233px;
  }
  .service_selected{
    margin-left: 14px !important;
  }
  .booking_div{
    padding: 3px 16px !important;
    font-size: 22px !important;
    margin: 10px 10px 20px 10px !important;
  }
  .booking_div1{
    padding: 3px 32px !important;
    font-size: 22px !important;
    margin: 10px 10px 20px 0px !important;
  }
  .booking_div2{
    padding: 3px 13px !important;
    font-size: 22px !important;
    margin: 10px 4px 20px 0px !important;
  }
  .gr_close{
    position: absolute;
    bottom: 17px;
    right: 12px;
  }
  .add_services{
    margin-left: 70px;
  }
  .service_selected_cl{
    width: 96% !important;
    margin-top: 23px !important;
    margin-left: 6px !important;
  }
  .client_sl{
    position: absolute !important;
    left: -41px !important;
    top: 70px !important;
  }
  .product_name{
    position: absolute;
    top: 68px;
    left: 22px;
  }
}
@media (min-width: 406px) and (max-width: 500px) {
  .grClose{
    position: absolute;
  }
  .add_service{
    margin-left: 71px;
  }
  .service_select{
    font-size: 21px;
    background-size: 21px;
  }
  .time_box{
    max-height: 60px;
    width: 107px;
    padding: 8px 0px 1px 0px;
    margin: 11px 0px 0px 23px;
  }
  .time_box_dis{
    margin: 11px 16px 41px 17px;
    padding: 8px 27px 2px 27px;
    max-height: 60px;
  }
  .time_box_dis1{
    width: 107px;
    margin: 11px 0px 40px 3px;
    max-height: 60px;
    padding: 8px 12px 2px 13px;
  }
  .staff_member{
    margin-top: 70px;
  }
  .client_svc{
    position: absolute;
    top: 285px;
    left: -53px;
  }
  .service_select1{
    width: 97%;
    margin: 6px 0px 0px 7px;
  }
  .price_div{
    margin-top: 6px;
  }
  .service_svc1{
    position: absolute;
    bottom: 344px;
    left: 5px;
  }
  .start_time1 {
    width: 97px;
    font-size: 16px;
  }
  .currency_div {
    width: 97%;
    margin: 5px 5px 25px 5px;
  }
  .currency_sml {
    margin: 0px 10px 0px 6px;
  }
  .editable_currncy .currency_sml {
    margin: 0px 3px 0px 6px;
  }
  
  .start_time{
    font-size: 15px;
  }
  .main_service_div , .body_bg_lightGray .main_service_div{
    width: 100%;
  }
  .checkout_page{
    padding-left: 0px !important;
    width: 100% !important;
  }
  .service_svc{
    position: absolute;
    top: 233px;
  }
  .service_selected{
    margin-left: 14px !important;
  }
  .booking_div{
    padding: 3px 16px !important;
    font-size: 23px !important;
    margin: 10px 10px 20px 10px !important;
  }
  .booking_div1{
    padding: 3px 32px !important;
    font-size: 23px !important;
    margin: 10px 10px 20px 5px !important;
  }
  .booking_div2{
    padding: 3px 13px !important;
    font-size: 23px !important;
    margin: 10px 5px 20px 5px !important;
  }
  .gr_close{
    position: absolute;
    bottom: 17px;
    right: 12px;
  }
  .add_services{
    margin-left: 70px;
  }
  .service_selected_cl{
    width: 96% !important;
    margin-top: 23px !important;
    margin-left: 6px !important;
  }
  .client_sl{
    position: absolute !important;
    left: -41px !important;
    top: 70px !important;
  }
  .product_name{
    position: absolute;
    top: 68px;
    left: 22px;
  }
  .service_selected5{
    width: 97%;
    margin-left: 5px;
  }
}

.service_selected_cl {
  width: 101%;
  padding: 13px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #00000036;
  font-size: 22px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/down-arrow-icon.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding-right: 40px;
  background-size: 25px;
  margin-top: 5px;
  margin-left: -15px;
}

.booking_div{
  font-weight: 100;
  border: 1px solid black;
  padding: 7px 52px;
  font-size: 30px;
  border-radius: 8px;
  margin: 37px 0px 25px 100px;
  box-shadow: inset 0 0 8px #c3c0bbbf;
}
.booking_div1{
  font-weight: 100;
  border: 1px solid black;
  padding: 7px 71px;
  font-size: 30px;
  border-radius: 8px;
  margin: 37px 0px 25px 18px;
  box-shadow: inset 0 0 8px #c3c0bbbf;
}
.booking_div2{
  border: 1px solid black;
  padding: 7px 46px;
  font-size: 30px;
  border-radius: 8px;
  margin: 37px 0px 25px 16px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.main_service_div1{
  width: 70%;
}

.checkout_page1{
  width: 45%;
  position: absolute;
  right: -4%;
  top: 31%;
}
.about_comm{
  font-size: 9px;
}
.commission_modal > div > .modal-content {
  height: 350px !important;
  width: 350px !important;
  top: 271px;
  right: 165px;
}

._newCheckout_wrapper {
  border: 0px solid transparent;
  margin-top: 2rem;
}


 ._newCheckout_wrapper .nav-tabs .nav-link.active {
  background-color: #29282e;
  color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

._newCheckout_wrapper  .nav-link.active {
  color: #ffffff;
  background-color: #2a272e;
  border-color: #dee2e6 #dee2e6 #fff0;
  font-weight: 500;
  font-size: 1.2rem;
}

._newCheckout_wrapper .nav-link {
  margin-bottom: -1px;
  background-color: #fdfdfd;
  border: 1px solid #d1d1d1;
  border-radius: 0.25rem;
  min-width: 200px;

  margin: 0rem 0.5rem 0rem 0.5rem;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 200;
  box-shadow: inset 1px 2px 2px 2px #5555551f;
}

.body_bg_lightGray {
  background: #f4f4f4;
  min-height: calc(100vh - 68px);
  margin-top: 74px;
}

.body_bg_lightGray .top_header {
  padding: 0.8rem 2.2rem;
  width: calc(100% - 250px);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  background: #f9f9f9;
  text-align: center;
  min-height: 73px;
  box-shadow: 0px 0px 13px #0000005c !important;
}
.native-platform .top_header,
.body_bg_lightGray .top_header{
  box-shadow: 0px 3px 6px #00000046 !important;
  z-index: 99999
}
.native-platform .sidenava{
  margin-top: 40px
}
._field_label_vk{
  font-size: 0.9rem;
  font-weight: 500;
}
._booking_setting ._field_label_vk{
  font-size: 1rem;
}


.body_bg_lightGray .service_selected1 {
  width: 100%;
  padding: 13px;
  border-radius: 9px;
  outline: none;
  border: 1px solid #00000036;
  font-size: 1.2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/down-arrow-icon.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  padding-right: 40px;
  background-size: 25px;
  margin-top: 0px;
}

.body_bg_lightGray .input_group_u{
  margin-bottom: 0.8rem;
}

.body_bg_lightGray .button-group-service{
  margin: 1.5rem 5rem 0rem 3rem;
}

.button-group-service{
  display: flex;
  justify-content: left;
  align-items: center;
}

.button-group-service button {
  margin-right: 1rem;
  font-size: 0.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 500;
}

.body_bg_lightGray .button-group-service {
  margin: 1.5rem 0rem 0rem 0rem;
}

.body_bg_lightGray .service_2 {
  font-size: 16px;
  background-color: #ffa500d9;
  color: black;
  padding: 4px 16px;
  font-weight: 500;
  border-radius: 5px;
  margin-left: 15px;
}

.body_bg_lightGray .service_1 {
  font-size: 16px;
  color: #fff;
  padding: 4px 16px;
  font-weight: 500;
  border-radius: 5px;
  margin-left: 15px;
}

.body_bg_lightGray  .gr_close {
  font-size: 21px;
  cursor: pointer;
  font-weight: 800;
  /* margin-top: 16px; */
  margin-top: 3px;
  border: 1px;
  margin-right: 0px;
}

.body_bg_lightGray .service_div {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
  margin-top: 25px;
  padding: 10px 15px;
}

.product_row{
  cursor: pointer;
}

.body_bg_lightGray ._disabled_input{
  background-image: unset !important;
  background-color: #f5f3f3 !important;
  cursor: not-allowed;
}


.service_flex_div .cm_bao {
  background: #2a272e;
  color: #fff;
  padding: 20px;
}
.service_flex_div .cm_bao i{
  color: #fff;
}

.service_flex_div  .payMnt-methdo > .btn {
  background: #000 !important;

}


._disabled_dropdown_selector{
  background-image: none;
}

.nav-item.nav-link{
  cursor: pointer;
}

.service_flex_div .newclient-checkout .payment-bill .tble p {
  padding: 8px 0px;
  font-size: 0.8rem;
  font-weight: 800;
}

.body_bg_lightGray .time_box_dis {
  margin: 0px 0px 0px 0px;
  padding: 13px 10px;
  
}

.body_bg_lightGray .time_box_dis h6, .body_bg_lightGray .time_box h6{
  font-size: 0.7rem;
}

.body_bg_lightGray  .start_time1 {
  width: 100% !important;
  vertical-align: middle;
  font-size: 0.7rem;
  border: 0;
  text-align: center;
  padding-bottom: 0.5rem;
}

.body_bg_lightGray .time_box {
  width: 100% !important;
  margin-bottom: 14px;
  padding: 11px 0px 1px 0px;
}

.start_time {
  vertical-align: middle;
  font-size: 13px;
  color: gray;
  font-weight: normal;
}

/* .fix_time_box .__time {
  width: calc(100% / 3 - 12px);
  margin: 0 4px;max-height: 58px;
} */
.fix_time_box .__time {
  width: calc(100% / 3 - 0px);
  margin-left: 8px;
  max-height: 58px;
  
}
.fix_time_box .__time:first-child {
  margin-left: 0;
}
.__time.time_box {
  background-color: #F7F7F7;
  vertical-align: middle;
  border-radius: 7px;
  text-align: center;
  padding: 8px 10px 2px 10px;
  border: none;
}
.__time.time_box.active {
  background-color: #ffffff;
  border: 1px solid #00000045;
}
.__time.time_box_dis {
  background-color: #F7F7F7;
  vertical-align: middle;
  border-radius: 7px;
  text-align: center;
  /* padding: 8px 20px 2px 20px; */
  padding: 8px 10px 2px 10px;
}

.b-s-1,.btn-light:focus {
  box-shadow: 1px 2px 9px #52525285;
  background: #fff !important;
  background-color: #fff !important;
}

.__custom_gray{
    background: #eeeeee;
}

.__datepicker_input_height {
  height: 18px !important;
}

.user-dtls .text p {
  color: #e0e0e0;
  font-weight: 300;
}





/* .cm_right_panal {
  display: inline-block;
  width: calc(99% - 30px);
  margin-left: 20px;
  vertical-align: top;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.31);
  border-radius: 9px;
  padding: 30px;
  margin-bottom: 274px;
  position: relative;
} */
@media (max-width: 991px) {

  .discoverd-modalWrp .new-stng-cmn._sms_reminder {
    padding: 1rem;
}
.discoverd-modalWrp .new-stng-cmn._sms_reminder ._text ._head{
  flex-wrap: wrap;
}
.discoverd-modalWrp .new-stng-cmn._sms_reminder ._text {
 padding-left: 0px;
  margin-left: 0;
  text-align: center;
}
.discoverd-modalWrp .new-stng-cmn._sms_reminder ._text h4{
  width: 100%;
  text-align: center;
}
  ._reminder_section {
    width: 100%;
}
  .visible-staff-body {
    display: none;
  }
  .salon_switch_container._header_toggle{
    display: none;
  }
  ._add_service_container {
    flex-wrap: wrap;
}
  ._add_service_box.form-group {
    width: calc(100% / 2 - 32px);
    margin: 0 15px;
}
  /* .checkout_page {
    width: 100%;
} */
.cm_right_panal.client_checkout.phone-payemnet-taken-change.iinvoice .text-center {
 max-width: 100%; 
  width: 100%;
  margin-left: 0px
}
.cm_right_panal.client_checkout.phone-payemnet-taken-change.iinvoice .cm_btns.mobile-change-btn {
  padding: 0px;
}
.cm_right_panal.client_checkout.phone-payemnet-taken-change.iinvoice .cm_btns.mobile-change-btn .btn{
  margin-right: 0;
}
.commission_modal > div > .modal-content {
  height: auto !important;
  width: auto !important;
  top: inherit;
  right: inherit;
}
.currency_sml1{
  padding-left: 1rem!important;
    text-align: left !important;
}
.editable_currncy .currency_sml1{
  padding-left: 0rem!important;
}

.show__date {
  top: 40%;
}
  .service_row {
    height: auto !important;
}

.fix_time_box .__time {
  margin: 0;
  margin-right: 10px;
  width: calc(100% / 3 - 8px);
}
.fix_time_box .__time:last-child {
  margin-right: 0px;
}
.service_div .input_group_u , .service_div .service_selected1{
  width: 100%;
}
.service_div .col-md-6 ,
.service_div .col-md-9{
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-bottom: 10px;

}
.main_service_div button.btn.btn-light {
  margin-left: 10px;
}
.currency_div {
  margin: 5px 5px 5px 5px;
}
.sidenava ul li a.active .menu_icon._markt svg path{
  fill: #29282E !important;
}
.sidenava ul li a.active .menu_icon._checkout > svg > path {
  fill: #29282E !important;
}
}

@media (min-width: 991px) {

  .checkout_page .cm_right_panal.client_checkout.remove-brder {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 383px){
  .grClose{
    position: absolute;
  }
    .product_main_div {
    top: 50% !important;
    left: 0% !important;
    transform: translate(-0%,-50%) !important;
    width: 100% !important;
  }
  .product_delete , .product_cancel ,.product_save{
    /* margin: 1px 7px 1px 33px; */
    font-size: 16px;
    padding: 12px 32px;min-width: 115px
  }
 
}
.__open_dropdown{
  display: block;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus{
  box-shadow: 0 0 0 0rem rgb(38 143 255 / 0%);
  background-color: #0062cc00; 
  border-color: #0062cc00
}
/* //check the code */

.edit_price_input{
  background-color: #F7F7F7;
    border-radius: 7px;
    font-size: 22px;
    min-height: 29px;
    border: none;
    padding-top: 0px !important
}
.edit_price_input:focus-visible{
  outline: none;
}
.currency_div.currency_div_edit {
  background-color: #fff;
  min-height: 28px;
  border: 1px solid #00000036;
}
.currency_div.currency_div_edit .currency_sml {
  padding: 10px 0px;
}
.currency_div.currency_div_edit .edit_price_input{
  background-color: #fff;
}
.currency_div.currency_div_edit.border_blue {
  border-color: #007EA0;
}

.overlay-wrapper-checkout{
  width: 100%;
  background: #ffffff75;
  position: fixed;
  height: 100vh;
  z-index: 9;
  filter: blur(54px);
}

.cursor-notAllow{
  cursor: not-allowed !important;
}

.new-stng-cmn .btn-secondary {
  border-radius: 9px;
  transition: all 0.25s;
  padding: 0.85rem 2rem;
  font-size: 20px;
  font-weight: bold;
}

.proceesing_input {
  border: 0px;
  width: 30px;
  cursor: auto;
  border-radius: 0px;
  padding: 2px;
  margin-right: 0px;
  text-align: right;
}

.proceesing_input:focus-visible{
  border: 0px;
  outline: 0px;
}

.processingInputBorder {
  border: 1px solid #d0d0d0;
  width: 95px;
  cursor: auto;
  text-align: center;
  border-radius: 8px;
  padding: 2px;
  margin-right: 1px;
  display: inline-block;
}


/* changes for app modal close icon not working */
@media (max-width: 575px){
  .modal-content {
    padding-top: 20px !important
  }
  .modal-header button.close {
    top: 11px !important;
}
}
.native-platform-modal .cm_modal.popupscroll {
  height: calc(100dvh - 2px);
    padding-top: 29px;
}
.native-platform-modal .modal-header button.close {
  top: 40px !important;
}
.native-platform-modal .cm_modal.popupscroll .modal-body {
  margin-top: 26px;
}
.native-platform-modal .cm_discript{
  display: none;
}
/* .Toastify__toast-container {
  z-index: 999999999 !important;
} */
#show-notification #mainToast{z-index: 99999 !important;}
.home_main .react-datepicker__tab-loop .react-datepicker-popper{
  z-index: 9 !important;
}


.backgroun-shadow.checkout-parent.disabled .radio-container, .backgroun-shadow.checkout-parent.disabled .border-div.detl.pointer-event, .backgroun-shadow.checkout-parent.disabled  input[type="radio"]{
 cursor: not-allowed !important;
}


.border-div.detl.flex-just-align-center.pointer-event input[type="radio"],.border-div.detl.flex-just-align-center.pointer-event .radio-container,.border-div.detl.flex-just-align-center.pointer-event.disabled {
  cursor: not-allowed !important;
 }

 .marketing-widrawal-history .table tr td {
  font-size: 16px;
  color: #000;
}
 .processing-dairy-loader {
  width:calc(100% - 250px);
  position: fixed;
  z-index: 999999;
  background: #5555552e;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketing-widrawal-history  .tble-btn {
  background: #007EA0;
  color: #fff;
  padding: 2px 8px;
  border-radius: 15px;
  font-size: 15px;
}

.marketing-widrawal-history ul.pagination>li {
  width: 36px;
  height: 36px;
  background: #dddbdb;
  box-shadow: 0px 2px 4px #5552;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
}

.marketing-widrawal-history ul.pagination>li.active {
  background: #117d9d;
}

.marketing-widrawal-history ul.pagination>li.active a{
  color: #fff !important;
}

.marketing-widrawal-history ul.pagination>li:first-child, .marketing-widrawal-history ul.pagination>li:last-child {
  display: none;
}

._setting_Name_label{
  display: flex;
  text-align: center;
  align-items: center;
  margin-bottom: 0px;
  margin: 12px  0;
}

._setting_Name_label h5 {
  margin-bottom: 0px !important;
}
._card_row_parent{
  width: 746px;
  overflow-x: auto;
}
._card_row ,
._card_row td._btn_group{
  display: flex;
  align-items: center;

}
._card_row td{
  font-size: 15px;
  font-weight: 500;
margin-bottom: 0;
color: #000;padding: 0;
}
._card_row td:first-child{
  width:100px;
}
._card_row td:nth-child(2){
  width:185px;
}
._card_row td:nth-child(3){
  width:120px;
}
._card_row td._btn_group{
  width:calc(100% - ( 285px + 120px + 20px ) );
}
._card_row ._icon{
  width: 20px;
  cursor: pointer;
}
._card_row td._btn_group .btn{
  border-radius: 9px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 1px 16px;
  margin-right: 10px;
}
._card_row {
  border-top: 1px solid #e6e6e6;
    padding: 18px 0;
}
._card_row td._btn_group .btn:first-child{
  background: #C36464;

}
._card_row td._btn_group .btn:last-child{
  background: #007EA0 ;
}

._card_row ._icon svg{
  width: 16px;
  height: 25px;
  transform: rotate(180deg);
}
.card_popup {

}
.card_popup ._card_info h4{
  font-size: 24px;
  font-weight: 500;
  margin-bottom: -10px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
._hide_card_no {
  font-size: 28px;
  letter-spacing: 2px;
}
.card_popup ._card_info h4 span{
  display: inline-block;
  background: #C36464;
  border-radius: 9px;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  padding: 2px 10px;
}
.card_popup ._card_info p{
  font-size: 16px;
  color: #707070;
  font-weight: 500;margin-bottom: 0;
  display: flex;
    align-items: center;
}
.card_popup {
  padding: 20px
}
.card_popup ._expired p{
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
}
.card_popup .btn__group{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.card_popup ._expired h6{
  font-size: 15px;font-weight: 600;
}
.card_popup .salon_switch_container h6{
font-weight: 600;margin-bottom: 0;
}
.card_popup_modal .modal-dialog {
  background: transparent !important;
}
.card_popup_modal .modal-content {
  background: #fff !important;
}
.card_popup .btn__group .btn {
  padding: 0.7rem 1.75rem;
  font-size: 18px;
}


_mt_35{
  /* margin-top: 35px; */
}


.checkbox_disabled {
  opacity: 0.5;
}


.__reminder .cm_btns.withcheckoutbtn{
    width: 100%;
}

.__reminder .cm_btns.withcheckoutbtn .btn-white {
  box-shadow: 1px 0px 6px #0000006e;
}

._card_row{
  cursor:pointer;
}
.processing-dairy-loader img {
  width: 180px;
  height: 180px;
  /* margin-left: -20%; */
  /* margin-top: -15%; */
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -87%);
}

.react-select-status__control{
  width: 182px;
  height: 52px;
  border-radius: 10px;
  margin-right: 13px;
  border: 3px #d7d7d7 solid !important;;
}

.react-select-status__control.css-yk16xz-control {
  border-radius: 10px;
  margin-right: 13px;
  border: 3px #d7d7d7 solid !important;
}

.btn-booking-status{
  line-height: 38px;
  border: 1px solid #0005;
  border-radius: 6px;
  margin-left: 20px;
}

.tb_noshow_color {
  background-color: #FF6584;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
}

.tb_pending_color{
  background-color:#007EA0;
  color:#fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
}

.tb_paid_color{
  background-color:#C8EEEE;
  color:#555;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
}

.noshow_error{
  margin-right: 37px !important;
  margin-top: -7px !important;
}

.booking_capture_card_label{
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  padding-left: 1.4rem;
}

.booking_capture_card_label ._card_icon{
  padding-bottom: 5px;
}

.active-input-field{
  background-color: #ffffff !important;
}

.inactive-input-field{
  cursor: not-allowed;
}

.button-shadow{
  box-shadow: 0px 2px 3px #555;
}

.saloon-settings-wrp  .redBorder{
  border-color: #ff0000 !important;
}

.redBorder > div.css-yk16xz-control{
  border-color: #ff0000 !important;
  border: 1px solid #ff0000 !important;
}

@media (max-width: 991px) {
  .processing-dairy-loader {
    width:calc(100%);
  }
}

@media(max-width:991px) {
  .mob-btnss-div._new_transaction_change {
  
    display: flex !important;
    flex-wrap: wrap;
  }
  
  .mob-btnss-div._new_transaction_change .booking_status_filter .btn:last-child {
    margin: 0 0px 0 0 !important;
  }
  .mob-btnss-div._new_transaction_change .booking_status_filter .btn {
    margin: 0 7px 0 0 !important;
  }
  .mob-btnss-div._new_transaction_change .booking_status_filter {
    order: 0;
      display: flex;
    width: calc(100% - 55px );
  }
  .mob-btnss-div._new_transaction_change .filter.right-container-block {
    order: 1;
    width: 45px;
    margin: 0 0px !important;
  }
  .right-container .mob-btnss-div._new_transaction_change .btn.btn-dark-blue {
    order: 2;
    min-width: 75px;
    height: 42px;
    font-size: 10px;
    margin-right: 1px !important;
    margin-left: 0px !important;
  }
  .mob-btnss-div._new_transaction_change .start-end-datepicker.right-container-block{
    order: 3;
      /* background: darkcyan; */
      width: calc(100% - 76px - 61px);
      margin: 0 !important;
  }
  .mob-btnss-div._new_transaction_change .cm_search_wrapper.client_search{
    order: 4;
    top: 47px;
  }
  .mob-btnss-div._new_transaction_change  .booking_status_filter .btn {
    /* width: 76px; */
    width: calc(100% / 3) !important;
    font-size: 9px;
  }
  .mob-btnss-div._new_transaction_change .start-end-datepicker.right-container-block .sm_date_picker input {
    margin: 5px 0 0px;
  }
  ._new_transaction_change .right-container-block.cm_search_wrapper.client_search.analytics-search-bar.seacrh-mob--view{
    top: 95px;
  }
  ._new_transaction_change  .right-container-block.cm_search_wrapper.client_search.m-0.analytics-search-bar.seacrh-mob--hide .fa-search {
    color: #727272;
    right: auto;
    left: 0px;
    width: 100%;
    text-align: center;
    height: 100%;
    top: -4px !important;
  }
  ._new_transaction_change .cm_search_wrapper.client_search.analytics-search-bar.seacrh-mob--view i {
    top: calc(50% - 19px);
  }
  ._new_transaction_change .seacrh-mob--view .fa-close {
    padding: 0.5rem;
    top: calc(50% - 26px) !important;
  }
  ._new_transaction_change .filter .css-26l3qy-menu {
    left: initial;
    right: 0;
}
}
@media (min-width: 768px) {
  ._new_transaction_change  .right-container-block.cm_search_wrapper.client_search.m-0.analytics-search-bar.seacrh-mob--hide .fa-search {
  
    top: 12px !important;
  }
  .right-container .mob-btnss-div._new_transaction_change .btn.btn-dark-blue,
  .mob-btnss-div._new_transaction_change  .booking_status_filter .btn {
    font-size: 13px;
  }
}
@media (min-width: 1101px) and (max-width: 1236px){
  ._new_transaction_change .cm_search_wrapper.client_search.m-0.analytics-search-bar {
    width: calc(35% - 160px);
}
}
@media (min-width: 992px) and (max-width: 1900px){
  ._new_transaction_change .right-container-block.cm_search_wrapper.client_search.m-0.analytics-search-bar.seacrh-mob--view .fa-close {
    display: none;
  }
  
}
@media (max-width: 991px) {
  .cancel_appointment_modal .text-right {
    text-align: center !important;
    margin: 42px 0 0 0;
}

  ._service_parent_body._marketting_parent{height: calc(100%  )!important;}
  ._row .input_group_u .css-1fhf3k1-control ,
  ._booking_setting ._row .form-control:focus, 
  ._booking_setting ._row .input_group_u .css-yk16xz-control, 
  ._booking_setting ._row .input_group_u .css-1pahdxg-control{
    height: calc(2.5em + 0.75rem + 7px) !important;
  
  }
  .cm_search_wrapper.client_search.search_product._product_search i {
    right: 12px;
    top: 35px !important;
}
  .cm_search_wrapper.client_search.search_product._product_search ,
  .add_btn.__add_btn{
    position: unset;
    margin-top: 17px !important;
    min-width: auto;
}
  .booking-urlpage .new-stng-cmn.sln-fmr-dtls-mob._booking_setting.saloon-settings-wrp ,
  .booking-urlpage .new-stng-cmn.card-reader-list.mb-3.mb-md-4.order-3.pos-relative{
    padding: 1rem;
  }
  .booking-urlpage .new-stng-cmn.sln-fmr-dtls-mob._booking_setting.saloon-settings-wrp .form-group.pl-0 ,
  .booking-urlpage .new-stng-cmn.sln-fmr-dtls-mob._booking_setting.saloon-settings-wrp ._row._add_btn{
   margin-bottom: 0;
  }
  .setting_page .top-header-setting-title {
    background: transparent;
  }
  
}
.para-30 {
  font-size: 1.215rem;
}

@media (max-width: 768px){

  .booking_capture_card_label{
    padding-left: 0.5rem !important
  }
  /* .booking_card.bkng-crd-dary-new h5 ,
  .booking_card.bkng-crd-dary-new .cm_sdule_time{
    font-size: 10px !important;
}
.booking_card.bkng-crd-dary-new .cm_sdule_time{
  font-size: 8px;
}
  .booking_card.cm_wrap_text h5{
    width: calc(100% - 31px) !important;
  } */
}

@media (min-width: 1101px) and (max-width: 1300px){
  .body_bg_lightGray .checkout_page{
    padding-left: 20px;
  }
  .cm_right_panal.client_checkout.newclient-checkout .backgroun-shadow{
    margin-left: 6px;
  }
}
.editable_currncy .currency_sml1.currency_sml_input {
  text-align: center !important;
}
