.login-home-temp.no-topbar .cm-navbar-new {
    display: none;
}
.cur-point{
    cursor: pointer;
}
.__black_btn{
    background: var(--bgColor);
    border-radius: 5px;
    transition: all 0.25s;
    color: #fff;
    border-color: var(--bgColor);
    padding: 0.5rem 2rem;
    font-size: 20px;
    font-weight: 500;
}
.__sky_blue_btn{
    background: #B2D8E2;
    border-color: #B2D8E2;   
}
.blue_btn.disabled, .blue_btn:disabled{
    background: #B2D8E2;
    border-color: #B2D8E2;  
}
.blue_btn{
    background: #007EA0;
    border-color: #007EA0;
}
.home_main{
    /* background:pink; */
    display: flex;
    height: 100dvh;
    overflow: hidden;
}

._bokking_solt.new_timeslot .rwdpDayBoxDesktop::-webkit-scrollbar ,
._service_parent_body::-webkit-scrollbar,
._staff_parent::-webkit-scrollbar,
.home_main::-webkit-scrollbar,
._bokking_solt.new_timeslot::-webkit-scrollbar{
    display: none  !important;
    width: 0;
    background: transparent;
}

.__left{
    width: calc(100% - 560px);
    background: #fff;
   
}
.__left._left_service{
    overflow-y: auto;scroll-behavior: smooth
}
._left_service::-webkit-scrollbar {
    width: 0;
    background: transparent;
}
.banner_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.__right{
    width: 560px;
   
    text-align: center;
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
}
.__right.text_parent{
    background: #fff;
}
.text_parent .beu_logo, .text_parent .__bottom{
    width: 100%;
}
.text_parent h2{
    font-size: 32px;
    font-weight: bold;
}
.text_parent p {
    font-size: 17px;
    color: #29282E;
    margin: 32px 0;
}

.beu_logo img {
    width: 114px;
    height: 38px;
    object-fit: contain;
}
.client_logo .logo{
    width: 100%;
    text-align: center;
}
.barber_shop{
    
}
.client_logo .logo p{
    background: #eee;
    width: 170px;
    height: 104px;
    margin: 0 auto;
}
.text_parent .__bottom .btn{
    min-width: 222px
}
.text_parent .__bottom .btn:hover{
    color:#fff
}
.mobile_logo {
    position: absolute;
    top: 90px;
    width: 170px;
    height: 88px !important;
    left: 50%;
    transform: translate(-50%);
    background: #fff;
    padding: 15px;
    border-radius: 8px;
}
.mobile_logo img {
    width: 100%;
    height: 100% !important;
}
ul.__left_breadcrumb{
    padding-left: 0;
}
.__left_breadcrumb li {
    display: inline;
    font-size: 40px;
    font-weight: bold;
    margin-right: 45px;
    position: relative;
}
.__left_breadcrumb li svg{
    width: 29px;
    height: 29px;
    margin-top: -7px;
    cursor: pointer;
}
.__left_breadcrumb li a{
    color: #BEBEC0;
}
.__left_breadcrumb li a:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #BEBEC0;
    position: absolute;
    top: 50%;
    transform: translate(18px, -50%) rotate(45deg);
}
/* ------------staff page css start--- */

._staff_parent {
    width: 100%;
    overflow-y: auto;
    height: calc( 100dvh - 140px );
    /* background: pink; */
}
._staff_parent_display{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
._staff {
    width: 158px;
    height: 214px;
    background: #FFFFFF ;
    border: 1px solid #BABABA;
    border-radius: 20px;
    text-align: center;
    margin: 20px;
    cursor: pointer;padding: 10px;
}
._staff.active {
    background: #29282E;
    color: #fff;
}
._staff.active p{
    color: #fff;
}
._staff img:hover {
    border: none;
}
._staff p {
    color: #29282E;
    font-size: 12px;
    margin-bottom: 0;
}
._staff h6 {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 6px;
    margin-top: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 46px;
    white-space: break-spaces;
   
}

._staff img {
    width: 116px;
    height: 116px;

}
/* ------service page css start----- */
._service_parent_body{
    overflow-y: auto;
    height: calc( 100dvh - 192px );
    padding-bottom: 100px;
    scroll-behavior: smooth;
    transition: transform .5s ease-in-out 0.1s;
}
._service_parent_body.add_bottom_pading{
    padding-bottom: 800px;
}
.services_tab li {
    display: inline-block;
    /* background: pink; */
    padding: 8px 25px;
    cursor: pointer;
}
ul.services_tab li.active {
    /* width: 160px;
    height: 46px; */
    background: #29282E ;
    border-radius: 25px;
    color: #fff !important;
}
ul.services_tab li.active a{
    color:#fff
}
._service_parent h3 {
    font-size: 22px;
    font-weight: 700;
    margin:27px 0 15px 0;
}
._service_body {
    display: flex;
    flex-wrap: wrap;
}

.____service {
    width: 245px;
    height: 112px;
    background: #FFFFFF ;
    border: 1px solid #BABABA;
    border-radius: 20px;
    padding: 15px;
    margin-right: 14px;
    margin-bottom: 14px;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}
.____service.active {
    background: #29282E;
    color: #fff;
    border-color: #29282E;
}
.____service ._bottom{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}
.____service ._bottom ._price {
    font-size: 19px;
    font-weight: 600;
}
.____service ._bottom ._time {
    font-size: 15px;
}
.____service h6 {
    width: 100%;
    font-weight: 800;
    font-size: 15px;
}
.pos_relative{

    position: relative;
}
._info__icon {
    position: absolute;
    right: 8px;
    top: 3px;
    z-index: 1080;
    cursor: pointer;
}
._tooltip_body{
    background: #FFFFFF ;
    border: none;
    box-shadow: 0px 3px 6px #00000064;
    border-radius: 9px;
    z-index: 9999;
    font-family: "HelveticaNeue";
}
._tooltip_body .arrow {
    display: none;
}
._tooltip_body .popover-body h5 {
    font-size: 16px;
    font-weight: 500;
}
._tooltip_body .popover-body p {
    font-size: 12px;
    line-height: 1.8;
    margin-bottom: 0;
    font-weight: 400;
}
._info__icon svg {
    width: 18px;
    height: 18px;
}
.____service.pos_relative.active ._info__icon svg {
    fill: #ffffff;
}
._right_box {
    width: 460px;
    min-height: 650px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 10px #0000002F;
    border-radius: 15px;padding: 20px;margin: 0 auto;
    position: relative;
}
.add-absoute {
    position: absolute;
    bottom: 84px;
    left: 50%;
    transform: translateX(-50%);
}
.remove-absoute {
    position: inherit;
    bottom: 0;
    left: 0;
    transform: initial;
}
._right_box ._heading {
    font-size: 23px;
    text-align: left;
    margin: 9px 0 20px 0;
    font-weight: 600;
}
._right_box .btn{
    min-width: 250px;
}
._staff_detail{
   display: flex;
   align-items: baseline;
}
._staff_detail img {
    width: 60px;
    height: 60px;
}
._staff_detail p {
    width: calc(100% - 75px - 116px);
    margin-left: 20px ;
    margin-right: 20px ;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}
._staff_detail p ._datee {
    color: #0d0d0dc4;
    font-size: 13px;
    padding: 10px 0 2px 0;
}
._staff_detail h4 {
    width: 96px;
    text-align: right;
    font-size: 18px;
    font-weight: 600;
}
.__service_hr{
    width: 60%;
}
.selected__service td {
    font-size: 13px;
    color: #000;
    border-bottom: none;
    padding: 9px;
}
.selected__service td:first-child{
    padding-left: 0;
}
.selected__service td:last-child{
    padding-right: 0;
}
.selected__service tr.total td {
    font-weight: 600;
}
/* .__service:first-child {
    margin-left: 0;
} */
._bokking_solt.new_timeslot {
    height: calc(100dvh - 141px );
    overflow-x: auto;
}
/* ._bokking_solt.new_timeslot .rwdpDayPickerContainer {
    padding-left: 0;
} */
._bokking_solt.new_timeslot h3{
    margin-left: 32px;
    font-weight: 500;
    font-size: 34px;
}
._bokking_solt.new_timeslot .rwdpDayBoxDesktop .rwdp-DayCircle-container {
    margin-top: 25px;
    margin-bottom: 40px;
}
._bokking_solt.new_timeslot .rwdpDayBoxDesktop{
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    cursor: pointer;
    width: 100%;
}

._bokking_solt.new_timeslot .rwdpNextWeekArrow, ._bokking_solt.new_timeslot .rwdpPrevWeekArrow{
    display: none;
}
._bokking_solt.new_timeslot .rwdpDayBoxDesktop .rwdp-DayCircle-container{
    margin-right: 65px
}
._bokking_solt.new_timeslot .rwdp-DayCircle strong {
    top: 68px !important;
    font-size: 12px;
}
._bokking_solt.new_timeslot .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center{
    width: 64px !important;
    height: 61px !important;
    max-width: 64px !important;
    font-size: 25px;
    font-weight: 600;
    border: 1px solid #29282e96;
    background: transparent;
}
._bokking_solt.new_timeslot .rwdp-DayCircle.rwdp-DayCircle-selected.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center{
    background: #007EA0;
    color: #fff;
    border-color: #007EA0;
}
._not_available {
    text-align: center;margin-top: 100px;
}
._not_available .btn{
    border-radius: 20px;
}
._not_available h6{font-weight: 600;}
._not_available p span svg {
    width: 76px;
    height: 65px;
}
._time_slot {
    margin-top: 1.5rem;
}
.slot_parent {
    padding-left: 0;
    display: inline-flex;
    width: 100%;flex-wrap: wrap;
    margin-bottom: 0;
}
._time_slot h4 {
    margin-left: 18px;
    font-weight: 600;
}

.slot_parent li {
    width: calc(100% / 5 - 36px);
    border: 1px solid #BABABA;
    border-radius: 15px;
    font-size: 14px;
    text-align: center;
    display: block;
    padding: 13px 8px;
    margin: 13px 18px;
    font-weight: 600;
    cursor: pointer;
}
.slot_parent li.selected {
    background: #0084a9;
    color: #fff;
    border-color: #0084a9;
}
.rwdp-DayCircle.rwdp-DayCircle-unavailable.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
    border-color: #BEBEC0 !important;
    color: #BEBEC0 !important;
}
.icon_arrow {
    display: none;
}
.back-arrow-image {
    position: absolute;
    margin-left: 24px;
    background: #fff;
    padding: 4px;
    border-radius: 7px;
    margin-top: 24px;
    width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;cursor: pointer;
  }
  .back-arrow-image svg {
    width: 26px;
    height: 26px;
    
  }
  ._bokking_solt.new_timeslot h3 {
    margin-left: 32px;
    font-weight: 600;
    font-size: 21px;
    box-shadow: 0px -1px 10px #0000002F;
    padding: 8px 18px;
    margin-top: 13px;
    border-radius: 7px;
}
  ._bokking_solt.new_timeslot .icon svg{
    width: 18px;
    height: 18px;
    transform: rotate(-90deg);
    margin-left: 10px;
}
._bokking_solt.new_timeslot h3.__newcalender._calender_open .icon svg {
    transform: rotate(90deg);
}
.__newcalender {
    /* margin-left: 32px;
    font-weight: 600;
    font-size: 21px;
    box-shadow: 0 -1px 10px rgba(0,0,0,.1843137254901961);
    padding: 8px 18px;
    margin-top: 13px;
    border-radius: 7px;
    border: none;
    display: inline; */
}
.parent__calender{
    position: relative;
    display: inline-block
}
.parent__calender .react-datepicker-wrapper{
    background: pink;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    opacity: 0;
    height: 65%;
    transform: translate(-50% , -50%);
    width: 100%;
}
.parent__calender .react-datepicker__input-container , .parent__calender input.hiden_picker{
height: 100%;
width: 100%;
}
@media (max-width: 768px) {
    ._bokking_solt.new_timeslot{
        padding-top: 20px;
    }
    ._bokking_solt.new_timeslot h3{display: inline;}
    ._bokking_solt.new_timeslot .react-datepicker-wrapper,
    .parent__calender{
        margin-bottom: 30px;
    }
    .__left.p-4 {
        padding: 15px !important;
    }
    .add-absoute ,.remove-absoute{
        position: initial;
        transform: initial;
    }
    .____service h6 {
        font-size: 13px;white-space: break-spaces
    }
    .____service ._bottom ._time {
        font-size: 13px;
    }
    .____service ._bottom ._price {
        font-size: 16px;
    }
    ._service_parent h3 {
        font-size: 18px;
    }
    .__left_breadcrumb li {
        font-size: 24px;}
        .__left_breadcrumb li svg {
            width: 20px;
            height: 20px;
            margin-top: -4px;
        }
        .back-arrow-image {
            width: 40px;
            height: 40px
        }
    ul.__left_breadcrumb{padding-left: 0;}
    .services_tab li {
        padding: 4px 12px;
        font-size: 13px;
    }
    .services_tab {
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth
    }
    .services_tab::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    .slot_parent li {
      width: calc(100% / 3 - 12px);
      margin: 6px 6px;
      border-radius: 9px;
      padding: 10px 8px;
    }
    ._time_slot h4 {
        margin-left: 8px;
        font-size: 16px;
    }
    ._time_slot {
        margin: 20px 0;
    }
    ._bokking_solt.new_timeslot .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
        width: 52px !important;
        height: 49px !important;
        max-width: 52px !important;
        font-size: 21px;
    }
    ._bokking_solt.new_timeslot .rwdpDayBoxDesktop .rwdp-DayCircle-container {
        margin-right: 15px;
    }
    ._bokking_solt.new_timeslot h3 {
        margin-left: 8px;
        font-size: 23px;
    }
   
    ._bokking_solt.new_timeslot .rwdpDayPickerContainer {
        padding: 8px 4px 0px !important;
    }
    ._not_available p span svg {
        width: 59px;
        height: 49px;
    }
    ._not_available h6 , ._not_available p{
        font-size: 14px;
    }
    ._not_available .btn {
        border-radius: 5px;
        font-size: 13px;
        font-weight: 600;
        padding: 6px 10px !important;
    }
    ._bokking_solt.new_timeslot .rwdp-DayCircle strong {
        top: 57px !important;
    }
    ._staff img {
        width: 84px;
        height: 84px;
    }
    ._staff {
        width: calc(100% / 3 - 12px);
        height: 200px;
        border-radius: 9px;
        text-align: center;
        margin: 6px;
        padding: 4px;
        /* max-width: 158px; */
    }
    ._staff h6{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 46px;
        /* min-height: 40px; */
    }
    .__right._service {
        width: 100%;
        text-align: center;
        padding: 0px;
    }

    /* ----bottom service changes--- ---- */
    .home_main {
        position: relative
    }
    .__right._service.__mobile {
        /* width: 100%;
        min-height: 70px !important;
        height: 70px !important;
        overflow: hidden; */
        /* background: #fff !important;
        position: absolute;
        bottom: 0px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px -1px 10px #0000002F;
        border-radius: 15px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; */
       
    }
    .__right.summary_show_hide_btn{
        width: 100%;
        min-height: 70px !important;
        height: 70px !important;
        overflow: hidden;
        background: #fff !important;
        position: absolute;
        bottom: 0px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px -1px 10px #0000002F;
        border-radius: 15px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 8px 12px 12px;
    }
    .__right.summary_show_hide_btn ._heading {
        font-size: 23px;
        text-align: left;
        margin: 9px 0 20px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .Smooth_collapse_parent{
        position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0 -1px 10px rgba(0,0,0,.1843137254901961);
    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
    }
    .by_defalt_open{
        height: auto;
        overflow: hidden;
        transition: height 0.25s ease 0s;  
    }
    .without_drag .drag_summary ._right_box {
        width: 100%;
        height: 100%;
        min-height: 100%;
        background: #ffffff00;
        box-shadow: 0px 0px 0px #0000002F;
        border-radius: 0;
    }
    .__right._service.__mobile.show_full_drag_panel{
        width: 100%;
        height: auto !important;
        min-height: auto !important;
        max-height: 100%;
        overflow: auto;
        box-sizing: border-box;

    }
    .Smooth_collapse_parent.Show{
        display: block !important;
        height: 70px !important;
    }



    ._right_box{
        /* position: absolute;
    width: 100%;
    bottom: 0;
    left:0 */
    }
    ._right_box {
        width: 100%;
        min-height: 150px
    }
    ._staff_detail img {
        width: 41px;
        height: 41px;
    }
    ._staff_detail p {
        width: calc(100% - 75px - 10px);
        text-align: left;
        margin-left: 10px ;
        margin-right: 10px ;
        font-size: 13px;
    }

    /* ._bar_togle span {
        width: 100px;
        height: 4px;
        background: #e4e4e4;
        margin: 0 auto;
        display: block;
        border-radius: 5px;
    } */
    ._right_box ._heading {
        display: flex;
        justify-content: space-between;
        align-items: center;margin-bottom: 15px;
    }
    ._staff_detail p ._datee{
        padding-top: 2px;
    }
   
    /* .__right.align-items-start._service.__mobile.react-draggable.react-draggable-dragged {
        transform: translate(0px, 370px);
    } */
    .__right._service.__mobile ._right_box {
        /* height: 54px;
        min-height: 54px;
        overflow: hidden; */
        padding: 12px;
        padding-top: 8px;
     
    }
    .without_drag .drag_summary ._right_box{
        /* width: 100%;
        min-height: 150px !important;
        height: 150px;
        overflow: hidden; */
        /* width: 100%;
    height: 100%; */
    }
    .without_drag.show_full_drag_panel .drag_summary ._right_box {
        height: auto;
        /* max-height: 100%;
        overflow: auto; */
    }
    .cm_modal.booking_bg.popupscroll.new-confirm-booking {
        overflow: hidden;
        white-space: normal;
    }
    .new-confirm-booking  .booking-info.pt-4.ml-3.mr-3 {
        margin: 0 8px !important;
    }
}
/* ---for new service animation start------ */
@media (max-width: 768px) {
    .popupscroll.booking_popup {
        overflow-y: auto !important;
        /* height: calc(95vh - 2px); */
        height: auto;
    }
    .phone_right_panel_parent {
        position: absolute;
        width: 100%;
        height: auto;
        background: #fff;
        left: 0;
        bottom: 0;
        box-shadow: 0 -1px 10px rgba(0,0,0,.1843137254901961);
        border-radius: 15px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .invisible_button {
        width: 100%;
        height: 100%;
        background: #00ffff00;
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 1;
    }
    .invisible_button.Hide{
        height: calc(100% - 70px);
    }
    .phone_right_panel_parent ._right_box{
    width: 100%;
    height: 100%;
    min-height: 100%;
    background: hsla(0,0%,100%,0);
    box-shadow: 0 0 0 rgba(0,0,0,.1843137254901961);
    border-radius: 0;
    text-align: center;
    }
    .smooth_scroll_body.height_70 {
        width: 100%;
        height: 70px !important;
        display: block !important;
        transition: height 0.8s ease-in-out 0s
    }
    .icon_arrow {
        display: block;
        width: 38px;
        height: 38px;
        position: absolute;
        top: 12px;
        right: 13px;
    }
    .icon_arrow.closed{
        transform: rotate(180deg);   
    }
    .phone_right_panel_parent ._right_box{
        padding: 0px 15px 0px;
    }
    .phone_right_panel_parent ._right_box .btn {
        margin-bottom: 16px!important;
        margin-top: 16px!important;
    }
    /* .container_actual{
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.8s ease-in-out 0s
    }
    .showContainer {
        max-height:400px
      }
      
      .hideContainer {
        max-height:50px; /* Set to a value greater than the maximum expected height */
      /* } */
}
@media (max-width: 991px) {
.__left{width: 100%;background: #fff;}   
.__right.text_parent {
    padding-top: 46px;
}
.____service{
    width: calc(100% / 2 - 7px);
    height: 81px;
    border-radius: 8px;
    padding: 8px
}
.____service:nth-child(even){
    margin-right: 0;
}
.____service:nth-child(odd){
    margin-left: 0;
}
.home_main {
    flex-wrap: wrap;
    overflow: inherit;
    position: relative;
}
.banner_image{
    width: 100%;
}
.banner_image img{
    height: 77vh;
    object-fit: cover;
}
.text_parent {
    width: 100%;
    height: calc( 100dvh - 77vh );
}
.text_parent p {
    margin: 0;
}
/* .__right._service.dragble_disabled ._right_box {
    height: 130px;
    overflow: hidden;
} */
/* .__right._service.dragble_disabled .drag_summary {
    transform: translate(0px, 95px) !important
} */

}
@media (min-width: 991px) and (max-width: 1300px){
    .__left {
        width: calc(100% - 435px);
    }
    .__right {
        width: 435px;
    }

}
@media (max-width: 480px) {
    ._staff {
        width: calc(100% / 2 - 18px);
    /* height: 232px; */
    height: 166px;
    margin: 9px;
    padding: 8px;
    }
    ._staff img {
        width: 90px;
        height: 90px;
    }
    ._staff h6 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        margin-top: 12px;
    }
    .__bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .__bottom p{
        width: 100%;
        text-align: center;
        white-space: break-spaces;
    }
    .text_parent .__bottom .btn {
        margin: 0 auto;
        height: fit-content;
    }
    .__bottom .btn.__black_btn.px-5.mt-5 {
        margin-top: 20px !important;
        margin-bottom: 30px !important;
    }
    .__right.text_parent {
        padding-top: 20px;
    }
    ._service_parent_body.add_bottom_pading{
        padding-bottom: 200px;
        /* background: rgb(156, 202, 252); */
    }
    .banner_image img {
        height: 67vh
    }
    .__right.text_parent {
        height: calc( 100dvh - 67vh );
    }
   


}
@media (max-width: 768px) {
     /* ----------------for dragble component---------------- */
     .home_main {
        /* height: 95vh; */
        overflow: hidden;
        /* position: relative; */
       }
       .__left.p-4{
        padding-bottom: 0 !important;
       }
  
    ul.__left_breadcrumb {
        margin-top: 40px;
        margin-bottom: 30px !important;
    }
    .show_full_drag_panel ._right_box .btn {
        /* margin-bottom: 40px !important; */
        /* ---new onw-- */
        margin-bottom: 16px !important;
        margin-top: 16px !important;
    }
    .__service_hr {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }
    .selected__service td{
        padding: 4px 9px !important;
    }
    /* ----fix the service mobile issue--- */
    .no-topbar {
        min-height: 100dvh !important;
        overflow: hidden !important;white-space: nowrap;
        }
        .home_main {
        height: 100dvh !important;
        overflow: hidden !important;
        
        }
        
        ._service_parent_body {
        overflow-y: auto;
        /* height: calc( 100dvh - 180px ) !important; */
        padding-bottom: 100px;
        }
        /* .__left_breadcrumb.service_breadcrum{
            min-height: 100px;
            margin-top: 0;
            padding-top: 40px;
            margin-bottom: 0 !important;
            padding-bottom: 30px;
        } */
  

}
@media (min-width: 769px) and (max-width: 991px){
    ._staff {
            height: 226px;
    }
}

/* ----animation---- */

.slide-down {
    animation: slide-down 0.3s linear both;
  }
  
  .slide-up {
    animation: slide-up 0.3s linear both;
  }
  .__right._service.__mobile{
    overflow: hidden;

  }
  .__right._service.__mobile.show_full_drag_panel{
    max-height: 100%;
  }
  @keyframes slide-down {
    0% {
      visibility: visible;
      height: 100%;
    }
  
    100% {
      visibility: visible;
      height: 70px;
    }
  
  }
  
  @keyframes slide-up {
    0% {
      visibility: visible;
      height: 500px;
    }
    50%{
        visibility: visible;
        height: 500px;  
    }
    100% {
        visibility: visible;
        height: 500px;
      }
  }
.native-platform .home_main {
    height: 95dvh !important;
}
.native-platform .__right.text_parent {
    height: calc( 95dvh - 67vh );
}
.native-platform ._staff_parent {
    height: calc( 95dvh - 140px );
}
.native-platform ._service_parent_body {
    height: calc( 95dvh - 180px ) !important;
}
.native-platform ._bokking_solt.new_timeslot {
    height: calc(95dvh - 141px );
}


.modal-dialog.hide_gray_content_bg.modal-dialog-centered .modal-content {
    background: transparent;
    box-shadow: none;
    border-color: transparent !important;
}