.categories-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: #f5f5f5;
  border-radius: 8px;
  min-height: 100vh;
}

.category {
  transition: all 0.2s ease;
}


.category[data-dragging="true"] {
  opacity: 0.8;
  box-shadow: 0 5px 15px rgba(0,0,0,0.15);
  cursor: grabbing;
  background: #f8f8f8;
  border: 1px dashed #888;
}
.menu-icon.category-menu-icon:focus-visible, .menu-icon.category-menu-icon:focus {
  border: red;
  outline: red;
}


.category .drag-handle {
  display: flex;
  align-items: center;
  cursor: grab;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

/* .category .drag-handle:hover {
  background-color: rgba(0, 0, 0, 0.05);
} */

.category .drag-handle svg {
  fill: #666;
  transition: fill 0.2s ease;
  transform: rotate(90deg);
}

/* .category .drag-handle:hover svg {
  fill: #333;
} */

/* Rotate icon when category is collapsed */
.category[data-collapsed="true"] .drag-handle svg {
  transform: rotate(0deg);
}

/* .category h3 {
  margin: 0;
  padding: 20px;
  background-color: var(--primary-color);
  color: white;
  font-size: 1.5rem;
} */

/* Items container */
.items {
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: 1;
  max-height: 1000px; /* Adjust based on your needs */
  transition: all 0.3s ease;
  overflow: hidden;
}

.items.collapsed {
  max-height: 0;
  opacity: 0;
  padding: 0 16px;
}

/* Individual item styling */
.item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.drag-handle {
  display: flex;
  align-items: center;
  /* padding: 8px; */
  cursor: grab;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.drag-handle:active {
  cursor: grabbing;
}

.item[data-dragging="true"] {
  opacity: 0.5;
  cursor: grabbing;
}

.item-content {
  flex: 1;
}

/* Optional: Style the SVG on hover */
.drag-handle:hover svg {
  color: #333;
}

.item h4 {
  color: var(--primary-color);
  margin: 0 0 15px 0;
  font-size: 1.2rem;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--secondary-color);
}

.item p {
  margin: 8px 0;
  color: #666;
  font-size: 0.95rem;
  display: flex;
  justify-content: space-between;
}

.item p:last-child {
  margin-bottom: 0;
}

/* Dragging state */
.item[data-dragging="true"] {
  background: var(--secondary-color);
  color: white;
  opacity: 0.9;
  box-shadow: var(--shadow);
  cursor: grabbing;
  opacity: 0.5;
  z-index: 1;
}

.item[data-dragging="true"] h4 {
  color: white;
  border-bottom-color: white;
}

.item[data-dragging="true"] p {
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .items {
    grid-template-columns: 1fr;
  }
/*   
  .app {
    padding: 10px;
  } */
  
}

.collapse-button {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 12px;
}

.items.collapsed {
  display: none;
}

/* Optional: Add transition for smooth collapse/expand */
.items {
  transition: all 0.3s ease;
}

/* Additional visual enhancements */
.category {
  /* ... existing styles ... */
  position: relative;
}

/* Add subtle hover effect */
.category:hover {
  background: #f8f8f8;
}

/* Style the drag handle on hover */
.drag-handle:hover {
  color: #4f46e5;
}

/* Add animation for collapse/expand */
.items {
  transition: all 0.3s ease;
}

.items.collapsed {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

/* Add a subtle animation when items are reordered */
.item {
  transition: transform 0.2s ease;
}

/* Common styles for both items and categories */
.item, .category {
  /* background: white;
  border: 1px solid #ddd;
  border-radius: 4px; */
  /* margin-bottom: 8px; */
  transition: all 0.15s ease;
}

/* Dragging states for both items and categories */
.item[data-dragging="true"],
.category[data-dragging="true"] {
  opacity: 0.5;
  background: #f8f8f8;
  cursor: grabbing;
}

/* Common drag handle styles */
.drag-handle {
  display: flex;
  align-items: center;
  /* padding: 8px 4px; */
  cursor: grab;
  color: #666;
}

.drag-handle:hover {
  color: #333;
}

.drag-handle:active {
  cursor: grabbing;
}

/* Category specific styles */
.category {
  /* padding: 16px; */
}

/* .category-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
} */

/* .category-header h3 {
  margin: 0;
  flex: 1;
} */

/* Items container */
.items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.items.collapsed {
  display: none;
}

/* Item specific styles */
.item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
}

.item-content {
  flex: 1;
}

/* Add tooltip cursor to indicate clickable */
.drag-handle svg {
  cursor: pointer;
}

.collapse-icon {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.collapse-icon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.collapse-svg {
  transition: transform 0.2s ease;
  fill: #666;
}

.collapse-svg:hover {
  fill: #333;
}

.collapse-svg.collapsed {
  transform: rotate(-90deg);
}

/* Update drag handle styles to match */
.drag-handle {
  display: flex;
  align-items: center;
  /* padding: 4px; */
  cursor: grab;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

/* .drag-handle:hover {
  background-color: rgba(0, 0, 0, 0.05);
} */

.drag-handle svg {
  fill: #666;
  transition: fill 0.2s ease;
}

.drag-handle:hover svg {
  fill: #333;
}

.drag-handle:active {
  cursor: grabbing;
}

/* Animation for category movement */
@keyframes categoryDrag {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.category[data-dragging="true"] {
  animation: categoryDrag 0.3s ease;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  /* .app {
    padding: 10px;
  } */
  
  .categories-container {
    padding: 8px;
    gap: 12px;
  }
  
  .category {
    padding: 0;
    margin-bottom: 10px;
  }
  
  /* .category-header {
    padding: 12px;
  } */
  
  /* .category-header h3 {
    font-size: 1.2rem;
  } */
  
  .item {
    padding: 8px;
  }
  
  .item h4 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .item p {
    font-size: 0.9rem;
  }
  
  /* Increase touch targets for mobile */
  .drag-handle {
    padding: 0;
    min-width: 25px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .drag-handle svg {
    width: 16px;
    height: 16px;
  }
  
  /* Prevent text selection while dragging on mobile */
  .category[data-dragging="true"],
  .item[data-dragging="true"] {
    user-select: none;
    -webkit-user-select: none;
  }
  
  /* Add visual feedback for touch devices */
  .category:active,
  .item:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  /* Improve scrolling experience on mobile */
  .categories-container {
    -webkit-overflow-scrolling: touch;
  }
}

/* Add touch-action manipulation to enable proper touch handling */
.drag-handle {
  touch-action: none;
  -webkit-touch-callout: none;
}














/* .list-container {
    position: relative;
    padding: 20px 0;
    transition: height 0.2s ease;
  }
  
  .planet-item {
    position: relative;
    border-radius: 4px;
    padding: 20px 20px 20px 60px;
    margin: 10px 0;
    background: white;
    cursor: default;
    user-select: none;
    transition: all 0.3s ease;
  }
  
  .drag-handle {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    cursor: move;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px 0 0 4px;
    transition: background 0.2s ease;
  }
  
  .drag-handle:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .content {
    position: relative;
  }
  
  h2 {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
  }
  
  .subtitle {
    color: #666;
    font-style: italic;
    font-size: 14px;
    line-height: 1.2;
    margin-top: 4px;
  }
  
  .collapse-content {
    max-height: 300px;
    opacity: 1;
    overflow: hidden;
    transition: all 0.3s ease;
    margin-top: 10px;
  }
  
  .collapse-content.collapsed {
    max-height: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
  }
  
  .description {
    line-height: 1.4;
  }
  
  .extra-content {
    margin-top: 10px;
    color: #666;
    line-height: 1.4;
  }
  
  .counter {
    margin-top: 15px;
  }
  
  .counter input {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .draggable-list {
    position: relative !important;
  }
  
  .draggable-list-item {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    transition: transform 0.3s ease !important;
  }
  
  .planet-item.dragged {
    padding: 8px 20px 8px 60px;
    margin: 4px 0;
    overflow: hidden;
  }
  
  .list-container.dragging .draggable-list-item {
    margin: 2px 0;
  }
  
  .list-container.dragging .planet-item {
    height: 45px !important;
    transition: all 0.3s ease;
  }
  
  .planet-item:not(.dragged) {
    transition: all 0.3s ease;
  } */