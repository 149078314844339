.h_n._new__service {
    /* scroll-behavior: smooth;
    overflow-y: auto; */
}

.new-setting-menu-bar.service_settingbar .setting-menu-bar ul li a .icon {
    width: calc(48px - 1rem);
    /* background: red; */
    color: #29282E;
}
.body_container.cm_bg_white._nowhite {
    background: #F7F7F7;
}
._service_sidebar{
    margin-top: 100px;
}
.search_filter_container , .category-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search_filter_container ._left .client_search .form-control {
    border-radius: 9px;
    min-width: 380px;
}
.search_filter_container .cm_search_wrapper.client_search i{
    position: absolute;
    top: 48%;
    transform: translate(0, -50%);
}
.search_filter_container ._left{
  display: flex;  
}
._dragable_component svg{
fill:#C1C1C1;
width: 25px;
height: 25px;
}
.category-header ._first svg,
.service-items .service-item.react-draggable svg{
    /* cursor: move; */
}
.search_filter_container ._left ._filter{
    border: 1px solid #b0b3b5 !important;
    min-width: 120px;
    border-radius: 10px;
    line-height: 3;
    line-height: 2.3 !important;
    text-align: center;
    cursor: pointer;
    background: #fff;

}
.search_filter_container ._left .cm_search_wrapper{
    margin-right: 30px !important;
}
.search_filter_container ._right svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
}
.applied_filter_container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.applied_filter_container span {
    border-radius: 100px;
    padding: 7px 16px;
    background: #000;
    color: #fff;
    min-width: 150px;
    display: flex;
    margin: 0 12px 12px 0;
    justify-content: space-between;
    font-weight: 200;
    font-size: 13px;
}
.applied_filter_container span ._cross{
 cursor: pointer;
}
.applied_filter_container span ._cross svg{
    width: 18px;
    height: 18px;
}
._new__service .form-control, ._new__service .form-control:focus,
.search_filter_container ._left ._filter{
   height: calc(1.8em + 0.75rem + 2px) !important;
   line-height:1.8;
   
}
._new__service .form-control, ._new__service .form-control:focus{
    padding: 0.375rem 2rem 0.375rem 0.75rem !important;
}
.search_filter_container ._left ._filter {
    height: calc(1.8em + 0.65rem + 0px) !important;
}
.cm_search_wrapper i {
    top: 12px;
}
.category-header ._first{
display: flex;
align-items: center;
width: calc(100% - 30px);
}
.category-header ._first h2 {
    font-size: 24px;
    font-weight: 600;
    margin-left: 12px;
    margin-bottom: 0;
    cursor: pointer;
    text-align: left;
}
._dot svg{
    width: 21px;
    height: 22px;
    color: #888;
}
.transaction_Dropdown.dropdown .btn-primary, 
.transaction_Dropdown.__service_dropdown.dropup button#dropdown-basic {
    background: transparent;
    box-shadow: none;padding-right: 0;
}
._new__service .transaction_Dropdown.__service_dropdown.dropup button.btn-primary {
    padding: 10px 10px;
    font-size: 0px;
}
.transaction_Dropdown.__service_dropdown.dropup .dropdown-toggle::after{
    display: none;
}
.__service_dropdown.transaction_Dropdown.dropdown .btn-primary span svg{
    fill: #9d9d9d;
    background: #ffffff00;
    z-index: 99;
}

.transaction_Dropdown.__service_dropdown.dropdown {
    /* z-index: 9999; */
    /* z-index: 1; */
}
.transaction_Dropdown .dropdown-menu {
    position: absolute !important;
    z-index: 1050 !important;
    /* top: -100% !important; */
  }
._dragable_component {
    /* z-index: 1; */
    position: relative;
} 


.transaction_Dropdown.__service_dropdown .dropdown-menu.show {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000064;
    border-radius: 9px;
    border: none;
    text-align: left;
    min-width: 194px;
    padding: 12px 0;
    z-index: 99;
}
.transaction_Dropdown.__service_dropdown .dropdown-item, 
.transaction_Dropdown.__service_dropdown .dropdown-item:hover{
    padding: 7px 1.5rem;font-weight: 300;
    color:#000
}
.transaction_Dropdown.__service_dropdown .text-danger.dropdown-item{
    color: #FF0000 !important;
    font-weight: 400;
}
.category-header .transaction_Dropdown button{
    padding: 0;
}

.service-item,
.__dot_popup .detail .service-items{
    border: 1px solid #8888883d;
    border-radius: 20px;
    margin: 20px 0;
    padding: 13px 26px;
    background: #ffffff;
    display: flex;
    align-items: center;
}
.__dot_popup .detail .service-items{
    border-radius: 12px
}
/* .service-item:first-child{
    margin-top:0 ;
} */.category .drag-handle:focus {
    border: red;
    outline: red;
}
.service-items {
   padding-top: 5px;
   padding-bottom: 5px;
}
.service-item ._text{
 width: calc(100% - 40px);
 margin-left: 20px;
 margin-right: 20px;
}
.service-header ,
.__dot_popup .detail .service-items ._text .service-header{
    display: flex;
    align-items: center;
}
._not_aasigned{
    color:#007EA0
}
.__doty {
    margin: 0 1px;
}
.__doty svg {
    fill: #29282E;
    width: 10px;
}
.service-item ._text ._cost,
.__dot_popup .detail .service-items ._text ._cost{
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    justify-content: space-between;
}

.service-item ._text ._cost b ,
.__dot_popup .detail .service-items ._text ._cost b{
    font-size: 15px;
    font-weight: 500;
}
.service-item ._text .descption{
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    width: 90%;
    text-align: left;
    min-height: 20px;
}
.__dot_popup .detail .service-items ._text .descption{
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0; 
}
/* .service-header h3{
    font-size: 14px;
    margin-bottom: 0;
} */
.service-header h3 {
    font-size: 15px;
    margin-bottom: 0;

}
/* .__dot_popup .detail .service-items ._text .service-header h3{
    font-weight: 800;
} */
._service__popup .modal-content {
    background: #fff;
    border: none;
    box-shadow: 0px 3px 6px #00000052;
    border-radius: 9px;
    padding: 25px;
}
/* body.modal-open #root {
    filter:none;
    -webkit-filter:none;
  } */
  ._light_bg{
    background: #00000023;
  }
  .filters-popup {
    padding: 25px;
}
.filter-group {
    margin-bottom: 22px;
}
.filter-actions button {
    min-width: 122px;
}
._new__service button.btn.btn-outline-dark:hover, 
.filter-actions button.btn.btn-outline-dark:hover {
    background: transparent;
    color: #000;
}
  .filter-group .css-1nkvc89-control,
  .filter-group .css-yk16xz-control ,
  .filter-group .my-select__control,
  .filter-group .form-controls {
    height: 44px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 9px;
    padding: 0 16px;
}


.filter-group label {
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 500 !important;
}
/* .filter-group .css-1uccc91-singleValue , */
.filter-group .my-select__single-value
.my-select__value-container{
    color: #707070;
}
/* .filter-group .css-tlfecz-indicatorContainer,
.filter-group .css-tlfecz-indicatorContainer:hover , */
.filter-group .my-select__indicator,
.filter-group .my-select__indicator:hover
{
    color: #000000;opacity: 1;

}

.filter-group .css-tlfecz-indicatorContainer svg,
.filter-group .css-6q0nyr-Svg{
    width: 26px;
    height: 26px;
    opacity: 1;
}
.filter-group .check_wraper {
    padding-left: 34px;
    font-size: 14px;
    font-weight: 100 !important;
    display: flex;
    align-items: center;
}
.filter-group ._checkbox {
    margin-top: 27px;
    margin-left: 26px;
}
.filter-group .check_wraper > span {
    width: 20px;
    height: 20px;
    border: 1px solid #061e1e;
    border-radius: 5px;
}
.filter-group .check_wraper p {
    margin-bottom: 0;
}
.filter-group .check_wraper > input {
    width: 20px;
    height: 20px;
}
.filter-group .check_wraper > span::after {
    width: 6px;
    height: 13px;
    left: calc(50% - 3px);
    top: calc(50% - 9px);
}
/* {
    width: 44px;
    height: 32px
} */
._delete_popup h4 {
    font-size: 23px;
}
._delete_popup p {

    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #29282E;
    line-height: 2.4;
}
.filter-actions ._deletebtn{
    background: #D50000;
    border-color: #D50000;
}
.filter-actions.text-right .btn {
    font-weight: 600;
}
.more__option.btn.btn-dark-blue.dropdown-toggle.btn.btn-primary {
    background: #007EA0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000053;
    border-radius: 9px;
    color: #fff !important;
    min-width: 110px;
    line-height: 1.8;
}
.more__option_menu.dropdown-menu.show {
    background: #FFFFFF ;
    box-shadow: 0px 3px 6px #00000064;
    border-radius: 9px;
    border: none;
}
.more__option_menu.dropdown-menu .dropdown-item{
    border-bottom: none;font-weight: 300;cursor: pointer;
}
.__add_service .cm_box_view{
    box-shadow: 0px 3px 6px #00000052;
    border-radius: 9px;background: #fff;
}
.__add_service .cm_box_view._left_right_spac {
    padding:32px 8% 0 8% ;
}
.__add_service h4 {
    font-weight: 400;
    font-size: 1.4rem;
}
.filter-group .css-yk16xz-control:hover,
.filter-group css-1pahdxg-control:hover{
    border-color: #707070ba
}
.filter-group textarea {
    height: 162px;
    resize: vertical;
    border: 1px solid #707070;
    border-radius: 9px  
}
.staff-settings {
    overflow-x: auto;
    padding: 0;
}
table {
    width: 100%;
    border-collapse: collapse;
  }

.staff-settings th, .staff-settings td {
    font-size: 14px;
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #eee;
    /* vertical-align: top; */
    vertical-align: middle;
  }  
  .staff-settings td, .staff-settings td input, .staff-settings td ._fix_left_sign input {
    width: 114px;
}
  .staff-settings th {
    font-weight: 500;
    padding:  15px !important
   
}
.staff-settings input[type="text"], 
.staff-settings input[type="number"] {
    border: 1px solid #707070;
    border-radius: 9px;
    padding: 10px;
}


 
  ._fix_left_sign span{
    position: absolute;
    background: #ffffff;
    top: 1px;
    left: 1px;
    display: flex;
    width: 25px;
    height: calc(100% - 2px);
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    border-right: 1px solid #707070;
    align-items: center;
    justify-content: center;
    color: #000;
    font-weight: 500;
}
._fix_left_sign input[type="text"],
._fix_left_sign input[type="number"]{
    width: calc(100% - 30px);
    padding-left: 30px;
}
  
  ._new__service input[type="text"],
  ._new__service textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 9px;
    font-size: 14px;
  }
  .filter-group .my-select__control{
    font-size: 14px;
    padding: 0 1px; 
  }
  ._new__service input[type="text"]:focus-visible ,
  ._new__service input[type="number"]:focus-visible ,
  ._new__service textarea:focus-visible {
    outline: none;
}
.cm_box_view.staff-settings{
    /* max-height: 300px; */
    max-height: calc(100vh - 230px);
    overflow-x: auto
}
.staff-settings th.fixed-column:first-child {
    z-index: 9;
}
.cm_box_view.staff-settings table {
    min-width: 1036px;
}
.service-category{
    /* margin: 30px 0; */
    margin: 10px 0;
}
._dragable_component {
    padding-bottom: 90px;margin-top: 24px;
}
.new-setting-menu-bar.service_settingbar .icon svg {
    width: 24px;
    height: 24px;
}
body.modal-open .new-setting-wrapper{
    filter:blur(0px);
    -webkit-filter: blur(0px);
  }

   .service-items {
    position: relative; 
  }
  
  .__service_dropdown .dropdown-menu {
    position: absolute;
    top: 100%; 
    left: 0;
    z-index: 1050; 
  }
  ._add_service_tooltip ._profile{
    position: absolute;
    right: 29px;
    width: 50px;
    height: 50px;
    z-index: 1;
    border-radius: 100px;
    border: 1px solid #eee;
    top: 19px;
  }
  ._add_service_tooltip ._profile img {
    width: 100%;
    height: 100%;border-radius: 100px;
    
  }
  .tooltip_parent._add_service_tooltip .tooltip-body {
    box-shadow: 0px 3px 6px #00000064;
    border-radius: 9px;
    width: 236px;
    background: #fff;
    padding-top: 0;
    padding: 4px 34px;
    font-size: 13px;
    top: 74px;
    right: 26px;
}
.my-select__dropdown-indicator svg {
    transform: rotate(0deg);
    /* transition: transform 0.2s ease-in-out; */
  }
  .my-select__control--menu-is-open .my-select__dropdown-indicator svg {
    transform: rotate(180deg);
  }
  span._notassign {
    color: #007EA0;
    font-weight: 600;
    margin-left: 6px;
}
 
@media (min-width: 1200px) and (max-width: 1450px) {
    .search_filter_container ._left .client_search .form-control{
        min-width: 280px;
    }
}
@media (max-width: 1100px) {
    ._service_sidebar {
        margin-top: 0px;
    }
    ._add_service_tooltip ._profile {
        right: 12px;
        top: -68px;
    }
    .tooltip_parent._add_service_tooltip .tooltip-body {
        top: -15px;
        right: 8px;
    }
 /* -----add /edit service page--- */
 .__add_service .cm_box_view._left_right_spac{padding: 0;}
 .salon_switch_container .switch{z-index: 0;}
 .staff-settings th {
    border-bottom: 1px solid #000000;
}
.staff-settings td:first-child {
    border-bottom: #ffffff00;
}
.fade.add_edit_service_content.tab-pane {
    height: calc(100vh - 66px - 105px);
}
nav.add_edit_service.nav.nav-tabs {
    border-bottom: 0;margin-top: 20px;
}
.add_edit_service a.nav-item.nav-link{
    font-size: 14px;
    font-weight: 600;
}
.add_edit_service a.nav-item.nav-link:hover{border: none;}
.add_edit_service a.nav-item.nav-link.active{
    background: #000;
    color: #fff;
    border-radius: 100px;
    min-width: 90px;
    text-align: center;line-height: 1.4;
}
.tab-content {
    margin-left: -15px;
    margin-right: -15px;
}
.add_edit_service_content .cm_box_view .filters-popup,
.add_edit_service_content .cm_box_view.staff-settings{
    padding: 21px;
    box-shadow: 0px 4px 8px #00000052 !important;
}
.cm_box_view._left_right_spac h4{
    display: none;
}
.bottom_fixed_btn {
    position: fixed;
    background: #ffffff;
    width: 100%;
    padding: 20px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 9;
        left: 0;
}
.bottom_fixed_btn .btn.btn-white {
    border: 1px solid #000;
}
.bottom_fixed_btn .btn{
    min-width: 100px;
    min-width: 130px
}
.staff-settings{ 
    overflow-x: auto; position: relative; 
} 
.staff-settings table { 
    min-width: 100%; border-collapse: separate; border-spacing: 0; 
} 

.staff-settings th, 
.staff-settings td {
     white-space: nowrap; padding: 10px; 
    } 
.staff-settings .fixed-column { 
    position: sticky; left: 0;top: 0;background-color: #fff; z-index: 1; 
} 
.staff-settings th.fixed-column {
     z-index: 2; 
    } /* Add box-shadow for better visual separation */ 
.staff-settings .fixed-column::after { 
    content: ''; position: absolute;
top: 0; right: 0; bottom: 0; width: 10px; box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.1); 
}
.transaction_Dropdown.__service_dropdown span svg {
    width: 19px;
    height: 19px;fill: #888;
}
/* .staff-settings td.fixed-column {
    background: pink;
    width: 115px;
    white-space: pre-line;
} */

}
@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 710px;
    }
  

}
@media (max-width: 991px){
    .__add_service .cm_box_view._left_right_spac {
        padding: 32px 8% 0 8% ;
    }
    .search_filter_container {
        margin-top: 20px !important;
    }
    ._dragable_component {
        margin-top: 16px;
    }
    .cm_error_msg, .account-table span .cm_error_msg{display: block;}
    .search_filter_container ._left .client_search .form-control {
        min-width: 380px;
    }
    /* .search_filter_container ._left{
     width: calc(100% - 60px);
    }
    .cm_search_wrapper.client_search {
        min-width: calc(100% - 60px);
    } */
    /* .search_filter_container ._left .client_search .form-control{
        min-width: 100%;
    } */
    .search_filter_container ._left ._filter {
        min-width: 50px;
    }
    .search_filter_container ._left .cm_search_wrapper {
        margin-right: 10px !important;
    }
    .search_filter_container ._left ._filter svg {
        width: 25px;
        height: 25px;
    }
    ._right, .search_filter_container ._left ._filter {
        border: 1px solid #b0b3b5 !important;
        min-width: 50px;
        border-radius: 10px;
        line-height: 2.5 !important;
        text-align: center;
        cursor: pointer;
        background: #fff;
        height: calc(1.5em + 0.75rem + 3px) !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
   ._service__popup .modal-dialog-centered,
   ._service__mobile_popup .modal-dialog-centered{
        align-items: flex-end;
        /* min-height: calc(100% - 0rem); */
        background: rgba(7, 208, 253, 0);
        /* --new-- */
        max-height: calc(100% - 0rem);
        margin: 3.4rem auto;
        margin-bottom: 0;
    }
    ._service__popup .modal-content ,
    ._service__mobile_popup .modal-content{
        padding: 8px;
        border-top-left-radius: 9px !important;
        border-top-right-radius: 9px !important;
        box-shadow: 0px 9px 20px #0000007a !important;
        padding-top: 5px !important;
    }
    ._service__popup._service_delete_popup .modal-dialog-centered{
        align-items: center; 
    }
    ._service__popup._service_delete_popup .modal-content{
        padding: 8px;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        box-shadow: 0px 0px 0px #000000 !important;
        padding-top: 5px !important;
        margin: 16px;
        border-radius: 12px !important;
    }
    ._delete_popup h4 {
        font-size: 19px;
        font-weight: 500;
    }
    ._delete_popup p {
        margin-top: 14px;
        font-size: 15px;
        line-height: 1.4;
    }
    ._service__popup._service_delete_popup .filter-actions.text-right {
        text-align: center !important;
        margin-top: 24px;
    }
    /* ---dot popup design start--------- */
    ._service__mobile_popup .modal-content {
        height: calc(100vh - 200px);
        background: rgba(181, 220, 7, 0);
        box-shadow: 0px 9px 20px #00000000 !important;
        transform: translateY(0) !important;
        border: none;
    } 
    ._service__mobile_popup .modal-content .modal-body{
        height: 100%;
        background: rgba(255, 255, 255, 0);
        overflow: hidden;
        overflow-y: auto;
    }
    .__dot_popup.modal-body{padding: 0rem;}
    
    .__dot_popup ._option{
        border-radius: 12px;
        background: #fff;
        position: absolute;
        width: 100%;
        /* box-shadow: 0 17px 41px #00000036;
        bottom: 12px; */

        box-shadow: 0 9px 8px #00000036;
        bottom: 45px;
    }
    .__dot_popup ._option p {
        text-align: center;
        padding: 18px;
        margin-bottom: 0;
        border-bottom: 1px solid #e6e6e6;
        font-size: 17px;
    }
    .__dot_popup ._option p.red:last-child{
        border-bottom :0;}
    .__dot_popup ._option p.red{
        color:#FF0000;
        font-weight: 600;
    }


     /* ---dot popup design start--------- */
    .filters-popup {
        padding: 12px;
    }
    .filter-actions.text-right.mt-5 {
        text-align: center !important;
        display: flex;
        justify-content: space-between;
    }
    .category-header ._first h2 {
        font-size: 21px;
        text-align: left;
    }
    ._light_bg {
        background: #0000001d;
    }
  body.modal-open.filter_modal_body #root{
    filter:blur(0);
    -webkit-filter: blur(0px);
  }

  .bottom-modal .modal-content {
    border-radius: 0; 
    transition: transform 0.8s ease; 
  }
  
  .bottom-modal.slide-in .modal-content {
    transform: translateY(0); 
    /* transform: translateY(-26px);  */
  }
  
  .bottom-modal.slide-out .modal-content {
    transform: translateY(100%); 
  }
  .applied_filter_container span{
    font-weight: 400;
  }
  ._fix_mobile_add_btn.d-flex.topbar_btn_group{
    position: absolute;
    right: 8px !important;
    top: 14px !important;
    display: block !important;
  }
  ._fix_mobile_add_btn .more__option.btn.btn-dark-blue.dropdown-toggle.btn.btn-primary {
    min-width: 78px;
    padding-left: 0;
    padding-right: 0;
  }
  .dropdown-menu{
    padding:0;
  }









}
@media (max-width: 991px){

}

@media (max-width: 600px){
    /* .__add_service .cm_box_view._left_right_spac {
        padding: 32px 4% 0 4% ;
    } */
    .search_filter_container ._left{
        width: calc(100% - 60px);
       }
       .search_filter_container .cm_search_wrapper.client_search {
           min-width: calc(100% - 60px);
       }
       .search_filter_container ._left .client_search .form-control{
        min-width: 100%;
       }
       .service-item, .__dot_popup .detail .service-items{
        border-radius: 12px;
        margin: 20px 0;
        padding: 9px 10px;
        }
        .service-item ._text{
            width: calc(100% - 30px);
            margin-left: 15px;
            margin-right: 0px;
        }

}


input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  








  .modal._no_animation,
.modal._no_animation .modal-dialog {
    transition: none !important;
}

.modal._no_animation.fade .modal-backdrop,
.modal._no_animation.fade .modal-backdrop.show {
    transition: none !important;
    opacity: 0.5;
}

/* Optional: Remove slide animation if you're using it */
.modal._no_animation .bottom-modal {
    animation: none !important;
}