.bookingClanderView{
  /* margin-top: 7rem; */
  margin-top: 4rem;
  overflow: auto;
}
.new--diary--clndr .new--diary-clnder-body {
  /* margin-top: 5rem; */
  /* margin-top: 7rem;
  overflow-y: auto;
  height: calc(100vh - 216px);
  margin-left: 63px; */
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 65px);
  /* overflow-x: auto;
  overflow-y: hidden; */
}
.new--diary-clnder-body{
  display: flex;
  justify-content: flex-start;
}
.fc .fc-timegrid-slot{
  background: #fff;
}

.new--diary--clndr > .calendar_wrapper{
  /* width: calc(100vw / 4); */
}
.new--diary--clndr{
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 216px);
  /*height: calc(100vh);    !* 80% zoom remove 216px *!*/
  margin-top: 7rem;
}
.new--diary--clndr > .staff_user{
  position: sticky;
  position: -webkit-sticky;
}
.new--diary--clndr > .new--dairy-clnader-body{
  display: flex;
}
.time-clnder--0 .demo-app-main .staff_user{
  height: 69.72px;
    opacity: 0;
}
.time-clnder--0 .demo-app-main .fc-timegrid-slots>table>tbody>tr>td{
  display: block;
  border: 0px none !important;
}
.time-clnder--0 .demo-app-main .fc-timegrid-slots>table>tbody>tr>td:nth-child(2){
  display: none;
}
.time-clnder--0 .demo-app-main .fc-timeGridDay-view > table, .time-clnder--0 .demo-app-main .fc-timeGridDay-view > table tr td{
  border: 0px none !important;
}
.time-clnder--0 .demo-app-main .fc .fc-timegrid-col-bg .fc-bg-event{
  background: transparent !important;
}
.time-clnder--0 .demo-app-main .fc-direction-ltr .fc-timegrid-slot-label-frame{
  margin-top:0px ;
}
.calendar_wrapper.time-clnder--0{
  /* margin-top: 6rem; */
  position: sticky;
  left: 0;
  z-index: 9;
}
.new--diary-clnder-body .calendar_wrapper.time-clnder--0::before{
  display: none;
}
.new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper.time-clnder--0{
  min-width: initial;
  max-width: initial;
}
.calendar_wrapper.time-clnder--0 .demo-app-main{
  width: 65px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
  display: none;
}

.new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper,  .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper {
  min-width: calc(100vw / 4 - 78px);
  max-width: calc(100vw / 4 - 78px);
}
.new--diary--clndr > .new-dairy-temp-4 > .calendar_wrapper, .new--diary--clndr > .new--dairy-clnader-body > .new-dairy-temp-4 > .calendar_wrapper{
  min-width: initial ;
  max-width: none ;
  width: calc(100% - 30px);
}

.new--diary--clndr > .new--diary-clnder-body> .calendar_wrapper  .demo-app-main .fc-media-screen{
  border-right: 1px solid #707070;
}
.new--diary-clnder-body{
  position: relative;
}
.new--diary--clndr > .new--diary-clnder-body > .calendar_wrapper .fc-v-event, .new--diary--clndr > .calendar_wrapper .fc-v-event:focus, .new--diary--clndr > .new--diary-clnder-body > .new--dairy-clnader-body > .calendar_wrapper .fc-v-event, .new--diary--clndr > .new--dairy-clnader-body .calendar_wrapper .fc-v-event:focus{
  background: transparent !important;
  border: 0px none !important;
  box-shadow: initial;
}
.new--diary-clnder-body .fc-event.fc-v-event{
  background: transparent !important;
  border: 0px none !important;
  box-shadow: initial;
}
.booking_card.bkng-crd-dary-new .cm_sdule_time{
  margin-bottom: 0.45rem;
  /* position: static; */
  bottom: 0px;
  text-align: right;
  font-size: 10px;
}
.fc-timegrid-event .fc-event-main .bkng-crd-dary-new{
  display: flex;
}
.fc-timegrid-event .fc-event-main .bkng-crd-dary-new{
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  justify-content: space-between;
  box-shadow: 0px 1px 4px #888;
}
.new--diary--clndr .top_fix{
  top: 0px;
  padding-left: 35px;
}
.new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper .fc .fc-timegrid-col.fc-day-today{
  background: rgba(255, 255, 255, 0.1);
}
.booking_card.bkng-crd-dary-new h5{
  font-size: 14px;
}

/* .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink{
display: none;
} */
.booking_card.bkng-crd-dary-new{
  left: inherit;
  right: inherit;
  position: static;
  /* height: auto; */
}
.fc-scrollgrid-sync-table tr>td:nth-child(1){
  display: none;
}
.new--diary-clnder-body .fc-event .fc-event-main{
  display: flex;
  justify-content: flex-end;
  color: #333;
}

.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid table > tbody> tr> td:nth-child(1){
  display: none;
  border: 0px none !important;
}
.bookingClanderView>.new--diary--clndr>.new--diary-clnder-body .fc-theme-standard .fc-scrollgrid{
  border: 0px none !important;
}
#root > div > div:nth-child(1) > div > div.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard > div.fc-view-harness.fc-view-harness-active > div > table > tbody > tr>td:nth-child(1){
  display: block;
}

.bookingClanderView>.new--diary--clndr>.new--diary-clnder-body>.calendar_wrapper:nth-child(1) .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid table > tbody> tr> td:nth-child(1){
  display: table-cell;
  }

  .userProfile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #d9d9d8;
    overflow: hidden;
    background: #fff;
}


.userProfile img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}


.staff_user {
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 0.45rem 0.9rem;
  align-items: center;
  box-shadow: 0px 2px 10px #5554;
  margin: 0.5rem 0.5rem 1rem;
  border-radius: 13px;
  text-align: left;
  background:#bcebea;
  top: 0px;
  font-size: 16px;
  position: sticky;
  position: -webkit-sticky;
  z-index: 999;
  /*cursor: pointer;*/
}
.staff_user_pointer {
  cursor: pointer;
}
.booking_card.pointer-event.black-event{
  color: #fff;
}
.staff_user > div > p{
  margin-bottom: 0.5rem;
}

.fc .fc-timegrid-now-indicator-arrow {
  border-color: #282c34;
  border-color: #282c34;
}

.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  left: 0;
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.fc .fc-timegrid-now-indicator-arrow{
  margin-top: -7.5px;
}
.fc .fc-timegrid-now-indicator-line {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  border-style: solid;
  border-color: #282c34;
  border-color: #282c34;
  border-width: 2px 0 0;
}

.bookingClanderView{
  padding: 22px .25rem 22px calc(250px + 1.5rem);
}
.nav_menu_open .bookingClanderView{
  padding: 22px 1.5rem 22px calc(60px + 1.5rem);
}

.staff_user h3 {
font-size: 0.8rem;
font-weight: 600;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.staff_user > div:not(.userProfile){
  width: calc(100% - 40px);
}

.top_header {
padding: .8rem 2.2rem;
width: calc(100% - 250px);
position: fixed;
right: 0;
top: 0;
z-index: 99999;
background: #f0f0f0 !important;
text-align: center;
/* min-height: 70px; */
min-height: 73px;
box-shadow: 0px 0px 6px #0000005c !important;
}
body.diary-temp .top_header {
  z-index: 999;
}
.fc-scrollgrid.fc-scrollgrid-liquid>thead .fc-scroller{
display: none;
}

.fc-timegrid-now-indicator-arrow{
  top: 1995.9px;
  width: 60px;
  height: 15px;
  border-color: rgb(40 44 52);
  background-color: #282c34;
  font-size: 12px;
  color: rgb(255, 255, 255);
  border: 0px;
  padding-left: 12px;
  border-width: 0px 0 0px 0px;
}

.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  left: 0;
  border-width: 0px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}


table.fc-col-header >thead>tr>th:nth-child(2){
  display: none;
} 

.fc-timegrid-slots>table>tbody>tr>td:nth-child(1){
  display: none;
}

.new--diary-clnder-body>.calendar_wrapper:nth-child(1) .fc-timegrid-slots>table>tbody>tr>td:nth-child(1) {
  display: block;
  border: 0px !important;
  /* margin-top: 1px; */
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: right;
  margin-top: -14px;
  z-index: 9999;
  position: relative;
    /* top: -14px; */
    top: 3px;
}
.time-clnder--0 .demo-app-main .fc-direction-ltr .fc-timegrid-slot-label-frame{
  margin-top: 4px;
  background: #fff;
}
.fc-timegrid-slots tbody tr:first-child .fc-timegrid-slot-label-frame {
  top: -5px !important;
}
.new--diary-clnder-body > .calendar_wrapper:first-child .fc-timegrid-body table tbody tr:first-child .fc-timegrid-slot-label-frame{
  margin-top: 0px;
}
.staff_user.not--Working{
  background: #061e1e;
  color: #fff;
}
.new--diary-clnder-body .calendar_wrapper::before{
  content: '';
  /* top: 0px; */
  background: #fff;
  /* width: 100%; */
  width: calc( 100% - 248px );
  position: fixed;
  left: 248px;
  z-index: 9;
  height: 88px;
}
.nav_menu_open .new--diary-clnder-body .calendar_wrapper::before {
  width: calc(100% - 60px);
  left: 60px;
}

.month-date-hiden_picker {
  display: none;
}

/* For Mobile View */
@media screen and (max-width: 1200px){
  .staff_user.not--Working p, .staff_user p {
    font-size: 10px;
  }
  .time-clnder--0 .demo-app-main .staff_user{
    height: 60.72px;
  }
}

@media screen and (max-width: 991px){
  .top_header{
    width: 100%;
  }
  .new--diary-clnder-body .calendar_wrapper::before{
    width: calc( 100% - 0px );
    left: 0px;
  }
}

@media screen and (max-width: 767px){
 .calendar_wrapper.clnder--0:first-child .staff_user{
    padding-bottom:calc(0.5rem + 40px) !important;
  }
  .top_header {
    z-index: 99999;
  }
  .bookingClanderView{
    padding: 22px 0px 0px;
    margin-top: 1.5rem;
  }
  .fc-timegrid-now-indicator-arrow{
    padding-left: 2px;
    height: 17px;
  }
  .calendar_wrapper.time-clnder--0{
    /* margin-top: 7rem; */
  }
  .time-clnder--0 .demo-app-main .staff_user{
    height: 86.14px;
  }
  .time-clnder--0 .demo-app-main .fc-timegrid-slots>table>tbody>tr>td{
    padding: 0px !important;
  }
  .new--diary--clndr{
    /* margin-top: 3.75rem; */
  }
  .bookingClanderView .new--diary--clndr .top_fix .top_fix_box{
    width: calc(100vw / 3 - 26px) !important;
  }
  .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper, .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper{
    min-width: calc(100vw / 3 - 15px);
  }
  .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper:first-child{
    /* min-width: calc(100vw / 3 - -20px); */
    min-width: calc(100vw / 3 + 30px)
  }
  .bookingClanderView .new--diary--clndr .top_fix .top_fix_box:first-child{
    width: calc(100vw / 3 - 10px + 30px) !important;
  }
  .new--diary--clndr > .new-dairy-temp-4 > .calendar_wrapper, .new--diary--clndr > .new--dairy-clnader-body > .new-dairy-temp-4 > .calendar_wrapper{
    width: calc(100% - 15px);
  }

  .booking_card.bkng-crd-dary-new img{
    display: none;
  }
  .bookingClanderView .new--diary--clndr .top_fix_box{
    padding: 0px 2px 9px !important;
  }
  .booking_card.bkng-crd-dary-new h5{
    padding: 0;
  }
  
  .booking_card.bkng-crd-dary-new .cm_sdule_time{
    position: static;
    margin-top: auto;
  }
  .calendar_wrapper .booking_card.bkng-crd-dary-new{
    left: initial !important;
    right: initial !important;
    position: static !important;
  }
  .fc-direction-ltr .fc-timegrid-slot-label-frame{
    text-align: left;
  }
  .bookingClanderView>.new--diary--clndr>.new--diary-clnder-body>.calendar_wrapper:nth-child(1) .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid table > tbody> tr> td:nth-child(1){
    padding: 0px !important;
    width: 35px;
    padding-right: 2px !important;
  }
  .bookingClanderView>.new--diary--clndr>.new--diary-clnder-body>.calendar_wrapper:nth-child(1) .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid table > tbody> tr> td:nth-child(2){
    /* width: 122px; */
  }

  .new--diary--clndr > .new--diary-clnder-body> .calendar_wrapper .demo-app-main .fc-media-screen {
    border-right: 1px solid #707070;
    position: relative;
}

  .bookingClanderView>.new--diary--clndr td{
    padding: 0px 5px !important;
  }
  .bookingClanderView>.new--diary--clndr>.new--diary-clnder-body>.calendar_wrapper td,  .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper td{
    padding: 0px 0px 0px 2px!important;
    padding-top: 0px !important;
  }
  .new--diary--clndr .top_fix{
    padding-left: 14px !important;
    display: table;
  }
  .new--diary-clnder-body .fc-event .fc-event-main{
    justify-content: center;
  }
  .new--diary-clnder-body .staff_user{
    background: transparent;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    box-shadow: initial;
    text-align: center;
    padding: 0px;
  }
  .staff_user.not--Working{
    color: #212529;
  }
  .staff_user h3{
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    max-width: 94px;
    overflow: hidden;
    margin-top: 7px;
    margin-bottom: 1px;
  }
  .staff_user > div:not(.userProfile){
    width: 100%;
  }
  .staff_user > div > p{
    font-size: 11px;
    margin-bottom: 0px;
  }
  .booking_card{
    min-height: 16px !important;
  }
  .booking_card.bkng-crd-dary-new h5 ,
  .booking_card.bkng-crd-dary-new .cm_sdule_time{
    font-size: 9px !important;
}
.booking_card.bkng-crd-dary-new .cm_sdule_time{
  font-size: 8px;
}
  .booking_card.cm_wrap_text h5{
    width: calc(100% - 57px) !important;
  }
}

@media (min-width:768px) and (max-width:991px){
  .bookingClanderView , .nav_menu_open .bookingClanderView{
  padding: 0;
  }
  .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper, .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper{
    min-width: calc(100vw / 3 - 28px);
    max-width: calc(100vw / 3 - 28px);
  }
  .new--diary--clndr > .new-dairy-temp-4 > .calendar_wrapper, .new--diary--clndr > .new--dairy-clnader-body > .new-dairy-temp-4 > .calendar_wrapper{
    min-width: initial !important;
    max-width: none !important;
  }
}

/* .fc-timegrid-now-indicator-arrow {
  display: none;
}
.calendar_wrapper.time-clnder--0 .fc-timegrid-now-indicator-line {
  display: none;
} */
.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  display: block;
}
.fc-timegrid-now-indicator-line .new_indicator{
  width: 60px;
  height: 16px;
  border-color: rgb(40 44 52);
  background-color: #282c34;
  font-size: 12px;
  color: rgb(255, 255, 255);
  border: 0px;
  line-height: 1;
  text-align: center;
}

.calendar_wrapper.time-clnder--0  .fc-timegrid-cols colgroup col{
  width: 8px !important;
}

.calendar_wrapper.time-clnder--0 .fc-timegrid-now-indicator-container {
  top: -10px;
}
body.zoom-110 .calendar_wrapper.time-clnder--0 .fc-timegrid-now-indicator-container {
  top: -14px;
}
body.zoom-125 .calendar_wrapper.time-clnder--0 .fc-timegrid-now-indicator-container {
  top: -19px;
}
body.zoom-150 .calendar_wrapper.time-clnder--0 .fc-timegrid-now-indicator-container {
  top: -25.4px;
}
body.zoom-175 .calendar_wrapper.time-clnder--0 .fc-timegrid-now-indicator-container {
  top: -29px;
}
body.zoom-200 .calendar_wrapper.time-clnder--0 .fc-timegrid-now-indicator-container {
  top: -10px;
}
body.zoom-200 .fc-timegrid-now-indicator-line .new_indicator{
    width: auto;
}
.fc-theme-standard .fc-scrollgrid{
  border-left: 0;
}
.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  padding-top: 2px;
}
.calendar_wrapper.clnder--0 .fc-timegrid.fc-timeGridDay-view.fc-view table.fc-scrollgrid tbody tr td:nth-child(2) .fc-timegrid-now-indicator-container .new_indicator{
  display: none
}

.calendar_wrapper.clnder--0:first-child .staff_user {
  opacity: 1;
  background: white;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    box-shadow: none;
    padding-bottom: 0.5rem;
}
.calendar_wrapper.clnder--0:first-child::before{
  background: #fff0;
}
.calendar_wrapper.clnder--0:first-child .staff_user div h3,
.calendar_wrapper.clnder--0:first-child .staff_user div p{
  opacity:0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden
}
.calendar_wrapper.clnder--0:first-child .staff_user div h3{
  /* height: 20px; */
  background-color: yellow;
}
.calendar_wrapper.clnder--0:first-child .staff_user div p{
  /* height: 12px; */
    background: aqua;
    /* width: 100%; */
    overflow: hidden;
}
/* .calendar_wrapper.clnder--0:first-child .staff_user div{width: 100%;} */
.calendar_wrapper.clnder--0:first-child .demo-app-main{background: #fff;}

.calendar_wrapper.clnder--0:first-child ,#clnder-abcd {
  min-width: 50px !important;
  max-width: 50px !important;
  /* background: pink; */
  /* position: fixed; */
  width: 100%;
  height: 100%;
  height: calc(100vh - 216px);
    /* margin-top: -1.5rem; */
    margin-top: 0rem;

    position: sticky;
    left: 0;
    z-index: 999;
    align-self: flex-start;
}
.calendar_wrapper.clnder--0:first-child .fc-timegrid-slots table colgroup col ,
.calendar_wrapper.clnder--0:first-child .fc-media-screen .fc-timegrid-cols ,
.calendar_wrapper.clnder--0:first-child .fc-media-screen .fc-timegrid-cols table
.calendar_wrapper.clnder--0:first-child .fc-media-screen .fc-timegrid-cols table colgroup col{
  width: 50px;
}

.calendar_wrapper.clnder--0:first-child .fc-event-main{
display: none;
}

.calendar_wrapper.clnder--0:first-child .fc-scrollgrid{

  /* background: rgb(192, 255, 3); */
}
/* .calendar_wrapper.clnder--0:first-child .fc-timegrid-slots table tr td:first-child{background: cyan;}
.calendar_wrapper.clnder--0:first-child .fc-timegrid-slots table tr td:last-child{background: pink;}
.calendar_wrapper.clnder--0:first-child .fc-timegrid-cols table tr td:last-child{background: rgb(198, 23, 52);} */
/* .fc-scrollgrid .fc-timegrid-body .fc-timegrid-cols table tbody tr td */
.new--diary--clndr .new--diary-clnder-body{
  width: calc(100% - 6px);
}
@media (min-width:1450px) {
  .new--diary--clndr.more_column_fix_width >.new--diary-clnder-body> .calendar_wrapper,  
  .new--diary--clndr.more_column_fix_width  > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper {
    /* min-width: 350px ;
    max-width: 350px ; */
    min-width: 280px ;
    max-width: 280px ;
  }
  .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper.clnderbox_5, 
  .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper.clnderbox_5{
    min-width: calc(100vw / 5 - 68px);
    max-width: calc(100vw / 5 - 68px);
  }
  .nav_menu_open .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper.clnderbox_5,
  .nav_menu_open  .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper.clnderbox_5 {
    min-width: calc(100vw / 5 - 36px);
    max-width:calc(100vw / 5 - 36px);
  }    
  .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper.clnderbox_6, 
  .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper.clnderbox_6{
    min-width: calc(100vw / 6 - 58px);
    max-width: calc(100vw / 6 - 58px);
  }
  .nav_menu_open .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper.clnderbox_6, 
  .nav_menu_open .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper.clnderbox_6{
    min-width: calc(100vw / 6 - 28px);
    max-width: calc(100vw / 6 - 28px);
  }
}
/* when screen size greater 1500px and staffsize is lessthan 6 then devide by  */
/* @media (min-width:1800px) {
  .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper.clnderbox_6, 
  .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper.clnderbox_6{
    min-width: calc(100vw / 6 - 58px);
    max-width: calc(100vw / 6 - 58px);
  }
  .nav_menu_open .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper.clnderbox_6, 
  .nav_menu_open .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper.clnderbox_6{
    min-width: calc(100vw / 6 - 28px);
    max-width: calc(100vw / 6 - 28px);
  }

} */
@media (min-width:2100px) {
  .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper.clnderbox_7, 
  .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper.clnderbox_7{
    min-width: calc(100vw / 7 - 48px);
    max-width: calc(100vw / 7 - 48px);
  }
  .nav_menu_open .new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper.clnderbox_7, 
  .nav_menu_open .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper.clnderbox_7{
    min-width: calc(100vw / 7 - 24px);
    max-width: calc(100vw / 7 - 24px);
  }
}

@media (min-width: 768px){
  .booking_card{
    min-height: 19px;
  }
  .booking_card.bkng-crd-dary-new h5 ,
  .booking_card.bkng-crd-dary-new .cm_sdule_time{
    font-size: 10px !important;
}
.booking_card.bkng-crd-dary-new .cm_sdule_time{
  font-size: 8px;
}
  .booking_card.cm_wrap_text h5{
    width: calc(100% - 31px) !important;
  }
}