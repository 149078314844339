
/* ---------------------------------------------------------------new web page start----------------------------- */
body {
  margin: 0;
  font-family: 'Helvetica Neue',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 /* background: linear-gradient(#c8edee, #daceec);
  background: -moz-linear-gradient(#c8edee, #daceec); 
  background: -webkit-linear-gradient(#c8edee, #daceec); 
  background: linear-gradient(#c8edee, #daceec);  */
  min-height: 100vh;
  background: #f7f7f7;
  /* background: pink; */

}

.cm-navbar-new {
  padding: .1rem .5rem !important;
  background: #0000004f !important;
  /* position: fixed; */
  width: 100%;
  /* z-index: 99; */
  backdrop-filter: blur(8px);
  /* opacity: 0; */
} 
.login-home-temp .cm-navbar-new{
position: fixed;
z-index: 99;
}
body:not(.login-home-temp) .cm-navbar-new{
display: none;
}
.react-datepicker-wrapper input:focus{
outline: none;
}
.start-end-datepicker .sm_date_picker:first-child input {

text-align: right;
}
.cm-navbar-new .img-logo {
  width: 76%;
  height: auto;
}

.bodyContainer{
  overflow-x: hidden;
  /* padding-top: 76px; */
}
.cm_bg_home{
  padding-top: 76px;
}
.cm_bg{
margin-top:96px !important;
}
.banner-container{
  position: relative;
}
.hide-desktop{
  display: none  !important;
}
.hide-mobile{
  display: block;
}
.hide-mobile-banner{
  display: block;
}
.hide-desktop-banner{
  display: none  !important;
}
.banner-container .text{
  position: fixed;
  top: 33%;
  color: #fff;
  left: 94px
}
.banner-container .text h3{
  font-weight: 400;
  font-size: 72px;
}
.banner-container .text h3 b{
  font-weight: 600;
}
.banner-container .text .btn{
  width: 256px;
  padding: 14px 9px;
  font-weight: 600;
}
.banner-container .text p{
  line-height: 1.3;
  font-size: 24px;
}
.banner-container .text h3:nth-child(2) {
  position: relative;
}
/* .banner-container .text h3:nth-child(2):after {
  width: 5px;
  height: 100%;
  content: '';
  position: absolute;
  top: 9px;
  background: #00b7ea;
  right: -8px;
} */

button.slick-arrow.slick-next , button.slick-arrow.slick-prev {
  display: none !important;
}
.pad-0{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.btn-white {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}
.btn-blue , .btn-blue:hover{
  background-color: #007EA0;
  border-color: #007EA0;
  color: #fff;
}
.management_platform p {
  font-size: 37px;
  margin: 90px 0;
  text-align: center;
  font-weight: 400;
}
.management_platform {
  margin: 100px auto;
}
.happy_business_parent {
  background: #29282E;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.happy_business {
  display: flex;
  padding: 200px 0 100px;
  align-items: center;
}
.happy_business .text {
  width: 51%;
  margin-right: 12%;
}
.happy_business .img {
  width: 44%;
  z-index: 1;
}
.dot {
  position: absolute;
  top: -24%;
  right: -36%;
}
.happy_business .text h4 {
  font-size: 40px;
  margin-bottom: 50px;
  font-weight: 200;
}

.happy_business .text p {
  font-size: 20px;
  font-weight: 200;
}
.stats {
  background: #fff;
  border-radius: 10px;
  color: #000;
  padding: 55px;
  padding-bottom: 95px;
}
.stats p span svg {
  width: 54px;
  height: 54px;
}
.stats p {
  font-size: 18px;
    margin-bottom: 1px;
    font-weight: 400;
}
.stats h4 {
  font-size: 32px;
  font-weight: 600;
}

.traingleshap{
  content: '';
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  height: 290px;
  background: linear-gradient(to bottom right, #fff0 0%, #ffffff05 49.7%, #29282e 50%, #29282e 100%);
  transform: rotate(
180deg);
}
.heading:after {
  position: absolute;
  width: 260px;
  height: 11px;
  background: #007ea0;
  bottom: -8%;
  left: 50%;
  transform: translate(-50% , 100%);
  content: '';
}
.heading {
  font-size: 44px;
  font-weight: 600;
  position: relative;
}
.feature .text h4 {
  font-size: 40px;
  position: relative;
}
.feature .text h4:before {
  position: absolute;
  content: '';
  left: -24px;
  top: 0;
  background: #007ea0;
  width: 5px;
    height: 51px
}
.dot-left {
  position: absolute;
  top: -302px;
  left: -37%;
}
.happy_business.feature .img {
  width: 55%;
  z-index: 1;
  margin-right: 7%;
}

.happy_business.feature .text {
  width: 50%;
  margin-right: 0px;
}
.happy_business.feature .text p {
  font-size: 17px;
  line-height: 2;
  margin-bottom: 33px;
}
.happy_business.feature .feature-list p {
  width: 50%;
  font-size: 14px !important;
  line-height: 1.5;
  font-weight: 500;
}
.feature-list {
  display: flex;
  flex-wrap: wrap;
}
.happy_business.feature .feature-list p {
  width: 46%;
  font-size: 14px !important;
  line-height: 1.4;
  font-weight: 400;
  position: relative;
}

.happy_business.feature .feature-list p:before {
  position: absolute;
  content: '';
  left: -24px;
  top: 50%;
  background: #007ea0;
  width: 15px;
  height: 5px;
  transform: translate(-50% , -50%);
}


.happy_business.feature .feature-list p:nth-child(odd) {
  margin-right: 4%;
}
.happy_business.feature .feature-list p:nth-child(even) {
  margin-left: 4%;
}
.traingleshap.top{
  transform: rotate( 0deg);
}
.dot.emp_dot {
  position: absolute;
  top: -12%;
  right: -60%;
}
.happy_business.feature .img.bue-market div:nth-child(2) img {
  width: 50%;
}
.plan_parent p{
  font-size: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 30px;
  z-index: 1;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #007ea0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #007ea0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #007ea0;
  -webkit-box-shadow: 0 0 1px #007ea0;
  -moz-box-shadow:0 0 1px #007ea0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
p.toggle span{
  color:#707070
}
p.toggle span.selected{
color:#000;
font-weight: 600;
}
.plan_container_parent {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.plan_container {
  background: #fff;
  border-radius: 10px;
  width: 43%;
  z-index: 9;
}

.plan_container .header {
  display: flex;
  align-items: baseline;
  padding: 18px 38px;
  justify-content: space-between;
  background: #29282e;
  color: #fff;
  border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.plan_container .header h3{
font-size: 41px;

}
.plan_container .header p{
font-size: 14px;
text-align: right;
}

.plan_container .body{
  padding: 34px 47px;
}
.plan_container .body h4{
  text-align: center;
  font-size: 21px;
}
.plan_container .body h5{
  font-size: 18px;
    margin-top: 50px;
}

.plan_container .body p {
  font-size: 14px;
  margin-bottom: 37px;
  display: flex;
}
.plan_container .body p svg rect {
  fill: #000;
}
.plan_container_parent{

}
.plan_container .body p svg {
  width: 16px;
  height: 16px;
  fill: #000;
  margin-right: 41px;
}
.plan_container .body button.btn.btn-blue {
  width: 255px;
  padding: .8rem .75rem;
  border-radius: 8px;
}
nav.plan_tab_parent.nav.nav-tabs {
  border: 1px solid #dee2e670;
  box-shadow: 0px 0px 3px #eee;
  margin-bottom: 57px;
}
nav.plan_tab_parent.nav.nav-tabs .nav-item.nav-link {
  width: 50%;
  text-align: center;
  border: none;
  font-size: 18px;
  padding: 21px 0;
}
nav.plan_tab_parent.nav.nav-tabs .nav-item.nav-link {
background-color: #eee;
}
nav.plan_tab_parent.nav.nav-tabs .nav-item.nav-link.active {
  background-color: #29282e;
  color: #fff;
}
.plan_logo_parent {
  margin-top: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.plan_logo_parent .logo_card .text h3{
  margin-bottom: 0;
  font-size: 36px;
}
.plan_logo_parent .logo_card .text p {
  margin-bottom: 0;
  font-size: 17px;

}
.plan_logo_parent .logo_card .text {
  margin-left: 25px;
}
.plan_logo_parent .logo_card {
  background: #fff;
    width: 445px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    box-shadow: 0px 3px 6px #e4e4e4;
    -webkit-box-shadow: 0px 3px 6px #e4e4e4;
     -moz-box-shadow:0px 3px 6px #e4e4e4;
    padding: 15px 10px;
}
.vs{
  background: #007ea0;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color: #fff;
    font-size: 38px !important;
}

.traingleshap_reqestdemo{
  content: '';
    width: 100%;
    height: 136px;
    background: linear-gradient(to top right, #fff0 0%, #ffffff05 49.7%, #29282e 50%, #29282e 100%);
    transform: rotate( 
180deg);
}
.traingleshap.req_demo{
  height: 156px;
}
.request-demo {
  background: #29282E;
  color: #fff;
  text-align: center;
  padding: 50px;
}
.request-demo h2 {
  font-size: 41px;
  margin-bottom: 34px;
}
.request-demo p {
  font-size: 20px;
}
.request-form {
  background: #fff;
  padding: 40px 35px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #bfbfbf;
  margin-top: -200px;
  margin-bottom: 140px;
}
.request-form .btn{
  padding: .9rem .75rem;
    font-size: 18px;
    /*margin-top: 85px;*/
}
.footer svg {
  width: 30px;
  height: 30px;
}
.footer {
  background: #29282e;
  color: #fff;
  padding: 74px 35px 25px;
}
.footer .copyright p{
font-size: 13px;
}
.footer .menu ul li{
  font-size: 17px;
  margin-bottom: 20px;
}
.midfont {
  font-weight: 500;
}

.slick-dots li {
  margin: 0 0px;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
}

.slick-dots li button:before {
  font-size: 12px;
  opacity: .25;
  color: white;
}
.get-bue{
  font-size: 18px;
  font-weight: 200;
  text-decoration: underline;
  color: #29282E;
}
.big_dot {
  position: absolute;
  top: -35%;
  right: 0%;
}
.happy-business-dot{
  display: none;
}
.plan_logo_parent div {
  z-index: 1;
}
/* --------------------payment page-------------------- */
.payment-parent{
display: flex;
align-items: center;
  justify-content: center;
  /* margin: 50px 0; */
}
.payment-form{
margin: 20px;
padding: 20px;
width: 700px;
z-index: 1;
}
.plan-package-parent{
  display: flex;
  position: relative;
  justify-content: space-between;
}
.plan-package {
  background: #eee;
  box-shadow: inset 3px 1px 8px #c0c0c0;
  border-radius: 8px;
  width: 49%;
  padding: 5px 20px;
}
.plan-package h2{
font-size: 60px;
font-weight: 200;
margin-bottom: 0;
}
.plan-package.selected{
  background: #007EA0;
      color: #fff;
      box-shadow: 0px 0px 8px #a8a8a8;
  
}
.plan-package p , .plan-package p span{
font-size: 18px;
font-weight: 500;
margin-bottom: 5px;

}
.back-arrow span svg,  .back-arrow a svg{
  width: 46px;
  height: 45px;
}
.back-arrow {
  margin-top: 50px;
}
.plan-package p {
  display: flex;
  justify-content: space-between;
}
.toltip-msz {
  position: absolute;
  top: 134px;
  right: 0;
  background: #dff1f4;
  padding: 8px 12px;
  border-radius: 6px;
  color: #888;
}
.toltip-msz:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #dff1f4;
  top: -10px;
  border-radius: 7px;
  right: 21px;
}


.payment-bill{
  margin: 20px; 
  padding: 35px; 
  z-index: 1;
  
}
.payment-bill h2 {
  font-size: 32px;
  font-weight: 500;
  color: #007EA0;
  margin-bottom: 0;
}
.payment-bill a {
  /*font-size: 12px;*/
  font-weight: 500;
  /*color: #868686;*/
  margin-bottom: 0;
}

.payment-bill .tble {
  display: flex;
  justify-content: space-between;
}
.payment-bill .tble p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 200;
  padding: 14px 20px;
}
.payment-bill .tble.total p {
  font-weight: 500;
}
.box-conteiner{
  background:#fff;
  border-radius: 7px;
  box-shadow: 0px 1px 6px #d0d0d0;

}
.payment-bill .tble p:nth-child(1){

}
.payment-bill .tble p:nth-child(2){

}
.payment-bill{

}
/* -----------animated textbox----- */
.form-input{
  position: relative;
  margin: 16px 0;
}
.textbox{
  border: 1px solid #70707026;
  border-radius: 8px;
  outline: none;
  background: none;
  padding: 10px;
  color: #000;
  width: 100%;
  height: 62px;
  font-size: 20px;
  font-weight: 500;
}
.form-label{
  position: absolute;
  top: 1rem;
  left: 2.5rem;
  cursor: text;
  transition: top 200ms ease-in , left 200ms ease-in , font-size 200ms ease-in;
  color: #868686;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 0px;
}
.textbox:focus{
  border: 1px solid #007EA0;
}
/* .textbox:not(:focus)~.form-label */
.textbox:focus ~ .form-label, .stripe-inputs .stripe-elements-wrp:not(.StripeElement--empty) ~ .form-label, .stripe-inputs .StripeElement--focus ~ .form-label {
  top:0.3rem;
  left: 0.5rem;
  font-size:12px;
  color: #868686;
  font-weight: 500;
}
.stripe-elements-wrp > div, .stripe-elements-wrp > div > iframe, .stripe-elements-wrp > div > iframe > *{
height: 100% !important;
}
.stripe-elements-wrp .ElementsApp input{
height: 51px !important;
}
.cm_active .form-label{
position: absolute;
top:0.3rem;
  left: 0.5rem;
  font-size:12px;
  color: #868686;
  font-weight: 600;
}
.applycoupn{
  color: #007EA0;
  font-size: 18px;
  font-weight: 200;
  display: block;
}
.payment-body {
  overflow: hidden;
  margin-top: 70px;
  min-height: calc( 100vh - 70px);
}
.payment-body .big_dot {
  position: absolute;
  top: -59%;
  right: -37%;
  transform: rotate(
17deg);
}

.check_wrapper {
  display: inline-flex;
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
}

.check_wrapper input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 99;
}
.term-services .check_wrapper input{
  height: auto ;
}
.check_wrapper > span {
  display: inline-block;
  height: 20px;
  width: 20px !important;
  background-color: #eee0 !important;
  border-radius: 5px;
  border: 2px solid #000000;
}

.check_wrapper > span:after {
  content: '';
  position: absolute;
  width: 6px;
  height: 10px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(35deg);
  left: 7px;
  top: 4px;
  transition: all 0.25s;
  opacity: 0;
}

.check_wrapper input:checked + span {
  background: #000;
  border-color: #000;
}

.check_wrapper input:checked + span:after {
  opacity: 1;
}

/* --------rounded checkbox------ */
.round_check_wrap {
display: block;
position: relative;
padding-left: 35px;
cursor: pointer;
font-size: 22px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

/* Hide the browser's default checkbox */
.round_check_wrap input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

/* Create a custom checkbox */
.round_check_wrap .checkmark {
position: absolute;
top: 0;
left: 0;
height: 30px;
  width: 30px;
  background-color: rgb(255, 255, 255);
  border-radius: 9px;
  border: 1px solid #707070
}

.round_check_wrap input:checked ~ .checkmark {
background-color: #007EA0;
border-color: #007EA0;
}

.checkmark:after {
content: "";
position: absolute;
display: none;
}
.round_check_wrap p {
font-size: 18px;
font-weight: normal;
padding-left: 13px;
margin-bottom: 0;
}
.round_check_wrap input:checked ~ .checkmark:after {
display: block;
}

.round_check_wrap .checkmark:after {
left: 9px;
  top: 3px;
  width: 9px;
  height: 17px;
border: solid white;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}
.selected.dropdown-item {
background: #c4dcea87 ;
border-radius: 9px;
}


.top-position{
  position: absolute;
  top: 0;
}
.diary-parent{
/* overflow-x: auto;
overflow-y: hidden; */
overflow-y: auto;
}
.new-booking-tab a.nav-item.nav-link .switch , .new-appointment-page , .footerbtn-parent{
display: none;
}
.mt-16{
margin-top: 16px
}
.toggle.small-toggle .switch{
width: 53px;
  height: 22px;
}
.toggle.small-toggle .slider:before {
height: 16px;
width: 16px;
}
.small-toggle .switch .slider:before {
transform: translateX(28px);
}
.body_container.cm_bg_white{
margin-top: 100px;
}
.order-1.menu {
order: 2;
}
/* .dashboard {
padding-top: 100px !important;
} */
.setting_page .nav-tabs .nav-link, .staff_page .nav-tabs .nav-link{
text-align: center !important;
}
/* .dashboard {
padding-top: 120px !important;
} */
.newbooking-for-mobile .booking-parent {
padding-top: 7rem;

}

/* ----------new invoice--------- */
.user-dtls {
display: flex;
width: 100%;
align-items: center;
justify-content: space-between;
text-align: left;
}

.user-dtls img {
width: 60px;
height: 60px;
border-radius: 100px;
}
.user-dtls .text h3 {
font-size: 20px;
margin-bottom: 0;
}
.user-dtls .text p {
color: #464646;
font-weight: 300;
}
.bobking_detail .cm_b_details {
background: #f7f7f7;
border-radius: 5px;
padding: 3px 12px;
margin: 15px 0;
}
.bobking_detail .cm_b_details h5 {
font-size: 17px;
font-weight: 600;
}
.bobking_detail .cm_b_details p {
font-size: 12px;
color: #4c4c4c;
font-weight: 300;
}
.user-dtls .text p, .bobking_detail .cm_b_details p {
margin-bottom: 0;
}
.new_invoice .cm_head {
margin-bottom: 0;
}
.new_invoice .cm_body {
padding: 15px 20px;
background: #fff;
border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.three_box {
display: flex;
width: 100%;
}
.three_box .cm_b_details {
width: calc( 100% / 2 - 12px );
margin: 6px;
}
.three_boxthree .cm_b_details {
width: calc( 100% / 3 - 3px);
margin: 2px;
}
.invoice-bill-detls {
display: flex;
justify-content: space-between;
border-bottom: 1px solid #eee;
padding: 8px 12px;
}
.invoice-bill-detls p {
margin-bottom: 0;
}
.invoice-bill-parent {
background: #fff;
margin-left: -20px;
margin-right: -20px;
}

.invoice-bill-detls.totls {
font-weight: 600;
}
/* ----------------new confirm booking---------- */
.top-banner{
position:relative
}
.top-banner img{
    object-fit: fill;
}
.services_img img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100px;
    border: 6px solid #f7f7f7;
}
.services_img {
    position: absolute;
    bottom: -24px;
    right: 24px;
}
.booking-detail {
  background: #f7f7f7;
  padding: 9px 15px;
  margin-top: 4px;
}
.booking-detail .booking-info p {
    font-weight: 300;
}
.booking-detail .booking-info h5 {
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 0;
}
.booking-detail .booking-info {
    margin-top: 20px;
}
.booking-timming-detail {
    display: flex;
    justify-content: space-between;
align-items: center;
}
.booking-timming-detail p {
    font-weight: 300;
    font-size: 15px;
}
.booking-timming-detail h5, .booking-timming-detail p {
    margin-bottom: 0;
}
.booking-timming-detail .date {
    background: #007ea0;
    border-radius: 5px;
    color: #fff;
    padding: 12px;
}
.booking-timming-detail .service p, .booking-timming-detail .server-name p {
    color: #4e4e4ef0;
}
.booking-timming-detail .service h5 {
    font-size: 18px;
}
.b_dtls {
    display: flex;
    align-items: center;
}
.modal-header.closesvg p svg path {
  fill: #000;
}
.modal-header.closesvg p svg {
  width: 28px
;
  height: 28px
;
}
.payment-failed .stap-action .btn{
width: 80%;
margin-left: 10%;
  padding: 0.8rem 0.75rem 1.1rem;
  font-size: 20px;
}
.PhoneInputInput, .PhoneInputInput:focus {
border: 0px;
outline: 0px;
}
.PhoneInputInput {
background: #fbfbfb;
outline: 0;
border: 0;
}
.payment-table table{
width: 100%;
min-width: 1250px;
}
.payment-table table p{
margin-bottom: 0;
}
.tble-btn{
background: #007EA0;
  color: #fff;
  padding: 2px 8px;
  border-radius: 15px

}
.payment-table table th:last-child {
min-width: 150px;}
.payment-table .table-responsive {
  border: 1px solid #eee;
}
.payment-table table td{
  padding-left: 8px;
  padding-right: 8px;
}
.payment-table table  thead th {
  background: #DFF1F4;
  padding: 18px 8px;
  color: #727272;
}
.payment-table table td, .payment-table table th{
font-size:14px
}
.payment-table table tr td:first-child p , 
.payment-table table  thead th:first-child ,
.payment-table table  thead th:first-child span svg path, .analytics-tabsHead--wrap .payment-table .table-responsive .top-corner svg path{
color: #007ea0;
fill:#007ea0;
}

.refend-btn{
background: #FF6584;
}
.pending-btn{
background: #CFFECB;
color: #000;
}
.payment-table th span svg path{
fill: #727272;
}
.payment-table th span svg {
width: 10px;
height: 10px;
margin-left: 8px;

}
.bottom-corner svg {
transform: rotate(
180deg);
}

/*

.client-graph-section{}
.occupancy{}
.booking-type{} */
.booking-grap-section{
position: relative;
background: #CFFECB;
}
.inner-booking {
position: absolute;
  top: -112px;
  right: 22%;
  width: 28%;
  transform: translate(-50% ,-50%);
  height: 232px;
  border-bottom: 0px solid #eee;
  box-shadow: 0px -2px 2px #e6e6e6 !important;
  background: #CFFECB;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.revenue-graph-section{
background:#E8FFF1;
}
.client-graph-section {
background: linear-gradient( 45deg, #FECBCB, #FEF0CB);
}
.top-graphsection-parent ,.pagination-wrapper {
display: flex;
align-items: center;
  justify-content: space-between;
}
.page-link {
background-color: rgba(255, 255, 255, 0); 
border: 1px solid #dee2e600; 
}
.pagination-wrapper {
   padding: 10px 66px 0;
}
.heading-withbtn-search{
display: flex;
justify-content: space-between;
align-items: center;
}
.heading-withbtn-search .btn svg {
width: 18px;
height: 18px;
margin-right: 10px;
}
.page-item.active .page-link {
background-color: #117D9D;
border-color: #117D9D;
border-radius: 9px;
}
.pagination-wrapper h6 {
font-size: 12px;
color: #727272;
}
.pagination-sm .page-link {
padding: 0.15rem 0.5rem;
}
.pagination li.page-item {
margin: 0px 6px;
}
.top-graphsection-parent >div {
width: calc( 100% / 3 - 20px);
}
.graph-shadow{
min-height: 200px;
min-height: 200px;
    /* border-radius: 9px;
  border-radius: 9px;
    box-shadow: 0px 0px 3px #c0bebe;
  box-shadow: 0px 0px 3px #c0bebe;
    padding: 15px; */
  padding: 15px;
    border-radius: 0px;
    box-shadow:0px 3px 3px #c0bebea8;
    padding: 15px;
    border: 1px solid #c0bebe40;
}
.circle-icon {
background: #AAC5D1;
width: 35px;
height: 35px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 100px;
}
.circle-icon svg {
width: 17px;
height: 17px

}
.toptab {
display: flex;
align-items: center;
justify-content: space-between;
}
.toptab-parent ul.react-tabs__tab-list ul li:after {
width: 0;
height: 0;
}
.toptab-parent ul.react-tabs__tab-list {
min-height: auto;
height: 100%;
overflow-y:hidden;
}
/* ---radio custom button---- */
.radio-container {
display: block;
position: relative;
padding-left: 35px;
margin-bottom: 4px;
cursor: pointer;
font-size: 22px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}
.checkout-parent .bobking_detail .pointer-event > div {
padding-top: 1px;
}

/* Hide the browser's default radio button */
.radio-container input {
position: absolute;
opacity: 0;
cursor: pointer;
}
.toptab-parent .react-tabs__tab {
padding: 16px 0px 0px !important;
}
/* Create a custom radio button */
.checkmark {
position: absolute;
top: -1px;
left: 0;
height: 25px;
width: 25px;
background-color: #eee;
border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
background-color: #fff;
border: 2px solid #007EA0;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #007ea0;
}
.checkout-parent label.radio-container {
font-size: 14px;
color: #868686;
}
.checkout-parent .detl input {
width: 52px;
border-radius: 9px;
border: 1px solid #eee;
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-left: none;
height: 26px;
}
.currency-input {
border: 1px solid #eee;
border-radius: 9px;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
height: 26px;
width: 22px;
display: inline-block;
text-align: center;
}
.checkout-parent .detl input:focus {
outline: 0;
}
.checkout-parent .detl input::-webkit-outer-spin-button,
.checkout-parent .detl input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.checkout-parent .detl input[type=number] {
-moz-appearance: textfield;
}

.border-div {
border: 1px solid #707070;
border-radius: 6px;
}
.flex-just-align-center{
display: flex;
align-items: center;
justify-content: space-between;
}
.checkout-parent .border-div h5{
color:#007EA0;
font-size: 15px;
margin-bottom: 0px;
}

.checkout-parent .detl {
padding: 8px 10px;
margin:18px 0;
}

.cm_right_panal.client_checkout.newclient-checkout{
margin-left: 0;
vertical-align: top;
box-shadow: 0px 0px 0px rgb(0 0 0 / 31%);
border-radius: 0px;
padding: 0px;
margin-bottom: 0;
background: #fff0 !important;
}
.backgroun-shadow{
box-shadow: 0px 3px 6px rgb(0 0 0 / 31%);
margin-left: 20px;
    vertical-align: top;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 31%);
    border-radius: 9px;
    padding: 22px;
   margin-bottom: 24px;
    position: relative;
    background: #fff !important;

}
.toptab {
display: flex;
align-items: center;
justify-content: space-between;
/* background: linear-gradient(90deg, #BCEBEA, #C4DCEA); */
background: #f7f7f7;
padding: 5px;
border-top-left-radius: 8px;
border-top-right-radius: 8px;
margin-right: 40px;
}

.toptab.first h6 {
padding: 2px 20px;
margin-bottom: 0;
font-weight: 500;
}
.toptab h6 {
padding: 2px 20px;
margin-bottom: 0;
font-weight: 200;
}
.toptab-parent ul {
padding-inline-start: 0px;
}
.toptab .circle-img {
width: 50px;
height: 50px;
padding: 0;
}
body.diary-temp {
overflow: hidden;
}
.newclient-checkout .btn:last-child {
margin-right: 0;
}

.btn:not(:disabled):not(.disabled) {
cursor: pointer;
}
.newclient-checkout .btn ,.refund-parent.bobking_detail .btn{
box-shadow: 0px 0px 4px #242424;
margin-top: 0px;
margin-right: 15px;
}
.modal-content.payment-failed.analytics-popup {
background: rgb(255 255 255 / 0%) !important;
box-shadow: 0px 0px 0px #5d5d5d;
border: none;
}
.analytics-popup .cm_modal.booking_bg.popupscroll {
background: transparent;
}
.analytics-popup .payment-table .table-responsive {
background: #fff;
}
.refund-parent {
width: 335px;
margin: 0 auto;
background: #fff;
padding: 15px;
margin-top: 30px;
border-radius: 7px;
}
.dashboard.booking-dash {
padding-top: 100px;
}
.hair-dairy-dtls {
width: calc(100% - 60px );
}
.booking_card.bkng-crd-dary p ,.booking_card.pointer-event p {
font-size: 9px;
}
.booking_card.bkng-crd-dary img {
width: 30px;
height: 30px;
}
.booking_card.bkng-crd-dary h4 ,.booking_card.bkng-crd-dary h5 , .booking_card.pointer-event h5, .booking_card.pointer-event h4 {
font-size: 11px;
}
.booking_card {
padding: 4px !important;
}
.newclient-checkout .payment-bill .tble {
margin-left: -22px;
border-bottom: 1px solid #7070704a;
margin-right: -22px;
padding: 0 19px;
}
.newclient-checkout .payment-bill .tble p {
padding: 8px 0px;
font-size: 15px;
font-weight: 400;
}
.newclient-checkout .payment-bill .tble.green {
color: #06a000a3;
}
.newclient-checkout .payment-bill .tble.green.disabled {
color: #06a0006b;
}
.newclient-checkout .payment-bill .tble p span svg {
width: 12px;
height: 12px;
cursor: pointer;
}
.noshadow{
box-shadow: 0px 0px 0px rgb(0 0 0 / 31%) ;
}
.booking-temp .bodyContainer {
overflow-x: inherit;
}
.top_fix_box img {
border-radius: 100px;
}
.menu-show-desktop{
display: block;
}
.menu-show-mobile{
display: none;
}
.close-icon {
position: absolute;
top: 21px;
right: 28px;
}
.close-icon svg {
width: 20px;
height: 20px;
}
.close-icon svg g path {
fill: #000;
}
.booking_card.pointer-event.black-event {
background: #29282E !important;
}
.cm_right_panal.client_checkout.phone-payemnet-taken-change.iinvoice .cm_btns.mobile-change-btn {
padding: 30px;
}
.cm_right_panal.client_checkout.phone-payemnet-taken-change.iinvoice {
box-shadow: none;
border-radius: 0;
padding: 0;
}
.cm_right_panal.client_checkout.phone-payemnet-taken-change.iinvoice .text-center {
box-shadow: 0px 3px 6px rgb(0 0 0 / 31%);
border-radius: 9px;
padding: 30px;
background: #fff;
width: 360px;
    max-width: 360px;
    margin-left: 52px;
}
.booking-parent.view-booking-parent .new-invoice-parent{
display: flex;
justify-content: center;
}
.booking-parent.view-booking-parent .new-invoice-parent .tab_content_wrapper {
max-width: 400px;
width: 400px;
}
.cm_right_panal.client_checkout.remove-brder {
padding: 23px;
}
.cm_right_panal.client_checkout.remove-brder .tab-content {
margin-left: -38px;
margin-right: -38px;
}
.equal-input .form-control, .form-control:focus, .equal-input.form-control {
height: calc(2.5em + 0.75rem + 7px) !important;
}
.equal-input.form-control, .equal-input.form-control:focus {
height: calc(2.5em + 0.75rem + 7px) !important;
}
.equal-finishtime input {
height: calc(2.5em + 0.75rem + 7px) !important;
}
.fix-with-4line {
width: 156px;
display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.client_checkout .card-body ul li div:nth-child(2) {
width: calc(100% - 119px );
}
.complete-booking{
background: #707070 !important;
color: #fff !important;
}
.body_container.cm_bg_white {
margin-top: 74px;
}
.bg-black{
background: #000;
}
.checkout-client {
  max-width: 500px;
  margin: 0 auto;
}
.btn_full_plus {
  background: #DFF1F4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  width: 100%;
  font-weight: 600;
}
.btn_full_plus span svg{
  width: 15px;
  height: 15px;
    margin-right: 16px;
}
.btn_full_plus span svg g rect{
  fill:#000
}
.checkout-client .client_table tbody tr td p:last-child {
  margin-bottom: 0;
  font-size: 18px;
  text-transform: lowercase;
  color: #000;
  font-weight: 300;
}
.checkout-client .client_table tbody tr .alfa_text h3 {
  padding-top: 0;
  margin: 0;
  text-align: center;
  font-size: 45px;
}
.checkout-client .client_table tbody tr td p:first-child {
  margin-bottom: 0;
  font-size: 18px;
  color: #000;
  font-weight: 500;
}
.checkout-client .client_table tbody tr td {
  border-top: 0;
  border-color: #dee2e661;
}
.selectService .service_selected{position: relative;}
.selectService .service_selected:before {
  content: '';
  background: #D6D5EC;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.checkout_flex__div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout_main_service_div .invoice_card_wrapper.new_invoice {
  min-width: 390px;
}
.checkout_main_page .cm_right_panal.client_checkout.phone-payemnet-taken-change.iinvoice {
  width: 320px;
  margin-left: 46px;
  margin-bottom: 0;
}
.input_group_u .css-yk16xz-control , .input_group_u .css-1pahdxg-control {
  /* background: pink; */
  padding: 10px;
    border-radius: 9px;
    border-color: #00000036;
    box-shadow: none;

}
.css-1okebmr-indicatorSeparator{
  display:none
}
/* ---------------------------------------------------------------new wen page end----------------------------- */
/* ------------------------------------------media query start----------------------- */
@media (min-width : 1500px) {
  .payment-body .big_dot {
      position: absolute;
      top: -79%;
      right: -14%;
      transform: rotate( 
  17deg);
  }
}
@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1215px !important;
}
.new-setting-wrapper .container{
  max-width: 1260px !important;
}
.dot.emp_dot {
  right: -40%;
}

}

@media(max-width: 991px){
  .checkout-client .client_table .alfa_text,
  .checkout-client .client_table thead tr th:nth-child(1), 
.checkout-client .client_table tbody tr td:nth-child(1), 
.checkout-client .client_table thead tr th:last-child, 
.checkout-client .client_table tbody tr td:last-child
 {
    display: block;
    display: table-cell;
}
  .menu-show-desktop{
    display: none  !important;
  }
  .menu-show-mobile{
    display: block;
  }
 
  .sidenava {
    width: 100%;
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    padding: 0px;
    padding-top: 0px;
    background: transparent;
    z-index: 99999;

  }
  .sidenava-meue {
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
    background: #29282E;
    width: 250px;
    height: 100%;
    padding: 10px;
    padding-top: 110px;
  }
  .sidenava-meue.sideMenu-bar{
    padding-top: 80px;
  }
  .sidenava .sidenava-meue ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .sidenava {
    left: -100% !important;
  }
  .nav_menu_open .sidenava{
    left: 0px !important;
  }

  .sidenava .sidenava-meue ul li {
    margin-bottom: 30px;
  }
  
  .sidenava .sidenav-meue ul li a {
    display: block;
    padding: 10px;
    border-radius: 10px;
  }
  
  .sidenava .sidenav-meue ul li a.active,
  .sidenava .sidenav-meue ul li a:hover {
    background: #e6ffff;
  }
  .sidenava_text {
    margin-left: 20px;
  }
  .sidenava-meue ul {
    padding-inline-start: 0;
  }
  
  .nav_menu_open .sidenava ul li a {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    display: block;
    color: #fff;
  }
  .nav_menu_open .sidenava ul li a .menu_icon > svg{
    fill: #fff;
  }
  .sidenava ul li a .menu_icon.trncIcon > svg #Path_1539 path:nth-child(2), .sidenava ul li a .menu_icon.trncIcon > svg #Path_1540 path:nth-child(2){
    fill: #fff;
  }
  .sidenava  ul li a.active , .sidenava ul li a:hover {
    background: #fff;
    color: #29282E;
  }
  .sidenava  ul li a.active .menu_icon > svg, .sidenava ul li a:hover .menu_icon > svg, .sidenava  ul li a.active .menu_icon.trncIcon > svg #Path_1539 path:nth-child(2), .sidenava ul li a:hover .menu_icon.trncIcon > svg #Path_1539 path:nth-child(2), .sidenava  ul li a.active .menu_icon.trncIcon > svg #Path_1540 path:nth-child(2), .sidenav ul li a:hover .menu_icon.trncIcon > svg #Path_1540 path:nth-child(2){
    fill: #29282E;
  }
  .user_pic-profile > span{
    color: #fff;
  }
  .user_pic{
    display: none;
  }
  .sidenava-meue .user_pic{
   display: block!important;
   left: -9px;
  top: 62px;
  }

  .booking-parent.view-booking-parent .new-invoice-parent{
    flex-wrap: wrap;
  }
  .booking-parent.view-booking-parent .new-invoice-parent .tab_content_wrapper,
  .booking-parent.view-booking-parent .new-invoice-parent .tab_content_wrapper .invoice_card_wrapper{
    width: 100%;
    max-width: 100%;
  }

  .top_fix_box.cm_dark_b .hair-dairy-dtls, .top_fix_box.cm_dark_b .text_red {
    color: #000000 !important;
}
  .order-1.menu {
    order: 1;
}
.backgroun-shadow{
  margin-left: 0px;
}
 .new-appointment-page , .footerbtn-parent{
  display: block;
}
.m_revers {
  top: 0px !important;
  margin: 0px !important;
}
.m_revers button.btn {
  font-size: 16px !important;
}
.container.tab_content_wrapper{
  margin-top: 40px;
}


  .hide-desktop{ /* visible in mobile */
        display: block !important;
  }
  .hide-mobile{
      display: none !important;
  }
  /* .body_container.cm_bg_white{
    margin-top: 0px;
  } */
  .body_container.cm_bg_white {
    margin-top: 66px;
  }
  .request-demo {
      margin-top: -1px;
  }
  .happy-business-dot{
      position: absolute;
      top:50%;
      left:-242px;
  }
  .cm-navbar-new .logo-web img {
      width: 60%;
  }
  .happy_business .text p {
      font-size: 14px;
  }
  .plan_logo_parent {
      flex-wrap: wrap;
      margin-top: 50px
  }
  .plan_logo_parent > div{
   width: 100%;
  }
  .vs {
      margin: 0px auto 26px;
  }
  .plan_logo_parent .logo_card {
      width: 100%;
  }
  .plan_container_parent {
      flex-wrap: wrap;
  }
  .stats {
      padding: 30px;
      padding-bottom: 50px;
      margin: 5px;
  }
  .plan_container {
      width: 100%;
  }
  .happy_business {
      flex-wrap: wrap-reverse;
      padding: 75px 0 40px ;
  }
  .bodyContainer {
    padding-top: 0px;
  }
  .cm_bg_home{
      padding-top: 60px;
  }
  .dashboard.booking-dash {
    margin-top: 60px;
}
  .cm_bg {
      margin-top: 79px !important;
  }
  .happy_business .text h4 {
      font-size: 23px;
      text-align: left;
      margin-bottom: 37px;
      font-weight: 500;
      padding-left: 14px;
  }
  .feature .text h4:before {
      left: 0px;
      width: 5px;
      height: 27px;
  }
  .happy_business.feature .text {
      width: 100%;
      margin-right: 0px !important;
      margin-top: 20px;
  }
  .happy_business.feature .img {
      width: 100%;
      margin-right: 0px;
  }
  .happy_business.feature .feature-list p {
      width: 100%;
      padding-left: 35px;
      margin-bottom: 26px;
  }
  
  .happy_business.feature .feature-list p:nth-child(even) {
      margin-left: 0%;
  }
  .container.pad-0 ,.container-fluid.pad-0.plan_parent ,.container{
      padding-right: 9px !important;
      padding-left: 9px !important;
  }
/* --------------old page design responsive start----------- */
  .body_container.cm_bg_white .container {
      padding-right: 0px !important;
      padding-left: 0px !important;
  }
  .client_table {
      box-shadow: 0 0px 2px rgb(0 0 0 / 31%) !important;
      -webkit-box-shadow: 0 0px 2px rgb(0 0 0 / 31%) !important;
-moz-box-shadow:0 0px 2px rgb(0 0 0 / 31%) !important;

  }
  .client_table thead tr th:nth-child(1), 
  .client_table tbody tr td:nth-child(1) ,
  .client_table thead tr th:last-child, 
  .client_table tbody tr td:last-child {
      display: none;
  }
    ::-webkit-scrollbar {
      display: none;
  }
  .cm_box_view {
      padding: 0px !important;
      box-shadow: 0px 0px 0px rgb(0 0 0 / 31%) !important;
      padding-top: 20px;
      border-radius: 0px !important;
  }
  .cm_fw_b {
      font-weight: 500 !important;
      font-size: 22px;
      margin: 20px 0;
      padding-top: 26px;
  }
  .cm_fw_l {
      font-size: 18px;
  }
  .staff_page .top_right {
      min-width: 90px;
  }
  .seetings_wrap_box {
      padding: 0 0px !important;
      box-shadow: 0px 0px 0px rgb(0 0 0 / 31%) !important;
  }
  .border_right {
      border-right: none !important;
  }
  .seetings_wrap_box .col-md-6 {
      padding: 15px 1rem !important;
  }
  .staff_page .tab-pane {
      padding: 0px !important;
      box-shadow: 0px 0px 0px rgb(0 0 0 / 31%) !important;
  }
  .only_shadow {
      padding: 0px 0px !important;
      box-shadow: 0px 0px 0px rgb(0 0 0 / 31%) !important;
  }
  footer{
      display: none !important;
  }
  .footer-section {
      min-height: calc(100vh - 70px) !important;
      margin-top: 70px !important;
  }
  .dashboard {
      padding: 25px 0px !important;
  }
  .contentBg {
      margin: 0px 0px !important;
      box-shadow: 0px 0px 0px #bbb !important;

  }
/* ------------------------old page design responsive end------------------------------------ */
  .happy_business.feature .feature-list p:before {
      left: 8px;
  }
  .happy_business .text , .happy_business .img{
      width: 100%;
      margin-right: 0;
  }
  .management_platform p {
      font-size: 26px;
      font-weight: 300;
     margin: 40px 0;
  }
  .management_platform .col-6:nth-child(odd) {
      padding-right: 7px;
  }
  .management_platform .col-6:nth-child(even) {
      padding-left: 7px;
  }
  .management_platform {
      margin: 80px auto;
  }
  .happy_business .img {
      width: 80%;
      margin: 0 auto;
  }
  .dot {
      top: -6%;
      right: -59%;
  }
  .traingleshap {
      height: 74px;
      background: linear-gradient(to bottom right, #fff0 0%, #ffffff05 49.7%, #29282e 51%, #29282e 100%);
  }
  .feature-parnt{
      display: none;
  }
  .heading {
      font-size: 26px;
  }
  .heading:after {
      width: 138px;
      height: 7px;
      
  }
  .happy_business.feature {
      flex-wrap: wrap;
  }
  .happy_business.feature {
      padding: 50px 0 30px;
  }
  .traingleshap.top {
      height: 62px;
  }
  .happy_business.feature .text p {
      font-size: 14px;
      line-height: 1.5;
  }
  .happy_business.feature.self-emp {
      flex-wrap: wrap-reverse;
  }
  .dot.emp_dot {
      top: -3px;
      right: -18%;
  }
  .beumarket .happy_business.feature {
      flex-wrap: wrap-reverse;
  }
  .beumarket {
      margin: 40px auto;
  }
  .beumarket .happy_business.feature .text {
      margin-top: 25px;
  }
  .plan_parent p {
      font-size: 14px;
      font-weight: 200;
  }
  .toggle span.selected {
      font-weight: 500;
  }
  .plan_container .header p {
      font-size: 12px;
  }
  .plan_container .header h3 {
      font-size: 36px;
      font-weight: 200;
  }
  .plan_container .header {
      padding: 8px 15px;
  }
  .plan_container .body h4 {
      font-size: 16px;
      font-weight: 200;
  }
  .plan_container .body h5 {
  font-size: 14px;
      margin-top: 39px;
      font-weight: 500;
      margin-bottom: 25px !important;
  }
  .plan_container .body p svg {
      margin-right: 11px;
  }
  .plan_container .body {
      padding: 34px 30px;
  }
  .plan_container .body h5 {
      margin-left: -10px;
      margin-right: -10px;
  }
  .plan_container .body p {
      font-size: 12px;
      font-weight: 500;
  }
  .plan_logo_parent .logo_card .text h3 {
      font-size: 18px;
      font-weight: 500;
  }
  .plan_logo_parent .logo_card .img img {
      max-width: 65%;
  }
  .plan_logo_parent .logo_card{
      padding: 6px 10px;
  }
  .plan_logo_parent .logo_card .text {
      margin-left: 15px;
  }
  .vs {
      width: 50px;
      height: 50px;
      font-size: 20px !important;
  }
  .banner-container .text {
      position: fixed;
      top: 11%;
      color: #fff;
      left: 22px;
  }
  .banner-container .text h3 {
      font-weight: 400;
      font-size: 40px;
      font-weight: 200;
  }
  .banner-container .text h3:nth-child(2){
    display: inline-block;
    padding-right: 7px;
  }
  .banner-container .text {
      position: absolute;
      top: 11%;
      color: #fff;
      left: 22px;
      width: 89%;
  }
  
  .mobile {
      position: absolute;
      top: 431px;
      width: 100%;
      text-align: center;
  }
  .banner-container .text p {
      font-size: 18px;
  }
  .banner-container .text h3:nth-child(2):after {
      /* width: 5px;
      height: 91%;
      content: '';
      position: absolute;
      top: auto;
      background: #00b7ea;
      right: 0px; */
  }
  .payment-parent {
      flex-wrap: wrap;
  }
  .payment-bill ,.payment-form{
      width: 100%;
  }
  .applycoupn, .applyCpn {
      margin-top: 35px;
  }
  .toltip-msz {
      top: 82px;
  }
  .plan-package h2 {
      font-size: 29px;
      font-weight: 500;
  }
  .plan-package p, .plan-package p span {
      font-size: 12px;
  }
  .plan-package {
      padding: 5px 11px;
  }
  .payment-bill {
      padding: 15px
  }
  .payment-bill .tble p {
      padding: 14px 0px;
  }
  .footer .menu ul li {
      font-size: 15px;
      margin-bottom: 15px;
  }
  .footer {
      padding: 25px 15px 20px;
  }
  .request-form {
      margin-bottom: 66px;
  }
  /* .modal-dialog.remove-margin-phone{
      margin: 0rem;
  } */
  /* .modal-dialog.remove-margin-phone .modal-content {
      border: 0px;
      border-radius: 0;
  } */
  .back-arrow span svg {
      width: 25px;
      height: 25px;
  }
  .back-arrow {
  margin-top: 14px;
  }
  .booking_form_wrapper {
      box-shadow: 0px 0px 0px rgb(0 0 0 / 31%) !important;
      border-radius: 0px !important;
  }
  .tab_content_wrapper {
      margin-top: 80px;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 31%);
      border-radius: 9px;
      background: #fff;
  }
  .booking-for-mobile .booking-parent .tab_content_wrapper{
    margin-top: 10px;
  }
  .top_header {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);
      -webkit-box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);
      -moz-box-shadow:0px 3px 6px rgb(0 0 0 / 12%);

      min-height: 66px  !important;
  }
  .newbooking-for-mobile{
      background: #ffffff00 !important;
  }
  /* .cm_picker_wrap {
      padding: 0 0px !important;
  } */
  .newbooking-for-mobile .cm_row.m-0 {
      display: flex;
      flex-wrap: wrap-reverse;
  }
  .newbooking-for-mobile .fade.tab-pane .cm_25 .form-group {
    width: calc( 100% / 2 - 2% ) !important;
    margin-left: 2% !important;
}
.newbooking-for-mobile .cm_25 {
    display: flex;
    margin-top: 15px;
}
.fix-footer{
  display: flex!important;
  position: fixed;
  bottom: 5%;
  width: 94%;
  background-color: #ffffff;
  padding-top: 12px;
  padding-bottom: 7px;
}
.footer-btn .btn-blue {
  margin-left: 2%;
  box-shadow: 0px 0px 5px #8888889e;
  margin-top: 0 !important;
  
}
.footer-btn .btn-bhite {
    box-shadow: 0px 0px 5px #888;
    margin-right: 2%;
}
.newbooking-for-mobile .cm_body ul{
  margin-top: 0px;
}
.newbooking-for-mobile .cm_width_25 .cm_body, .newbooking-for-mobile .cm_width_25 .cm_footer {
  min-width: 270px;
  width: 100%;
  max-width: 100%;
}
.new-booking-tab .nav-item.active:after {
  height: 0px !important;
}
.new-booking-tab p.toggle{
  display: flex;
  align-items: flex-end;
}
.new-booking-tab .switch {
  width: 45px;
  height: 21px;
}
.new-booking-tab .slider:before {
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
}

.new-booking-tab .nav-item {
font-size: 18px !important;
}
.event-tg .switch{
position: relative;
left: -22px;
}
.new-booking-tab .event-tg .switch .slider:before {
left: 25px;
}
.new-booking-tab a.nav-item.nav-link.active .switch {
  display: block;
  margin-bottom: 0;
}
.new-booking-tab .text-right.cm_right_check {
text-align: center !important;
}
.new-appointment-page{
padding: 40px 15px;
}
.srvc_table {
border: 0px solid #dee2e6;
}
.cm_right_panal{
margin-bottom: 0 !important;
}
.new-booking-tab {
margin-top: 30px;
}
.times_no {
/* padding-top: 35px !important; */
/* padding-top: 140px !important;
top: 156px !important; */
}

.about_hairdresser h3 {
font-size: 15px !important;
}
.body_container.cm_bg_white {
padding-top: 5px;
}
.about_hairdresser h5 {
font-size: 13px;
}
.about_hairdresser img {
top: 9px !important;
width: 46px !important;
height: 46px !important;
}
.booking_card h5 {
font-size: 13px;
}
.mt-16{
margin-top: 0;
}
.cm_select {
font-size: 16px !important;
}
.online-btn{
display: none;
}
.booking-parent{
position: relative;
}

.booking-parent {
position: relative;
/* width: 100%;
height: 94vh;
background: pink; */
}
.container.tab_content_wrapper.new-booking-mid {
/* position: absolute;
top: 50%;
transform: translateY(-50%); */
}
.footerbtn-parent {
/* position: absolute;
bottom: 0px;
width: 100%; */
}

.container.tab_content_wrapper.new-booking-mid {
margin-top: 40%;
}
.newbooking-for-mobile .booking-parent {
margin-top: 70px;
}
.newbooking-for-mobile .cm_right_panal {
margin-top: 0 !important;
padding-top: 0 !important;
background: #fff !important;
margin-top: 0px
}
.newbooking-for-mobile .tab_content_wrapper{
margin-top: 0px;
}
.newbooking-for-mobile .cm_right_panal.client_checkout {
margin-top: 33px !important;
}
.newbooking-for-mobile .booking-parent {
padding-top: 0rem;
}
/* .dropdown-item:last-child {
background: white;
border: 1px solid #eaeaea;
} */
.newbooking-for-mobile .cm_right_panal.client_checkout .cm_btns.m_revers,
.cm_btns.mobile-change-btn 
{
display: flex;
}
.cm_bg_uniq {
margin-right: 4px;
}
.newbooking-for-mobile .cm_right_panal.client_checkout .cm_btns.m_revers .btn ,
.cm_btns.mobile-change-btn .btn {
margin-bottom: 0px !important;
margin-top: 0px !important;
margin-right: 10px;
}
.newbooking-for-mobile .cm_right_panal.client_checkout .cm_btns.m_revers .btn.btn-primary ,
.cm_btns.mobile-change-btn .btn.btn-primary {
margin-right: 0;
}
.cm_right_panal.client_checkout .cm_btns button.btn {
font-size: 20px;
font-weight: 300;
padding: 7px 10px;
}
.cm_right_panal.client_checkout.phone-payemnet-taken-change {
background: transparent !important;
box-shadow: 0px 0px 0px !important;
}

.cm_right_panal.client_checkout.phone-payemnet-taken-change  .cm_bao {
background: #bfe4ea00 !important;
}
.cm_right_panal.client_checkout.phone-payemnet-taken-change  .cm_bao h3 {
display: none;
}
.booking_form_wrapper .cm_row .cm_25 .form-group{
width: 100% !important;
margin-left: 0 !important;
}
.booking-parent {
margin-bottom: 14%;
}
.footerbtn-parent.mt-5.mb-3 {
position: fixed;
  width: 100%;
  
  left: 0;
  bottom: -16px}

.fix-footer {
 display: flex!important;
  position: absolute;
  width: 100%;
  background-color: #fff;
  padding:12px 25px;
  left: 0;
  bottom: -3px;
}

}

@media(min-width:991px) and (max-width:1100px){
.new--diary--clndr >.new--diary-clnder-body> .calendar_wrapper, .new--diary--clndr > .new--dairy-clnader-body >.new--diary-clnder-body> .calendar_wrapper{
  min-width: calc(100vw / 4 - 20px) !important;
  max-width: calc(100vw / 4 - 20px) !important;
}
.new--diary--clndr > .new-dairy-temp-4 > .calendar_wrapper, .new--diary--clndr > .new--dairy-clnader-body > .new-dairy-temp-4 > .calendar_wrapper{
width: calc(100% - 15px) !important;
max-width: initial !important;
min-width: initial !important;
}
.menu-show-desktop{
  display: none  !important;
}
.menu-show-mobile{
  display: block;
}

.sidenava {
  width: 100%;
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  padding: 0px;
  padding-top: 0px;
  background: transparent;
  z-index: 99999;

}
.sidenava-meue {
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
  background: #29282E;
  width: 250px;
  height: 100%;
  padding: 10px;
  padding-top: 110px;
}
.sidenava-meue.sideMenu-bar{
  padding-top: 80px;
}
.sidenava .sidenava-meue ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidenava {
  left: -100% !important;
}
.nav_menu_open .sidenava{
  left: 0px !important;
}



.sidenava .sidenava-meue ul li {
  margin-bottom: 30px;
}

.sidenava .sidenav-meue ul li a {
  display: block;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
}

.sidenava .sidenav-meue ul li a.active,
.sidenava .sidenav-meue ul li a:hover {
  background: #e6ffff;
}
.sidenava_text {
  margin-left: 20px;
}
.sidenava-meue ul {
  padding-inline-start: 0;
}

.nav_menu_open .sidenava ul li a {
  width: 100%;
  border-radius: 9px;
  color: #fff;
}
.sidenava ul li a {
  padding: 10px;
  display: block;
}
.sidenava ul li a .menu_icon.trncIcon > svg #Path_1539 path:nth-child(2), .sidenava ul li a .menu_icon.trncIcon > svg #Path_1540 path:nth-child(2){
  fill: #fff;
}
.sidenava  ul li a.active , .sidenava ul li a:hover {
  background: #fff;
  color: #29282E;
}

.footerbtn-parent.mt-5.mb-3 {
  position: fixed;
    width: 100%;
    left: 0;
    bottom: -16px}

.fix-footer {
   display: flex!important;
    position: absolute;
    width: 100%;
    background-color: #fff;
    padding:12px 25px;
    left: 0;
    bottom: 0;
}
.sidenava  ul li a.active .menu_icon > svg, .sidenava ul li a:hover .menu_icon > svg, .sidenava  ul li a.active .menu_icon.trncIcon > svg #Path_1539 path:nth-child(2), .sidenava ul li a:hover .menu_icon.trncIcon > svg #Path_1539 path:nth-child(2), .sidenava  ul li a.active .menu_icon.trncIcon > svg #Path_1540 path:nth-child(2), .sidenava ul li a:hover .menu_icon.trncIcon > svg #Path_1540 path:nth-child(2){
  fill: #29282E;
}
.sidenava ul li a .menu_icon > svg{
  fill: #fff;
}
.user_pic-profile > span{
  color: #fff;
}


.user_pic{
  display: none;
}
.sidenava-meue .user_pic{
 display: block!important;
 left: -9px;
top: 62px;
}
.top_header .menu_toogle_icon {
  top: 13px;
}
.top_header {
width: 100% !important;
/* background: pink; */
}
.user_pic-profile {
  border-bottom: 1px solid #fff;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
}
.user_pic-profile > img {
  max-width: 100%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.bookingClanderView {
padding: 22px 0px 0px !important;

}

}

@media(max-width :767px){
  .checkout_flex__div {
    flex-wrap: wrap;
}
.checkout_main_page .cm_right_panal.client_checkout.phone-payemnet-taken-change.iinvoice {
  margin-left: 0px;
}

.cm_right_panal.client_checkout.newclient-checkout .cm_bao{
  margin: -21px -21px 14px -21px
  }
  .cm_right_panal.client_checkout .cm_bao {
    margin: 0px -30px 0px -30px;
}

.cm_right_panal.client_checkout.remove-brder {
  padding: 0px 29px 29px 29px;
}

.cm_right_panal.client_checkout.remove-brder .tab-content {
margin-left: -44px;
margin-right: -44px;
}

/* --new box-shadow tab booking start---- */
nav.inset-shdow-booking.new-booking-tab.nav.nav-tabs{
  background: #F7F7F7;
  margin: 20px 30px;
  border-radius: 9px;
  padding: 4px;
  box-shadow: inset 2px 3px 3px #d8d6d6;
}

.inset-shdow-booking.new-booking-tab .nav-item.nav-link.active {
  border: none;
  background: #007ea0;
  color: #fff;
  border-radius: 9px;
}
.inset-shdow-booking.new-booking-tab .nav-item.nav-link {
  width: calc( 100% / 2) !important;
  margin-bottom: 0;
  text-align: center;
}
/* --new box-shadow tab booking end---- */
.search-mob-pad .my-forms {
  padding-left: 38px;
}
.setting_page nav.nav.nav-tabs ,h2.cm_bookin_deatils.booking_tab.row {
  flex-wrap: nowrap !important;
}
h2.cm_bookin_deatils.booking_tab.legal_tab.row{
  flex-wrap: wrap !important;
}
.setting_page .nav-tabs .nav-link{
    padding: 6px 10px !important;
}
.cm_bookin_deatils.booking_tab.row li {
  margin-right: 0 !important;
}
ul.booking {
  padding-inline-start: 0 !important;
}
.setting_page .nav-tabs .nav-link{
  padding: 6px 10px;
}
.staff_page .tab-content h3 {
  font-size: 19px;
  font-weight: 600;
  margin: 18px 0;
}
.add-newbooking svg {
  width: 20px !important;
}
.add-newbooking {
  width: 46px !important;
  height: 46px !important;
  background-color: #007ea0 !important;
  box-shadow: 0px 0px 5px #00323f !important;
}
.top_fix_box {
  width: calc(100vw / 3 - 25px) !important;
  background: #fff !important;
  margin: 0 5px !important;
  border-radius: 0px !important;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 20%) !important;
  padding: 9px 2px !important;
}
.booking_card {
  width: calc(100% + 6px)!important;
  right: 7px !important;
  left: -20px !important;
}
.top_fix {
  margin-bottom: 0px !important;
  padding-left: 40px !important;
}

.diary-page-container-body > div.times_no {
width: 40px !important;
  margin-top: -51px;
}
.times_no > div {
padding: 0 4px !important;
text-align: center;
}
.about_hairdresser h3, .hair-dairy-dtls h3 {
font-size: 13px !important;
margin: 8px 0 !important;
  margin-bottom: 0px !important;
}
.about_hairdresser h5, .hair-dairy-dtls h5 {
  font-size: 11px !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;font-weight: 300;
}
.booking_card.bkng-crd-dary .cstm-name{
white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.hair-dairy-dtls , 
/* .booking_card.cm_wrap_text h5 , */
.booking_card.bkng-crd-dary h4, 
.booking_card.bkng-crd-dary h5, 
.booking_card.bkng-crd-dary p{
width:  100%  !important;
}
.inner-box img {
width: 48px;
height: 48px;
border-radius: 100px;
object-fit: cover;
border: 1px solid #f7f7f7
}
.inner-box {
justify-content: center !important;
flex-wrap: wrap-reverse !important;
text-align: center;

}

.diary-parent {
left: -15px !important;
width: calc( 100% + 30px )!important;
}
.first-column .cm__col.cm__border, .first-column .top_fix .top_fix_box {
width: calc(100vw / 1 - 44px) !important;
}
.second-column .cm__col.cm__border, .second-column .top_fix .top_fix_box {
width: calc(100vw / 2 - 23px) !important;
}
.hi-column .cm__col.cm__border {
width: calc(100vw / 3 - 15px) !important;
}
.hi-column .top_fix .top_fix_box {
width: calc(100vw / 3 - 26px) !important;
}
.addservice-popup .cm_tab_row.cm_fw_b {
align-items: baseline;
padding-top: 0;
}
.addservice-popup .cm_main_col, .addservice-popup .cm_main_col_2, .addservice-popup .cm_main_col_3 {
font-size: 15px;
}
.addservice h2{
font-size: 22px;
}
.cm_modal.cm_calendar.popupscroll{
width: 100%;
}
.account-table .row {
margin-bottom: 20px;
}
.account-table .border-right.accountleft p {
margin-bottom: 0;
padding-bottom: 0;
}

.cm_25 .form-group .cm_picker_wrap {
padding: 0;
}
.booking-temp .services-offered th, td {
padding: 14px 9px !important;
}
ul.buttons_border_wrap {
top: calc(100% + 0px) !important;
width: 100% !important;
}
/* .cm_bao {
margin: 0px -23px !important;
} */

.top-container {
margin: 0 !important;
flex-wrap: wrap;
}
/* .top-container .right-container .d-flex.align-items-flex-start {
flex-wrap: wrap;
width: 100%;
justify-content: center;
}
.top-container .right-container button.btn.btn-dark-blue.mr-3 {
margin-bottom: 20px;
} */
.transacion-container {
width: calc( 100%) !important;
flex-wrap: wrap;
margin-bottom: 12px;
}
.transacion-box {
width: calc( 100% ) !important;
min-height: 100px;
max-height: 100px;
margin: 8px auto !important;
}
.transacion-box p {
font-size: 14px;
}
.transacion-box h2 {
font-size: 30px;
}
.right-container {
margin: 0 auto;
}
.right-container .cm_search_wrapper.mt-5.client_search.m-0.analytics-search-bar {
/* margin: 20px 0 !important; */
}

}

@media(max-width: 414px){
  .hide-desktop-banner{ /* visible in mobile */
      display: block !important;
}
.hide-mobile-banner{
    display: none !important;
}
.setting_page .nav-tabs .nav-link, .staff_page .nav-tabs .nav-link {
  padding: 9px 5px !important;
  font-size: 13px;
}
.top_right {
  top: -37px !important;
}
.times_no > div {
    font-size: 14px;
}

}
@media(max-width : 575px){
  .checkout_main_service_div {
    width: 94%;
    margin: 0 auto;
}
  .checkout_main_page ,
  .checkout_main_page .cm_right_panal.client_checkout.phone-payemnet-taken-change.iinvoice,
  .checkout_main_service_div .invoice_card_wrapper.new_invoice{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
.alfa_text h3 {
  padding-top: 0;
  margin: 0;
  text-align: center;
}
  .btn_full_plus {
    width: 110%;
    margin-left: -16px;
    margin-right: 23px;
  }
  .modal-dialog.remove-margin-phone{
    margin: 0rem;
}
.modal-dialog.remove-margin-phone .modal-content {
  border: 0px;
  border-radius: 0;
}
.border-right.accountleft {
    border-right: 0px !important;
}
.modal-open .popup-content, .modal-open .modal-content {
  border-radius: 0px !important;
  box-shadow: 0px 0px 0px #5d5d5d00 !important;
  border-color: #70705c00 !important;
}
.cm_staff_catd h3 {
  font-size: 15px !important;
  font-weight: 600 !important;
  }
  .cm_staff_catd img {
      top: 7px !important;
      right: 8px !important;
      width: 35px !important;
      height: 35px !important;

  }
  .cm_staff_catd {
      padding: 13px 15px !important;
      min-width: 176px !important;
  }
  .cm_over_auto .col-lg-3.col-md-4.col-sm-6 {
      padding-left: 8px;
      padding-right: 4px;
  }
  
  
}
 @media (min-width:415px) and (max-width:515px) {
  .banner-container .text p {
      font-size: 10px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      text-align: left;
  }
  
  .banner-container .text h3 {
      font-size: 20px;
      margin-bottom: 0px;
  }
  
  .banner-container .text {
      top: 24%;
  }
  .banner-container .text .btn {
      width: 116px;
      padding: 3px;
      font-weight: 600;
      font-size: 10px;
      margin-top: 11px !important;
  }
  .mobile {
      position: initial;
       top: 0px; 
      text-align: left;
  }
 }
@media (min-width:1100px) and (max-width:1300px) {
  .top_fix_box{
    width: calc(100vw / 2 - 117px) !important;
  }
}
@media (min-width:1300px) {
  .cm__col {
    width: calc(100vw / 4 - 90px) !important;
}
.top_fix_box {
  width: calc(100vw / 4 - 118px) !important;
  padding: 9px 8px;
}
.hair-dairy-dtls {
  width: calc(100% - 40px );
}
.inner-box img{
  width: 30px;
}
.about_hairdresser h3 , .hair-dairy-dtls h3{
  font-size: 16px !important;
 }
.hair-dairy-dtls h5{
  font-size: 14px;
}
}
@media (min-width:1541px) {
.cm__col {
  width: calc(100vw / 5 - 90px) !important;
}
.top_fix_box {
width: calc(100vw / 5 - 118px) !important;
padding: 9px 8px;
}
.hair-dairy-dtls {
width: calc(100% - 40px );
}
.inner-box img{
width: 30px;
}
.about_hairdresser h3 , .hair-dairy-dtls h3{
font-size: 16px !important;
}
.hair-dairy-dtls h5{
font-size: 14px;
}
}

.react-tabs__tab--selected .toptab{
/* background: linear-gradient(
90deg, #79faf7, #2e6a8d);
-webkit-background: linear-gradient(
90deg, #79faf7, #2e6a8d); */
background: linear-gradient(90deg, #BCEBEA, #C4DCEA);
-webkit-background: linear-gradient(90deg, #BCEBEA, #C4DCEA);
}


.payment-table ul.pagination>li{
width: 36px;
height: 36px;
background: #dddbdb;
box-shadow: 0px 2px 4px #5552;
border-radius: 4px;
display: flex;
justify-content: center;
align-items: center;
margin-right: 4px;
margin-left: 4px;
}


.payment-table ul.pagination>li.active{
background: #117d9d;
}
.payment-table ul.pagination>li.active a{color: #fff;}

.payment-table ul.pagination>li:first-child,.payment-table ul.pagination>li:last-child{
display:none;
}

.btn-primary:disabled{
background: #adc9c9 !important;
}

.native-platform{
margin-top: 40px;
}
.native-platform .navbar-collapse{
height: calc(100vh - 90px);
top: 90px;
}

.native-platform .dairy-container-header {
  /* top: 24px; */
  top: 40px;
}
.native-platform  .sidenav {
top: 33px;
}
.native-platform  .top_header ,
.native-platform .top-header-setting-title,
.native-platform .body_bg_lightGray .top_header{
top: 39px !important;
}
.native-platform .new-setting-menu-bar .setting-menu-bar {
top: 102px;
}
.analytics-tabsHead--wrap .toptab {
  margin-right: 26px;
}

.cap-text{
  text-transform: capitalize;
}

.client-booking-back-arrow{
  width: 42px;
  height: 42px;
  cursor: pointer;
  transform: rotate(90deg);
}

.arrow_li{
  margin-right: 10px !important;
}

.react-datepicker__input-container>h3{
  cursor: pointer;
}

._calender_down_arrow{
  width: 48px;
}
@media (min-width: 768px){
  .booking_card{
    min-height: 19px;
  }
  .booking_card.bkng-crd-dary-new h5 ,
  .booking_card.bkng-crd-dary-new .cm_sdule_time{
    font-size: 10px !important;
}
.booking_card.bkng-crd-dary-new .cm_sdule_time{
  font-size: 8px;
}
  .booking_card.cm_wrap_text h5{
    width: calc(100% - 20px) !important;
  }
}